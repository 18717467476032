import _ from "lodash";
import React from "react";
import * as H from "../../../hooks";
import { Form } from "../../../Common";
import { TC } from "../../../Constants";

const RenovationComponent = ({ value, setter, ...props }) => {
    const lg = H.useLanguage();

    //#region Value
    const validValue = React.useMemo(() => Array.isArray(value) && value.length > 0 ? value : [{}], [value]);

    const typeValues = React.useMemo(() => [
        { label: "Toiture", value: "roof" },
        { label: "Mur", value: "wall" },
        { label: "Vitrage", value: "window" },
        { label: "Eclairage", value: "light" },
        { label: "Superficie", value: "area" },
        { label: "HVAC / GTB", value: "hvac" },
    ], []);
    //#endregion

    //#region Callbacks
    const onChangeProp = React.useCallback((prop, val, index) => setter?.(validValue.map((v, i) => {
        if (i !== index) return v;
        let newVal = _.cloneDeep(v);
        newVal[prop] = val;
        return newVal;
    })), [validValue, setter]);

    const onAddValue = React.useCallback(() => setter?.(validValue.concat({})), [validValue, setter]);
    const onRemove = React.useCallback(index => setter?.(validValue.filter((x, i) => i !== index)), [validValue, setter]);
    //#endregion

    //#region Display
    const table = React.useMemo(() => validValue.map(({ date, type, description }, i) => <div key={i} className="d-flex p-1 border border-1">
        <div className='flex-grow-1 p-1 border-end border-1 d-flex align-items-center' style={{ width: "30%" }}>
            <Form.NumField
                customClass="w-100"
                noBottomMargin
                value={date}
                onChange={date => onChangeProp("date", date, i)}
            />
        </div>
        <div className='flex-grow-1 p-1 border-end border-1 d-flex align-items-center' style={{ width: "30%" }}>
            <Form.Select customClass="w-100" className options={typeValues} hideLabel value={type} onChange={val => onChangeProp("type", val, i)} />
        </div>
        <div className='flex-grow-1 p-1 border-end border-1' style={{ width: "30%" }}>
            <Form.TextField customClass="h-100" isTextArea hideLabel autoExpand value={description} onChange={val => onChangeProp("description", val, i)} />
        </div>
        <div className='flex-shrink-1 p-2 d-flex align-items-center'>
            <button onClick={() => onRemove(i)} className='btn btn-danger stop-hiding'>
                <i className='fa fa-times'></i>
            </button>
        </div>
    </div>), [validValue, typeValues, onChangeProp, onRemove]);

    const addValButton = React.useMemo(() => <div className="pt-2 p-1 border-bottom border-end border-start border-1">
        <button className="btn btn-primary stop-hiding" onClick={onAddValue}>
            <i className="fa fa-plus mr-2"></i>{lg.getStaticText(TC.ADD)}
        </button>
    </div>, [lg, onAddValue]);
    //#endregion

    return <div>
        <label className="form-label">Date de rénovation(s)</label>
        <div className="p-2 w-100">
            {table}
            {addValButton}
        </div>
    </div>
}

export default RenovationComponent;