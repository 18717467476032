import React from 'react';
import classNames from 'classnames';
import CrumbsMenu from './CrumbsMenu';
import { Logo } from '../../../Common';
import { useAuth } from '../../../hooks';
import { useDispatch } from "react-redux";
import { Navbar, Nav } from 'react-bootstrap';
import { RESOURCE, T } from "../../../Constants";
import AppContext from "../../../Context/Context";
import NavbarTopDropDownMenus from "./NavbarTopDropDownMenus";
import TopNavRightSideNavItem from "./TopNavRightSideNavItem";
import { ADD_PORTAL, REMOVE_PORTAL } from "../../../reducers";
import { navbarBreakPoint, topNavbarBreakpoint } from '../../../config';

type NavBarTopProps = {
  loading?: boolean;
  routes: T.RoutesCollection[];
}

const NavbarTop: React.FC<NavBarTopProps> = ({ routes, loading }) => {
  const dispatch = useDispatch();
  const [{ isMobile }] = useAuth();
  const navBarTopContainerRef = React.useRef<HTMLDivElement | null>(null);
  const { config: { showBurgerMenu, navbarPosition, navbarCollapsed }, setConfig } = React.useContext(AppContext);

  const handleBurgerMenu = React.useCallback(() => {
    if (navbarPosition === 'top') setConfig('navbarCollapsed', !navbarCollapsed);
    else if (["vertical", "combo"].includes(navbarPosition)) setConfig('showBurgerMenu', !showBurgerMenu);
  }, [navbarCollapsed, navbarPosition, setConfig, showBurgerMenu]);

  //#region NavBar Props
  const isNavBarExpanded = React.useMemo(() => ["top", "combo"].includes(navbarPosition) ? topNavbarBreakpoint : true, [navbarPosition]);

  const toggleClass = React.useMemo(() => classNames(
    'toggle-icon-wrapper me-md-3 me-2',
    {
      'd-lg-none': navbarPosition === 'top',
      [`d-${navbarBreakPoint}-none`]: navbarPosition === 'vertical' || navbarPosition === 'combo'
    }
  ), [navbarPosition]);
  //#endregion

  //#region NavBar
  const nav = React.useMemo(() => {
    if (navbarPosition === 'top' || navbarPosition === 'combo') return <Navbar.Collapse in={navbarCollapsed} className="scrollbar pb-3 pb-lg-0">
      {loading
        ? <i className='fa fa-spin fa-spinner'></i>
        : <Nav navbar>
          <NavbarTopDropDownMenus routes={routes} />
        </Nav>}
    </Navbar.Collapse >

    return <CrumbsMenu />
  }, [navbarCollapsed, navbarPosition, routes, loading]);
  //#endregion

  const validLogoSrc = React.useMemo(() => RESOURCE.RESOURCE_URL(RESOURCE.AISET_LOGO), []);

  //#region Portal
  React.useEffect(() => {
    dispatch(ADD_PORTAL("topNavBarContainer", navBarTopContainerRef));
    return () => { dispatch(REMOVE_PORTAL("topNavBarContainer")) };
  }, [dispatch]);
  //#endregion

  return <div ref={navBarTopContainerRef}>
    <Navbar className="navbar-glass fs--1 navbar-top sticky-kit" style={{ zIndex: 999 }} expand={isNavBarExpanded} >
      <Navbar.Toggle className={toggleClass} as="div" >

        <button style={{ minWidth: "0" }} className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center" onClick={handleBurgerMenu} id="burgerMenu" >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" source={validLogoSrc} width={100} id="topLogo" />


      {!isMobile && nav}
      <TopNavRightSideNavItem />

    </Navbar>
    {isMobile && nav}
  </div>
};

export default NavbarTop;
