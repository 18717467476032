import React from "react";
import { Flex } from "../Layout";
import * as H from "../../hooks";
import { T, TB } from "../../Constants";

export type CenterMessageProps = React.HTMLAttributes<HTMLDivElement> & {
    /** The content of then message */
    children?: string | T.AllowArray<React.ReactElement>;
    /** Size of the font */
    size?: "sm" | "md" | "lg";
    /** Font displayed in italics */
    italics?: boolean;
    /** Font displayed in bold */
    bold?: boolean;
    /** Create a border around the message */
    bordered?: boolean;
}

const CenterMessage: React.FC<CenterMessageProps> = ({ children, size, italics, bold, ...props }) => {
    const lg = H.useLanguage();

    const className = React.useMemo(() => {
        let classNames = ["h-100 w-100"];

        if (props.bordered) classNames.push("border rounded")

        if (size === "lg") classNames.push("fs-200");
        else if (size === "md") classNames.push("fs-150");
        if (italics) classNames.push("fst-italic");
        if (bold) classNames.push("fw-bold");
        if (TB.validString(props.className)) classNames.push(props.className);
        return classNames.join(" ");
    }, [size, bold, italics, props.className, props.bordered]);

    return <Flex {...props} className={className} alignItems="center" justifyContent="center">
        {typeof children === "string"
            ? lg.getStaticText(children)
            : children}
    </Flex>;
}

export default CenterMessage;