import React from 'react';
import { TB } from '../../Constants';
import * as echarts from 'echarts/core';
import { useLanguage } from '../../hooks';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, BarChart } from 'echarts/charts';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import EChartsReactCore from 'echarts-for-react/lib/core';
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components';
echarts.use([TitleComponent, TooltipComponent, GridComponent, PieChart, BarChart, CanvasRenderer, LegendComponent]);

//#region Types
type DataItem = {
    name?: string;
    color?: string;
    values: { value: number, category: string }[];
}

type ToolTipParams = {
    bgColor?: string;
    textColor?: string;
    borderWidth?: number;
    borderColor?: string;
    transitionDuration?: number;
    valueFormatter?: ((value: number) => string);
}

type LegendParams = {
    color?: string;
    orientation?: "horizontal" | "vertical",
    formatter?: string | ((name: string) => string);
}

type DoughnutChartProps = {
    title?: string;
    height?: string;
    data: DataItem[];
    centerText?: string;
    categories: string[];
    legend?: boolean | LegendParams;
    tooltip?: boolean | ToolTipParams;
}
//#endregion

//#region Constants
const defaultLegend: LegendParams = {
    color: "#000000",
    orientation: "vertical",
}

const defaultTooltip: ToolTipParams = {
    borderWidth: 1,
    bgColor: "#FFFFFF",
    transitionDuration: 0,
}
//#endregion

const DoughnutChart: React.FC<DoughnutChartProps> = ({ legend, title, data, categories, centerText, tooltip, height, ...props }) => {
    const { getStaticText } = useLanguage();
    const myChart = React.useRef<EChartsReactCore | null>(null);

    //#region Legend
    const legendObj = React.useMemo(() => {
        if (!legend) return;
        let params = typeof legend === "boolean" ? defaultLegend : { ...defaultLegend, ...TB.getObject(legend) };
        return {
            left: "left",
            orient: params.orientation,
            formatter: params.formatter,
            textStyle: {
                color: TB.getColor(params.color) || "#000000",
            }
        }
    }, [legend]);
    //#endregion

    //#region Tooltip
    const toolTipObj = React.useMemo(() => {
        if (!tooltip) return { show: false };
        let params = typeof tooltip === "boolean" ? defaultTooltip : { ...defaultTooltip, ...TB.getObject(tooltip) };
        return {
            trigger: 'item',
            padding: [7, 10],
            axisPointer: { type: 'none' },
            borderColor: params.borderColor,
            backgroundColor: params.bgColor,
            borderWidth: params.borderWidth,
            valueFormatter: params.valueFormatter,
            textStyle: { color: params.textColor },
            transitionDuration: params.transitionDuration,
        }
    }, [tooltip]);
    //#endregion

    //#region Get Values ordered
    const vCategories = React.useMemo(() => TB.getArray(categories).filter(TB.validString), [categories]);

    const orderValues = React.useCallback((data: DataItem) => {
        let values = TB.getArray(data.values).filter(TB.validObject);
        return vCategories.map(c => values.filter(v => v.category === c)[0]?.value || 0);
    }, [vCategories]);
    //#endregion

    const getOption = React.useCallback(() => ({
        legend: legendObj,
        tooltip: toolTipObj,
        yAxis: [{ type: "value" }],
        xAxis: [{ type: "category", data: vCategories, axisTick: { show: false } }],
        series: TB.getArray(data).map(d => ({
            barGap: 0,
            type: "bar",
            name: d.name,
            color: d.color,
            data: orderValues(d),
            emphasis: { focus: "series" },
        })),
    }), [legendObj, toolTipObj, vCategories, data, orderValues]);

    return <>
        {TB.validString(title) && <div className='w-100 text-center'><span className='h5'>{getStaticText(title)}</span></div>}
        <ReactEChartsCore ref={myChart} echarts={echarts} option={getOption()} style={{ height: TB.getString(height, '20rem') }} />
    </>
}

export default DoughnutChart;