import React from 'react';
import { Flex } from '../Layout';
import RadioItem from './RadioItem';
import { SoftBadge } from '../Item';
import { AppContext } from '../../Context';
import { RESOURCE as R } from '../../Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Offcanvas, Button, ButtonGroup, Form } from 'react-bootstrap';

const SettingsPanel = () => {
    const { config: { isFluid, isDark, navbarPosition, navbarStyle, showSettingPanel }, setConfig, configDispatch } = React.useContext(AppContext);

    const src = React.useMemo(() => ({
        cardImg: R.RESOURCE_URL(R.SP_CARD),
        arrowsH: R.RESOURCE_URL(R.SP_ARROWS_H),
        vibrantImg: R.RESOURCE_URL(R.SP_VIBRANT),
        paragraph: R.RESOURCE_URL(R.SP_PARAGRAPH),
        invertedImg: R.RESOURCE_URL(R.SP_INVERTED),
        transparentImg: R.RESOURCE_URL(R.SP_DEFAULT),
        darkModeImg: R.RESOURCE_URL(R.SP_FALCON_DARK),
        defaultModeImg: R.RESOURCE_URL(R.SP_FALCON_DEFAULT),
    }), []);

    const navbars = React.useMemo(() => [
        { name: 'transparent', image: src.transparentImg },
        { name: 'inverted', image: src.invertedImg },
        { name: 'card', image: src.cardImg },
        { name: 'vibrant', image: src.vibrantImg }
    ], [src]);

    return <Offcanvas
        placement="end"
        className="border-0"
        show={showSettingPanel}
        style={{ maxWidth: '22rem' }}
        onHide={() => setConfig('showSettingPanel', false)}
    >
        <Offcanvas.Header closeButton closeVariant="white" className="bg-shape settings-panel-header" >
            <Offcanvas.Title as="div" className="py-1 z-index-1 light">
                <div className="d-flex justify-content-between align-items-center mb-1">
                    <h5 className="text-white">
                        <FontAwesomeIcon icon="palette" className="me-2 fs-0" />
                        Settings
                    </h5>
                    <Button variant="primary" size="sm" className="rounded-pill mt-0 mb-0" style={{ fontSize: '12px' }} onClick={() => configDispatch({ type: 'RESET' })} >
                        <FontAwesomeIcon icon="redo-alt" style={{ fontSize: '10px' }} className="me-1" />
                        Reset
                    </Button>
                </div>
                <p className="mb-0 fs--1 text-white opacity-75">
                    Set your own customized style
                </p>
            </Offcanvas.Title>
        </Offcanvas.Header>

        {/* <ScrollBarCustom> */}
        <Offcanvas.Body className="scrollbar">
            <h5 className="fs-0">Color Scheme</h5>
            <p className="fs--1">Choose the perfect color mode for your app.</p>

            <ButtonGroup className="btn-group-navbar-style">
                <RadioItem
                    label="light"
                    active={!isDark}
                    name="theme-mode"
                    image={src.defaultModeImg}
                    onChange={({ target }) => setConfig('isDark', !target.checked)}
                />
                <RadioItem
                    label="dark"
                    active={isDark}
                    name="theme-mode"
                    image={src.darkModeImg}
                    onChange={({ target }) => setConfig('isDark', target.checked)}
                />
            </ButtonGroup>

            <hr />

            <Flex justifyContent="between">
                <img src={src.arrowsH} alt="" width={20} className="me-2 h-100" />
                <div className="flex-1">
                    <h5 className="fs-0">Fluid Layout</h5>
                    <p className="fs--1 mb-0">Toggle container layout system</p>
                </div>
                <Form.Check
                    type="switch"
                    checked={isFluid}
                    id="fluid-mode-switch"
                    onChange={({ target }) => setConfig('isFluid', target.checked)}
                />
            </Flex>
            <hr />

            <Flex>
                <img src={src.paragraph} alt="" width={20} className="me-2 h-100" />
                <div>
                    <Flex alignItems="center" className="fs-0">
                        Navigation Position
                        <SoftBadge bg="success" pill className="fs--2 ms-2">
                            New
                        </SoftBadge>
                    </Flex>
                    <p className="fs--1 mb-2">
                        Select a suitable navigation system for your web application
                    </p>
                    <Form.Check
                        inline
                        type="radio"
                        label="Vertical"
                        id="verticalNav-radio"
                        name="NavBarPositionRadioButton"
                        checked={navbarPosition === 'vertical'}
                        onChange={() => setConfig('navbarPosition', 'vertical')}
                    />
                    <Form.Check
                        inline
                        label="Top"
                        type="radio"
                        id="topNav-radio"
                        name="NavBarPositionRadioButton"
                        checked={navbarPosition === 'top'}
                        onChange={() => setConfig('navbarPosition', 'top')}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        label="Combo"
                        id="combo-radio"
                        name="NavBarPositionRadioButton"
                        checked={navbarPosition === 'combo'}
                        onChange={() => setConfig('navbarPosition', 'combo')}
                    />
                </div>
            </Flex>

            <hr />
            <h5 className="fs-0 d-flex align-items-center">
                Vertical Navbar Style{' '}
            </h5>
            <p className="fs--1">Switch between styles for your vertical navbar</p>
            <ButtonGroup className="btn-group-navbar-style">
                {navbars.slice(0, 2).map(item => (
                    <RadioItem
                        key={item.name}
                        label={item.name}
                        image={item.image}
                        name="navbar-style"
                        active={navbarStyle === item.name}
                        onChange={() => setConfig('navbarStyle', item.name)}
                    />
                ))}
            </ButtonGroup>
            <ButtonGroup className="btn-group-navbar-style">
                {navbars.slice(2, 4).map(item => (
                    <RadioItem
                        key={item.name}
                        label={item.name}
                        image={item.image}
                        name="navbar-style"
                        active={navbarStyle === item.name}
                        onChange={() => setConfig('navbarStyle', item.name)}
                    />
                ))}
            </ButtonGroup>
        </Offcanvas.Body>
    </Offcanvas>
};

export default SettingsPanel;
