import { TB, T } from "../../../../Constants";

const toPdf: T.DiaPdfFn = (doc, position, { data, ...context }) => {
    let contentSize = { height: 100, width: 150 };
    if (!data.color) data.color = '#FFFFFF';

    contentSize.width -= 2;
    contentSize.height -= 2;

    doc.save();

    /* item border */
    doc.rect(position.x, position.y, position.width, position.height)
        .lineWidth(1)
        .fillOpacity(1)
        .fillAndStroke(data.color, '#000');

    /* photo */
    try {
        if (TB.mongoIdValidator(context.icon)) doc.image(
            TB.iconIdToUrl(context.icon),
            position.x + contentSize.width / 4,
            position.y + contentSize.height / 4,
            { width: contentSize.width / 2, height: (contentSize.height / 4) * 2.5 }
        );
    }
    //Can't open jpeg apparently
    catch (err) {
        console.error(err);
    }

    /* title */
    doc.fillColor('#000')
        .font('Helvetica', 12)
        .text(context.title, position.x, position.y + 5, {
            width: contentSize.width,
            height: contentSize.height,
            align: 'center'
        });

    doc.restore();
}

export default toPdf;