import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import FlatLabelInput from "./FlatLabelInput";
import { importNewImg } from "../../Constants/FileToolBox";
import { ImgSelect, Form as CForm, Form } from "../../Common";
import { TC, ICONS, RESOURCE, T, TB, FP } from "../../Constants";

//#region Types
export type GenTabApi = {
    changesMade?: () => boolean;
    saveData?: () => null | { data: T.BuildingData, toDelete?: string, toAdd: T.File | null };
}

type GenTabProps = {
    data?: T.BuildingData;
    icons: T.ReducedFileOptions[];
    api?: React.RefObject<GenTabApi | null>;
    countries: { label: string, value: string }[];
}

type PropsItem = {
    prop: string;
    label: string;
    multiple?: boolean;
    type: "date" | "number";
    formatValue?: (value: any) => string;
    onChange: (value: string | undefined, params: PropsItem) => void;
}

const isPropItem = (obj: any): obj is PropsItem => TB.validObject(obj) && TB.validString(obj.prop);
//#endregion

//#region Constants
const TEXT_CODES = [
    TC.GLOBAL_NAME, TC.GLOBAL_AFFECTATION, TC.STREET, TC.NUMBER, TC.ZIP, TC.TOWN, TC.GLOBAL_LABEL_BUILD, TC.GLOBAL_LOCATION,
    TC.BUILD_EDIT_ICON, TC.BUILD_EDIT_COLOR, TC.BUILD_EDIT_VISUAL, TC.GLOBAL_ERROR_OCCURRED, TC.BUILD_EDIT_PICTURE, TC.BUILD_CONST_DATE,
    TC.BUILD_REPAIR_DATE, TC.GLOBAL_PROPERTIES_LABEL, TC.DESCRIPTION, TC.BUILD_REPAIR_DATE, TC.GLOBAL_REQUIRED_FIELD, TC.GLOBAL_SURFACE_LA,
    TC.GLOBAL_SURFACE_TA, TC.GLOBAL_SURFACE_CA, TC.GLOBAL_SURFACE_AA, TC.GLOBAL_SURFACE_PA, TC.GLOBAL_SURFACE_IFA, TC.GLOBAL_SURFACE_NRA,
    TC.GLOBAL_SURFACE_NFA, TC.GLOBAL_SURFACE_UTA, TC.GLOBAL_SURFACE_UCA, TC.GLOBAL_SURFACE_UAA, TC.GLOBAL_SURFACE_UPA, TC.GLOBAL_SURFACE_BHS,
    TC.GLOBAL_SURFACE_RENT, TC.GLOBAL_SURFACE_HEAT, TC.GLOBAL_SURFACE_RENTED, TC.GLOBAL_SURFACE_GFA, TC.BUILD_BUY_DATE, TC.BUILD_PERMIT_DATE,
    TC.BUILD_PERMIT_END_DATE, TC.BUILD_OFF_FIELD_LEVEL, TC.BUILD_ON_FIELD_LEVEL, TC.BUILD_DALLE_HEIGHT, TC.BUILD_ROOD_HEIGHT, TC.BUILD_FAKE_FLOOR_HEIGHT,
    TC.GLOBAL_HEIGHT, TC.BUILD_FAKE_ROOF_HEIGHT, TC.BUILD_NORM_SURFACE, TC.BUILD_LEVELS, TC.BUILD_SURFACE_OTHERS, TC.BUILD_TIME_MARKS,
    TC.GLOBAL_VALUE_HIGHER_THAN, TC.GLOBAL_VAL_LOWER_THAN, TC.GLOBAL_VALUE_INVALID, TC.GLOBAL_ADDRESS
];
//#endregion

const GenTab: React.FC<GenTabProps> = ({ icons, api, data, countries, ...props }) => {
    const failedLoadImg = React.useRef(false);
    const ogFileArray = React.useRef<T.File[] | undefined>(data?.picture);

    const [auth] = H.useAuth();
    const [roots] = H.useRoots();
    const [forms] = H.useFormIds();
    const lg = H.useLanguage(TEXT_CODES);
    const [sites, set_sites] = React.useState<string[]>([]);
    const [tags, set_tags, tags_status] = H.useAsyncState<T.Option[]>([]);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [affectations, setAffectations, affectStatus] = H.useAsyncState<T.Option[]>([]);
    const [buildData, setBuildData] = React.useState<T.BuildingData>(TB.validObject(data) ? data : { name: "", color: "#f79646" });

    const renderRegularField = React.useCallback(({ prop, label, ...props }) => {
        let hasError = TB.validString(errors[prop]);

        return <FlatLabelInput className="mb-2" labelWidth={5} label={lg.getStaticText(label)}>
            <BS.InputGroup>
                <BS.Form.Control value={buildData[prop] ?? ""} {..._.omit(props, 'formatValue')} isInvalid={hasError} onWheel={e => e.currentTarget.blur()} />
                {TB.validString(props.unit) && <BS.InputGroup.Text>{props.unit}</BS.InputGroup.Text>}
            </BS.InputGroup>
            {hasError && <span className="invalid-feedback shown">{lg.getStaticText(errors?.[prop])}</span>}
        </FlatLabelInput>
    }, [errors, buildData, lg]);

    //#region Load Options
    React.useEffect(() => {
        let isSubscribed = true;
        S.getEmplacementResource({ type: "emplacement_affectation" })
            .then(({ data }) => isSubscribed && setAffectations(data as T.Option[], "done"))
            .catch(() => isSubscribed && setAffectations([], "error"));
        return () => { isSubscribed = false };
    }, [setAffectations]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getContextSites(roots).then(sites_infos => {
            let sites = sites_infos.data.map(s => s.value);
            set_sites(sites);
            S.getNoteTags({ type: "building", context: { roots: sites } })
                .then(({ data }) => isSubscribed && set_tags(data, "done"))
                .catch(() => isSubscribed && set_tags([], "error"));
        }).catch(() => isSubscribed && set_tags([], "error"));
        return () => { isSubscribed = false };
    }, [set_tags]);
    //#endregion

    //#region Building
    const hasPicture = React.useMemo(() => Array.isArray(buildData.picture) && buildData.picture.length > 0, [buildData.picture]);

    const onErrorImgLoad = React.useCallback((event) => {
        if (TB.validObject(event.target) && !failedLoadImg.current) {
            event.target.src = RESOURCE.RESOURCE_URL(RESOURCE.NO_IMG_FOUND);
            failedLoadImg.current = true;
        }
    }, []);

    const buildPropArray = React.useMemo(() => [
        { label: TC.GLOBAL_NAME, prop: "name" },
    ], []);

    const on_create_tag = React.useCallback((text: string) => {
        let submission = { users: [auth.userId], sites, type: "building", name: text } as T.NoteTag;
        S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
            let new_value = data.submissions[0]._id;
            if (new_value) {
                setBuildData(p => ({ ...p, affect_detail: new_value }));
                set_tags(p => p.concat({ label: text, value: new_value }));
            }
        }).catch(M.Alerts.updateError);
    }, [auth.userId, sites, set_tags]);

    const buildingContent = React.useMemo(() => <BS.Col md={7}>
        {buildPropArray.map(({ label, prop }) => <React.Fragment key={prop}>
            {renderRegularField({ label, prop, onChange: e => setBuildData(p => ({ ...p, [prop]: e.target.value })) })}
        </React.Fragment>)}
        <CForm.Select
            labelPosition="left"
            options={affectations}
            value={buildData.affectation}
            loading={affectStatus === "load"}
            error={{ code: errors?.affectation }}
            label={TC.GLOBAL_AFFECTATION_PRINCIPAL}
            onChange={a => setBuildData(p => ({ ...p, affectation: a }))}
        />
        <CForm.Select
            addResource
            options={tags}
            labelPosition="left"
            value={buildData.affect_detail}
            loading={tags_status === "load"}
            label={TC.GLOBAL_AFFECTATION_DETAIL}
            error={{ code: errors?.affect_detail }}
            typeahead={{ onAddOption: on_create_tag }}
            onChange={a => setBuildData(p => ({ ...p, affect_detail: a }))}
        />
        <CForm.TextField
            labelPosition="left"
            value={buildData.link}
            error={{ code: errors?.link }}
            onChange={link => setBuildData(p => ({ ...p, link }))}
            label={lg.getTextObj(forms[FP.BUILDING_FORM], "link", "link")}
        />
        <CForm.TextField
            textArea
            rows={2}
            min_height="50px"
            max_height="200px"
            labelPosition="left"
            value={buildData.description}
            error={{ code: errors?.description }}
            onChange={description => setBuildData(p => ({ ...p, description }))}
            label={lg.getTextObj(forms[FP.BUILDING_FORM], "description", "description")}
        />
    </BS.Col>, [buildPropArray, affectations, affectStatus, buildData, errors, forms, lg, tags, tags_status, on_create_tag, renderRegularField]);

    const removePicture = React.useCallback(() => setBuildData(p => ({ ...p, picture: [] })), []);

    const updatePicture = React.useCallback(() => {
        importNewImg({ title: lg.getStaticText(TC.BUILD_EDIT_PICTURE), fileMaxSize: "10MB" })
            .then(files => {
                if (files !== null) setBuildData(p => ({ ...p, picture: files }))
            })
            .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_OCCURRED }));
    }, [lg]);

    React.useEffect(() => {
        failedLoadImg.current = false
    }, [buildData.picture]);

    const imgContent = React.useMemo(() => <BS.Col md={5}>
        <BS.Figure className="w-100 h-100 border rounded p-1 bg-white position-relative" style={{ minHeight: "200px" }}>
            <BS.Figure.Image
                width="100%"
                height="100%"
                onError={onErrorImgLoad}
                src={buildData.picture?.[0]?.url ?? ""}
                style={{ maxHeight: "250px", objectFit: "contain" }}
            />
            <BS.Figure.Caption className="text-center">
                {buildData.picture?.[0]?.originalName ?? ""}
            </BS.Figure.Caption>
            <BS.ButtonGroup className="position-absolute top-0 end-0 p-1 m-1">
                <BS.Button onClick={updatePicture} size="sm">
                    <i className="fa fa-pencil-alt"></i>
                </BS.Button>
                <BS.Button disabled={!hasPicture} onClick={removePicture} size="sm" variant="danger">
                    <i className="fa fa-times"></i>
                </BS.Button>
            </BS.ButtonGroup>
        </BS.Figure>
    </BS.Col>, [buildData.picture, hasPicture, onErrorImgLoad, removePicture, updatePicture]);
    //#endregion

    //#region Location
    const locPropArray = React.useMemo(() => [
        { label: TC.STREET, prop: "street" },
        { label: TC.NUMBER, prop: "number" },
        { label: TC.ZIP, prop: "zipcode" },
        { label: TC.TOWN, prop: "town" },
    ], []);

    const locContent = React.useMemo(() => <>
        {locPropArray.map(({ label, prop }) => <BS.Col md={6} key={prop}>
            {renderRegularField({ label, prop, onChange: e => setBuildData(p => ({ ...p, [prop]: e.target.value })) })}
        </BS.Col>)}
    </>, [locPropArray, renderRegularField]);
    //#endregion

    //#region Properties
    const onChangeProp = React.useCallback((strValue, params: PropsItem) => {
        let { type, prop } = params, newValue;

        if (type === "date") {
            let date = TB.getDate(strValue);
            newValue = date === null ? "" : date.toISOString();
        }
        else if (type === "number") {
            let num = TB.getNumber(strValue);
            newValue = isNaN(num) ? "" : num;
        }
        else newValue = strValue;

        setBuildData(p => ({ ...p, [prop]: newValue }));
    }, []);

    const repairListContent = React.useMemo(() => {
        let renovationsArray = TB.getArray(buildData.renovations)
            .filter(TB.isRenovationItem).concat({ date: NaN, description: "" });

        const onChangeDate = (dateStr: string, index: number) => {
            let date = parseInt(dateStr);
            let newRenovationArray = renovationsArray.map((r, i) => i !== index ? r : { ...r, date }).filter(TB.isRenovationItem);
            setBuildData(p => ({ ...p, renovations: newRenovationArray }));
        }

        const addDescription = (index: number) => {
            M.askLargePrompt({ title: lg.getStaticText(TC.DESCRIPTION), required: true, defaultVal: renovationsArray[index]?.description })
                .then(text => {
                    if (TB.validString(text)) {
                        let newRenovationArray = renovationsArray.map((r, i) => i !== index ? r : { ...r, description: text }).filter(TB.isRenovationItem);
                        setBuildData(p => ({ ...p, renovations: newRenovationArray }));
                    }
                });
        }

        const removeItem = (index: number) => {
            M.askConfirm({}).then(confirm => {
                if (confirm) {
                    let newRenovationArray = renovationsArray.filter((r, i) => i !== index).filter(TB.isRenovationItem);
                    setBuildData(p => ({ ...p, renovations: newRenovationArray }));
                }
            });
        }

        return <FlatLabelInput className="mb-2" labelWidth={5} label={lg.getStaticText(TC.BUILD_REPAIR_DATE)}>
            <BS.Stack>
                {renovationsArray.map((r, i) => <BS.InputGroup className="mb-1" key={i}>
                    <BS.Form.Control
                        type="number"
                        value={r?.date}
                        onChange={e => onChangeDate(e.target.value, i)}
                    />
                    <BS.Button size="sm" title={lg.getStaticText(TC.DESCRIPTION)} onClick={() => addDescription(i)}>
                        <i className="fa fa-sticky-note"></i>
                    </BS.Button>
                    <BS.Button size="sm" variant="danger" disabled={!TB.isRenovationItem(r)} onClick={() => removeItem(i)}>
                        <i className="fa fa-times"></i>
                    </BS.Button>
                </BS.InputGroup>)}
            </BS.Stack>
        </FlatLabelInput>;
    }, [lg, buildData]);

    const groupList = React.useMemo(() => [
        {
            label: TC.BUILD_NORM_SURFACE,
            props: [
                { label: TC.GLOBAL_SURFACE_LA, prop: "surfaceLA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_GFA, prop: "area", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_IFA, prop: "surfaceIFA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_NFA, prop: "surfaceNFA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_NRA, prop: "surfaceNRA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_TA, prop: "surfaceTA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_UTA, prop: "surfaceUTA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_CA, prop: "surfaceCA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_UCA, prop: "surfaceUCA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_AA, prop: "surfaceAA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_UAA, prop: "surfaceUAA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_PA, prop: "surfacePA", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_UPA, prop: "surfaceUPA", onChange: onChangeProp, type: "number", unit: "m²" },
            ]
        },
        {
            label: TC.BUILD_LEVELS,
            props: [
                { label: TC.BUILD_OFF_FIELD_LEVEL, prop: "offFieldLevel", onChange: onChangeProp, type: "number" },
                { label: TC.BUILD_ON_FIELD_LEVEL, prop: "onFieldLevel", onChange: onChangeProp, type: "number" },

            ]
        },
        {
            label: TC.BUILD_SURFACE_OTHERS,
            props: [
                { label: TC.BUILD_PROTECTED_VOLUME, prop: "protectedVolume", onChange: onChangeProp, type: "number", unit: "m³" },
                { label: TC.GLOBAL_SURFACE_BHS, prop: "surfaceBHS", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_RENT, prop: "surfaceRENT", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_RENTED, prop: "surfaceRENTED", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_HEAT, prop: "surfaceHEAT", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_SURFACE_CLIMATISED, prop: "surfaceCold", onChange: onChangeProp, type: "number", unit: "m²" },
                { label: TC.GLOBAL_HEIGHT, prop: "height", onChange: onChangeProp, type: "number", unit: "m" },
                { label: TC.BUILD_DALLE_HEIGHT, prop: "dalleHeight", onChange: onChangeProp, type: "number", unit: "m" },
                { label: TC.BUILD_ROOD_HEIGHT, prop: "roofHeight", onChange: onChangeProp, type: "number", unit: "m" },
                { label: TC.BUILD_FAKE_ROOF_HEIGHT, prop: "fakeRoofHeight", onChange: onChangeProp, type: "number", unit: "m" },
                { label: TC.BUILD_FAKE_FLOOR_HEIGHT, prop: "fakeFloorHeight", onChange: onChangeProp, type: "number", unit: "m" },

            ]
        },
        {
            label: TC.BUILD_TIME_MARKS,
            props: [
                { label: TC.BUILD_CONST_DATE, type: "number", prop: "ENT_TECH_BUILD_YEAR", onChange: onChangeProp },
                { label: TC.BUILD_BUY_DATE, type: "date", prop: "buyDate", onChange: onChangeProp, formatValue: val => TB.formatDate(val, "YYYY-MM-DD") },
                repairListContent,
            ]
        }
    ], [onChangeProp, repairListContent]);

    const propertyContent = React.useMemo(() => <>
        {groupList.map(({ label, props }, i) => <BS.Col key={i} md={12} className="mb-3">
            <BS.Row>
                <BS.Col md={12}>
                    <span className="h4">{lg.getStaticText(label)}</span>
                </BS.Col>
            </BS.Row>
            <BS.Row>
                {props.map((p, i) => <BS.Col key={i} md={6}>
                    {isPropItem(p) ? renderRegularField({
                        ...p,
                        onChange: e => p.onChange(e.target.value, p),
                        value: typeof p.formatValue === "function" ? p.formatValue(buildData[p.prop] ?? "") : buildData[p.prop] ?? ""
                    }) : p}
                </BS.Col>)}
            </BS.Row>
        </BS.Col>)}
    </>, [groupList, buildData, renderRegularField, lg]);
    //#endregion

    //#region Visual
    const iconOptions = React.useMemo(() => (Array.isArray(icons) ? icons : []).map(({ label, url, ...r }) => ({ title: label, src: url, ...r })), [icons]);
    const defaultImg = React.useMemo(() => iconOptions.filter(({ title }) => title === ICONS.DEFAULT_ICON_BUILD)[0]?.value, [iconOptions]);

    const iconSelect = React.useMemo(() => <BS.Col md={2}>
        <BS.Form.Label>{lg.getStaticText(TC.BUILD_EDIT_ICON)}</BS.Form.Label>
        <ImgSelect
            options={iconOptions}
            defaultValue={defaultImg}
            addNewImage={() => alert("todo")}
            selected={buildData.selectIcon ?? ""}
            onSelect={a => setBuildData(p => ({ ...p, selectIcon: a }))}
        />
    </BS.Col>, [lg, iconOptions, defaultImg, buildData.selectIcon]);

    const colorSelect = React.useMemo(() => <BS.Col md={2}>
        <Form.Color
            no_auto_focus
            noBottomMargin
            value={buildData.color}
            label={TC.BUILD_EDIT_COLOR}
            onChange={c => setBuildData(p => ({ ...p, color: c }))}
        />
    </BS.Col>, [buildData.color]);
    //#endregion

    //#region Rows
    const rowsList = React.useMemo(() => [
        { label: TC.GLOBAL_LABEL_BUILD, items: [imgContent, buildingContent] },
        { label: TC.GLOBAL_ADDRESS, items: [locContent] },
        { items: [propertyContent] },
        { label: TC.BUILD_EDIT_VISUAL, items: [iconSelect, colorSelect] },
    ], [locContent, propertyContent, imgContent, iconSelect, colorSelect, buildingContent]);
    //#endregion

    //#region Errors
    React.useEffect(() => setErrors({}), [buildData]);
    //#endregion

    //#region Save Data
    const changesMade = React.useCallback(() => !_.isEqual(buildData, data), [buildData, data]);

    const saveData = React.useCallback(() => {
        let errorObj: { [key: string]: string } = {};
        if (!TB.validString(buildData.town)) errorObj.town = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.validString(buildData.name)) errorObj.name = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.validString(buildData.street)) errorObj.street = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.validString(buildData.number)) errorObj.number = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.validString(buildData.zipcode)) errorObj.zipcode = TC.GLOBAL_REQUIRED_FIELD;

        if (Object.keys(errorObj).length > 0) {
            setErrors(errorObj);
            return null;
        }

        let toDelete: (string | undefined), toAdd: (T.File | null) = null;

        if (buildData?.picture?.[0]?.name !== ogFileArray.current?.[0]?.name) {
            if (Array.isArray(ogFileArray.current) && TB.isFile(ogFileArray.current[0])) toDelete = ogFileArray.current[0].url;
            if (Array.isArray(buildData.picture) && TB.isFile(buildData.picture[0])) toAdd = buildData.picture[0];
        }

        M.Alerts.success_alert();
        return { data: buildData, toAdd, toDelete };
    }, [buildData]);

    React.useEffect(() => {
        if (TB.validObject(api) && TB.validObject(api.current)) {
            api.current.saveData = saveData;
            api.current.changesMade = changesMade;
        }
    }, [api, saveData, changesMade]);
    //#endregion

    return <div>
        {rowsList.map(({ label, items }, i) => <React.Fragment key={i}>
            {label && <BS.Row className="my-3">
                <BS.Col md={6}>
                    <span className="h4">{lg.getStaticText(label)}</span>
                </BS.Col>
            </BS.Row>}
            <BS.Row className="my-1">
                {items.map((x, i) => <React.Fragment key={i}>{x}</React.Fragment>)}
            </BS.Row>
        </React.Fragment>)}
    </div>
}

export default GenTab;