import { TB } from "../Constants";
import { useEventListener } from "./useHooks";
import { MutableRefObject, useCallback, useState } from "react";

const useTextAreaPosition = (area: MutableRefObject<HTMLTextAreaElement>) => {

    const [position, setPosition] = useState({ start: 0, end: 0, selection: "" });

    const checkCursor = useCallback(() => {
        if (area) {
            const end = area.current?.selectionEnd || 0;
            const start = area.current?.selectionStart || 0;
            const selection = TB.getString(area.current?.value).substring(start - 1, end);

            setPosition(p => {
                if (p.end === end && p.start === start && p.selection === selection) return p;
                return { end, start, selection };
            });
        }
    }, [area]);

    useEventListener('cut', checkCursor, area);
    useEventListener('keyup', checkCursor, area); // Arrow keys
    useEventListener('touchstart', checkCursor, area); // Mobile
    useEventListener('mousedown', checkCursor, area); // Click down
    useEventListener('paste', checkCursor, area); // Clipboard actions
    useEventListener('input', checkCursor, area); // Other input events
    useEventListener('keypress', checkCursor, area); // Every character written
    useEventListener('select', checkCursor, area); // Some browsers support this event
    useEventListener('selectstart', checkCursor, area); // Some browsers support this event
    useEventListener('selectionchange', checkCursor, area); // Most browsers don't support this event

    return position;
}

export default useTextAreaPosition;