import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import * as G from '../Grid';
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { TB, T, TC } from "../../../Constants";
import { CellsTypes as CT } from '../AgGridDefs';

export type EntityDataTableProps = {
    isFullHeight?: boolean;
    /** If data was already loaded in another component, provide them as default */
    data?: Data;
    /** Hide the table if it doesn't have data */
    hide_if_empty?: boolean;
    /** If the data isn't provided, the list of datasets to fetch data from */
    datasets?: string[];
    /** What times to restrict the data to */
    time?: Record<"from" | "to", string>;
    /** How to group the data */
    group?: Parameters<T.API.Utils.Energy.EntityDataCsv>[0]["group"];
    /** Callback to set data when they've been loaded */
    update_data?: (data: Data) => void;
}

type Row = BaseRow | TransposedRow;
type Views = "normal" | "transposed" | "indexed";
type Data = ReturnType<T.API.Utils.Energy.GetData>;
type BaseRow = Record<"id" | "year" | "month" | "day" | "hour", string> & Record<number, string>;
type TransposedRow = Record<"name" | "id" | "issue", string> & Record<"sum" | "count", number> & Record<number, number>;

const EntityDataTable: React.FC<EntityDataTableProps> = ({ update_data, isFullHeight = false, ...props }) => {
    const lg = H.useLanguage();
    const grid_ready = H.useBoolean(false);
    const table = React.useRef<G.TableRef<Row>>(null);
    const [data, set_data, status] = H.useAsyncState<Data>([]);

    React.useEffect(() => {
        let isSubscribed = true;

        const push_data: typeof set_data = (data, status) => {
            if (typeof data === "function") return;
            update_data?.(data);
            set_data(data, status);
        }

        if (props.data) push_data(props.data, "done");
        else if (!props.datasets || props.datasets.length === 0) push_data([], "done");
        else if (props.datasets) S.getData({ datasets: props.datasets, index: true, group: props.group, ...props.time })
            .then(({ data }) => isSubscribed && push_data(data, "done"))
            .catch(() => isSubscribed && push_data([], "error"));
        return () => {
            isSubscribed = false;
            set_data([], "load");
        }
    }, [set_data, props.data, props.datasets, props.group, props.time]);

    const update_view = React.useCallback((view: Views) => {
        let rows: Row[] = [];
        let columns: G.TableProps<Row>["columns"] = [];

        if (view === "transposed") {
            // Create a list of the different timestamps
            let times = _.uniq(data.map(d => d.datapoints.map(dp => dp[1])).flat());
            // Shortcut for the amount of timestamps found
            let amount_of_times = times.length;
            // Create the basic columns
            columns.push(
                { headerName: TC.DATA_ENTRY_TABLE_NAME, field: "name" },
                { headerName: TC.DATA_ENTRY_TABLE_ISSUE, field: "issue", hide: true },
                { headerName: times.length.toString(), field: "count", type: CT.TYPE_NUMBER, hide: true },
                { headerName: TC.DATA_ENTRY_TABLE_VALUE_SUM, field: "sum", type: CT.TYPE_NUMBER, hide: true, params: { maxDigit: 3 } },
            );
            // Create the times columns
            for (let time of times) {
                let formatted_time = moment.unix(time / 1000).format('DD/MM/YYYY HH:mm:ss');
                columns.push({ field: String(time), headerName: formatted_time, params: { maxDigit: 3 }, type: CT.TYPE_NUMBER });
            }

            for (let api_result of data) {
                let row: TransposedRow = { name: api_result.target, id: api_result._id, issue: "", sum: 0, count: 0 };

                for (let datapoint of api_result.datapoints) {
                    let [value, time] = datapoint;
                    row[time] = value;
                    if (typeof value === "number" && !isNaN(value)) {
                        row.count++;
                        row.sum += value;
                    }
                }

                if (row.count < amount_of_times - 1) row.issue = lg.getStaticText(TC.DATA_ENTRY_TABLE_PROBLEM);
                else if (row.sum === 0) row.issue = lg.getStaticText(TC.DATA_ENTRY_TABLE_ALWAYS_ZERO);
                else if (row.sum < 0) row.issue = lg.getStaticText(TC.DATA_ENTRY_TABLE_NEGATIVE_DATA);
                else row.issue = lg.getStaticText(TC.DATA_ENTRY_TABLE_SEEMS_OK);
                rows.push(row);
            }
        }
        else {
            let time_entity_records: Record<number, Record<string, number>> = {};
            // Create the time columns
            columns.push(
                { field: 'id', headerName: 'Date', type: CT.TYPE_DATE, params: { isDateTime: true } },
                { field: 'year', headerName: 'Year', hide: true, type: CT.TYPE_NUMBER },
                { field: 'month', headerName: 'Month', hide: true, type: CT.TYPE_NUMBER },
                { field: 'day', headerName: 'Day', hide: true, type: CT.TYPE_NUMBER },
                { field: 'hour', headerName: 'Hour', hide: true, type: CT.TYPE_NUMBER },
            );
            // Keep track for each timestamps of the value of each dataset
            for (let api_result of data) {
                // Create a column for each dataset
                columns.push({ field: api_result._id, headerName: api_result.target, type: CT.TYPE_NUMBER, params: { maxDigit: 3 } });
                // Choose the datapoints array to use based on the view
                let datapoints_array = (view === "normal" ? api_result.datapoints : api_result.index) || [];
                // Add the datapoints to the dictionary
                for (let datapoint of datapoints_array) {
                    let [value, time] = datapoint;
                    if (time_entity_records[time]) time_entity_records[time][api_result._id] = value;
                    else time_entity_records[time] = { [api_result._id]: value };
                }
            }
            // Format the rows
            for (let [time, entity_records] of Object.entries(time_entity_records)) {
                let time_number = TB.getNumber(time);
                if (isNaN(time_number)) time_number = 1;
                else time_number = time_number / 1000;
                // Calculate the separate time values
                let year = moment.unix(time_number).format('YYYY');
                let month = moment.unix(time_number).format('MM');
                let day = moment.unix(time_number).format('DD');
                let hour = moment.unix(time_number).format('HH');
                // Create the new row
                rows.push({ id: time, year, month, day, hour, ...entity_records });
            }
        }
        // Update the grid
        table.current?.grid?.updateGridOptions?.({ rowData: rows, columnDefs: columns as any });
    }, [data, lg]);

    const getContextMenuItems = React.useCallback<G.TableProps<Row>["getContextMenuItems"]>(e => {
        let items: ReturnType<G.TableProps<Row>["getContextMenuItems"]> = [
            { name: "Transpose", action: () => update_view("transposed") },
            { name: "Index", action: () => update_view("indexed") },
            { name: "Normal", action: () => update_view("normal") },
        ];
        let default_items = TB.getArray(e.defaultItems);
        if (default_items.length > 0) items.push("separator", ...default_items);
        return items;
    }, [update_view]);

    React.useEffect(() => {
        if (data.length > 0 && grid_ready.value) update_view("normal")
    }, [data.length, update_view, grid_ready.value]);

    if (props.hide_if_empty && data.length === 0) return null;
    return <div className={`w-100 ${isFullHeight ? 'h-100' : ''}`}>
        <C.Spinner error={status === "error"}>
            <G.Table<Row>
                sideBar
                rows={[]}
                ref={table}
                enableCharts
                columns={null}
                origin='Data'
                api_provided_rows
                adaptableId='entity'
                remove_unknown_columns
                export_dashboard_button
                rowSelection="multiple"
                getRowId={r => r.data.id}
                loading={status === "load"}
                columns_base="all_but_edit"
                isRowSelectable={() => true}
                groupDisplayType="singleColumn"
                onReadyGrid={grid_ready.setTrue}
                getContextMenuItems={getContextMenuItems}
            />
        </C.Spinner>
    </div>;
}

export default EntityDataTable;