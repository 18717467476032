import DOM from 'react-dom/client';
import DefaultComp from './NoRenderComp';
import { ROLES } from "../../../Constants";
import { ReactComponent } from 'react-formio';
import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default class RoleSelect extends ReactComponent {
    static get builderInfo() {
        return {
            weight: -10,
            icon: "users",
            group: "Custom",
            documentation: "",
            title: "Role Select",
            schema: RoleSelect.schema(),
        };
    }

    static editForm = (...extend) => {
        return baseEditForm(
            [
                {
                    key: "data",
                    components: [
                        {
                            // Select a field property
                            type: 'select',
                            input: true,
                            key: 'limitRoles',
                            multiple: true,
                            label: 'Select a role',
                            weight: 10,
                            dataSrc: 'custom',
                            defaultValue: [ROLES.USER_ROLE_AUTHENTICATED],
                            clearOnHide: true,
                            data: {
                                custom: ROLES.ALL_ROLES,
                            },
                        }
                    ]
                }
            ],
            ...extend
        );
    };;


    attachReact = element => DOM.createRoot(element).render(<DefaultComp />);
    detachReact = element => element ? DOM.createRoot(element).unmount() : undefined;
    static schema = () => ReactComponent.schema({ type: "RoleSelect", label: "Role Select" });
}