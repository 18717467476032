import mongoose from 'mongoose';
import { validate } from "uuid";
import { APIS, REGEX } from '../Data';
import { arrayWrapper } from './array';
import * as URL from "../Constants.URL";
import { getString, validString } from './string';
const ObjectId = mongoose.Types.ObjectId;

export const mongoIdValidator = (_id: any): _id is string => {
    _id = ObjectIdStringify(_id);
    return ObjectId.isValid(_id) && typeof _id === "string" && _id.length === 24 && String(new ObjectId(_id)) === _id;
}

export const isUuid = (uuid: any): uuid is string => validate(uuid);
export const multiMongoIdValidator = (ids: any): ids is string[] => Array.isArray(ids) && ids.map(ObjectIdStringify).every(mongoIdValidator);
export const ObjectIdStringify = <A = any>(id: A): string => typeof id === "object" && id !== null && !Array.isArray(id) ? id.toString?.() : id as string;

/**
 * Extract all mongo ids that are present in the string provided
 */
export const extractIdFromString = (str?: string): string[] => {
    let matches = getString(str).match(REGEX.MONGO_ID_REGEX_LOOSE);
    if (matches) return matches.filter(mongoIdValidator);
    return [];
}

/**
 * Extracts ids from one or more string of one or multiple ids
 */
export const findIds = (str?: string | string[]) => {
    let stringIds = arrayWrapper(str).filter(validString).join();
    return extractIdFromString(stringIds);
}

/**
 * Turns an icon id into an url
 * @param id The id of the icon
 * @returns The url for the icon image
 */
export const iconIdToUrl = (id?: string) => mongoIdValidator(id) ? URL.APP_DOMAIN + APIS.FILE_API + "icon/" + id : null;