import _ from "lodash";

export const P_LOAD_OWN = "pLoadOwn";
export const P_LOAD_ALL = "pLoadAll";
export const GT_LOAD_OWN = "gtLoadOwn";
export const GT_LOAD_ALL = "gtLoadAll";
export const GU_LOAD_ALL = "guLoadAll";
export const GU_LOAD_UNDER = "guLoadUnder";

//#region Grouping
export const ADMIN_LOAD_TYPES = [P_LOAD_ALL, GT_LOAD_ALL, GU_LOAD_ALL];
export const DEFAULT_LOAD_TYPES = [P_LOAD_OWN, GT_LOAD_OWN, GU_LOAD_UNDER];

export const GET_LOAD_TYPES = (loadTypes?: string[], isAdmin = false) => {
    if (isAdmin) return ADMIN_LOAD_TYPES;
    if (!Array.isArray(loadTypes)) return DEFAULT_LOAD_TYPES;
    return _.union(loadTypes.concat(DEFAULT_LOAD_TYPES));
}
//#endregion