import React from "react";
import { TB, TC } from "../Constants";
import { EnergyDashboard, EnergyProps } from "../Components/Dashboard";
import { renderBlankModal, BlankModalFnProps } from "../Components/Modal/BlankModal";

type RenderEnergyDashboardProps = EnergyProps & Omit<BlankModalFnProps, "renderContent" | "children" | "autofocus" | "showHeader">;

const renderEnergyDashboard = (params: RenderEnergyDashboardProps) => {
    let vParams = TB.getObject(params);
    let { /* nodeId, suggestions, */ ...props } = vParams;
    if (!TB.validString(props.title)) props.title = TC.TREE_GRAPH;

    return renderBlankModal({
        ...vParams,
        autofocus: false,
        title: vParams?.title || TC.GLOBAL_ENERGY,
        children: <EnergyDashboard
            tab={props.tab}
            times={props.times}
            group={props.group}
            origin={props.origin || ""}
            // suggestions={suggestions}
            datasets={props.datasets}
        // no_default={props.no_default}
        />,
    });
};

export default renderEnergyDashboard;