// Must be imported first
import "./sentry";
/* /!\ Order needs to stay, otherwise socket doesn't work anymore */
import DomClient from 'react-dom/client';
import './helpers/initFA';
import { Provider } from "react-redux";
import { store as async_store } from "./Redux";
import RouterSwitch from './Layouts/RouterSwitch';
import Main from './Main';

import './index.scss';
import "./assets/scss/user.scss";
import "./assets/scss/theme.scss";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./Global Style/bootstrap-typeahead-styles.scss";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const container = document.getElementById("root");
const root = DomClient.createRoot(container);
// First display a loading screen while waiting for the store to be ready
root.render(<div className='fs-150 d-flex justify-content-center align-items-center min-vw-100 min-vh-100' children={<i className='fa fa-spin fa-spinner' />} />);
// Store is ready, render the app
async_store.then(store => root.render(<Provider store={store} children={<Main children={<RouterSwitch />} />} />));