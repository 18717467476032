import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as S from "../../../services";
import { Spinner } from "../../../Common";
import { Table, TableProps } from "../Grid";
import { CellsTypes as CT } from "../AgGridDefs";
import { T, TABS, TC, FP, TB, RIGHTS } from "../../../Constants";

//#region Types
export type EquipStoreTableProps = {
    /** The context to load site-specific equip store from */
    context: T.ContextParams;
    /** The id of the table */
    origin?: string;
}

type Row = T.API.EDL.StoreEquipTableItem;
type Sites = T.Awaited<ReturnType<typeof S.getContextSites>>["data"];
//#endregion

//#region Constants
const TEXT_CODES = [TC.EQUIP_STORE_TABLE_DELETE, TC.EQUIP_STORE_TABLE_EDIT, TC.EQUIP_STORE_TABLE_NEW, TC.EQUIP_STORE_TABLE_SITE_NEW];
//#endregion

export const EquipStore: React.FC<EquipStoreTableProps> = props => {
    const rights = H.useRights();
    const [forms] = H.useFormIds();
    const lg = H.useLanguage(TEXT_CODES);
    const [sites, setSites] = H.useAsyncState<Sites>([]);
    const [items, setItems, status] = H.useAsyncState<Row[]>([]);

    //#region Load data
    React.useEffect(() => {
        let isSubscribed = true;
        S.getStoreEquipItems(props.context)
            .then(({ data }) => isSubscribed && setItems(data, "done"))
            .catch(() => isSubscribed && setItems([], "error"));
        return () => { isSubscribed = false };
    }, [setItems, props.context]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getContextSites(props.context)
            .then(({ data }) => isSubscribed && setSites(data, "done"))
            .catch(() => isSubscribed && setSites([], "error"));
        return () => { isSubscribed = false };
    }, [setSites, props.context]);
    //#endregion

    //#region Callbacks
    const updates = React.useMemo(() => ({
        create: (site?: string) => {
            let sub;
            if (TB.mongoIdValidator(site)) sub = TB.submissionToArrayUpdate({ site });

            M.renderFormModal({ path: FP.EQUIPSTORE_FORM, modalProps: { size: "lg" }, forcedSubmission: sub }).then(equipStore => {
                if (equipStore) S.getStoreEquipItemsFromIds(equipStore._id)
                    .then(({ data }) => setItems(p => p.concat(data)))
                    .catch(M.Alerts.loadError);
            });
        },
        update: (item: typeof items[number]) => {
            M.renderFormModal({ path: FP.EQUIPSTORE_FORM, modalProps: { size: "lg" }, submissionId: item.id }).then(equipStore => {
                if (equipStore) S.getStoreEquipItemsFromIds(equipStore._id)
                    .then(({ data }) => setItems(p => p.map(s => data.filter(d => d.id === s.id)[0] || s)))
                    .catch(M.Alerts.loadError);
            });
        },
        delete: (item: typeof items[number]) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) S.deleteStoreEquip(item.id)
                    .then(() => setItems(p => p.filter(es => es.id !== item.id)))
                    .catch(M.Alerts.deleteError);
            });
        },
    }), [setItems]);
    //#endregion

    //#region Context Menu
    const canEditNonSpecific = React.useMemo(() => rights.isRightAllowed(RIGHTS.TECH.WRITE_EQUIP_TYPE), [rights]);

    const getContextMenu = React.useCallback<TableProps<Row>["getContextMenuItems"]>(params => {
        let customItems = [] as ReturnType<TableProps<Row>["getContextMenuItems"]>;
        let defaultItems = TB.getArray(params.defaultItems).filter(TB.validString);

        let item = params.node?.data as typeof items[number];

        if (canEditNonSpecific) customItems.push({
            action: () => updates.create(),
            name: lg.getStaticText(TC.EQUIP_STORE_TABLE_NEW),
        });

        for (let site of sites) customItems.push({
            action: () => updates.create(site.value),
            name: lg.getStaticText(TC.EQUIP_STORE_TABLE_SITE_NEW, site.label),
        });

        // Can edit if site-specific or allow to edit general types
        if (item && (TB.mongoIdValidator(item.site) || canEditNonSpecific)) {
            if (customItems.length > 0) customItems.push("separator");

            customItems.push(
                {
                    action: () => updates.update(item),
                    name: lg.getStaticText(TC.EQUIP_STORE_TABLE_EDIT),
                },
                {
                    action: () => updates.delete(item),
                    name: lg.getStaticText(TC.EQUIP_STORE_TABLE_DELETE),
                },
            );
        }

        if (customItems.length > 0 && defaultItems.length > 0) customItems.push("separator");
        return customItems.concat(defaultItems);
    }, [canEditNonSpecific, updates, sites, lg]);
    //#endregion

    //#region Columns
    const form_id = React.useMemo(() => forms[FP.EQUIPEMENT_FORM], [forms]);

    const columns = React.useMemo<TableProps<Row>["columns"]>(() => [
        { field: "brandLabel", headerName: "brand", params: { header_id: form_id } },
        { field: "modelLabel", headerName: "model", params: { header_id: form_id } },
        { field: "omniclass", headerName: TC.EQUIP_STORE_OMNICLASS },
        { field: "siteLabel", headerName: FP.SITE_FORM },
        { field: "isGeneric", headerName: TC.EQUIP_STORE_IS_GENERIC, type: CT.TYPE_CHECKBOX },
        { field: "categories.1", headerName: " " },
        { field: "categories.2", headerName: " " },
        { field: "categories.3", headerName: " " },
        { field: "categories.4", headerName: " " },
        { field: "categories.5", headerName: " " },
        { field: "categories.6", headerName: " " },
    ], [form_id]);
    //#endregion

    return <div className="w-100">
        <Spinner error={status === "error"}>
            <Table<Row>
                sideBar
                rows={items}
                status={status}
                columns={columns}
                getRowId={r => r.data.id}
                getContextMenuItems={getContextMenu}
                adaptableId={props.origin || TABS.EQUIPMENTS_TYPE_TABLE}
                columns_base={React.useMemo(() => ["filterable", "sortable", "grouped"], [])}
            />

        </Spinner>
    </div>;
};

export const EquipStoreContext: React.FC = () => {
    const [roots] = H.useRoots();
    H.useCrumbs(TC.TAB_EQUIP_STORE);
    H.useAuth({ tabName: TABS.EQUIPMENTS_TYPE_TABLE });

    return <EquipStore context={roots} origin={TABS.EQUIPMENTS_TYPE_TABLE} />;
}