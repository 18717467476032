import React from 'react';
import classNames from 'classnames';
import { T, TB } from '../../Constants';

type FlexProps = T.HTML.DivProps & {
  id?: string;
  inline?: boolean;
  className?: string;
  children?: React.ReactNode;
  direction?: 'column' | 'row';
  wrap?: "wrap" | "nowrap" | "wrap-reverse";
  breakpoint?: "sm" | "md" | "lg" | "xl" | "xxl";
  alignItems?: "start" | "end" | "center" | "baseline" | "stretch";
  alignContent?: "start" | "end" | "start" | "between" | "around" | "stretch";
  justifyContent?: "start" | "end" | "center" | "between" | "around" | "evenly";
  /** To render the element as something other than a 'div' */
  as?: Parameters<typeof React.createElement>[0];
}

const Flex = React.forwardRef<HTMLDivElement, FlexProps>(({ id, justifyContent, alignItems, alignContent, inline = false, wrap, className = "", children, breakpoint, direction, ...rest }, ref) => {
  const classesCondition = React.useMemo(() => ({
    [`d-${breakpoint ? breakpoint + '-' : ''}flex`]: !inline,
    [`d-${breakpoint ? breakpoint + '-' : ''}inline-flex`]: inline,
    [`flex-${direction}`]: direction,
    [`justify-content-${justifyContent}`]: justifyContent,
    [`align-items-${alignItems}`]: alignItems,
    [`align-content-${alignContent}`]: alignContent,
    [`flex-${wrap}`]: wrap
  }), [alignContent, alignItems, breakpoint, direction, inline, justifyContent, wrap]);

  const divId = React.useMemo(() => TB.validString(id) ? id : undefined, [id]);

  return React.createElement(
    rest.as || "div",
    { ref, id: divId, className: classNames(classesCondition, className), ...rest } as any,
    children
  );
});

Flex.displayName = "Flex";
export default Flex;
