import React from "react";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import { T, TC } from "../../../Constants";
import { CellsTypes as CT } from "../../Gestion/AgGridDefs";

type Row = T.Prediction["training"]["openings"][number];

export type OpeningsTableProps = {
    /** The list of openings */
    openings: Row[];
}

const DAYS = [
    TC.DAY_MONDAY,
    TC.DAY_TUESDAY,
    TC.DAY_WEDNESDAY,
    TC.DAY_THURSDAY,
    TC.DAY_FRIDAY,
    TC.DAY_SATURDAY,
    TC.DAY_SUNDAY,
    TC.PRED_CONFIG_HOLIDAYS,
]

const OpeningsTable: React.FC<OpeningsTableProps> = props => {
    const lg = H.useLanguage();

    const rows = React.useMemo(() => (props.openings || []).map(o => ({
        ...o,
        label: lg.getStaticText(DAYS[o.day_type] || o.day_type_label),
    })), [props.openings, lg]);

    const columns = React.useMemo<G.TableProps<Row>["columns"]>(() => [
        { field: "label", headerName: TC.PRED_OPEN_DAY_LABEL },
        { field: "nr_days", headerName: TC.PRED_OPEN_NUMBER, type: CT.TYPE_NUMBER, params: { maxDigit: 3 } },
        { field: "average_open", headerName: TC.PRED_OPEN_AVG_OPEN, type: CT.TYPE_NUMBER, params: { maxDigit: 3 } },
        { field: "open_hours_count", headerName: TC.PRED_OPEN_HOURS_COUNT, type: CT.TYPE_NUMBER, params: { maxDigit: 3 } },
        { field: "earliest_open", headerName: TC.PRED_OPEN_EARLY_OPEN, type: CT.TYPE_NUMBER, params: { maxDigit: 3 } },
        { field: "latest_close", headerName: TC.PRED_OPEN_LATE_CLOSE, type: CT.TYPE_NUMBER, params: { maxDigit: 3 } },
        { field: "average_consumption_on", headerName: TC.PRED_OPEN_CONS_ON, type: CT.TYPE_NUMBER, params: { maxDigit: 3 } },
        { field: "average_consumption_off", headerName: TC.PRED_OPEN_CONS_OFF, type: CT.TYPE_NUMBER, params: { maxDigit: 3 } },
    ], []);

    return <div style={{ height: "500px" }}>
        <G.Table<Row>
            rows={rows}
            columns={columns}
            sideBar="filters_columns"
            adaptableId="openings_pred"
            getRowId={r => r.data.day_type.toString()}
            columns_base={["filterable", "sortable", "grouped"]}
        />
    </div>;
}

export default OpeningsTable;