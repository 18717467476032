import axios from "axios";
import { getHeader } from "../Headers";
import { T, APIS } from "../../Constants";

const { PRED_API } = APIS;

type TrainModel = T.API.ToAxios<T.API.Utils.Predictions.TrainModel>;
type ValidateModel = T.API.ToAxios<T.API.Utils.Predictions.ValidateModel>;
type TogglePredUpdate = T.API.ToAxios<T.API.Utils.Predictions.TogglePredUpdate>;
type GenerateModelData = T.API.ToAxios<T.API.Utils.Predictions.GenerateModelData>;
type PredictionDatasets = T.API.ToAxios<T.API.Utils.Predictions.PredictionDatasets>;
type PredValidationInfo = T.API.ToAxios<T.API.Utils.Predictions.PredValidationInfo>;
type GetPredSettingsData = T.API.ToAxios<T.API.Utils.Predictions.GetPredSettingsData>;
type UpdatePredUpdateFrequency = T.API.ToAxios<T.API.Utils.Predictions.UpdatePredUpdateFrequency>;

/** Create datasets for a prediction */
export const predictionDatasets: PredictionDatasets = params => axios.post(PRED_API + "create/datasets", params);
/** Get the necessary data for the prediction settings page */
export const getPredSettingsData: GetPredSettingsData = params => axios.post(PRED_API + "settings/info", params);
/** Send a model to be created/updated and trained */
export const trainModel: TrainModel = prediction => axios.post(PRED_API + "train", prediction, getHeader("EDIT"));
/** Validate a model */
export const validateModel: ValidateModel = prediction => axios.post(PRED_API + "validate", { prediction }, getHeader("EDIT"));
/** Toggle the auto-update of a prediction model */
export const togglePredUpdate: TogglePredUpdate = prediction => axios.post(PRED_API + "toggle/update", { prediction }, getHeader("EDIT"));
/** Change the frequency of a prediction model's auto-update */
export const updatePredUpdateFrequency: UpdatePredUpdateFrequency = params => axios.post(PRED_API + "edit/update", params, getHeader("EDIT"));
/** Get the necessary values for the validation panel of a prediction */
export const predValidationInfo: PredValidationInfo = params => axios.post(PRED_API + "info/validate", params);
/** Generate the data for an existing prediction */
export const generateModelData: GenerateModelData = prediction => axios.post(PRED_API + "data/model", { prediction });