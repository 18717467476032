import _ from "lodash";
import React from "react";
import moment from "moment";
import * as M from "../Modal";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { T, TB, TC } from "../../Constants";

//#region Types
type RegSelectProps = {
    action: string;
    element: string;
    selected?: string;
    options: T.RegDoc[];
    actions: T.Submission<T.RegAction>[];
    onEdited?: (doc: T.RegDoc) => void;
    onCreated?: (doc: T.RegDoc) => void;
    onDeleted?: (docId: string) => void;
};
//#endregion

const RegSelect: React.FC<RegSelectProps> = ({ selected, action, actions, element, options, onEdited, onCreated, onDeleted }) => {

    //#region Options
    const vOptions = React.useMemo(() => {
        let actionsNames = TB.getArray(actions)
            .filter(a => TB.mongoIdValidator(a?._id) && TB.validString(a?.data?.name))
            .map(a => [a._id, a.data.name]);

        let actionNamesObj = Object.fromEntries(actionsNames);

        return TB.getArray(options)
            .filter(rd => TB.ObjectIdStringify(actionNamesObj[rd.regAction]) && rd.regAction === action)
            .map(rd => ({ label: `${actionNamesObj[rd.regAction]} (${moment(rd.last_control).format("DD/MM/YY")})`, value: rd._id }))
    }, [options, actions, action]);

    const selectedOptions = React.useMemo(() => vOptions.filter(o => o.value === selected), [vOptions, selected]);
    //#endregion

    //#region Callbacks
    const changeDoc = React.useCallback((create = false) => {

        let params = { action, elemId: element } as Parameters<typeof M.renderRegDoc>[0];
        // Create new doc
        if (create) params.forceNewDoc = true;
        // Edit current doc
        else params.regFormId = selected;

        M.renderRegDoc(params).then(results => {
            if (results !== null) {
                if (results.action === "deleted") onDeleted?.(results.doc?._id);
                else (create || results.doc._id !== selected ? onCreated : onEdited)?.(results.doc);
            }
        });
    }, [action, element, selected, onEdited, onCreated, onDeleted]);

    const deleteDoc = React.useCallback(() => {
        M.askConfirm().then(confirmed => {
            if (confirmed) S.removeRegForm(selected).then(() => onDeleted?.(selected))
                .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_DELETE }));
        });
    }, [selected, onDeleted]);

    const onSelectDoc = React.useCallback((docId?: string) => {
        if (!TB.mongoIdValidator(docId)) onDeleted?.(selected);
        else {
            let doc = _.find(options, o => o._id === docId);
            if (doc) onEdited?.(doc);
        }
    }, [onDeleted, onEdited, options, selected]);
    //#endregion

    return <BS.InputGroup>
        <C.TypeAhead
            options={vOptions}
            selectedItems={selected}
            onChange={opt => onSelectDoc(opt[0]?.value)}
        />

        {selectedOptions.length > 0 && <>
            <C.Button onClick={() => changeDoc()} size="sm" variant="info" icon="edit" />
            <C.Button onClick={deleteDoc} size="sm" variant="danger" icon="times" />
        </>}
        {selectedOptions.length === 0 && <C.Button onClick={() => changeDoc(true)} size="sm" icon="plus" />}
    </BS.InputGroup>
}

export default RegSelect;