import DOM from 'react-dom/client';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class Materials extends ReactComponent {
    static get builderInfo() {
        return {
            weight: 1,
            group: "Custom",
            icon: "hard-hat",
            documentation: "",
            title: "Materials",
            schema: Materials.schema(),
        };
    }

    static schema = () => ReactComponent.schema({ type: "Materials", label: "Materials" });
    attachReact = element => DOM.createRoot(element).render(<DefaultComp />);
    detachReact = element => element ? DOM.createRoot(element).unmount() : undefined;
}