import axios from "axios";
import { T, APIS } from "../../Constants";

const { FORM_API } = APIS;

type GetFormsSummary = T.API.ToAxios<T.API.Form.GetFormsSummary>;
type GetFormComponents = T.API.ToAxios<T.API.Form.GetFormComponents>;
type GetFormComponentsData = T.API.ToAxios<T.API.Form.GetFormComponentsData>;

export const getFormComponents: GetFormComponents = p => axios.get(FORM_API + "components", { params: p });
export const getSubmissionAndCertifications = <A extends object>(p: T.API.Form.GetSubmissionFormParams) => axios.post<T.API.Form.GetSubmissionFormResults<A>>(FORM_API + "submission", p);

/** Create a summary of all the forms present in the database */
export const getFormsSummary: GetFormsSummary = () => axios.get(FORM_API + "summary");
/** Get the necessary data to re-create simple single components from a form */
export const getFormComponentsData: GetFormComponentsData = params => axios.post(FORM_API + "components/data", params);