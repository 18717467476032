import { API_URL } from "./apis";
import * as ICON from "./IconsLabels";
import { validString } from "../Tools";
import { APP_DOMAIN } from "../Constants.URL";

// Images Resources
export const SHAPE_1 = "shape_1";
export const BG_SHAPE = "bg_shape";
export const AISET_LOGO = "aiset_logo";
export const USER_AVATAR = "user_avatar";
export const HALF_CIRCLE = "half_circle";
export const NO_IMG_FOUND = "no_img_found";
export const EXE_FILE_GENERIC = "exe-file";
export const SP_CARD = "settings_panel_card";
export const FONT_FILE_GENERIC = "font-file";
export const TEXT_FILE_GENERIC = "text-file";
export const AUDIO_FILE_GENERIC = "audio-file";
export const VIDEO_FILE_GENERIC = "video-file";
export const SP_DEFAULT = "settings_panel_default";
export const SP_VIBRANT = "settings_panel_vibrant";
export const SP_INVERTED = "settings_panel_inverted";
export const SP_ARROWS_H = "settings_panel_arrows_h";
export const SP_PARAGRAPH = "settings_panel_paragraph";
export const SP_LEFT_ARROW = "settings_panel_left_arrow";
export const GRAPH_BAR_CUMULATED = "graph_bar_cumulated";
export const SP_FALCON_DARK = "settings_panel_falcon_dark";
export const ENTITY_CSV_TEMPLATE_A_IMG = "csv_template_a_img";
export const ENTITY_CSV_TEMPLATE_B_IMG = "csv_template_b_img";
export const SP_FALCON_DEFAULT = "settings_panel_falcon_default";
export const GRAPH_BAR_NON_CUMULATED = "graph_bar_non_cumulated";
// Other Files Resources
export const ENTITY_CSV_TEMPLATE_A = "csv_template_a";
export const ENTITY_CSV_TEMPLATE_B = "csv_template_b";
// Icon Submission Resources
export const ICON_SUB = ICON;

export const ALL_RESOURCES = [
    { code: SHAPE_1, isIcon: false, isImg: true, path: "Misc/shape-1.png" },
    { code: BG_SHAPE, isIcon: false, isImg: true, path: "Misc/bg-shape.png" },
    { code: SP_CARD, isIcon: false, isImg: true, path: "SettingsPanel/card.png" },
    { code: AISET_LOGO, isIcon: false, isImg: true, path: "logos/aiset_logo.png" },
    { code: USER_AVATAR, isIcon: false, isImg: true, path: "Misc/user_avatar.png" },
    { code: HALF_CIRCLE, isIcon: false, isImg: true, path: "Misc/half-circle.png" },
    { code: SP_DEFAULT, isIcon: false, isImg: true, path: "SettingsPanel/default.png" },
    { code: SP_VIBRANT, isIcon: false, isImg: true, path: "SettingsPanel/vibrant.png" },
    { code: NO_IMG_FOUND, isIcon: false, isImg: true, path: "Misc/image-not-found.jpg" },
    { code: SP_INVERTED, isIcon: false, isImg: true, path: "SettingsPanel/inverted.png" },
    { code: SP_ARROWS_H, isIcon: false, isImg: true, path: "SettingsPanel/arrows-h.svg" },
    { code: SP_PARAGRAPH, isIcon: false, isImg: true, path: "SettingsPanel/paragraph.svg" },
    { code: EXE_FILE_GENERIC, isIcon: false, isImg: true, path: "FilePreview/exe-file.png" },
    { code: FONT_FILE_GENERIC, isIcon: false, isImg: true, path: "FilePreview/font-file.png" },
    { code: TEXT_FILE_GENERIC, isIcon: false, isImg: true, path: "FilePreview/text-file.png" },
    { code: GRAPH_BAR_CUMULATED, isIcon: false, isImg: true, path: "Graphs/bar_cumulated.png" },
    { code: AUDIO_FILE_GENERIC, isIcon: false, isImg: true, path: "FilePreview/audio-file.png" },
    { code: VIDEO_FILE_GENERIC, isIcon: false, isImg: true, path: "FilePreview/video-file.png" },
    { code: SP_FALCON_DARK, isIcon: false, isImg: true, path: "SettingsPanel/falcon-mode-dark.jpg" },
    { code: ENTITY_CSV_TEMPLATE_A, isIcon: false, isImg: false, path: "csvTemplates/template_A.csv" },
    { code: ENTITY_CSV_TEMPLATE_B, isIcon: false, isImg: false, path: "csvTemplates/template_B.csv" },
    { code: SP_LEFT_ARROW, isIcon: false, isImg: true, path: "SettingsPanel/left-arrow-from-left.svg" },
    { code: GRAPH_BAR_NON_CUMULATED, isIcon: false, isImg: true, path: "Graphs/bar_not_cumulated.png" },
    { code: ENTITY_CSV_TEMPLATE_A_IMG, isIcon: false, isImg: true, path: "csvTemplates/template_A.PNG" },
    { code: ENTITY_CSV_TEMPLATE_B_IMG, isIcon: false, isImg: true, path: "csvTemplates/template_B.PNG" },
    { code: SP_FALCON_DEFAULT, isIcon: false, isImg: true, path: "SettingsPanel/falcon-mode-default.jpg" },

    { code: ICON.DEFAULT_FLOOR, isIcon: true, isImg: true, path: "" },
    { code: ICON.DEFAULT_LOCAL, isIcon: true, isImg: true, path: "" },
    { code: ICON.DEFAULT_ZONE, isIcon: true, isImg: true, path: "" },
];

const imgResourcesCodes = ALL_RESOURCES.filter(r => r.isImg && typeof r.code === "string").map(r => r.code);
const otherResourcesCodes = ALL_RESOURCES.filter(r => !r.isImg && typeof r.code === "string").map(r => r.code);

export const IS_IMG_RESOURCES = (code: any): code is string => imgResourcesCodes.includes(code);
export const IS_FILE_RESOURCES = (code: any): code is string => otherResourcesCodes.includes(code);

export const RESOURCE_URL = (resource?: string) => {
    if (typeof resource !== "string") return "";
    if (imgResourcesCodes.includes(resource)) return `${APP_DOMAIN}${API_URL}resources/img/${resource}`;
    if (otherResourcesCodes.includes(resource)) return `${APP_DOMAIN}${API_URL}resources/file/${resource}`;
    return "";
}

export const GET_RESOURCE_PATH = (name: string) => {
    let obj = ALL_RESOURCES.filter(({ code }) => code === name)[0];
    if (typeof obj === "object" && obj !== null) return obj.path;
    return null;
}

export const NO_IMG_URL = RESOURCE_URL(NO_IMG_FOUND);

export const IMAGE_BASED_ON_TYPE = (type: string, url?: string) => {
    if (type.includes("image")) return url;
    else if (!validString(type)) return RESOURCE_URL(NO_IMG_FOUND);
    else if (type.includes("text")) return RESOURCE_URL(TEXT_FILE_GENERIC);
    else if (type.includes("font")) return RESOURCE_URL(FONT_FILE_GENERIC);
    else if (type.includes("audio")) return RESOURCE_URL(AUDIO_FILE_GENERIC);
    else if (type.includes("video")) return RESOURCE_URL(VIDEO_FILE_GENERIC);
    else if (type.includes("application")) return RESOURCE_URL(EXE_FILE_GENERIC);
    else return RESOURCE_URL(NO_IMG_FOUND);
}