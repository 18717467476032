import React from "react";
import { v4 as uuid } from "uuid";
import * as C from "../../Common";
import DOM from "react-dom/client";
import { TB } from "../../Constants";

type RenderInContainer = (id?: string, zIndex?: number) => [(content: React.ReactNode) => void, (cb?: () => void) => void];

const renderInContainer: RenderInContainer = (id, zIndex) => {
    // A specific container id was provided
    if (TB.validString(id)) {
        // Fetch the required document
        let container = document.getElementById(id);
        if (container === null) return [null, null];
        let root = DOM.createRoot(container);
        // Create a render function
        let render = (content: React.ReactNode) => root.render(<C.ReduxWrapper children={content} />);
        // Create a dismount function
        let dismount = (cb?: () => void) => {
            // Unmount the container
            if (container) root.unmount();
            // Fire the callback, if one is provided
            if (typeof cb === "function") cb();
        }
        return [render, dismount];
    }
    // Create a new container for the modal
    else {
        // The container will be in the body, but outside the main 'root' container
        let body = document.body;
        if (body === null) return [null, null];
        // Use the provided z-index, or set it as a higher-modal per default
        let z_index = TB.getNumber(zIndex, 1005);
        // No negative z-index
        if (z_index < 0) z_index = 0;
        // Create a new container
        let containerId = uuid();
        let container = document.createElement("div");
        let root = DOM.createRoot(container);
        // Apply properties to it
        container.id = containerId;
        container.style.zIndex = z_index.toFixed(0);
        container.style.backgroundColor = "transparent";
        // Do not append, it must be before #root or creates a white flash
        body.prepend(container);
        // Create a render function
        let render = (content: React.ReactNode) => root.render(<C.ReduxWrapper children={content} />);
        // Create a dismount function
        let dismount = (cb?: () => void) => {
            if (container) {
                // Unmount the container
                root.unmount();
                // Check if the container is a child of the body
                if (body.contains(container)) body.removeChild(container);
                // Remove the created container
                else container.remove();
            }
            // Fire the callback, if one is provided
            if (typeof cb === "function") cb();
        }
        return [render, dismount];
    }
}

export default renderInContainer;