import DOM from 'react-dom/client';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class TicketGroup extends ReactComponent {

    static get builderInfo() {
        return {
            title: "TicketGroup",
            icon: "calendar",
            group: "Basic",
            documentation: "",
            weight: -10,
            schema: TicketGroup.schema()
        };
    }

    static schema = () => ReactComponent.schema({ type: "TicketGroup", label: "TicketGroup" });

    attachReact = element => DOM.createRoot(element).render(<DefaultComp />);
    detachReact = element => element ? DOM.createRoot(element).unmount() : null;
}