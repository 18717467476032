import _ from "lodash";
import * as T from "./Types"
import * as TB from "./ToolBox";
import * as S from "../services";
import * as TC from "./textCode";
import { v4 as uuid } from "uuid";
import * as M from "../Components/Modal";
import { CRAFT_FILE_URL } from "./Data/linkMaker";
import { uploadLocalFile } from "../services/user.service";
import FileUploadComp from "../Components/Formio/Components/Premium/FileUploadComp";

type ImportImgFnParams = {
    title?: string;
    label?: string;
    imageSize?: number;
    fileMaxSize?: string;
}

type ReportDownloadExtraParams = {
    name: string;
    extension: string;
    language?: number;
}

export const importNewImg = (params: ImportImgFnParams) => new Promise<T.File[] | null>((resolve, reject) => {
    let newParams: ImportImgFnParams = TB.getObject(params, { imageSize: 250, fileMaxSize: "15MB" });
    let images_promise = M.renderBlankModal<T.File[]>({
        title: newParams.title,
        onQuit: () => resolve(null),
        size: "md",
        //@ts-ignore
        renderContent: resolve => <FileUploadComp
            image
            setter={resolve}
            newStorage="local"
            label={TB.getString(newParams.label)}
            imageSize={TB.getNumber(newParams.imageSize, 250)}
            fileMaxSize={TB.getString(newParams.fileMaxSize, "15MB")}
        />,
    });
    images_promise.then(images => {
        if (Array.isArray(images) && images.every(TB.isFile) && images.length > 0) {
            let purgedImages = images
                .map(file => _.omit(file, ["isAdded", "isB64"]))
                .filter(TB.isFile);
            resolve(purgedImages);
        }
        else resolve(null);
    });
});

export const fileForSend = (file: File | string) => new Promise<FormData>((resolve, reject) => {
    let formData = new FormData();
    if (typeof file === "string") {
        formData.append("file", file);
        resolve(formData);
    }
    else getB64FromFileObject(file).then(b64 => {
        formData.append("file", b64);
        resolve(formData);
    }).catch(reject);
});

export const uploadNewFile = (name: string, b64: string, _id: string) => new Promise<{ name: string, url: string }>((resolve, reject) => {
    if (!TB.validString(name) || !TB.validString(b64) || !TB.mongoIdValidator(_id)) reject(null);
    else {
        let formData = new FormData();
        formData.append("file", b64);
        uploadLocalFile(_id, name, formData)
            .then(() => resolve({ name, url: CRAFT_FILE_URL(_id, name) }))
            .catch(reject);
    }
});

export const destructureFileListSingleFile = (fileList: FileList) => new Promise<T.File>((resolve, reject) => {
    if (fileList instanceof FileList) {
        let file = fileList.item(0);
        if (!file) reject(null);
        else {
            let { name, size, type } = file;
            getB64FromFileObject(file)
                .then(b64 => resolve({
                    size,
                    type,
                    url: b64,
                    storage: "local",
                    originalName: name,
                    name: `${uuid()}-${name}`,
                }))
                .catch(reject);
        }
    }
    else reject(null);
});

export const downloadJsReportFile = (templateName: string, options: ReportDownloadExtraParams, params: T.AnyObject) => new Promise((resolve, reject) => {
    let unmountLoader = M.renderLoader(TC.GLOBAL_GENERATING_FILE);
    if (!TB.validObject(params)) params = {};

    const handleError = (error?: any) => {
        unmountLoader();
        M.renderAlert({ delay: 10, type: "error", message: TC.GLOBAL_FAIL_GEN_FILE });
        reject(error);
    }

    if (!TB.validString(templateName) || !TB.validObject(options)) handleError();
    else if (!TB.validString(options.extension) || !TB.validString(options.name)) handleError();
    else S.file_report({ template: templateName, params }).then(({ data }) => {
        if (data instanceof ArrayBuffer) TB.asyncArrayBufferToBase64(data).then(base64 => {
            TB.downloadFile(base64, options.name, options.extension);
            unmountLoader();
        }).catch(handleError);
        else handleError();
    }).catch(error => handleError(error));
});

export const getB64FromFileObject = (file: File) => new Promise<string>((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = reject;
    reader.onload = () => {
        if (typeof reader.result === "string") resolve(reader.result);
        else resolve(arrayBufferToBase64(reader.result));
    }
});

export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}