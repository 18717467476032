import DOM from 'react-dom/client';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class Actions extends ReactComponent {
    static get builderInfo() {
        return {
            weight: 1,
            group: "Custom",
            title: "Actions",
            documentation: "",
            schema: Actions.schema(),
            icon: "exclamation-triangle",
        };
    }

    static schema = () => ReactComponent.schema({ type: "Actions", label: "Actions" });
    attachReact = element => DOM.createRoot(element).render(<DefaultComp />);
    detachReact = element => element ? DOM.createRoot(element).unmount() : undefined;
}