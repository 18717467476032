import { AxiosError } from "axios";
import * as R from "../../../reducers";
import { FP, TC } from "../../../Constants";

type AlertProps = Parameters<typeof R.renderAlert>[0];
type RenderAlertProps = AlertProps | string | Error | AxiosError;

const mixProps = (defaultProps: AlertProps, fnProps?: RenderAlertProps): AlertProps => {
    let extra_props: typeof defaultProps = {},
        as_axios_error = fnProps as AxiosError;
    // Error is just a string
    if (typeof fnProps === "string") extra_props.message = fnProps;
    // Axios error
    else if (typeof as_axios_error?.response?.data === "string") extra_props.message = as_axios_error?.response?.data;
    // Regular error or given props
    else extra_props = fnProps as AlertProps || {};
    // Mix the new props with provided ones
    return { ...defaultProps, ...extra_props };
};

const ERROR_LOAD: AlertProps = { type: "error", message: TC.GLOBAL_FAILED_LOAD };
const CLIPBOARD_COPY: AlertProps = { type: "success", message: TC.GLOBAL_COPIED };
const SUCCESS: AlertProps = { type: "success", message: TC.GLOBAL_SUCCESS_UPDATE };
const ERROR_DELETE: AlertProps = { type: "error", message: TC.GLOBAL_ERROR_DELETE };
const ERROR_UPDATE: AlertProps = { type: "error", message: TC.GLOBAL_ERROR_UPDATE };
const GEN_FILE_FAIL: AlertProps = { type: "error", message: TC.GLOBAL_FAIL_GEN_FILE };
const HAS_NOT_RIGHT: AlertProps = { type: "warning", message: TC.GLOBAL_HAVE_NOT_RIGHT };
const FILE_UPLOAD_FAIL: AlertProps = { type: "error", message: TC.FILE_FAILED_UPLOAD, delay: -1 };
const FAILED_CONTEXT_NAME: AlertProps = { type: "warning", message: TC.GLOBAL_FAILED_NAME_CONTEXT };
const ERROR_LOAD_INFINITE: AlertProps = { type: "error", delay: -1, message: TC.GLOBAL_FAILED_LOAD };
const LINK_DUPE: AlertProps = { type: "warning", message: TC.ALERT_LINK_DUPE_MSG, title: TC.ALERT_LINK_DUPE_TITLE };
const MISSING_SITES: AlertProps = { type: "warning", message: { ref: TC.GLOBAL_NO_X_CONTEXT, template: FP.SITE_FORM } };

export const loadError = (props?: RenderAlertProps) => R.renderAlert(mixProps(ERROR_LOAD, props));
export const success_alert = (props?: RenderAlertProps) => R.renderAlert(mixProps(SUCCESS, props));
export const updateError = (props?: RenderAlertProps) => R.renderAlert(mixProps(ERROR_UPDATE, props));
export const deleteError = (props?: RenderAlertProps) => R.renderAlert(mixProps(ERROR_DELETE, props));
export const linkDupeWarning = (props?: RenderAlertProps) => R.renderAlert(mixProps(LINK_DUPE, props));
export const haveNotRight = (props?: RenderAlertProps) => R.renderAlert(mixProps(HAS_NOT_RIGHT, props));
export const missingContextSites = (props?: RenderAlertProps) => R.renderAlert(mixProps(MISSING_SITES, props));
export const failedFileUpload = (props?: RenderAlertProps) => R.renderAlert(mixProps(FILE_UPLOAD_FAIL, props));
export const file_generation_fail = (props?: RenderAlertProps) => R.renderAlert(mixProps(GEN_FILE_FAIL, props));
export const infiniteLoadError = (props?: RenderAlertProps) => R.renderAlert(mixProps(ERROR_LOAD_INFINITE, props));
export const failedContextName = (props?: RenderAlertProps) => R.renderAlert(mixProps(FAILED_CONTEXT_NAME, props));
export const clipboard_copy_success = (props?: RenderAlertProps) => R.renderAlert(mixProps(CLIPBOARD_COPY, props));