import React from "react";
import * as C from "../Common";
import * as S from "../services";
import { T } from "../Constants";
import useSocket from "./useSocket";
import * as DOM from 'react-router-dom';
import useLanguage from "./useLanguage";
import { useSelector } from "react-redux";
import { Modal as M } from "../Components";

type Alerts = ReturnType<T.API.Access.User.GetUserInfosBubble>[number];

const useInfosBubbles = () => {
    const lg = useLanguage();
    const location = DOM.useLocation();
    const { setCallbacks, unsetCallbacks } = useSocket();
    const [alerts, set_alerts] = React.useState<Alerts[]>([]);
    const translations = useSelector((redux: T.ReduxSelector) => redux.storedLanguages.object);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getUserInfosBubble()
            .then(({ data }) => isSubscribed && set_alerts(data))
            .catch(() => isSubscribed && set_alerts([]));
        return () => {
            isSubscribed = false;
            set_alerts([]);
        }
    }, []);

    React.useEffect(() => {
        setCallbacks([{
            event: "reload_alerts",
            callback: () => S.getUserInfosBubble()
                .then(({ data }) => set_alerts(data))
                .catch(() => set_alerts([]))
        }]);

        return () => unsetCallbacks(["reload_alerts"]);
    }, [setCallbacks, unsetCallbacks]);

    React.useEffect(() => {
        const page_alerts = alerts.filter(alert => alert.page === "any" || "/" + alert.path === location.pathname);

        if (page_alerts.length > 0 && translations.length > 0) {
            for (const alert of page_alerts) {
                const alert_translation = translations.filter(t => t._id === alert._id);
                const title = alert_translation.find(t => t.prop === "title")?.[lg.prop] || alert.title;
                const message = alert_translation.find(t => t.prop === "message")?.[lg.prop] || alert.message;

                // Render the alert
                M.renderAlert({
                    title,
                    size: alert.size,
                    type: alert.variant,
                    bubble_id: alert._id,
                    delay: alert.duration,
                    children: <C.HtmlText html={message} />,
                    on_dismiss: type => type === "ignore" && set_alerts(p => p.filter(a => a._id !== alert._id)),
                });
            }
        }
    }, [location.pathname, alerts, translations, lg]);

};

export default useInfosBubbles;