import axios from "axios";
import { getHeader } from "../Headers";
import { APIS, T } from "../../Constants";

const { ITEM_ORDER_API } = APIS;

//#region Types
type SortItem = (items: { id: string, relative?: string }[]) => T.AxiosPromise<T.BulkResults>;
//#endregion

export const sortItem: SortItem = items => axios.post(ITEM_ORDER_API + "sortItem", { items }, getHeader("EDIT"));
export const getManyRelativeItems = (filter: T.AnyObject) => axios.post<T.ItemOrder[]>(ITEM_ORDER_API + "getManyRelativeItems", { filter });