import { TB } from "../../../Constants";
import renderInContainer from "../getContainer";
import { LinkModal, LinkModalProps } from "../Components";

export type AskLinkModalProps = Omit<LinkModalProps, "onClose" | "onCreate">;

const askLink = (params?: AskLinkModalProps) => new Promise<Parameters<LinkModalProps["onCreate"]>[0]>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};
    if (render && dismount) {
        let onClose = () => dismount(() => resolve(null));
        let onCreate: LinkModalProps["onCreate"] = link => dismount(() => resolve(link));

        render(<LinkModal {...params} onClose={onClose} onCreate={onCreate} />);
    }
    else resolve(null);
});

export default askLink;