/* import React from 'react'
import useEventListener from "./useEventListener";

type Handler = (event: MouseEvent) => void

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(ref: React.RefObject<T>, handler: Handler, mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',): void => {
    useEventListener(mouseEvent, event => {
        const el = ref?.current
        // Do nothing if clicking ref's element or descendent elements
        if (!el || el.contains(event.target as Node)) return;
        handler(event)
    });
}

export default useOnClickOutside */

import type { RefObject } from 'react'
import useEventListener from "./useEventListener";

type EventType =
  | 'mousedown'
  | 'mouseup'
  | 'touchstart'
  | 'touchend'
  | 'focusin'
  | 'focusout'

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T> | RefObject<T>[],
  handler: (event: MouseEvent | TouchEvent | FocusEvent) => void,
  eventType: EventType = 'mousedown',
  eventListenerOptions: AddEventListenerOptions = {},
): void {
  useEventListener(
    eventType,
    event => {
      const target = event.target as Node

      // Do nothing if the target is not connected element with document
      if (!target || !target.isConnected) {
        return
      }

      const isOutside = Array.isArray(ref)
        ? ref
            .filter(r => Boolean(r.current))
            .every(r => r.current && !r.current.contains(target))
        : ref.current && !ref.current.contains(target)

      if (isOutside) {
        handler(event)
      }
    },
    undefined,
    eventListenerOptions,
  )
}

export default useOnClickOutside;