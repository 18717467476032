import { settings } from './config';
import { AppContext } from './Context';
import { useToggleStyle } from './hooks';
import React, { useMemo, useReducer } from 'react';
import { configReducer } from './reducers/configReducer';

import "./Constants/_master.scss";

const getColor = (name: string, dom = document.documentElement) => getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try { return JSON.parse(store.getItem(key)) || defaultValue; }
  catch { return store.getItem(key) || defaultValue; }
};

const Main: React.FC<Record<"children", React.ReactElement>> = props => {
  const configState = useMemo(() => ({
    navbarCollapsed: false,
    showSettingPanel: false,
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    isNavbarVerticalCollapsed: getItemFromStore('isNavbarVerticalCollapsed', settings.isNavbarVerticalCollapsed),
  }), []);

  const [config, configDispatch] = useReducer(configReducer, configState);
  const { isLoaded } = useToggleStyle(config.isRTL, config.isDark, configDispatch);

  const setConfig = React.useCallback((key, value) => (configDispatch as any)({
    type: 'SET_CONFIG',
    payload: { key, value, setInStore: ['isFluid', 'isRTL', 'isDark', 'navbarPosition', 'isNavbarVerticalCollapsed', 'navbarStyle'].includes(key) }
  }), [configDispatch]);

  const provider_value = React.useMemo<any>(() => ({ config, setConfig, configDispatch }), [config, setConfig, configDispatch]);

  if (!isLoaded) return <div style={{ top: 0, left: 0, right: 0, bottom: 0, position: 'fixed', backgroundColor: config.isDark ? getColor('dark') : getColor('light') }} />;
  else return <AppContext.Provider value={provider_value} children={props.children} />;
};

export default Main;
