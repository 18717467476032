import "./Element.scss"
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { FP, T, TB, TC } from "../../../../Constants";

const get_stack_effect = (color: string, hidden = false) => {
  if (!hidden) return "";
  else return `0 1px 1px rgba(0,0,0,0.15),0 10px 0 -5px ${color},0 10px 1px -4px rgba(0,0,0,0.15),0 20px 0 -10px ${color},0 20px 1px -9px rgba(0,0,0,0.15)`;
};

const Node: React.FC<T.MakeRequired<T.DiaElementProps, "onPick">> = ({ allowDrop, toggle_menu, onDrop, onPick, ...props }) => {

  const [drop, use_drop] = useDrop<T.DiaElementProps["node"], void, { canDrop: boolean, isOver: boolean }>({
    accept: "node",
    drop: item => onDrop?.(item.id, props.node.id),
    canDrop: item => typeof allowDrop === "function" ? allowDrop?.(item, props.node) : true,
    collect: monitor => ({ canDrop: monitor.canDrop(), isOver: monitor.isOver({ shallow: true }) }),
  }, [onDrop, allowDrop, props.node.id]);

  const [drag, use_drag] = useDrag({
    item: props.node,
    type: 'node',
    canDrag: props.allowDrag,
    collect: monitor => ({ opacity: monitor.isDragging() ? 0.5 : 1 })
  }, [props.node.id, props.allowDrag]);

  const render = React.useMemo(() => ({
    img: TB.iconIdToUrl(props.node.icon),
    color: TB.getColor(props.node.color) || "#FFFFFF",
    hover_color: drop.canDrop ? "#4EFC03" : "#FC0303",
    mail: props.node.path === FP.USER_FORM ? props.node.data.email : undefined,
  }), [props.node.icon, props.node.color, drop.canDrop, props.node.path, props.node.data.email]);

  const style = React.useMemo<React.CSSProperties>(() => {
    if (drop.isOver) return {
      opacity: drag.opacity,
      transform: "scale(1.1)",
      color: render.hover_color,
      backgroundColor: render.hover_color,
      boxShadow: get_stack_effect(render.hover_color, props.hidden),
    };
    else return {
      color: render.color,
      opacity: drag.opacity,
      backgroundColor: render.color,
      boxShadow: get_stack_effect(render.color, props.hidden),
    }
  }, [drop.isOver, drag.opacity, render.hover_color, render.color, props.hidden]);

  const switch_menu = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(event => {
    event.preventDefault();
    toggle_menu?.();
  }, [toggle_menu]);

  const click_button = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(event => {
    // User pressed the control key, so toggle the node's full descendance
    if (event.ctrlKey) onPick?.(TC.TREE_REVEAL, props.node, false);
    // User only clicked the button, so toggle the node's direct children
    else onPick?.(TC.TREE_REVEAL, props.node, true);
  }, [onPick, props.node]);

  return <div key={props.node.id} className="w-100 h-100" ref={use_drop as any}>
    <div ref={use_drag as any} className="mainDivElement defaultElem" style={style}>
      <button className='slideMenuButton' onClick={click_button} onContextMenu={switch_menu}>
        <div className="name">
          <p children={props.node.label} />
          {render.mail && <span className="text-muted text-center text-break">{render.mail}</span>}
        </div>
        {render.img && <div className="otherInfos solo" children={<div className='img' children={<img src={render.img} alt="" />} />} />}
      </button>
    </div>
  </div>
}

export default Node;