import React from "react";
import * as H from "../../../hooks";
import { IconTip } from "../../Item";
import { T, TB } from "../../../Constants";
import { Accordion } from "react-bootstrap";
import { ComponentWrapper, Input, InputProps } from "../Input";

export type PanelProps = InputProps & {
    title?: string;
    collapsed?: boolean;
    collapsible?: boolean;
    components?: T.FormComponentsMain[];
}

const Panel: React.FC<PanelProps> = ({ collapsed, components, title, collapsible, onChange, ...props }) => {
    const lg = H.useLanguage();
    const isCollapsed = H.useBoolean(collapsed);

    React.useEffect(() => {
        if (isCollapsed.value && !collapsible) isCollapsed.setFalse();
    }, [isCollapsed, collapsible]);

    const vComponents = React.useMemo(() => TB.getArray(components), [components]);
    const collapse = React.useCallback(() => collapsible && isCollapsed.toggle(), [collapsible, isCollapsed]);

    return <ComponentWrapper {...props} tooltip={null} label={null}>
        <Accordion activeKey={isCollapsed.value ? "1" : "0"} onSelect={collapse}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    {lg.getStaticText(title)}
                    {props.tooltip && <span className="ms-2">
                        <IconTip tipContent={props.tooltip} icon="question-mark-circle" />
                    </span>}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                    {vComponents.map((c, i, a) => <Input
                        {...c}
                        key={c.key}
                        prop={c.key}
                        formId={props.formId}
                        lock_form={props.lock_form}
                        contextKey={props.contextKey}
                        unlock_form={props.unlock_form}
                        submissionId={props.submissionId}
                        noBottomMargin={i === a.length - 1}
                        set_translation={props.set_translation}
                        onAddCertification={props.onAddCertification}
                        onChange={(v, p) => onChange?.(v, p || c.key)}
                    />)}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </ComponentWrapper>
}

Panel.displayName = "Panel";
export default Panel;