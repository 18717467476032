import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as R from "../../reducers";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { TC, TB, REGEX, T } from "../../Constants";

const TEXT_CODES = [TC.FORGOT_PASSWORD];

const Login: React.FC = () => {
  H.useSocket();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lg = H.useLanguage(TEXT_CODES);
  const isLogging = H.useBoolean(false);
  const pwdRef = React.useRef<C.Form.PasswordRef>(null);
  const mailRef = React.useRef<C.Form.TextFieldRef>(null);
  const [errors, setErrors] = React.useState<T.Errors<Record<"mail" | "password", string>, "login">>({});

  //#region Events
  const checkUserInfos = React.useCallback(() => {
    let newErrors: typeof errors = {};
    let log = {
      password: pwdRef.current?.get_controlled?.() || "",
      mail: mailRef.current?.single?.current?.get_controlled_single?.() || "",
    };
    // Basic data validation
    if (!TB.validString(log.mail)) newErrors.mail = TC.GLOBAL_REQUIRED_FIELD;
    else if (!log.mail.match(REGEX.MAIL_REGEX)) newErrors.mail = TC.REG_MAIL_INVALID;
    if (!TB.validString(log.password)) newErrors.password = TC.GLOBAL_REQUIRED_FIELD;

    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
    else {
      // User tried to log too many times
      let noLogTimeLimit = localStorage.getItem("disableLog");
      let allowLog = noLogTimeLimit === null || new Date(noLogTimeLimit).getTime() < new Date().getTime();

      if (allowLog) {
        // Indicate that we start the login process
        isLogging.setTrue();
        S.login(log).then(({ data }) => {
          // Login infos invalid
          if (data === "INVALID_LOGIN") setErrors({ login: TC.LOGIN_INVALID });
          else {
            // Update local storage
            localStorage.setItem("formioToken", data.token);
            localStorage.setItem("formioUser", JSON.stringify(data.user));
            // Update redux
            dispatch(R.login({ user: data.user, admin_log: data.admin_log }));
            if (data.default_context) dispatch(R.selectDefaultContext(data.default_context));
            navigate("/");
          }
        })
          .catch(() => setErrors({ login: TC.LOGIN_FAIL }))
          .finally(isLogging.setFalse);
      }
      else setErrors({ login: TC.LOGIN_TEMP_NO_LOG });
    }
  }, [isLogging, navigate, dispatch]);

  H.useEventListener("keydown", e => {
    if (e.key === "Enter" || e.code === "Enter") {
      checkUserInfos();
      e.preventDefault();
    }
  });
  //#endregion

  //#region Reset Password
  const forgot_password = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(event => {
    event.preventDefault();
    let typed_mail = mailRef.current.single?.current?.get_controlled_single?.();
    M.askPrompt({ title: TC.PWD_CHANGE_MAIL_TITLE, label: TC.PWD_CHANGE_MAIL_MSG, defaultVal: typed_mail, mail: true, isRequired: true }).then(mail => {
      if (mail) S.forgot_password(mail as string).then(({ data }) => {
        if (data === "mail") M.renderAlert({ type: "info", message: TC.PWD_CHANGE_MAIL_SENT });
        else M.renderAlert({ type: "warning", message: TC.PWD_CHANGE_MAIL_NOT_EXISTS });
      }).catch(M.Alerts.loadError);
    })
  }, []);
  //#endregion

  return <div>
    <div className='mb-3'>
      <div className='text-center mb-3'>
        <i className="fa fa-user-circle-o fa-5x" />
      </div>

      {TB.validString(errors.login) && <C.ErrorBanner size="sm" type="warning" textCode={errors.login} />}

      <C.Form.TextField
        isEmail
        autoFocus
        ref={mailRef}
        label={TC.GENERAL_USERNAME}
        error={{ code: errors.mail }}
        placeholder={TC.GENERAL_USERNAME}
        onChange={() => errors.mail && setErrors(p => ({ ...p, mail: undefined }))}
      />

      <C.Form.Password
        ref={pwdRef}
        label={TC.PASSWORD}
        error={{ code: errors.password }}
        onChange={() => errors.password && setErrors(p => ({ ...p, password: undefined }))}
      />
    </div>

    <div className="mb-1">
      <C.Button icon={{ icon: "", spin: isLogging.value, spinIcon: "spinner" }} className="w-100" onClick={checkUserInfos} text={TC.LOGIN} />
    </div>

    <div className="text-muted text-center fs-85">
      <button onClick={forgot_password} className="text-decoration-underline btn-link-a">
        {lg.getStaticText(TC.FORGOT_PASSWORD)}
      </button>
    </div>
  </div>;
};

export default Login;
