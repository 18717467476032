import _ from "lodash";
import React from "react";
import moment from "moment";
import * as M from "../Modal";
import * as H from "../../hooks";
import { v4 as uuid } from "uuid";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { FlatLabelInput } from "../BuildEditor";
import { TC, T, TB, FP } from "../../Constants";
import * as US from "../../services/user.service";

//#region Types
export type RegDocProps = {
    action?: string;
    elemId?: string;
    regFormId?: string;
    portfolio?: string;
    forceNewDoc?: boolean;
    roots?: string | string[];
    onSave?: (regDoc: T.RegDoc | null, deletedDoc?: T.RegDoc) => void;
}

type Resource = {
    status: Status;
    regDoc?: T.RegDoc;
    elements: Elements[];
    regAction: T.Submission<T.RegAction & Record<"applicable_to", (string | Record<"id" | "name", string>)[]>> | null;
    /** The list of users found in a signature field, always contains the current user by default */
    users: T.Option[];
}

type Elements = T.API.Reg.RegElemResume;
type RegExtraSignature = T.RegExtraSignature;
type Errors = { [key in ErrorsKey]?: string };
type Status = "load" | "ready" | "error" | "invalidAction" | "invalidElem";
type ErrorsKey = "last_control" | "files" | "controller" | "elements" | "currentElem" | "frequency" | "status";
//#endregion

//#region Constants
const DF_REG_FORM: Partial<T.RegDoc> = {
    files: [],
    type: "reg",
    frequency: "1y",
    last_control: "",
    formerElement: [],
    currentElement: [],
}

const getDefaultRegForm = (action: string, elem: string) => {
    let clone = { ...DF_REG_FORM };
    clone.regAction = action;
    clone.currentElement = [{ elem, status: "conform", id: uuid() }];
    return clone;
}

const DF_INIT: Resource = { status: "load", regAction: null, elements: [], users: [] };
//#endregion

const RegDocForm: React.FC<RegDocProps> = ({ action, elemId, regFormId, onSave, roots, forceNewDoc, portfolio, ...props }) => {
    const lg = H.useLanguage();
    const [{ userId }] = H.useAuth();
    const is_saving = H.useBoolean(false);
    const [rootContext, , updateRoots] = H.useRoots();
    const [errors, setErrors] = React.useState<Errors>({});
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [showOnlyConform, setShowOnlyConform] = React.useState(true);
    const [extra_errors, set_extra_errors] = React.useState<Record<string, any>>({});
    const [note_tags, set_note_tags, note_tags_status] = H.useAsyncState<T.Option[]>([]);
    const [editedRegDoc, setEdited] = React.useState<Partial<T.RegDoc>>(getDefaultRegForm(action, elemId));
    const [{ status, regAction, regDoc, elements, users }, setResource] = React.useState<Resource>(DF_INIT);

    const auto_generate_lock = React.useMemo(() => {
        let is_final_file = !!editedRegDoc?.final_file;
        let is_auto_file = !!regAction?.data?.auto_file;
        if (!is_auto_file) return { show_file: true, disabled_all: false };
        else return { show_file: is_final_file, disabled_all: is_final_file };
    }, [regAction?.data?.auto_file, editedRegDoc?.final_file]);

    const reg_action_name = React.useMemo(() => lg.getElemObj(regAction?._id, "name", regAction?.data?.name), [lg, regAction]);

    //#region Fetch Data
    React.useEffect(() => updateRoots({ roots: roots, portfolio }), [updateRoots, roots, portfolio]);
    const apiParams = React.useMemo(() => ({ elemId, action, regFormId, isRegForm: true, ...rootContext }), [action, elemId, regFormId, rootContext]);

    React.useEffect(() => {
        let isSubscribed = true;
        const onError = (status: Status) => setResource({ ...DF_INIT, status });

        US.getFullResources(FP.ACTION_REG_FORM, apiParams)
            .then(({ data }) => {
                if (isSubscribed) {
                    if (data?.hasFailed) {
                        if (data?.error === "invalidAction") onError("invalidAction");
                        else if (data?.error === "invalidElem") onError("invalidElem");
                        else onError("error");
                    }
                    else setResource({
                        ...data,
                        status: "ready",
                        regDoc: forceNewDoc ? undefined : data.regDoc,
                    });
                }
            })
            .catch(() => isSubscribed && onError("error"));

        return () => { isSubscribed = false };
    }, [action, forceNewDoc, apiParams, rootContext]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getNoteTags({ context: { roots, portfolio }, user: userId, type: "regDoc" })
            .then(({ data }) => isSubscribed && set_note_tags(data, 'done'))
            .catch(() => isSubscribed && set_note_tags([], "error"));

        return () => {
            isSubscribed = false;
            set_note_tags([], "load");
        };
    }, [userId, roots, portfolio, set_note_tags]);
    //#endregion

    //#region Error & Load
    const errorBanner = React.useMemo(() => {
        if (status === "load") return <M.Loader />;
        if (status === "error") return <C.ErrorBanner type="danger" textCode={TC.GLOBAL_FAILED_LOAD} />;
        if (status === "invalidElem") return <C.ErrorBanner type="warning" textCode={TC.REG_INVALID_ELEM} />;
        if (status === "invalidAction") return <C.ErrorBanner type="warning" textCode={TC.REG_INVALID_ACTION} />;
        return null;
    }, [status]);
    //#endregion

    //#region Errors
    const invalids = React.useMemo<{ [key in ErrorsKey]?: boolean }>(() => Object.fromEntries(Object.entries(errors).map(([key, error]) => [key, TB.validString(error)])), [errors]);

    React.useEffect(() => {
        let date = TB.getDate(editedRegDoc.last_control);
        if (errors.last_control && date && date.getFullYear() <= 9999) setErrors(p => _.omit({ ...p }, "last_control"));
    }, [errors.last_control, editedRegDoc.last_control]);

    React.useEffect(() => {
        let f = TB.splitFrequency(editedRegDoc.frequency);
        if (errors.frequency && (f || editedRegDoc.frequency === null)) setErrors(p => _.omit({ ...p }, "frequency"));
    }, [errors.frequency, editedRegDoc.frequency])

    React.useEffect(() => {
        if (errors.controller && TB.validString(editedRegDoc.controller)) setErrors(p => _.omit({ ...p }, "controller"));
    }, [errors.controller, editedRegDoc.controller]);

    React.useEffect(() => {
        if (errors.elements && editedRegDoc.currentElement.every(e => TB.mongoIdValidator(e.elem))) setErrors(p => _.omit({ ...p }, "elements"));
    }, [errors.elements, editedRegDoc.currentElement]);
    //#endregion

    //#region Defaults
    React.useEffect(() => {
        if (status === "ready" && regDoc) {
            let updatedRegDoc = _.clone(regDoc);
            let date = TB.getDate(updatedRegDoc.last_control);
            updatedRegDoc.last_control = date === null ? undefined : moment(date).format("YYYY-MM-DD");

            let formerElements = TB.getArray(regDoc.formerElement).filter(f => f.elem === elemId);
            if (formerElements.length > 0) {
                updatedRegDoc.formerElement = updatedRegDoc.formerElement.filter(f => f.elem !== elemId);
                updatedRegDoc.currentElement = formerElements.concat(updatedRegDoc.currentElement);
            }
            setEdited(updatedRegDoc);
        }
    }, [status, regDoc, elemId]);

    React.useEffect(() => {
        if (status === "ready" && regAction && !regDoc) {
            let frequency = null;
            if (!regAction.data.noFreq) {
                if (TB.splitFrequency(regAction.data.frequency)) frequency = regAction.data.frequency;
                else frequency = "Y";
            }
            setEdited(p => ({ ...p, frequency, type: regAction.data.type }));
        }
    }, [status, regAction, regDoc]);
    //#endregion

    //#region Frequency
    const timeOptions = React.useMemo(() => [
        { value: "y", label: TC.YEAR },
        { value: "M", label: TC.GLOBAL_MONTH },
        { value: "w", label: TC.GLOBAL_WEEK },
        { value: "d", label: TC.GP_GROUP_DAY },
    ], []);

    const onChangeNumFrequency = React.useCallback((num: string) => {
        let number = TB.getNumber(num);
        if (isNaN(number)) number = 1;
        setEdited(p => {
            let newFreq = p.frequency.replace(/\d+/g, number.toString());
            if (!TB.splitFrequency(newFreq)) newFreq = number + "y";
            return { ...p, frequency: newFreq };
        });
    }, []);

    const defaultFrequency = React.useMemo(() => TB.splitFrequency(regAction?.data?.frequency), [regAction]);
    const onChangeUnitFrequency = React.useCallback((unit: string) => setEdited(p => ({ ...p, frequency: p.frequency.replace(/[a-zA-Z]+/g, unit) })), []);

    const frequency = React.useMemo(() => {
        let freq = TB.splitFrequency(editedRegDoc.frequency);
        if (freq !== null) return freq;
        if (editedRegDoc.frequency === null) return null;
        return { num: "", unit: "y" };
    }, [editedRegDoc.frequency]);

    const switchFrequency = React.useCallback((checked: boolean) => {
        if (checked) setEdited(p => ({ ...p, frequency: regAction?.data?.frequency || "" }));
        else setEdited(p => ({ ...p, frequency: null }));
    }, [regAction?.data?.frequency]);

    const defaultFreqPanel = React.useMemo(() => {
        if (!defaultFrequency) return null;
        if (!frequency || (frequency.unit === defaultFrequency.unit && frequency.num === defaultFrequency.num)) return null;

        let timeUnit = timeOptions.filter(t => t.value === defaultFrequency.unit)[0]?.label;

        return <div className="text-warning text-end">
            {lg.getStaticText(TC.REG_DEFAULT_FREQ)} {defaultFrequency.num} {timeUnit}
        </div>
    }, [timeOptions, frequency, defaultFrequency, lg]);

    const expirationDate = React.useMemo(() => {
        let date = TB.getDate(editedRegDoc.last_control);
        if (date === null || frequency === null) return "";
        /* @ts-ignore */
        return typeof frequency.num === "number" ? moment(date).add(frequency.num, frequency.unit).format("DD/MM/YYYY") : "";
    }, [editedRegDoc.last_control, frequency]);
    //#endregion

    //#region File
    const fileCol = React.useMemo(() => <C.Form.FileUploader
        multiple
        value={editedRegDoc.files}
        error={{ code: errors.files }}
        disabled={auto_generate_lock.disabled_all}
        onChange={files => setEdited(p => ({ ...p, files }))}
    />, [editedRegDoc.files, errors, auto_generate_lock.disabled_all]);
    //#endregion

    //#region Conformity Reason
    const getReason = React.useCallback((elem: Partial<Elements> & { label: string }, isExtra = false) => {
        if (!elem) return null;

        let errors: string[] = [];
        let c = elem.conformity;
        let isWarning = false;
        let isRedAlert = !c.resourceMatch || !(c.gammeMatch || c.unPreciseGamme) || !c.locMatch || (!c.conditionMatch && !c.matchError);

        if (isRedAlert) {
            if (!c.locMatch) errors.push(lg.getStaticText(TC.REG_INVALID_REGION_COUNTRY));
            else if (!c.resourceMatch) errors.push(lg.getStaticText(TC.REG_INVALID_RESOURCE));
            else if (!c.gammeMatch) errors.push(lg.getStaticText(TC.REG_NOT_COMPATIBLE_GAMME_EQUIP));
            else if (!c.conditionMatch) errors.push(lg.getStaticText(TC.REG_FAILED_CONDITION));
        }
        else {
            if (!c.isOccupation) errors.push(lg.getStaticText(TC.REG_CELL_NOT_OCCUPATION));
            else if (c.unPreciseGamme) errors.push(lg.getStaticText(TC.REG_GAMME_UNPRECISE));
            if (c.matchError?.noFormula) errors.push(lg.getStaticText(TC.REG_NO_FORMULA));
            else if (c.matchError?.invalidFormula) errors.push(lg.getStaticText(TC.REG_INVALID_FORMULA));
            else if (c.matchError?.missingTerms?.length > 0) for (let t of c.matchError.missingTerms) errors.push(lg.getStaticText(TC.REG_MISSING_TERM, t));
            if (errors.length > 0) isWarning = true;
        }

        let variant = "text-" + (isRedAlert ? "danger" : (isWarning ? "warning" : "success"));
        let icon = (isRedAlert ? "exclamation" : (isWarning ? "question" : "check")) + "-circle";

        if (!isRedAlert && !isWarning) return isExtra ? null : <i className={`fa fa-${icon} ${variant}`}></i>;

        let content = <i title={errors.join("\n")} className={`fa fa-${icon} ${variant}`} />;
        return content;
    }, [lg])
    //#endregion

    const extra_properties = React.useMemo(() => TB.nesting_reg_properties(regAction?.data?.extra_properties || []), [regAction?.data?.extra_properties]);

    //#region Elements
    const sortElemOptions = React.useCallback((a: Elements, b: Elements) => {
        if (a.path === b.path) {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return 1;
            return 0;
        }
        else return a.path > b.path ? 1 : -1;
    }, []);

    const renderElement = React.useCallback((e: Partial<Elements> & { value: string, label: string }) => <C.Flex justifyContent="between" alignItems="center">
        <i className={`fa fa-${e.path === FP.BUILDING_FORM ? "building" : (e.path === FP.EQUIPEMENT_FORM ? "cog" : "map-marker")}`}></i>
        <div title={e.full_loc} className="mx-2 text-break flex-grow-1 text-wrap text-center" children={e.label} />
        {getReason(e)}
    </C.Flex>, [getReason]);

    const sortByKey = React.useCallback((a: [string, T.RegFormElem[]], b: [string, T.RegFormElem[]]) => {
        if (a[0] === elemId) return -1;
        if (b[0] === elemId) return 1;
        if (a[0] === b[0]) return 0;
        if (TB.mongoIdValidator(a[0])) return -1;
        return 1;
    }, [elemId]);

    const nbGivenElem = React.useMemo(() => editedRegDoc.currentElement.filter(e => e.elem === elemId).length, [editedRegDoc.currentElement, elemId]);
    const groupedElem = React.useMemo(() => Object.entries(_.groupBy(editedRegDoc.currentElement, "elem")).sort(sortByKey), [editedRegDoc, sortByKey]);
    const elemOptions = React.useMemo(() => elements.sort(sortElemOptions).map(e => ({ ...e, value: e._id, label: e.name })), [elements, sortElemOptions]);

    const getElemOptions = React.useCallback((elem?: string) => {
        let alreadyConformStatus = TB.getArray(editedRegDoc?.currentElement)
            .filter(e => e.status === "conform" && TB.mongoIdValidator(e.elem))
            .map(e => e.elem);

        let options = elemOptions.filter(e => !alreadyConformStatus.includes(e.value) || e.value === elem);
        if (showOnlyConform) return options.filter(e => e.conform || e.value === elem);
        return options;
    }, [showOnlyConform, elemOptions, editedRegDoc?.currentElement]);

    const onAddElem = React.useCallback((elem?: string) => {
        setEdited(p => ({ ...p, currentElement: TB.getArray(p.currentElement).concat({ elem, status: "notes", id: uuid() }) }));
    }, []);

    const onChangeElem = React.useCallback((id: string, prop: string, value: string, textAreaRef?: HTMLTextAreaElement) => {
        TB.resizeTextArea(textAreaRef);
        let forcedStatus: T.RegStatus = null;

        setEdited(p => {
            if (prop === "elem") {
                let statuses = TB.getArray(p?.currentElement).filter(e => e.elem === value).map(e => e.status);
                if (statuses.includes("conform")) forcedStatus = "conform";
                else forcedStatus = "notes";
            }

            let newCurrentElement = p.currentElement.map(e => {
                if (e.id === id) {
                    let newE = _.set({ ...e }, prop, value);
                    if (forcedStatus) newE.status = forcedStatus;
                    return newE;
                }
                return e;
            });

            return { ...p, currentElement: newCurrentElement };
        });
    }, []);

    const onChangeStatus = React.useCallback((status: T.RegStatus, elemId: string, id: string) => {
        if (status === "conform") setEdited(p => {
            let isFound = false;
            let newArray = p.currentElement.map(e => {
                if (e.elem === elemId) {
                    if (isFound) return null;
                    else {
                        isFound = true;
                        return { ...e, status };
                    }
                }
                else return e;
            }).filter(e => e !== null);
            return { ...p, currentElement: newArray };
        });
        else setEdited(p => ({ ...p, currentElement: p.currentElement.map(e => e.id === id ? { ...e, status } : e) }));
    }, []);

    const onRemove = React.useCallback((id: string) => {
        setEdited(p => ({ ...p, currentElement: p.currentElement.filter(e => e.id !== id) }));
    }, []);
    //#endregion

    //#region Status
    const statusList = React.useMemo(() => [
        { value: "notes", label: TC.REG_FORM_NOTE, color: "#FFA726" },
        { value: "conform", label: TC.REG_FORM_CONFORM, color: "#4EAE53" },
        { value: "infraction", label: TC.REG_FORM_INFRACTION, color: "#FF7043" },
    ], []);

    const getStatusOptions = React.useCallback((elem: T.RegFormElem) => {
        let list = regAction?.data?.status_list || [];
        if (list.length > 0) return list.map(s => ({ value: s.code, label: s.lang[lg.prop] || Object.values(s.lang)[0], color: s.color }));
        else {
            let alreadyConformStatus = TB.getArray(editedRegDoc?.currentElement).some(e => e?.status === "conform" && e?.elem === elem?.elem && e.id !== elem.id);
            if (!alreadyConformStatus) return statusList;
            return statusList.filter(s => s.value !== "conform");
        }
    }, [lg, editedRegDoc?.currentElement, statusList, regAction?.data?.status_list]);

    const renderStatus = React.useCallback((opt: { label: string, color?: string }) => <span style={{ color: opt.color }}>{opt.label}</span>, []);
    //#endregion

    //#region Save
    const check_errors = React.useCallback((edit_doc = true) => {
        let errors: Errors = {};
        let new_extra_errors = {} as typeof extra_errors;
        let date = TB.getDate(editedRegDoc.last_control);
        let is_auto_generate_file = !!regAction?.data?.auto_file;
        let has_empty_files = editedRegDoc.files.length === 0 || editedRegDoc.files.every(f => f.storage === "removed");

        if (!date) errors.last_control = TC.GLOBAL_REQUIRED_FIELD;
        else if (date.getFullYear() > 9999) errors.last_control = TC.DATE_TOO_BIG;
        if (has_empty_files && !is_auto_generate_file) errors.files = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.validString(editedRegDoc.controller)) errors.controller = TC.GLOBAL_REQUIRED_FIELD;
        if (editedRegDoc.currentElement.length === 0) errors.currentElem = TC.GLOBAL_REQUIRED_FIELD;
        if (editedRegDoc.currentElement.some(e => !TB.mongoIdValidator(e.elem))) errors.elements = TC.GLOBAL_REQUIRED_FIELD;
        if (editedRegDoc.frequency !== null && !TB.splitFrequency(editedRegDoc.frequency)) errors.frequency = TC.GLOBAL_REQUIRED_FIELD;

        let is_missing_status = editedRegDoc.currentElement.some(e => {
            let available_status = getStatusOptions(e);
            return !available_status.some(s => s.value === e.status);
        });
        if (is_missing_status) errors.status = TC.REG_DOC_STATUS_REQUIRED_FIELD;

        const check_extra_prop = (item: typeof extra_properties[number], value: any, parent_prop: string) => {
            if (item.data_type === "date") {
                let date = TB.getDate(value);
                if (!date && item.required) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD;
            }
            else if (item.data_type === "number") {
                let number = TB.getNumber(value);
                if (isNaN(number) && item.required) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD
            }
            else if (item.data_type === "string") {
                let string = TB.getString(value);
                if (string.length === 0 && item.required) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD
            }
            else if (item.data_type === "boolean") {
                let bool = typeof value === "boolean" ? value : undefined;
                if (typeof bool !== "boolean" && item.required) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD
            }
            else if (item.data_type === "select") {
                if (!TB.validString(value) && item.required) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD
            }
            else if (item.data_type === "duration") {
                let freq = TB.splitFrequency(value);
                if (!freq && item.required) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD
            }
            else if (item.data_type === "element_multi_select") {
                let array_ids = value as string[];
                if (!Array.isArray(array_ids) || array_ids.length === 0) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD
                // Update the array through reference if some elements are no longer selected
                else if (edit_doc) array_ids.forEach((id, index) => {
                    let element_is_selected = (editedRegDoc.currentElement || []).some(e => e.elem === id);
                    if (!element_is_selected) array_ids.splice(index, 1);
                });
            }
            else if (item.data_type === "file" || item.data_type === "image") {
                let files = (value) as T.File[];
                if ((!Array.isArray(files) || files.length === 0) && item.required) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD
            }
            else if (item.data_type === "user_signature" || item.data_type === "simple_signature") {
                let signature = value as RegExtraSignature;
                if (!signature?.file && item.required) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD
            }
            else if (item.data_type === "simple_section") item.children.forEach(c => check_extra_prop(c, value?.[c.prop], parent_prop + item.prop));
            else if (item.data_type === "repetitive_section") {
                let dict = value as Record<string, any>;
                if (typeof dict !== "object" || dict === null) dict = {};
                // Update the array through reference if some elements are no longer selected
                if (edit_doc) Object.keys(dict).forEach(elem_id => {
                    let element_is_selected = (editedRegDoc.currentElement || []).some(e => e.elem === elem_id);
                    if (!element_is_selected) delete dict[elem_id];
                });
                // Check if values are not empty after removing unselected elements
                if (Object.values(dict).length === 0 && item.required) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD;
                // Check each element of the repetitive section
                else (editedRegDoc.currentElement).forEach(elem => item.children.forEach(c => check_extra_prop(c, dict[elem.elem]?.[c.prop], parent_prop + item.prop + elem.elem)));
            }
            else if (item.data_type === "list_section") {
                let array = value as any[];
                if (!Array.isArray(array)) array = [];
                if (array.length === 0 && item.required) new_extra_errors[parent_prop + item.prop] = TC.GLOBAL_REQUIRED_FIELD;
                else array.forEach((elem, index) => {
                    if (typeof elem !== "object" || elem === null) new_extra_errors[parent_prop + item.prop + index] = TC.GLOBAL_REQUIRED_FIELD;
                    else item.children.forEach(c => check_extra_prop(c, elem[c.prop], parent_prop + item.prop + index));
                });
            }
        };
        // Check if component for errors
        extra_properties.forEach(item => check_extra_prop(item, editedRegDoc.extra_properties?.[item.prop], ""));

        if (Object.keys(errors).length > 0 || Object.keys(new_extra_errors).length > 0) {
            setErrors(errors);
            set_extra_errors(new_extra_errors);
            return true;
        }
        else return false;
    }, [getStatusOptions, editedRegDoc, extra_properties, regAction?.data?.auto_file]);

    const saveForm = React.useCallback(() => {
        let has_errors = check_errors();
        if (!has_errors) {
            is_saving.setTrue();
            let unmount = M.renderLoader();

            let updatePromise: (doc: Partial<T.RegDoc>) => Promise<{ data: T.RegDoc }> | null = null;
            if (TB.mongoIdValidator(regDoc?._id)) updatePromise = S.updateRegForm;
            else updatePromise = S.createRegDoc;
            let actual_files = editedRegDoc.files?.filter?.(f => f.storage !== "removed");

            updatePromise({ ...editedRegDoc, files: actual_files || [] })
                .then(({ data }) => onSave?.(data))
                .catch(M.Alerts.updateError)
                .finally(() => {
                    unmount();
                    is_saving.setFalse();
                });
        }
    }, [onSave, editedRegDoc, is_saving, regDoc?._id, check_errors]);

    const deleteForm = React.useCallback(() => {
        if (regDoc && TB.mongoIdValidator(regDoc._id)) M.askConfirm().then(confirmed => {
            if (confirmed) S.removeRegForm(regDoc._id)
                .then(() => onSave?.(null, regDoc))
                .catch(M.Alerts.deleteError);
        });
    }, [regDoc, onSave]);

    const generate_file = React.useCallback((is_final = false) => {
        let has_errors = check_errors(false);
        if (has_errors) M.renderAlert({ type: "warning", title: TC.REG_DOC_FORM_GENERATE_FILE, message: TC.REG_DOC_FORM_GENERATE_FILE_HAS_ERRORS });
        else {
            const confirm_promise = new Promise<boolean | "canceled">((resolve) => {
                if (!is_final) resolve(true);
                else M.askConfirm({ title: TC.REG_DOC_FORM_GENERATE_FILE, text: TC.REG_DOC_FORM_GENERATE_FILE_CONFIRM }).then(confirmed => {
                    if (typeof confirmed !== "boolean") resolve("canceled")
                    else resolve(confirmed);
                });
            });

            confirm_promise.then(confirmed => {
                if (confirmed === "canceled") return;
                else {
                    is_saving.setTrue();
                    let unmount = M.renderLoader(TC.REG_DOC_FORM_GENERATING);

                    S.generateRegDocFile({ doc: editedRegDoc as T.RegDoc, is_final, users })
                        .then(({ data }) => {
                            // Update the edited reg doc with the new file
                            setEdited(data.doc);
                            // Notify the user that the doc has been saved
                            M.renderAlert({ type: "info", message: TC.REG_DOC_FORM_GENERATE_AUTO_SAVE, delay: 20 });
                            // Notify the user of the error that caused the file to not be generated
                            if (data.error) M.Alerts.file_generation_fail({ message: data.error });
                            // Download the generated file in the user's browser
                            if (data.file_link) {
                                const link = document.createElement('a');
                                link.href = data.file_link;
                                link.setAttribute('download', reg_action_name + '.docx'); // You can set a default file name here
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                        })
                        .catch(M.Alerts.updateError)
                        .finally(() => {
                            unmount();
                            is_saving.setFalse();
                        });
                }
            });
        }
    }, [check_errors, setEdited, is_saving, editedRegDoc, users, reg_action_name]);
    //#endregion

    const current_elem_options = React.useMemo(() => {
        let options = editedRegDoc.currentElement.map(e => {
            let elem = elements.find(el => el._id === e.elem);
            return { value: e.elem, label: elem?.name || e.elem };
        });
        return options;
    }, [elements, editedRegDoc.currentElement]);

    const render_extra_prop = React.useCallback((item: typeof extra_properties[number], setter: (value: any) => void, value = editedRegDoc?.extra_properties, name = "") => {
        let size = 6;
        let error_key = name + item.prop;
        let content: React.ReactNode = null;
        // Create a fully unique key
        let item_key = name + item.prop;
        if (item.data_type === "simple_section") size = 12;
        else if (item.data_type === "list_section") size = 12;
        else if (item.data_type === "repetitive_section") size = 12;

        if (item.data_type === "date") {
            content = <C.Form.DateTime
                noBottomMargin
                onChange={setter}
                required={item.required}
                value={value?.[item.prop]}
                label={item.names[lg.prop]}
                error={extra_errors[error_key]}
                disabled={auto_generate_lock.disabled_all}
            />;
        }
        else if (item.data_type === "number") {
            content = <C.Form.NumField
                noBottomMargin
                onChange={setter}
                required={item.required}
                value={value?.[item.prop]}
                label={item.names[lg.prop]}
                error={extra_errors[error_key]}
                disabled={auto_generate_lock.disabled_all}
                suffix={item.unit_tr?.[lg.prop] || item.unit}
            />;
        }
        else if (item.data_type === "string") {
            content = <C.Form.TextField
                noBottomMargin
                onChange={setter}
                required={item.required}
                value={value?.[item.prop]}
                label={item.names[lg.prop]}
                error={extra_errors[error_key]}
                disabled={auto_generate_lock.disabled_all}
                suffix={item.unit_tr?.[lg.prop] || item.unit}
            />;
        }
        else if (item.data_type === "boolean") {
            content = <C.Form.RadioBool
                noBottomMargin
                onChange={setter}
                name={name + item.prop}
                required={item.required}
                value={value?.[item.prop]}
                label={item.names[lg.prop]}
                error={extra_errors[error_key]}
                disabled={auto_generate_lock.disabled_all}
            />;
        }
        else if (item.data_type === "select") {
            content = <C.Form.Select
                noBottomMargin
                onChange={setter}
                required={item.required}
                value={value?.[item.prop]}
                label={item.names[lg.prop]}
                error={extra_errors[error_key]}
                disabled={auto_generate_lock.disabled_all}
                options={(item.unit || "").split("|").map(t => t.trim()).map((o, i) => ({ value: o, label: item.options?.[i]?.[lg.prop] || o }))}
            />;
        }
        else if (item.data_type === "duration") {
            content = <C.Form.Frequency
                noBottomMargin
                onChange={setter}
                required={item.required}
                value={value?.[item.prop]}
                label={item.names[lg.prop]}
                error={extra_errors[error_key]}
                disabled={auto_generate_lock.disabled_all}
            />;
        }
        else if (item.data_type === "file" || item.data_type === "image") {
            content = <C.Form.FileUploader
                multiple
                noBottomMargin
                onChange={setter}
                example_class="col-md-6"
                required={item.required}
                value={value?.[item.prop]}
                label={item.names[lg.prop]}
                error={extra_errors[error_key]}
                image={item.data_type === "image"}
                disabled={auto_generate_lock.disabled_all}
            />;
        }
        else if (item.data_type === "element_multi_select") {
            content = <C.Form.Select
                multiple
                noBottomMargin
                onChange={setter}
                required={item.required}
                value={value?.[item.prop]}
                label={item.names[lg.prop]}
                options={current_elem_options}
                error={extra_errors[error_key]}
                disabled={auto_generate_lock.disabled_all}
            />;
        }
        else if (item.data_type === "user_signature" || item.data_type === "simple_signature") {
            let description = "";
            let sign_data: RegExtraSignature = value?.[item.prop];
            let field_value: Pick<T.File, "url" | "storage">[] = sign_data?.file && !sign_data?.is_temp ? [{ url: sign_data.file, storage: "local" }] : null;
            if (sign_data) {
                description += lg.getStaticText(TC.REG_EXTRA_PROPS_SIGNED_THE, moment(sign_data.date).format("DD/MM/YYYY HH:mm"));
                if (item.data_type === "user_signature") {
                    let user_name = users.find(u => u.value === sign_data.user)?.label || sign_data.user;
                    description += ", " + lg.getStaticText(TC.REG_EXTRA_PROPS_SIGNED_BY, user_name);
                }
            }

            content = <C.Form.Signature
                noBottomMargin
                value={field_value}
                required={item.required}
                description={description}
                label={item.names[lg.prop]}
                error={extra_errors[error_key]}
                disabled={auto_generate_lock.disabled_all}
                onChange={temp_file_name => setter({ file: temp_file_name, date: new Date().toISOString(), user: userId, is_temp: true } as RegExtraSignature)}
            />
        }
        else if (item.data_type === "simple_section") {
            let section_value = value?.[item.prop] || {};
            if (typeof section_value !== "object" || section_value === null) section_value = {};
            // Shortcut to update a property of the section
            const section_setter = (v: any, prop: string) => setter({ ...section_value, [prop]: v });

            content = <BS.Accordion>
                <BS.Accordion.Item eventKey={item.prop}>
                    <BS.Accordion.Header children={item.names?.[lg.prop]} />
                    <BS.Accordion.Body>
                        <BS.Row>
                            {item.children.map(c => render_extra_prop(c, v => section_setter(v, c.prop), section_value, item_key))}
                        </BS.Row>
                    </BS.Accordion.Body>
                </BS.Accordion.Item>
            </BS.Accordion>;
        }
        else if (item.data_type === "list_section") {
            let value_items: any[] = value?.[item.prop] || [];
            if (!Array.isArray(value_items)) value_items = [];

            // Remove an item from the list
            const remove_list_item = (index: number) => setter(value_items.filter((_, i) => i !== index));
            // Shortcut to update a property of an item in the list
            const section_setter = (v: any, index: number, prop: string) => setter(value_items.map((val, i) => i === index ? { ...val, [prop]: v } : val));
            // Add a new empty item to the list and prevent the accordion from toggling
            const add_list_item: React.DOMAttributes<HTMLElement>["onClick"] = e => {
                e.stopPropagation();
                setter(value_items.concat({}));
            }

            content = <BS.Accordion>
                <BS.Accordion.Item eventKey={item.prop}>
                    <BS.Accordion.Header>
                        {item.names?.[lg.prop]}
                        {!auto_generate_lock.disabled_all && <C.IconTip className="ms-2 p-1 text-primary" icon="plus" onClick={add_list_item} />}
                    </BS.Accordion.Header>
                    <BS.Accordion.Body>
                        {value_items.length === 0
                            ? <C.CenterMessage onClick={add_list_item} className="btn-link pointer" children={TC.REG_PROP_LIST_SECTION_ADD_ITEM} />
                            : value_items.map((v, index) => <BS.Row key={item_key + index} className="mb-3 p-1 border border-2 rounded">
                                {!auto_generate_lock.disabled_all && <div className="text-end mb-1">
                                    <C.Button size="sm" icon="trash" variant="danger" onClick={() => remove_list_item(index)} />
                                </div>}
                                {item.children.map(c => render_extra_prop(c, v => section_setter(v, index, c.prop), v, item_key + index))}
                            </BS.Row>)}
                    </BS.Accordion.Body>
                </BS.Accordion.Item>
            </BS.Accordion>;
        }
        else if (item.data_type === "repetitive_section") {
            let value_items = value?.[item.prop] || {};
            if (typeof value_items !== "object" || value_items === null) value_items = {};
            // Shortcut to update a property of a section
            const section_setter = (v: any, id: string, prop: string) => setter({ ...value_items, [id]: { ...value_items[id], [prop]: v } });

            content = <BS.Accordion>
                <BS.Accordion.Item eventKey={item.prop}>
                    <BS.Accordion.Header children={item.names?.[lg.prop]} />
                    <BS.Accordion.Body>
                        {current_elem_options.length === 0
                            ? <C.CenterMessage children={TC.REG_PROP_ELEM_SECTION_NO_SELECTION} />
                            : current_elem_options.map((elem, i) => <BS.Row key={item_key + elem.value + i} className="mb-3 p-1 border border-2 rounded">
                                <C.Title level={4} text={elem.label} />
                                {item.children.map(c => render_extra_prop(c, v => section_setter(v, elem.value, c.prop), value_items[elem.value], item_key + elem.value))}
                            </BS.Row>)}
                    </BS.Accordion.Body>
                </BS.Accordion.Item>
            </BS.Accordion>;
        }
        else if (item.data_type === "end_section") content = null;

        if (content === null) return null;
        return <BS.Col key={item_key + item.prop} md={size} className="mb-2" children={content} />;
    }, [editedRegDoc.extra_properties, current_elem_options, extra_errors, userId, lg, auto_generate_lock.disabled_all, users]);

    const add_tag = React.useCallback((text: string) => new Promise((resolve, reject) => {
        let submission = { name: text, users: [userId], sites: [], type: "regDoc" } as T.NoteTag;
        S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
            let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
            if (new_tag) {
                let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                set_note_tags(p => p.concat(new_option));
                setEdited(p => ({ ...p, tags: p.tags.concat(new_option.value) }));
                resolve(new_option);
            }
            else resolve(null);
        }).catch(reject);
    }), [set_note_tags, userId]);

    const action_tip_content = React.useMemo(() => regAction?.data && <div>
        <div>{lg.getStaticText(TC.REG_WIZARD_TIP_REFERENCE)} : {regAction.data.reference}</div>
        <br />
        <div>{lg.getStaticText(TC.REG_WIZARD_TIP_SUGGESTED_ELEMENTS)}</div>
        {regAction.data.applicable_to.length > 0 && <ul className="text-start">
            {regAction.data.applicable_to.map(e => {
                if (typeof e === "string") return <li key={e}>{lg.getStaticText(e)}</li>;
                else return <li key={e.id}>{lg.getTextObj(e.id, "name", e.name)}</li>;
            })}
        </ul>}
    </div>, [regAction?.data, lg]);

    if (errorBanner) return errorBanner;
    return <div className="p-2" ref={containerRef}>
        <C.Flex justifyContent='between' alignItems="center" className="mb-3">
            <span className="h5">
                {lg.getElemObj(regAction?._id, "name", regAction?.data?.name)} {regDoc && ` - ${moment(regDoc.last_control).format("DD/MM/YY")}`}
            </span>
            {!auto_generate_lock.disabled_all && <C.Flex>
                {/* Show the buttons if file is auto-reg and no final file found */}
                {!auto_generate_lock.show_file && <BS.Dropdown className="me-2">
                    <BS.Dropdown.Toggle variant="sector" id="dropdown-basic">
                        <i className="fa fa-file-alt me-2" />
                        {lg.getStaticText(TC.REG_DOC_FORM_GENERATE_FILE)}
                    </BS.Dropdown.Toggle>
                    <BS.Dropdown.Menu>
                        <BS.Dropdown.Item onClick={() => generate_file(false)}>
                            <i className="fa fa-flask me-2" />
                            {lg.getStaticText(TC.REG_DOC_FORM_GENERATE_FILE_TEST)}
                        </BS.Dropdown.Item>
                        <BS.Dropdown.Item onClick={() => generate_file(true)}>
                            <i className="fa fa-file-export me-2" />
                            {lg.getStaticText(TC.REG_DOC_FORM_GENERATE_FILE_FINAL)}
                        </BS.Dropdown.Item>
                    </BS.Dropdown.Menu>
                </BS.Dropdown>}

                <BS.ButtonGroup>
                    <C.Button disabled={is_saving.value} onClick={saveForm} text={TC.GLOBAL_SAVE} icon="save" />
                    <C.Button onClick={deleteForm} disabled={!TB.mongoIdValidator(regDoc?._id)} icon="trash" variant="danger" />
                </BS.ButtonGroup>
            </C.Flex>}
        </C.Flex>

        <BS.Row className="align-items-center mb-4">
            <BS.Col className="mb-2" md={12}>
                <FlatLabelInput label={lg.getStaticText(TC.REG_FORM_REGLEMENTATION)}>
                    <span className="fw-bold">{lg.getElemObj(regAction?._id, "name", regAction?.data?.name)}</span>
                    <C.IconTip icon="question-circle ms-2" placement="right" tipContent={action_tip_content} />
                </FlatLabelInput>
            </BS.Col>
            <BS.Col className="mb-2" md={6}>
                <FlatLabelInput label={lg.getStaticText(TC.ACTION_REG_LAST_DATE)} required>
                    <BS.FormControl
                        type="date"
                        isInvalid={invalids.last_control}
                        value={editedRegDoc.last_control || ""}
                        disabled={auto_generate_lock.disabled_all}
                        onChange={e => setEdited(p => _.set({ ...p }, "last_control", e.target.value))}
                    />
                    <C.Feedback text={errors.last_control} />
                </FlatLabelInput>
            </BS.Col>
            <BS.Col className="mb-2" md={6}>
                <FlatLabelInput label={lg.getStaticText(TC.REG_FORM_CONTROLLER)} required>
                    <BS.FormControl
                        isInvalid={invalids.controller}
                        value={editedRegDoc.controller || ""}
                        disabled={auto_generate_lock.disabled_all}
                        onChange={e => setEdited(p => _.set({ ...p }, "controller", e.target.value))}
                    />
                    <C.Feedback text={errors.controller} />
                </FlatLabelInput>
            </BS.Col>
            <BS.Col className="mb-2" md={6}>
                <FlatLabelInput label={lg.getStaticText(TC.REG_FORM_PERIODICAL)}>
                    <BS.Form.Check
                        type="switch"
                        checked={editedRegDoc.frequency !== null}
                        disabled={auto_generate_lock.disabled_all}
                        onChange={e => switchFrequency(e.target.checked)}
                    />
                </FlatLabelInput>
            </BS.Col>
            {frequency !== null && <>
                <BS.Col className="mb-2" md={6}>
                    <FlatLabelInput required label={lg.getStaticText(TC.REG_FORM_PERIOD)}>
                        <C.Flex>
                            <BS.FormControl
                                type="number"
                                min={1}
                                value={frequency.num}
                                disabled={auto_generate_lock.disabled_all}
                                onChange={e => onChangeNumFrequency(e.target.value)}
                            />
                            <C.TypeAhead
                                options={timeOptions}
                                selectedItems={frequency.unit}
                                disabled={auto_generate_lock.disabled_all}
                                onChange={opt => onChangeUnitFrequency(opt[0]?.value)}
                            />
                        </C.Flex>
                        <C.Feedback text={errors.frequency} />
                    </FlatLabelInput>
                    {defaultFreqPanel}
                </BS.Col>
                <BS.Col className="mb-2" md={6}>
                    <FlatLabelInput label={lg.getStaticText(TC.REG_FORM_VALIDITY)}>
                        {expirationDate}
                    </FlatLabelInput>
                </BS.Col>
            </>}
            <BS.Col className="mb-2" md={12}>
                <FlatLabelInput label={lg.getStaticText(TC.REG_FORM_NOTES)}>
                    <BS.FormControl
                        as="textarea"
                        value={editedRegDoc.notes || ""}
                        disabled={auto_generate_lock.disabled_all}
                        onChange={e => setEdited(p => _.set({ ...p }, "notes", e.target.value))}
                    />
                </FlatLabelInput>
            </BS.Col>
            {auto_generate_lock.show_file && <BS.Col className="mb-2" md={12}>
                <FlatLabelInput required label={lg.getStaticText(TC.REG_FORM_FILE)} children={fileCol} />
            </BS.Col>}
            <BS.Col className="mb-2" md={12}>
                <C.Form.Select
                    multiple
                    options={note_tags}
                    labelPosition="left"
                    label={TC.DATASET_TAGS}
                    value={editedRegDoc.tags}
                    loading={note_tags_status === "load"}
                    disabled={auto_generate_lock.disabled_all}
                    onChange={tags => setEdited(p => ({ ...p, tags }))}
                    typeahead={{ allowNewOption: true, onAddOption: add_tag }}
                />
            </BS.Col>
        </BS.Row>

        <div>
            <div className="mb-3">
                {lg.getStaticText(TC.GLOBAL_ELEMENTS)}
                {!auto_generate_lock.disabled_all && <BS.Button onClick={() => onAddElem()} size="sm" className="ml-3"><i className="fa fa-plus"></i></BS.Button>}
            </div>

            <C.Feedback text={errors.currentElem} />

            {groupedElem.length > 0 && <BS.Row className="mb-2 text-center fw-bold g-2">
                <BS.Col md={4}>{lg.getStaticText(TC.GLOBAL_ELEMENT)}<span className="ms-1 text-danger">*</span></BS.Col>
                <BS.Col md={2}>{lg.getStaticText(TC.GLOBAL_STATUS)}</BS.Col>
                <BS.Col md={4}>{lg.getStaticText(TC.BR_TAB_LABEL_DETAILS)}</BS.Col>
                <BS.Col md={2}></BS.Col>
            </BS.Row>}

            {groupedElem.map(([elem, elemArray]) => <React.Fragment key={elem}>
                {elemArray.map((ce, index) => <BS.Row key={ce.id} className="text-center g-2 mb-1">
                    <BS.Col md={4}>
                        <C.TypeAhead
                            selectedItems={ce.elem}
                            renderItem={renderElement}
                            options={getElemOptions(ce.elem)}
                            renderInputExtra={([opt]) => getReason(opt, true)}
                            onChange={opt => onChangeElem(ce.id, "elem", opt[0]?.value)}
                            invalid={invalids.elements && !TB.mongoIdValidator(ce.elem)}
                            disabled={auto_generate_lock.disabled_all || (TB.mongoIdValidator(ce.elem) && ce.elem === elemId && nbGivenElem <= 1)}
                        />
                        {!TB.mongoIdValidator(ce.elem) && <C.Feedback text={errors.elements} />}
                    </BS.Col>
                    <BS.Col md={2}>
                        <C.TypeAhead
                            hideClearButton
                            renderItem={renderStatus}
                            selectedItems={ce.status}
                            options={getStatusOptions(ce)}
                            disabled={auto_generate_lock.disabled_all}
                            onChange={opt => onChangeStatus(opt[0]?.value as T.RegStatus, ce.elem, ce.id)}
                            colorSelected={(options: ReturnType<typeof getStatusOptions>) => options[0]?.color}
                        />
                        <C.Feedback text={errors.status} />
                    </BS.Col>
                    <BS.Col md={4}>
                        <BS.FormControl
                            as="textarea"
                            value={ce.notes || ""}
                            ref={TB.resizeTextArea}
                            disabled={auto_generate_lock.disabled_all}
                            onChange={e => onChangeElem(ce.id, "notes", e.target.value, e.target as any)}
                        />
                    </BS.Col>
                    {!auto_generate_lock.disabled_all && <BS.Col md={2}>
                        <C.Flex justifyContent="start">
                            <BS.Button disabled={TB.mongoIdValidator(ce.elem) && ce.elem === elemId && nbGivenElem <= 1} onClick={() => onRemove(ce.id)} className="me-2" size="sm" variant="danger">
                                <i className="fa fa-times"></i>
                            </BS.Button>
                            {ce.status !== "conform" && index === elemArray.length - 1 && TB.mongoIdValidator(ce.elem) && <BS.Button size="sm" onClick={() => onAddElem(ce.elem)}>
                                <i className="fa fa-plus"></i>
                            </BS.Button>}
                        </C.Flex>
                    </BS.Col>}
                </BS.Row>)}
            </React.Fragment>)}

            {groupedElem.length > 0 && !auto_generate_lock.disabled_all && <BS.Row className="mt-2">
                <BS.Col className="d-flex">
                    <BS.FormLabel className="me-2">{lg.getStaticText(TC.ACTION_SHOW_ALL_ELEMS)}</BS.FormLabel>
                    <BS.FormCheck type="switch" checked={!showOnlyConform} onChange={() => setShowOnlyConform(p => !p)} />
                </BS.Col>
            </BS.Row>}

            {extra_properties.length > 0 && <BS.Row className="g-1 my-3">
                {extra_properties.map(prop => render_extra_prop(prop, value => setEdited(p => ({ ...p, extra_properties: { ...p.extra_properties, [prop.prop]: value } }))))}
            </BS.Row>}

        </div>
    </div>;
}

export default RegDocForm;