import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as R from "../RegDoc";
import * as H from "../../hooks";
import { v4 as uuid } from "uuid";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { T, TC, TB, LM } from "../../Constants";
import * as US from "../../services/user.service";
import SignaturePad from "react-signature-pad-wrapper";
import FileUploadComp from "../Formio/Components/Premium/FileUploadComp";

//#region Types
export type TicketSignerProps = {
    popUp?: boolean;
    docList?: Docs[];
    onClose?: () => void;
    tickets: T.TicketType[];
    actions?: T.Submission<T.RegAction>[];
    onSubmit: (tickets: T.TicketType[]) => void;
    setDocList?: React.Dispatch<React.SetStateAction<Docs[]>>;
    /** Extra style to customize the modal */
    modal?: M.StyleModalProps;
}

export type StandaloneTickerSignerProps = {
    /** The id of the ticket to close and sign */
    ticket: string;
    /** Callback to quit without closing or signing the ticket */
    quit: () => void;
    /** Callback after the ticket has been signed and closed */
    on_signed: (ticket: T.Submission<T.TicketData>) => void;
    /** Display the component in a popup ? */
    popUp?: boolean;
    /** Extra style to customize the modal */
    modal?: M.StyleModalProps;
}

type Docs = {
    regDoc: T.RegDoc;
    tickets: string[];
};
//#endregion

export const TicketSigner: React.FC<TicketSignerProps> = ({ tickets, actions, onSubmit, docList = [], setDocList, ...props }) => {
    const preFnRefs = React.useRef(null);
    const postFnRefs = React.useRef(null);
    const hasSignedRef = React.useRef<{ signed: boolean, docs: Docs[] }>({ signed: false, docs: [] });

    const lg = H.useLanguage();
    const [{ userId }] = H.useAuth();
    const [signature, setSignature] = React.useState<string>();
    const [validTickets, setTickets] = React.useState<T.TicketType[]>([]);

    React.useEffect(() => setTickets(TB.getArray(tickets).filter(TB.isTicket)), [tickets]);

    const pictureSetter = React.useCallback((id, after) => setTickets(p => p.map(ticket => {
        if (ticket._id !== id) return ticket;
        return { ...ticket, data: { ...ticket.data, after } }
    })), []);

    const onChangeRegDoc = React.useCallback((regDoc: T.RegDoc, ticket: T.TicketType) => {
        setDocList?.(p => {
            let docIds = p.map(l => l.regDoc._id);
            if (!docIds.includes(regDoc._id)) return p.concat({ regDoc, tickets: [ticket._id] });
            return p.map(l => {
                if (l.regDoc._id === regDoc._id) return {
                    regDoc,
                    tickets: l.tickets.includes(ticket._id) ? l.tickets : l.tickets.concat(ticket._id)
                }
                return l;
            })
        });
    }, [setDocList]);

    const onRemoveRegDoc = React.useCallback((regDoc: string, ticket: T.TicketType) => {
        setDocList?.(p => p.map(l => {
            if (l.regDoc._id === regDoc) return { ...l, tickets: l.tickets.filter(id => id !== ticket._id) }
            return l;
        }))
    }, [setDocList]);

    const renderTicket = React.useCallback((ticket: T.TicketType) => <BS.ListGroup.Item key={ticket._id}>
        <span className="h5" onClick={() => console.log(ticket)}>{ticket.data.title}</span>
        <div>
            {ticket.data.type === "reg" || ticket.data.type === "doc_renewal"
                ? <div className="my-2">
                    <R.RegSelect
                        actions={actions}
                        action={ticket.data.regAction}
                        element={ticket.data.equipment}
                        options={docList.map(l => l.regDoc)}
                        onEdited={doc => onChangeRegDoc(doc, ticket)}
                        onCreated={doc => onChangeRegDoc(doc, ticket)}
                        onDeleted={docId => onRemoveRegDoc(docId, ticket)}
                        selected={_.find(docList, l => l.tickets.includes(ticket._id))?.regDoc?._id}
                    />
                </div>
                /* @ts-ignore Because component is in old JS, and doesn't know which props are optional */
                : <FileUploadComp
                    image
                    webcam
                    multiple
                    newStorage="local"
                    fileMaxSize="15mb"
                    postFunc={postFnRefs}
                    value={ticket.data.after}
                    preEditDataFunc={preFnRefs}
                    label={lg.getStaticText(TC.GLOBAL_AFTER_PIC)}
                    setter={val => pictureSetter(ticket._id, val)}
                />}
        </div>
    </BS.ListGroup.Item>, [lg, pictureSetter, onChangeRegDoc, onRemoveRegDoc, actions, docList]);

    //#region Submit
    React.useEffect(() => {
        hasSignedRef.current.docs = docList;
    }, [docList]);

    React.useEffect(() => {
        // Delete the unsaved docs if user quits before saving
        let ref = hasSignedRef.current;
        return () => {
            if (!ref.signed && ref.docs.length > 0) S.clearRegDocs(ref.docs.map(d => d.regDoc._id));
        }
    }, []);

    const submit = React.useCallback(() => {
        let sign = TB.getString(signature);

        let saveFilePromises = _.flatten(
            validTickets.map(({ data, _id }) => {
                if (!Array.isArray(data.after)) return null;
                return data.after.map((file, index) => new Promise<{ _id: string, fileName: string }>(resolve => {
                    let formData = new FormData();
                    formData.append("file", file.url);
                    US.uploadLocalFile(_id, file.name, formData)
                        .then(() => resolve({ _id, fileName: file.name }))
                        .catch(() => resolve({ _id: null, fileName: null }));
                }));
            })
        ).filter(p => p instanceof Promise);

        let uploadedSignature = new Promise(resolve => {
            let _id = validTickets[0]._id;
            let fileName = uuid() + "_signature.png";
            let formData = new FormData();
            formData.append("file", sign);
            US.uploadLocalFile(_id, fileName, formData).then(() => resolve(LM.CRAFT_FILE_URL(_id, fileName)));
        });

        let dismountLoader = M.renderLoader();

        Promise.all(saveFilePromises).then(replies => {
            uploadedSignature.then((signatureUrl: string) => {
                let filesNames = replies.map(r => r.fileName);
                let ticketsIds = validTickets.map(t => t._id);
                let emptyDoc = docList.filter(dl => dl.tickets.length === 0).map(dl => dl.regDoc._id);
                let update = { signature: signatureUrl, signedBy: userId, dateSignature: new Date().toISOString() };

                let updatedTickets = validTickets.map(ticket => {
                    let afterPicsName = replies.filter(r => r._id === ticket._id).map(r => r.fileName);
                    let vFiles = TB.getArray(ticket.data.after);

                    if (afterPicsName.length === 0) return { ...ticket, data: { ...ticket.data, ...update } };
                    return {
                        ...ticket,
                        data: {
                            ...ticket.data,
                            ...update,
                            after: vFiles.map(f => {
                                if (afterPicsName.includes(f.name)) return { ..._.omit(f, "isB64", "isAdded"), storage: "local", url: LM.CRAFT_FILE_URL(ticket._id, f.name) }
                                return f;
                            })
                        }
                    };
                }).filter(TB.isTicket);

                let files = _.flatten(validTickets.map(t => TB.getArray(t.data.after)))
                    .filter(f => filesNames.includes(f.name))
                    .map(f => {
                        let _id = _.find(replies, r => r.fileName === f.name)?._id;
                        let file = { ..._.omit(f, "isAdded", "isB64", "url"), url: LM.CRAFT_FILE_URL(_id, f.name) };
                        return { _id, file };
                    });

                S.closeTickets(ticketsIds, signatureUrl, files, emptyDoc)
                    .then(() => {
                        hasSignedRef.current.signed = true;
                        onSubmit?.(updatedTickets);
                    })
                    .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }))
                    .finally(() => dismountLoader());
            });
        });
    }, [validTickets, signature, docList, userId, onSubmit]);
    //#endregion

    //#region Misc
    const validSignature = React.useMemo(() => TB.validString(signature), [signature]);
    const closeTicketLabel = React.useMemo(() => validTickets.length > 1 ? TC.P_CLOSE_TICKETS : TC.GT_CLOSE_TICKET, [validTickets]);

    const allowedSubmit = React.useMemo(() => {
        let docTickets = _.flatten(docList.map(dl => dl.tickets));
        let regTickets = validTickets.filter(t => t.data.type === "reg" || t.data.type === "doc_renewal").map(t => t._id);
        return validSignature && regTickets.every(id => docTickets.includes(id));
    }, [validSignature, validTickets, docList]);
    //#endregion

    return React.createElement(
        props.popUp ? M.BlankModal : React.Fragment,
        props.popUp ? {
            ...props.modal,
            onQuit: props.onClose,
            size: props.modal?.size || "sm",
        } as M.BlankModalProps : null,
        <div>
            <BS.ListGroup className="my-3" children={validTickets.map(renderTicket)} />
            {/* @ts-ignore @ts-ignore Because component is in old JS, and doesn't know which props are optional */}
            <Signature value={signature} setter={setSignature} label={lg.getStaticText(TC.ER_SIGNATURE)} />
            <C.Button className="mt-3" onClick={submit} text={closeTicketLabel} icon="signature" disabled={!allowedSubmit} />
        </div>
    );
}

/** 
 * A version of the ticket signer to close a ticket without an event 
 */
export const StandaloneTickerSigner: React.FC<StandaloneTickerSignerProps> = props => {
    const [docs, set_docs] = React.useState<Docs[]>([]);
    const [data, set_data, status] = H.useAsyncState<ReturnType<T.API.Actions.TicketClosingData>>({ ticket: null });
    const data_prop = React.useMemo(() => ({ tickets: data.ticket ? [data.ticket] : [], actions: data.action ? [data.action] : [] }), [data]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (!props.ticket) set_data({ ticket: null }, "error");
        else S.ticketClosingData(props.ticket)
            .then(res => isSubscribed && set_data(res.data, "done"))
            .catch(() => isSubscribed && set_data({ ticket: null }, "error"));
        return () => {
            isSubscribed = false;
            set_data({ ticket: null }, "load");
        }
    }, [props.ticket, set_data]);

    if (status === "load") return <M.Loader />;
    else if (status === "error") return;
    else return <TicketSigner
        docList={docs}
        popUp={props.popUp}
        onClose={props.quit}
        setDocList={set_docs}
        tickets={data_prop.tickets}
        actions={data_prop.actions}
        onSubmit={tickets => props.on_signed(tickets?.[0] || null)}
        modal={{ ...props.modal, size: "sm", title: TC.TICKET_CLOSER_MODAL_TITLE }}
    />;
}

//#region Signature Component
const Signature = ({
    //#region Formio Props
    width,
    footer,
    hidden,
    hideLabel,
    maxWidth,
    minWidth,
    height = "150px",
    penColor = "black",
    label = "Signature",
    backgroundColor = "rgb(245,245,235)",
    //#endregion

    //#region Custom Props
    setter,
    value,
    //#endregion

    ...props
}) => {
    const padRef = React.useRef<any>(null);

    //#region Setter
    const onEndStroke = React.useCallback(() => setter?.(padRef.current?.toDataURL?.() ?? ""), [setter]);
    //#endregion

    //#region value
    const clearValue = React.useCallback(() => {
        padRef.current?.clear?.()
        setter?.();
    }, [setter]);
    //#endregion

    H.useEffectOnce(() => TB.validString(value) ? padRef.current?.fromDataURL?.(value) : undefined);
    React.useEffect(() => padRef?.current?.signaturePad?.addEventListener?.("endStroke", onEndStroke), [onEndStroke]);

    //#region Footer
    const footerElem = React.useMemo(() => !TB.validString(footer) ? undefined : <div className="p-1 mb-1 text-center">{footer}</div>, [footer]);
    //#endregion

    return !hidden && <div className="w-100 position-relative">
        <button className="position-absolute top-0 start-0 border border-1" onClick={clearValue}>
            <i className="fa fa-times py-1"></i>
        </button>
        {/* @ts-ignore */}
        <SignaturePad ref={padRef} options={{ penColor, backgroundColor }} redrawOnResize />
        {footerElem}
    </div>
}
//#endregion

//#region Render Modal
type TicketSignerPropsFn = Omit<TicketSignerProps, "onSubmit" | "onClose" | "popUp">;

export const renderTicketSigner = (params: TicketSignerPropsFn) => new Promise<T.TicketType[] | null>(resolve => {
    const [render, dismount] = M.renderInContainer();
    if (render && dismount) {
        if (!TB.validObject(params)) params = { tickets: [] };

        const close = () => dismount(() => resolve(null));
        const submit: TicketSignerProps["onSubmit"] = tickets => dismount(() => resolve(tickets));

        render(<TicketSigner {...params} popUp onClose={close} onSubmit={submit} />);
    }
    else resolve(null);
});
//#endregion