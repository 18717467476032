import { useMemo } from "react";
import { T } from "../Constants";
import { useSelector } from "react-redux";

const usePortal = (portalId: T.PortalIds) => {
    const portals = useSelector((redux: T.ReduxSelector) => redux.portals);
    const portalRef = useMemo(() => portals[portalId] || { current: null }, [portals, portalId]);
    return portalRef;
}

export default usePortal;