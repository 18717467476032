import axios from "axios";
import { APIS, T } from "../../Constants";

const { LANG_API } = APIS;

type FillLangTable = T.API.ToAxios<T.API.Utils.Lang.FillLangTable>;
type TranslateText = T.API.ToAxios<T.API.Utils.Lang.TranslateText>;
type TranslateManyText = T.API.ToAxios<T.API.Utils.Lang.TranslateManyText>;

/** Query translations without them being inserted in the database */
export const translateText: TranslateText = params => axios.post(LANG_API + "single/translate", params);
/** Query multiple translation without them being inserted in the database */
export const translateManyText: TranslateManyText = params => axios.post(LANG_API + "many/translate", params);
/** Manually request the script that auto-fills the translation table */
export const fill_lang_table: FillLangTable = () => axios.get(LANG_API + "fill/table");