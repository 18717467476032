import React from "react";
import * as I from "../types";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { TB, TC } from "../../../../Constants";

export type Props = {
    /** For the date cell, the format to display the date as */
    format?: string;
    /** When editing the date, ask for the hours and minutes too ? */
    isDateTime?: boolean;
    /** When editing the date, save as unix instead of ISO ? */
    saveUnix?: boolean;
}
export type FilterModel = {
    /** Wether to check for values inside the provided range, or outside of it */
    inRange?: boolean;
    /** A starting date */
    dateTo?: string;
    /** An ending date */
    dateFrom?: string;
}

export const sort = TB.sortDates;
export const type = "agCustomDate";

export const Cell = React.forwardRef<object, I.CellProps<Props>>((props, ref) => {

    const date = React.useMemo(() => {
        let params = props.colDef?.params || {};

        let date = TB.getDate(props.getValue?.());
        let format = params.format || "DD/MM/YYYY" + (params.isDateTime ? " HH:mm" : "");
        if (date) return TB.formatDate(date, format);
        return null;
    }, [props]);

    return React.createElement("div", { className: "text-center" }, date);
});

export const EditCell = React.forwardRef<I.EditorRef<string | number>, I.EditorProps<Props>>(({ onValueChange, stopEditing, ...props }, ref) => {
    const input = React.useRef<C.Form.DateTimeRef>(null);
    React.useEffect(() => input.current?.focus?.(), []);
    H.useEffectOnce(() => input.current?.setValue?.(props.value));

    const date_change = React.useCallback((date_str: string | number) => {
        if (props.colDef?.params?.saveUnix) date_str = TB.getDate(date_str)?.getTime?.();
        onValueChange(date_str);
    }, [onValueChange, props.colDef]);

    return <C.Flex className="h-100" alignItems="center">
        <C.Form.DateTime
            ref={input}
            noBottomMargin
            fire_controlled
            onChange={date_change}
            customClass="flex-grow-1"
            enableTime={props.colDef?.params?.isDateTime}
        />
    </C.Flex>
});

export const Filter: React.FC<I.FilterProps<FilterModel, Props>> = ({ getValue, onModelChange, ...props }) => {

    const doesFilterPass = React.useCallback<I.FilterAction["doesFilterPass"]>(params => {
        // Model is empty, so the filter is inactive
        if (!props.model) return true;
        // Destructure the model
        let to = props.model.dateTo,
            from = props.model.dateFrom,
            in_range = props.model.inRange;
        // Get the value for the examined row
        let value = getValue(params.node);
        // Check if the filter is active
        if (from && to) {
            // Check if the value is a date
            let value_date = TB.getDate(value);
            // The value is not a date, so it doesn't pass the filter
            if (!value_date) return false;
            else {
                let time_to = new Date(to).getTime();
                let time_value = value_date.getTime();
                let time_from = new Date(from).getTime();
                // We need to check if the value is inside the range
                if (in_range) return time_value >= time_from && time_value <= time_to;
                // We need to check if the value is outside the range
                else return time_value < time_from || time_value > time_to;
            }
        }
        else return true;
    }, [getValue, props.model]);

    I.useGridFilter({ doesFilterPass });
    const params = React.useMemo(() => props.colDef?.params || {}, [props.colDef?.params]);

    return <div className='p-1'>
        <C.Form.DateTime
            customClass="w-100"
            label={TC.GLOBAL_FROM}
            value={props.model?.dateFrom}
            enableTime={params?.isDateTime}
            onChange={date => onModelChange({ ...props.model, dateFrom: date })}
        />
        <C.Form.DateTime
            customClass="w-100"
            label={TC.GLOBAL_TO}
            value={props.model?.dateTo}
            enableTime={params?.isDateTime}
            onChange={date => onModelChange({ ...props.model, dateTo: date })}
        />
        <C.Form.RadioBool
            name="in_range"
            customClass="w-100"
            value={props.model?.inRange}
            label={TC.GLOBAL_DATE_IN_RANGE}
            onChange={bool => onModelChange({ ...props.model, inRange: !!bool })}
        />
    </div>
};

Cell.displayName = "AG_Date_Cell";
Filter.displayName = "AG_Date_Filter";
EditCell.displayName = "AG_Date_EditCell";