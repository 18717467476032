import React from "react";
import moment from "moment";
import * as I from "../types";
import * as BS from 'react-bootstrap';
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { T, TB, TC } from "../../../../Constants";

export type Props = object;
export type FilterModel = Status[];
type Status = Validity["status"] | "blanks";
type Validity = T.API.Reg.RegTableItem["validityDate"];

const STATUS = [
    { value: "blanks", label: null },
    { value: "expired", label: TC.REG_VALIDITY_EXPIRED },
    { value: "less3months", label: TC.REG_VALIDITY_UNDER_THREE_MONTHS },
    { value: "ok", label: TC.REG_VALIDITY_OVER_THREE_MONTH },
    { value: "none", label: TC.REG_NO_FREQUENCY },
    { value: "missing", label: TC.REG_MISSING }
] as T.Option<object, Status>[];

export const type = "agCustomRegValidityDate";
export const sort = (a: Validity, b: Validity) => TB.sortDates(a?.valid, b?.valid);

export const Cell = React.forwardRef<object, I.CellProps<Props>>((props, ref) => {
    const lg = H.useLanguage();
    const value = React.useMemo<Validity>(() => props.getValue?.() || null, [props]);

    const [text, color] = React.useMemo(() => {
        let date = TB.getDate(value?.valid);
        let dateStr: string = null;
        if (date !== null) dateStr = moment(date).format("DD/MM/YYYY");

        if (value?.status === "expired") return [dateStr, "#FF7043"];
        else if (value?.status === "ok") return [dateStr, "#4EAE53"];
        else if (value?.status === "less3months") return [dateStr, "#FFA726"];
        else if (value?.status === "missing") return [lg.getStaticElem(TC.REG_MISSING), "#CF1F31"];
        else if (value?.status === "none") return [lg.getStaticElem(TC.REG_NO_FREQUENCY), "#4EAE53"];
        else return [null, ""];
    }, [value, lg]);

    return <C.Flex direction="column" className="text-center text-white fw-bold" children={<div style={{ backgroundColor: color }} children={text} />} />;
});

export const Filter: React.FC<I.FilterProps<FilterModel>> = ({ onModelChange, getValue, ...props }) => {
    const lg = H.useLanguage();

    const doesFilterPass = React.useCallback<I.FilterAction["doesFilterPass"]>(params => {
        // Filter is null (= inactive), or nothing set as filtered, so everything pass
        if (!Array.isArray(props.model) || props.model.length === 0) return true;
        // Get the cell's value
        let value: Validity = getValue(params.node);
        // Value is empty
        if (!value?.status) return !props.model.includes("blanks");
        // Value is one of the selected status
        else return !props.model.includes(value.status);
    }, [props.model, getValue]);

    const toggle_status = React.useCallback((status: Status) => {
        let new_model = props.model || [];
        if (new_model.includes(status)) new_model = new_model.filter(x => x !== status);
        else new_model = new_model.concat(status);
        // No statuses are selected, so filter is disabled
        if (new_model.length === 0) new_model = null;
        // Update the model
        onModelChange(new_model);
    }, [props.model, onModelChange]);

    I.useGridFilter({ doesFilterPass });

    return <div className='p-2'>
        {STATUS.map(o => <BS.InputGroup key={o.value} className="align-items-center mb-1">
            <BS.Form.Check checked={!props.model?.includes?.(o.value)} onChange={() => toggle_status(o.value)} />
            <span className='ml-3 flex-grow-1 pointer' onClick={() => toggle_status(o.value)}>{o.label ? lg.getStaticText(o.label) : null}</span>
        </BS.InputGroup>)}
    </div>;
};

Cell.displayName = "AG_RegValidity_Cell";