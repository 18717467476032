import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import { T, TB } from "../../Constants";
import { InputGroup } from "react-bootstrap";
import InnerHtml from "dangerously-set-html-content";

export type RentersDashboardProps = object;
type Renters = ReturnType<T.API.Report.Dashboard.GetRenters>;

const RentersDashboard: React.FC<RentersDashboardProps> = props => {
    const [context] = H.useRoots();
    H.useCrumbs("todo Locataires");
    const [selected, setSelected] = React.useState([]);
    const [html, setHtml, htmlStatus] = H.useAsyncState("", "done");
    const [renters, setRenters, status] = H.useAsyncState<Renters>([]);

    //#region Renters List
    React.useEffect(() => {
        let isSubscribed = true;
        S.getRenters(context)
            .then(({ data }) => isSubscribed && setRenters(data, "done"))
            .catch(() => isSubscribed && setRenters([], "error"));
        return () => { isSubscribed = false };
    }, [context, setRenters]);

    const rentersOptions = React.useMemo(() => renters.map(r => ({
        value: r.id,
        label: r.name,
        rental: r.rental.map(r => r.name).join(", "),
        enseigne: r.enseigne.map(e => e.name).join(", "),
    })), [renters]);
    //#endregion

    //#region Select
    const typeaheadProps = React.useMemo<C.Form.SelectProps["typeahead"]>(() => ({
        renderItem: (a: typeof rentersOptions[number]) => <div>
            {a.label}
            {a.enseigne && <span className="text-muted mx-2">({a.enseigne})</span>}
            {a.rental && <span className="fw-bold fs-75 me-2">{a.rental}</span>}
        </div>,
    }), []);

    const load = React.useCallback(() => {
        setHtml("", "load");
        S.html_report({ template: "PortfolioEnseigne", params: { context: JSON.stringify(context), renter: selected } })
            .then(({ data }) => {
                if (TB.validString(data)) setHtml(data, "done");
                else setHtml("", "error");
            })
            .catch(() => setHtml("", "error"))
    }, [context, selected, setHtml]);

    const selectAll = React.useCallback(() => setSelected(rentersOptions.map(r => r.value)), [rentersOptions]);
    //#endregion

    return <C.Spinner status={status}>
        <C.Flex direction="column" className="w-100">
            <div className="mb-3">
                <InputGroup className="h-100">
                    <C.Button size="sm" onClick={load} text={"load"} />
                    <C.Form.Select
                        multiple
                        noBottomMargin
                        value={selected}
                        onChange={setSelected}
                        options={rentersOptions}
                        customClass="flex-grow-1"
                        typeahead={typeaheadProps}
                    />
                    <C.Button size="sm" className="ms-2" variant="danger" onClick={selectAll} text="*" />
                </InputGroup>
            </div>
            <div className="flex-grow-1 mb-3">
                <C.Spinner status={htmlStatus}>
                    <InnerHtml className="w-100 h-100" html={html} />
                </C.Spinner>
            </div>
        </C.Flex>
    </C.Spinner>;
}

export default RentersDashboard;