import _ from "lodash";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { URL, TB, REGEX, LM } from '../../../../Constants';
import { Form, TipContainer } from "../../../../Common";
import * as US from "../../../../services/user.service";
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import * as M from '../../../Modal';

const FileUploadComp = ({
    //#region Props
    label,
    hidden,
    tooltip,
    disabled,
    multiple,
    validate,
    hideLabel,
    clearOnHide,
    conditional,
    customClass,
    description,
    customConditional,

    image,
    newUrl,
    webcam,
    newStorage,
    options,
    imageSize,
    fileTypes,
    uploadOnly,
    fileMaxSize,
    fileMinSize,
    filePattern,

    value,
    setter,
    formId,
    context,
    fullKey,
    postFunc,
    readOnly,
    extraData,
    submissionId,
    preEditDataFunc,
    validationSetter,

    ...props
    //#endregion
}) => {
    const [toDelete, setToDelete] = useState([]);
    const [isHidden, setIsHidden] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const modalRef = useRef();
    const b64Ref = useRef({});


    //#region Props, Submission, context
    const url = useMemo(() => newUrl, [newUrl]);
    const storage = useMemo(() => newStorage, [newStorage]);
    const fullContext = useMemo(() => TB.validObject(context) ? context : {}, [context]);
    const submissionData = useMemo(() => TB.validObject(fullContext.data) ? fullContext.data : {}, [fullContext]);
    //#endregion

    //#region Value
    const validValue = useMemo(() => (Array.isArray(value) ? value : [value]).filter(x => TB.validObject(x) && TB.validString(x.storage)), [value]);
    const isDisabled = useMemo(() => disabled || readOnly || extraData?.noFile, [disabled, readOnly, extraData]);
    const hiddenOrDisabled = useMemo(() => isHidden || isDisabled, [isHidden, isDisabled]);
    //#endregion

    //#region Hide
    const condContext = useMemo(() => ({ show: undefined, ...fullContext }), [fullContext]);
    const { eq, show, when } = useMemo(() => TB.validObject(conditional) ? conditional : {}, [conditional]);

    const hiddenOnConditional = useMemo(() => {
        if (hidden) return true;
        else if ([eq, when].every(TB.validString) && typeof show === "boolean") {
            let dataVal = submissionData[when];
            let conditionVal = eq;
            if (["true", "false"].includes(conditionVal)) conditionVal = conditionVal === "true";
            if (dataVal === conditionVal) return !show;
            else return show;
        }
        return false;
    }, [eq, show, submissionData, when, hidden]);

    useEffect(() => {
        if (hiddenOnConditional) setIsHidden(true);
        else if (!TB.validString(customConditional)) setIsHidden(false);
    }, [condContext, hiddenOnConditional, customConditional]);

    useEffect(() => isHidden && clearOnHide && value !== undefined ? setter?.() : undefined, [isHidden, clearOnHide, setter, value]);
    //#endregion

    //#region Validation
    const { required, custom } = useMemo(() => TB.validObject(validate) ? validate : {}, [validate]);
    const validationContext = useMemo(() => ({ valid: undefined, value: validValue, ...fullContext }), [validValue, fullContext]);
    const [maxSize, minSize] = useMemo(() => [fileMaxSize, fileMinSize].map(s => TB.validString(s) && s.match(REGEX.FILE_SIZE_REGEX) ? TB.humanSizeFormatToBytes(s) : null), [fileMaxSize, fileMinSize]);

    const isValidSize = useCallback(byteSize => typeof byteSize === "number" ? (minSize <= byteSize && byteSize <= maxSize) : false, [minSize, maxSize]);

    const isValid = useMemo(() => {
        if (isHidden) return true;
        if (required && (!Array.isArray(value) || value.length === 0)) return setErrorMessage("Value is required") ?? false;
        if (typeof maxSize !== "number" && validValue.some(({ size }) => size > maxSize)) return setErrorMessage(`Some file exceed the size limit (${TB.bytesToHumanSizeFormat(maxSize)})`) ?? false;
        if (typeof minSize !== "number" && validValue.some(({ size }) => size < minSize)) return setErrorMessage(`Some file are smaller than the size limit (${TB.bytesToHumanSizeFormat(minSize)})`) ?? false;
        return true;
    }, [isHidden, maxSize, minSize, required, validValue, value]);

    useEffect(() => {
        if (isHidden) validationSetter?.(true);
        else if (!isValid) validationSetter?.(false);
        else if (!TB.validString(custom)) {
            setErrorMessage();
            validationSetter?.(true);
        }
    }, [custom, isHidden, isValid, validationContext, validationSetter]);
    //#endregion

    //#region File
    const imageList = useMemo(() => image ? validValue.map(({ url, originalName }) => ({ src: url, title: originalName })) : [], [validValue, image]);
    const onChangeFileType = useCallback((fileType, index) => setter?.(validValue.map((f, i) => i === index ? { ...f, fileType } : f)), [validValue, setter]);

    const fileTypesOptions = useMemo(() => {
        if (!Array.isArray(fileTypes)) return [];
        return fileTypes.filter(TB.validObject).filter(({ value, label }) => [value, label].every(TB.validString));
    }, [fileTypes]);

    const onRemoveFile = useCallback(index => {
        let toDelete = [];
        setter?.(validValue.filter(({ isAdded, storage, name, url }, i) => {
            if (i === index && ["url", "local"].includes(storage) && !isAdded) toDelete.push({ name, storage, url });
            return i !== index;
        }));
        if (toDelete.length > 0) setToDelete(prev => prev.concat(toDelete));
    }, [validValue, setter]);

    const onAddFile = useCallback(files => {
        if (!Array.isArray(files) || !TB.validObject(files[0])) return;

        let { name, size, type } = TB.validObject(files[0]) ? files[0] : {};
        if (image && (!TB.validString(type) || !type.includes("image"))) M.renderAlert({ type: "error", delay: -1, message: "File is not an image" });
        else {
            let dismount = M.renderLoader();
            TB.getB64FromFileObject(files[0])
                .then(b64 => {
                    let newFile = { size, type, storage, originalName: name, name: `${uuidv4()}-${name}`, url: b64, isAdded: true, isB64: true };
                    setter?.(validValue.concat(newFile));
                })
                .catch(error => alert(error))
                .finally(() => dismount?.());
        }
    }, [validValue, setter, image, storage]);

    const download = useCallback(index => {
        if (uploadOnly) return;
        let { url, originalName } = TB.validObject(validValue[index]) ? validValue[index] : {};
        if ([url, originalName].every(TB.validString)) TB.downloadFile(url, originalName);
        else alert("ERROR - FILE_NOT_FOUND");
    }, [validValue, uploadOnly]);

    const openImage = useCallback(index => {
        if (!image) return;
        let originalName = validValue[index]?.originalName;
        if (!TB.validString(originalName)) alert("ERROR - FILE_NOT_FOUND");
        else M.renderCarousel({ images: imageList, defaultTitle: originalName });
    }, [imageList, validValue, image]);

    const fileInputProps = useMemo(() => ({
        value: [],
        type: "file",
        className: "form-control",
        disabled: hiddenOrDisabled,
        accept: image ? "image/*" : filePattern,
        // capture: webcam ? "user" : "environment", //Seems to allow the user to choose between gallery and storage if commented
    }), [hiddenOrDisabled, filePattern, image]);
    //#endregion

    //#region Table
    const showFileTypeCol = useMemo(() => fileTypesOptions.length > 0, [fileTypesOptions]);
    const imgWidth = useMemo(() => isNaN(parseInt(imageSize)) ? "200" : imageSize, [imageSize]);
    const hideInput = useMemo(() => !multiple && validValue.length === 1, [validValue.length, multiple]);

    const tableHeader = useMemo(() => <thead>
        <tr className="bg-white">
            <th>File Name</th>
            <th className="text-center">Size</th>
            {showFileTypeCol && <th className="text-center">Type</th>}
            <th></th>
        </tr>
    </thead>, [showFileTypeCol]);

    const tableFooter = useMemo(() => hideInput ? undefined : <tfoot>
        <tr>
            <td className="text-center align-middle" colSpan={showFileTypeCol ? 4 : 3}>
                <div className="p-2">
                    <input onChange={e => onAddFile([...e.target.files])} {...fileInputProps} />
                </div>
            </td>
        </tr>
    </tfoot>, [showFileTypeCol, hideInput, fileInputProps, onAddFile]);

    const tableBody = useMemo(() => validValue.map(({ size, originalName, fileType, url, name }, i) => <tr key={name}>
        <td>
            <p>{originalName}</p>
            {image && <div className="pointer-zoom" onClick={() => openImage(i)}>
                <img src={url} alt={originalName} width={imgWidth}></img>
            </div>}
        </td>
        <td className={"text-center align-middle" + (isValidSize(size) ? "" : "text-danger fw-bold")}>{TB.bytesToHumanSizeFormat(size)}</td>
        {showFileTypeCol && <td className="text-center">
            <Form.Select
                hideLabel
                value={fileType}
                options={fileTypesOptions}
                readOnly={hiddenOrDisabled}
                setter={val => onChangeFileType(val, i)}
            />
        </td>}
        <td className="text-center align-middle">
            <div className="btn-group">
                {!uploadOnly && <button className="btn btn-primary" onClick={() => download(i)}><i className="fa fa-file-download"></i></button>}
                <button disabled={hiddenOrDisabled} onClick={() => onRemoveFile(i)} className="btn btn-danger"><i className="fa fa-times"></i></button>
            </div>
        </td>
    </tr>), [showFileTypeCol, fileTypesOptions, validValue, hiddenOrDisabled, imgWidth, onRemoveFile, openImage, onChangeFileType, download, isValidSize, uploadOnly, image]);

    const table = useMemo(() => <table className="table table-light border-left border-right">
        {tableHeader}
        <tbody>
            {tableBody}
        </tbody>
        {tableFooter}
    </table>, [tableHeader, tableFooter, tableBody]);
    //#endregion

    //#region Wrapper
    const wrapperClass = useMemo(() => TB.validString(customClass) ? customClass : "", [customClass]);
    const errorMessageSpan = useMemo(() => !TB.validString(errorMessage) ? undefined : <span className="invalid-feedback shown">{errorMessage}</span>, [errorMessage])
    //#endregion

    //#region Label, ToolTip, Description
    const tooltipSpan = useMemo(() => TB.validString(tooltip) ? <TipContainer tipContent={tooltip} /> : undefined, [tooltip]);
    const requiredStar = useMemo(() => required ? <span className="text-danger ml-1">*</span> : undefined, [required]);
    const descriptionTag = useMemo(() => !TB.validString(description) ? undefined : <p className="text-muted fs-85">{description}</p>, [description]);
    const labelTags = useMemo(() => hideLabel ? undefined : <label>{label} {requiredStar} {tooltipSpan}</label>, [requiredStar, tooltipSpan, hideLabel, label]);
    //#endregion

    //#region Pre-Submit Callback
    const preSubmitFunct = useCallback(data => new Promise(resolve => {
        let goodValue = validValue.map(({ isAdded, isB64, ...file }) => {
            let url = file.url;
            if (isB64 && file.storage !== "base64") {
                b64Ref.current[file.name] = file.url;
                url = "";
            }
            return { ...file, url };
        });

        let clone = _.set(data, fullKey, goodValue);
        resolve(clone);
    }), [validValue, fullKey]);

    useEffect(() => TB.validObject(preEditDataFunc?.current) && TB.validString(fullKey) ? preEditDataFunc.current[fullKey] = { action: preSubmitFunct } : undefined, [preEditDataFunc, fullKey, preSubmitFunct]);
    //#endregion

    //#region Post Submit Callback
    const decodedOptions = useMemo(() => {
        if (!TB.validString(options)) return {};
        try { return JSON.parse(options) }
        catch (err) { return {} };
    }, [options]);

    const constructUrl = useCallback((_id, name) => {
        if (!TB.validString(url)) return null;
        let variables = url.match(/\$\$.*\$\$/g);
        if (!Array.isArray(variables)) return url;
        else {
            let uniqueVariables = _.uniq(variables);
            let newContext = { _id, name, ...fullContext };
            let newUrl = url;

            uniqueVariables.forEach(str => {
                let vPath = str.replaceAll("$$", "");
                newUrl = newUrl.replaceAll(str, _.get(newContext, vPath));
            });

            return newUrl;
        }
    }, [url, fullContext]);

    const postSubmitFunct = useCallback(submission => new Promise(resolve => {
        if (!TB.mongoIdValidator(submission?._id)) resolve(submission);
        else {
            // if storage === local 
            let localStorage = validValue.filter(({ storage, isAdded }) => isAdded && storage === "local");
            // Upload the new ones (prepare backend for it)
            let localPromises = localStorage.map(({ name }) => new Promise(resolve => {
                let b64Object = b64Ref.current[name];
                let formData = new FormData();
                formData.append("file", b64Object);
                US.uploadLocalFile(submission?._id, name, formData)
                    .then(({ data }) => resolve({ name, url: LM.CRAFT_FILE_URL(submission._id, name) }));
            }));

            // if storage === url
            let urlStorage = validValue.filter(({ storage, isAdded }) => isAdded && storage === "url");
            // POST the new ones to the url, with the options as parameters
            let urlPromises = urlStorage.map(({ name }) => new Promise(resolve => {
                let url = constructUrl(submission?._id, name);
                let b64Object = b64Ref.current[name]?.split?.(";base64,")?.pop?.();
                let formData = new FormData();
                formData.append('file', b64Object);
                if (TB.validString(url)) return axios.post(url, { ...formData, ...decodedOptions }).then(() => resolve({ name, url }));
                else resolve({});
            }));

            Promise.all(localPromises.concat(urlPromises)).then(responses => {
                let bulk = responses.filter(({ url }) => TB.validString(url)).map(({ name, url }) => ({
                    updateOne: {
                        filter: { _id: submission._id },
                        update: Object.fromEntries([[`data.${fullKey}.$[element].url`, url]]),
                        arrayFilters: [{ "element.name": name }]
                    }
                }));

                if (bulk.length === 0) resolve(submission)
                // Update DB with urls
                else US.bulkSubmissionAction(bulk).then(({ data }) => {
                    // Update local too
                    let urlPerNameObj = Object.fromEntries(responses.map(({ name, url }) => [name, url]));
                    let dbData = _.get(submission, `data.${fullKey}`);
                    if (!Array.isArray(dbData)) resolve(submission);
                    else {
                        let newData = dbData.map(file => TB.validString(urlPerNameObj[file?.name]) ? { ...file, url: urlPerNameObj[file.name] } : file);
                        let updatedSub = _.set(submission, `data.${fullKey}`, newData);
                        resolve(updatedSub);
                    }
                })
            });

            // DELETE url (prepare backend for it, if storage === local)
            toDelete.forEach(({ storage, url }) => {
                if (storage === "local") US.deleteLocalFile(url);
                else axios.delete(url);
            })
        }
    }), [validValue, decodedOptions, toDelete, constructUrl, fullKey]);

    useEffect(() => TB.validObject(postFunc?.current) ? postFunc.current[fullKey] = { action: postSubmitFunct } : undefined, [postSubmitFunct, postFunc, fullKey]);
    //#endregion

    return <div>
        <div hidden={isHidden} className={wrapperClass}>
            {labelTags}
            {table}
            {errorMessageSpan}
            {descriptionTag}
        </div>
        <div ref={modalRef}></div>
    </div>
}

export default FileUploadComp;