import React from "react";
import * as B from "./Button";
import * as I from "../types";
import * as BS from "react-bootstrap";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { T, TC } from "../../../../Constants";

export const Cell = B.Cell;

export type FilterModel = ("true" | "false" | "null")[];
export type Props<A> = B.Props<A> & Partial<Record<"invert" | "use_is_selected_filters_label" | "distinctFalseFromEmpty", boolean>>;

const Filters = [
    { label: TC.GLOBAL_YES, value: "true" },
    { label: TC.GLOBAL_NO, value: "false" },
] as T.Option<object, FilterModel[number]>[];

const SelectFilters = [
    { label: TC.GRID_BOOL_FILTER_SELECTED, value: "true" },
    { label: TC.GRID_BOOL_FILTER_NOT_SELECTED, value: "false" },
] as T.Option<object, FilterModel[number]>[];

export const type = "agSelectButton";
const CheckBoxStyle: React.CSSProperties = { height: "1rem", width: "1rem" };

export const sort = (a: boolean, b: boolean) => {
    // Both booleans are true
    if (a && b) return 0;
    // One is true but not the other
    if (a || b) return a ? 1 : -1;
    // Both are false
    return 0;
}

export const Filter: React.FC<I.FilterProps<FilterModel, Props<any>>> = ({ onModelChange, getValue, ...props }) => {
    const lg = H.useLanguage();

    const filters = React.useMemo(() => {
        if (props.colDef.params?.use_is_selected_filters_label) return SelectFilters;
        // This one is for the checkbox
        else if (props.colDef.params?.distinctFalseFromEmpty) return Filters.concat({ value: "null", label: TC.CHECKBOX_FILTER_CELL_NO_VALUE });
        else return Filters;
    }, [props.colDef.params]);

    const doesFilterPass = React.useCallback<I.FilterAction["doesFilterPass"]>(params => {
        // Filter is null (= inactive), or nothing set as filtered, so everything pass
        if (!Array.isArray(props.model) || props.model.length === 0) return true;
        // All the values appear in the model, so nothing pass
        if (props.model.length === filters.length) return false;

        let value = getValue(params.node);
        let true_key: FilterModel[number] = props.colDef.params?.invert ? "false" : "true";
        let false_key: FilterModel[number] = props.colDef.params?.invert ? "true" : "false";

        // If filter is included, do not show values that are not boolean
        let pass_null_check = !props.model.includes("null") || typeof value === "boolean";
        // If filter is included, do not show values that are 'true'
        let pass_true_check = !props.model.includes(true_key) || typeof value !== "boolean" || !value;
        // If filter is included, do not show values that are 'false'
        let pass_false_check = !props.model.includes(false_key) || typeof value !== "boolean" || !!value;

        return pass_null_check && pass_true_check && pass_false_check;
    }, [props.model, getValue, filters]);

    const toggleFilter = React.useCallback((filter: FilterModel[number]) => {
        let new_model = props.model || [];
        if (new_model.includes(filter)) new_model = new_model.filter(s => s !== filter);
        else new_model = new_model.concat(filter);
        // No statuses are selected, so filter is disabled
        if (new_model.length === 0) new_model = null;
        // Update the model
        onModelChange(new_model);
    }, [props.model, onModelChange]);

    I.useGridFilter({ getModelAsString: JSON.stringify, doesFilterPass });

    return <div className='p-2'>
        {filters.map(f => <C.Flex className="mb-1 form-check" key={f.value} alignItems="center" onClick={() => toggleFilter(f.value)}>
            <BS.FormCheck.Input
                type="checkbox"
                style={CheckBoxStyle}
                onChange={() => toggleFilter(f.value)}
                checked={!props.model?.includes(f.value)}
            />
            <div children={lg.getStaticText(f.label)} className="ms-2" />
        </C.Flex>)}
    </div>
};