import React from "react";
import * as I from "../types";
import * as BS from "react-bootstrap";
import * as C from "../../../../Common";
import { T, TB, TC } from "../../../../Constants";

export type Props = object;
export type FilterModel = ("blank" | "clear" | "somber")[];

const Filters = [
    { label: " ", value: "blank" },
    { label: TC.GLOBAL_COLOR_CLEAR, value: "clear" },
    { label: TC.GLOBAL_COLOR_DARK, value: "somber" },
] as T.Option<object, FilterModel[number]>[];

export const type = "agCustomColor";

export const Cell = React.forwardRef<object, I.CellProps<Props>>(({ getValue, ...props }, ref) => {

    const color = React.useMemo(() => {
        let value = getValue?.();
        if (!TB.validString(value)) return null;
        else return TB.getColor(value) || "#FFFFFF";
    }, [getValue]);

    if (props.node?.group || !color) return null;
    return <div className="p-1 h-100 w-100 border" children={<div style={{ backgroundColor: color }} className="h-100 w-100" />} />;
});

export const Filter: React.FC<I.FilterProps<FilterModel>> = ({ onModelChange, getValue, ...props }) => {

    const doesFilterPass = React.useCallback<I.FilterAction["doesFilterPass"]>(params => {
        // Filter is null (= inactive), or nothing set as filtered, so everything pass
        if (!Array.isArray(props.model) || props.model.length === 0) return true;
        // Get the cell's value
        let value = TB.getColor(getValue(params.node));
        // Check if the value is blank, clear or somber
        let isBlank = !TB.validString(value);
        let isClear = !isBlank && TB.isColorWhite(value);
        // No color, so it's blank
        if (isBlank) return !props.model.includes("blank");
        // Color is clear
        else if (isClear) return !props.model.includes("clear");
        // Color is somber
        else return !props.model.includes("somber");
    }, [props.model, getValue]);

    const toggleFilter = React.useCallback((filter: FilterModel[number]) => {
        let new_model = props.model || [];
        if (new_model.includes(filter)) new_model = new_model.filter(s => s !== filter);
        else new_model = new_model.concat(filter);
        // No statuses are selected, so filter is disabled
        if (new_model.length === 0) new_model = null;
        // Update the model
        onModelChange(new_model);
    }, [props.model, onModelChange]);

    I.useGridFilter({ getModelAsString: JSON.stringify, doesFilterPass });

    return <div className='p-2'>
        {Filters.map(o => <C.Form.CheckBox
            key={o.value}
            noBottomMargin
            label={o.label}
            customClass="mb-1"
            labelPosition="right"
            onChange={() => toggleFilter(o.value)}
            value={!props.model?.includes?.(o.value)}
        />)}
    </div>
};

export const EditCell = React.forwardRef<I.EditorRef<string>, I.EditorProps<Props>>((props, ref) => {
    const input = React.useRef<HTMLInputElement>(null);
    const color = React.useMemo(() => TB.getColor(props.value) || "", [props.value]);

    React.useEffect(() => {
        // Auto open the picker pop-up
        let to = setTimeout(() => input.current?.click?.(), 1);
        return () => clearTimeout(to);
    }, []);

    return <div tabIndex={-1} className="h-100">
        <BS.FormControl ref={input} type="color" className="mx-auto w-100" value={color} onChange={e => props.onValueChange(e.target.value)} />
    </div>
});

Cell.displayName = "AG_Color_Cell";
Filter.displayName = "AG_Color_Filter";
EditCell.displayName = "AG_Color_EditCell";