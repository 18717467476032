import React from 'react';
import { Link } from 'react-router-dom';
import { TC } from '../../../Constants';
import { askSupport } from '../../Modal';
import { Dropdown } from 'react-bootstrap';
import { AppContext } from '../../../Context';
import { useAuth, useLanguage } from "../../../hooks";

const ADMIN_LINKS: Record<"path" | "label", string>[] = [
  { path: "matrix", label: TC.MATRIX_TAB },
  { path: "admin/board", label: TC.ADMIN_BOARD },
  { path: "admin/history", label: TC.TAB_MISSION_LOG },
];

const USER_LINKS = [
  { path: "profile", label: TC.PROFILE },
];

const ProfileDropdown = () => {
  const lg = useLanguage();
  const [{ isAdmin }] = useAuth();
  const { setConfig } = React.useContext(AppContext);

  const openConfigPanel = React.useCallback(() => setConfig?.("showSettingPanel", true), [setConfig]);

  return <Dropdown navbar as="li">
    <Dropdown.Toggle variant='link' bsPrefix="toggle" className="p-0 nav-link" >
      <i className="fa fa-2x fa-user-circle-o" aria-hidden="true"></i>
    </Dropdown.Toggle>

    <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
      <div className="bg-white rounded-2 py-2 dark__bg-1000">
        {/* Settings button */}
        {/* <Dropdown.Item onClick={openConfigPanel} children={lg.getStaticElem(TC.PROFILE_SETTINGS)} /> */}

        {/* Links for regular users */}
        {USER_LINKS.map(l => <Dropdown.Item as={Link} key={l.path} to={"/" + l.path} >
          {lg.getStaticElem(l.label)}
        </Dropdown.Item>)}

        {/* Feedback form */}
        <Dropdown.Item onClick={askSupport}>
          {lg.getStaticElem(TC.GLOBAL_SUPPORT)}
        </Dropdown.Item>

        <Dropdown.Divider />

        {/* Links for admins */}
        {isAdmin && <>
          {ADMIN_LINKS.map(l => <Dropdown.Item as={Link} key={l.path} to={"/" + l.path} >
            {lg.getStaticElem(l.label)}
          </Dropdown.Item>)}
          <Dropdown.Divider />
        </>}

        <Dropdown.Item as={Link} to="/logout">
          {lg.getStaticElem(TC.LOGOUT)}
        </Dropdown.Item>
      </div>
    </Dropdown.Menu>
  </Dropdown>
};

export default ProfileDropdown;