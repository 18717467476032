import * as AGR from '@ag-grid-community/react';
import * as AGCommunity from "ag-grid-community";
import { CustomCellEditorProps } from '@ag-grid-community/react';

/** Params that could be applied to every type of cell */
export type ColParamsGeneral<Row> = {
    /** Should the column display a value when grouped */
    show_grouped?: boolean;
    /** The id if translation is based on an id */
    header_id?: string;
    /** A template item for the header col translation */
    header_template?: string | number;
    /** Flag for inline edit, this value should not be empty */
    required?: boolean;
    /** Data concerning the ability to certify of a column */
    certify?: boolean | {
        /** The string version of the value. Either a field name or a callback */
        str_val?: string | ((row: Row) => string);
        /** The real value. Either a field name of a callback */
        value?: any | ((row: Row) => any);
        /** The real name of the prop to be certified, is also used for value unless value has it's own prop, default should be handled by context menu callback */
        prop?: keyof Row;
        /** The _id of the form of the value to save, default should be handled by context menu callback */
        form?: string;
        /** The _id of the submission to certify, default should be handled by context menu callback */
        item?: string;
    }
}

/** Type that allows to mix existing col definition with our own props */
type ColCustomParams<Type, Props> = Type & Record<"colDef", AGCommunity.ColDef & Record<"params", Props & ColParamsGeneral<any>>>;

// /** Other properties that can be defined for a type of column */
// export type ExtraProperties = AGCommunity.ColDef;

/** The properties received for a cell renderer component */
export type CellProps<Params> = ColCustomParams<AGCommunity.ICellRendererParams, Params>;
/** The properties received for an editor component */
export type EditorProps<Params> = ColCustomParams<CustomCellEditorProps, Params>;
/** The must-implement properties in the editor's reference */
export type EditorRef<Value = any> = object;
/** Ag-Grid's hook to check if a value passes it's filter */
export const useGridFilter = AGR.useGridFilter;
/** The properties received for a filter component */
export type FilterAction = Parameters<typeof AGR.useGridFilter>[0];
/** The props received for a custom Filter Component */
export type FilterProps<Model = any, Props = any> = Omit<AGR.CustomFilterProps<any, any, Model>, "colDef">
    & Record<"colDef", AGCommunity.ColDef & Record<"params", Props & ColParamsGeneral<any>>>