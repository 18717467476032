import DOM from 'react-dom/client';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class GammeSelect extends ReactComponent {
    static get builderInfo() {
        return {
            title: "GammeSelect",
            icon: "",
            group: "Basic",
            documentation: "",
            weight: -10,
            schema: GammeSelect.schema()
        };
    }

    static schema = () => ReactComponent.schema({ type: "GammeEquip", label: "GammeSelect" });

    attachReact = element => DOM.createRoot(element).render(<DefaultComp />);
    detachReact = element => element ? DOM.createRoot(element).unmount() : undefined;
}