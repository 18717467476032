import DOM from 'react-dom/client';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class ColorPicker extends ReactComponent {
    static get builderInfo() {
        return {
            title: "Color Picker",
            icon: "paint-brush",
            group: "Basic",
            documentation: "",
            weight: -10,
            schema: ColorPicker.schema()
        };
    }

    static schema = () => ReactComponent.schema({ type: "ColorPicker", label: "Color" });

    attachReact = element => DOM.createRoot(element).render(<DefaultComp />);
    detachReact = element => element ? DOM.createRoot(element).unmount() : undefined;
}