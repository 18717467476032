import _ from "lodash";
import React from "react";
import * as F from "../Form";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { T, TABS, TB, TC } from "../../Constants";
import { RegDocs, RegTableRef } from "../Gestion";
import { ErrorBanner, Flex, Button, Spinner } from "../../Common";
import AutoFillReg, { UnMountRef as AutoFillRef } from "./AutoFillReg";
import RegEquipment, { UnMountRef as EquipTabRef } from "./RegEquipments";
import RegDataFiller, { UnMountRef as RegDataFillerRef } from "./RegDataFiller";

//#region Type
type RegPageProps = {
    context?: T.ContextParams;
    /** Do not allow any edits */
    read_only?: boolean;
    /** The current mission, if an EDL reg is active */
    mission_id?: string;
};

type Tabs = "mission" | "missingData" | "equipments" | "applicable" | "table";
//#endregion

//#region Constants 
const TABS_MARGIN = { rem: 0.5, size: 2 };
const CONTAINER_MARGIN = { rem: 1, size: 3 };
const TABS_CONTAINER_ID = "regTabsContainer";
const INIT_RESOURCES: ReturnType<T.API.Reg.GetRegTabResults> = { actions: [], gammesProps: {}, categoryTree: {}, actionConformity: {}, gammes: [], mainTree: { gammes: [], categoryTree: {} } };
//#endregion

export const RegWizard: React.FC<RegPageProps> = props => {
    H.useLanguage();
    const navigate = useNavigate();
    const isMounted = H.useIsMounted();
    const editedRegTable = H.useBoolean(false);
    const editedElements = H.useBoolean(false);
    const updated_mission = H.useBoolean(false);
    const editedRegAffect = H.useBoolean(false);
    const regTableRef = React.useRef<RegTableRef>(null);
    const equipTabRef = React.useRef<EquipTabRef | null>(null);
    const autoFillRef = React.useRef<AutoFillRef | null>(null);
    const navBarContainerRef = H.usePortal("topNavBarContainer");
    const regDataRef = React.useRef<RegDataFillerRef | null>(null);
    const mission_ref = React.useRef<F.Missions.MissionFormRef>(null);
    const [navBarContainerRefForSize, navBarSize] = H.useElementSize();
    const [tabsContainerRef, tabsSize] = H.useElementSize<HTMLElement>();
    const [activeTab, setActiveTab] = React.useState<Tabs>(props.mission_id ? "mission" : "missingData");
    const [resources, setResources, resourcesStatus, setResourcesStatus] = H.useAsyncState<ReturnType<T.API.Reg.GetRegTabResults>>(INIT_RESOURCES);
    const [elements, set_elements, elem_status, set_elem_status] = H.useAsyncState<ReturnType<T.API.Reg.GetRegTabElements>>({ elements: [], show_wizard: false });

    const statuses = React.useMemo(() => [resourcesStatus, elem_status], [resourcesStatus, elem_status]);

    const tabs = React.useMemo<T.Option<Record<"show" | "enable", boolean>, Tabs>[]>(() => [
        { value: "mission", label: TC.CERT_HISTORY_MISSION, show: !!props.mission_id, enable: true },
        { value: "missingData", label: TC.REG_DATA_FILLING, show: true, enable: true },
        { value: "equipments", label: TC.EQUIPMENTS, show: true, enable: elements.show_wizard },
        { value: "applicable", label: TC.REG_ACTION_APPLICABLES, show: true, enable: elements.show_wizard },
        { value: "table", label: TC.REG_TABLE_TITLE, show: true, enable: elements.show_wizard }
    ], [elements.show_wizard, props.mission_id]);

    //#region Load Resources
    const fetchResources = React.useCallback(() => {
        let all_promises: Promise<void>[] = [];
        // Fetch the resources
        all_promises.push(new Promise((resolve, reject) => {
            S.getRegTabResources(props.context)
                .then(({ data }) => {
                    if (isMounted()) {
                        setResources(data, "done");
                        resolve();
                    }
                })
                .catch(error => {
                    if (isMounted()) {
                        setResourcesStatus("error");
                        reject(error);
                    }
                })
        }));
        // Fetch the elements
        all_promises.push(new Promise((resolve, reject) => {
            S.getRegTabElements(props.context)
                .then(({ data }) => {
                    if (isMounted()) {
                        set_elements(data, "done");
                        resolve();
                    }
                })
                .catch(() => {
                    if (isMounted()) {
                        set_elem_status("error");
                        reject();
                    }
                })
        }));
        return Promise.all(all_promises);
    }, [isMounted, setResourcesStatus, set_elem_status, set_elements, setResources, props.context]);

    React.useEffect(() => {
        fetchResources();
        return () => setResources(INIT_RESOURCES, "load");
    }, [fetchResources, setResources]);
    //#endregion

    //#region Crumbs
    const confirmSaveBeforeQuit = React.useCallback(() => M.askConfirm({
        noText: TC.ANNOT_NO_SAVE,
        text: TC.SCH_SAVE_BEFORE_QUIT,
        title: TC.GLOBAL_SAVE_BEFORE_QUIT,
        yesText: TC.GLOBAL_SAVE_AND_QUITE,
    }), []);

    const closePreviousTab = React.useCallback(() => new Promise<void>((resolve, reject) => {
        let regTableChanged = activeTab === "table" && editedRegTable.value;
        let save_mission = activeTab === "mission" && updated_mission.value;
        let equipTableChanged = activeTab === "equipments" && equipTabRef.current?.hasChanged;
        let saveData = activeTab === "missingData" && regDataRef.current?.askSave && !!regDataRef.current.save;
        let saveAttribution = activeTab === "applicable" && autoFillRef.current?.askSave && !!autoFillRef.current.save;

        if (saveData) confirmSaveBeforeQuit().then(confirmed => {
            if (confirmed) regDataRef.current.save().then(() => resolve()).catch(() => reject());
            else resolve();
        });
        else if (saveAttribution) confirmSaveBeforeQuit().then(confirmed => {
            if (confirmed) autoFillRef.current.save().then(() => resolve()).catch(() => reject());
            else resolve();
        });
        else if (save_mission) M.askConfirm({ title: TC.MISSION_WIZARD_AUTO_SAVE_MISSION, text: TC.MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT }).then(confirmed => {
            if (confirmed) {
                let result = mission_ref.current?.save?.();
                if (result && result !== "errors") result.then(success => {
                    if (success) {
                        updated_mission.setFalse();
                        resolve();
                    }
                });
                else reject();
            }
            else reject();
        });
        else if (regTableChanged || equipTableChanged) {
            let dismount = M.renderLoader(TC.REG_LOAD_RECALCULATE);
            fetchResources()
                .then(() => equipTabRef.current?.reset?.())
                .finally(() => {
                    dismount?.();
                    resolve();
                });
        }
        else resolve();

    }), [confirmSaveBeforeQuit, fetchResources, activeTab, editedRegTable.value, updated_mission]);

    const dealWithNewTab = React.useCallback((tab: string) => new Promise<void>(resolve => {
        if ((tab === "applicable" || tab === "equipments") && editedElements.ref.current) {
            let dismount = M.renderLoader(TC.REG_LOAD_RECALCULATE);
            fetchResources()
                .then(() => editedElements.setFalse())
                .finally(() => {
                    dismount?.();
                    resolve();
                });
        }
        else if (tab === "table" && editedRegAffect.ref.current && regTableRef.current?.reload) {
            let dismount = M.renderLoader(TC.REG_LOAD_RECALCULATE);
            regTableRef.current.reload()
                .then(() => editedRegAffect.setFalse())
                .finally(() => {
                    dismount?.();
                    resolve();
                });
        }
        else resolve();
    }), [editedElements, editedRegAffect, fetchResources]);

    const onChangeTab = React.useCallback((tab: Tabs) => {
        closePreviousTab()
            .then(() => dealWithNewTab(tab).then(() => setActiveTab(tab)))
            // Cancel the opening of the next tab
            .catch(console.error);
    }, [closePreviousTab, dealWithNewTab]);
    //#endregion

    //#region Table Reg Height
    React.useEffect(() => {
        let htmlNode = document.getElementById(TABS_CONTAINER_ID)?.childNodes?.[0] || null;
        /* @ts-ignore */
        tabsContainerRef(htmlNode);
    });

    React.useEffect(() => navBarContainerRefForSize(navBarContainerRef.current));

    const tableContainerHeight = React.useMemo(() => {
        let height = TB.getVH() - navBarSize.height - TB.remToPx(TABS_MARGIN.rem) - TB.remToPx(CONTAINER_MARGIN.rem) - tabsSize.height;
        if (height < 0) return 0;
        return height;
    }, [tabsSize.height, navBarSize.height]);
    //#endregion

    //#region Save
    const saveDataFiller = React.useCallback((changes: T.DataChange[]) => {
        let updatePromise = S.applyDataChange(changes);

        updatePromise.then(() => {
            editedElements.setTrue();
            set_elements(p => {
                let updates = _.groupBy(changes, "_id");
                let new_elements = p.elements.map(e => {
                    let changes = updates[e.submission._id];
                    if (Array.isArray(changes)) {
                        let elem = _.cloneDeep(e);
                        changes.forEach(c => _.set(elem, "submission.data." + c.prop, c.value));
                        return elem;
                    }
                    return e;
                });
                return { ...p, elements: new_elements };
            });
        })
            .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }));

        return updatePromise;
    }, [set_elements, editedElements]);

    const saveAutoFill = React.useCallback((changes: Parameters<T.API.Reg.RegSwitch>[0]) => {
        let updatePromise = S.switchReg(changes);

        updatePromise.then(() => {
            // Only relevant if the table has been mounted yet
            if (typeof regTableRef.current?.reload === "function") editedRegAffect.setTrue();
            set_elements(p => {
                let updates = _.groupBy(changes, c => c.elem);

                let new_elements = p.elements.map(e => {
                    let elemUpdates = updates[e.submission._id];
                    if (Array.isArray(elemUpdates)) {
                        let regArray = TB.getArray<T.ActionValueItem>(e.submission.data.reglementations);

                        elemUpdates.forEach(c => {
                            if (c.isSet && regArray.filter(a => a.action === c.reg).length === 0) regArray = regArray.concat({ action: c.reg });
                            else if (!c.isSet) regArray = regArray.filter(a => a.action !== c.reg);
                        });

                        let clone = _.cloneDeep(e);
                        _.set(clone, "submission.data.reglementations", regArray);
                        return clone;
                    }
                    return e;
                });

                return { ...p, elements: new_elements };
            });
        })
            .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }));
        return updatePromise;
    }, [editedRegAffect, set_elements]);
    //#endregion

    return <Flex id={TABS_CONTAINER_ID} direction="column" className={`flex-grow-1 mb-${CONTAINER_MARGIN.size}`}>

        <BS.Row className="g-1 pb-2">
            {tabs.map(tab => tab.show && <BS.Col key={tab.value}>
                <Button
                    size="sm"
                    text={tab.label}
                    className="w-100"
                    disabled={!tab.enable}
                    onClick={() => onChangeTab(tab.value)}
                    variant={activeTab === tab.value ? "primary" : "link"}
                />
            </BS.Col>)}
        </BS.Row>

        <Spinner status={activeTab === "missingData" ? elem_status : statuses}>

            {!elements.show_wizard && <ErrorBanner type="info" textCode={TC.REG_WIZARD_REDUCE_CONTEXT} />}

            {activeTab === "mission" && <F.Missions.MissionForm
                no_delete
                ref={mission_ref}
                navigate={navigate}
                mission_id={props.mission_id}
                onSave={updated_mission.setFalse}
                asset={props.context?.roots?.[0]}
                onChange={updated_mission.setTrue}
            />}

            {activeTab === "missingData" && <RegDataFiller
                api={regDataRef}
                save={saveDataFiller}
                context={props.context}
                reload={fetchResources}
                read_only={props.read_only}
                elements={elements.elements}
            />}

            {activeTab === "equipments" && <RegEquipment
                api={equipTabRef}
                resources={resources}
                context={props.context}
                read_only={props.read_only}
                elements={elements.elements}
                containerSize={tableContainerHeight}
                updateElements={elements => set_elements(p => ({ ...p, elements: typeof elements === "function" ? elements(p.elements) : elements }))}
            />}

            {activeTab === "applicable" && <AutoFillReg
                resources={resources}
                read_only={props.read_only}
                elements={elements.elements}
                save={saveAutoFill} api={autoFillRef}
            />}

            {activeTab === "table" && <Flex style={{ height: tableContainerHeight + 'px' }}>
                <RegDocs
                    only_reg
                    ref={regTableRef}
                    context={props.context}
                    hide_buttons="doc_type"
                    read_only={props.read_only}
                    hasChanged={editedRegTable.setTrue}
                />
            </Flex>}
        </Spinner>
    </Flex>
}

export const RegPage: React.FC = () => {
    H.useCrumbs(TC.CRUMB_REG);
    const [roots] = H.useRoots();
    H.useAuth({ tabName: TABS.REG_WIZARD });
    return <RegWizard context={roots} />;
};