import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import { FP, T, TB, TC } from "../../Constants";
import ActionRegUpdater from "./ActionRegUpdater";
import * as US from "../../services/user.service";

//#region Types
type SiteBuildRegActionsProps = {
    roots: string | string[];
    api?: React.RefObject<SiteBuildRegActionsAPI | null>;
}

export type SiteBuildRegActionsAPI = {
    reset?: () => void;
    changesMade?: () => boolean;
    saveData?: () => Promise<void>;
}

type Resources = {
    sites: T.SiteType[];
    buildings: T.BuildingType[];
    status: "load" | "ready" | "error" | "empty";
}

type Edit = { _id: string, array: T.ActionValueItem[] };
//#endregion

//#region Constants
const DF_INIT: Resources = { buildings: [], sites: [], status: "load" };
//#endregion

const SiteBuildRegActions: React.FC<SiteBuildRegActionsProps> = ({ roots, api, ...props }) => {
    const [edits, setEdits] = React.useState<Edit[]>([]);
    const { getStaticText } = H.useLanguage([FP.SITE_FORM, FP.BUILDING_FORM]);
    const [{ buildings, sites, status }, setResources] = React.useState<Resources>(DF_INIT);
    const { getCurrentData, pagination } = H.usePagination({ totalItems: sites.length + buildings.length, initialPageSize: 5 });

    //#region Load Data
    React.useEffect(() => {
        let isSubscribed = true;
        US.getSiteAndBuilds(roots)
            .then(({ data }: { data: T.FullOptionsLocationsTyped<T.BuildingType | T.SiteType>[] }) => {
                if (isSubscribed) {
                    let buildingsLoc: T.FullOptionsLocationsTyped<T.BuildingType>[] = data.filter(l => l.path === FP.BUILDING_FORM)
                    let buildings = buildingsLoc.map(b => b.submission);

                    let sitesRegroup = data.filter(l => l.path === FP.SITE_FORM).map(l => l.submission).concat(...buildingsLoc.map(b => TB.getArray(b.location.site)));
                    let sites = _.uniqBy(sitesRegroup, s => s._id);

                    if (sites.length > 0 || buildings.length > 0) setResources({ sites, buildings, status: "ready" });
                    else setResources({ ...DF_INIT, status: "empty" });
                }
            })
            .catch(() => isSubscribed && setResources({ ...DF_INIT, status: "error" }));
        return () => { isSubscribed = false };
    }, [roots]);
    //#endregion

    //#region Edits
    const findArray = React.useCallback((elemId: string) => {
        let array = _.find(edits, e => e._id === elemId);
        if (array) return array.array;
        return _.find([...buildings, ...sites], n => n._id === elemId)?.data?.reglementations;
    }, [edits, buildings, sites]);

    const changeEdit = React.useCallback((elemId: string, actions: T.ActionValueItem[]) => {
        setEdits(p => {
            if (_.find(p, e => e._id === elemId)) return p.map(e => e._id === elemId ? { ...e, array: actions } : e);
            else return p.concat({ _id: elemId, array: actions });
        });
    }, []);
    //#endregion

    //#region API
    const onSave = React.useCallback(() => new Promise<void>((resolve, reject) => {
        let updates: T.BulkMongoose[] = edits.map(e => ({
            updateOne: {
                upsert: false,
                filter: { _id: e._id },
                update: { "data.reglementations": e.array.filter(r => TB.mongoIdValidator(r.action)) },
            }
        }));

        US.bulkSubmissionAction(updates).then(({ data }) => {
            if (data?.hasFailed) reject(TC.GLOBAL_ERROR_UPDATE);
            else {
                /* Update locally */
                setResources(p => {
                    let sites = p.sites.map(s => {
                        let action = _.find(edits, e => e._id === s._id);
                        return action ? { ...s, data: { ...s.data, reglementations: action.array } } : s;
                    });
                    let buildings = p.buildings.map(b => {
                        let action = _.find(edits, e => e._id === b._id);
                        return action ? { ...b, data: { ...b.data, reglementations: action.array } } : b;
                    });
                    return { ...p, sites, buildings };
                });
                setEdits([]);
                resolve();
            }
        }).catch(() => reject(TC.GLOBAL_ERROR_UPDATE));
    }), [edits]);

    React.useEffect(() => {
        if (TB.validObject(api) && TB.validObject(api.current)) {
            api.current.saveData = onSave;
            api.current.reset = () => setEdits([]);
            api.current.changesMade = () => edits.length > 0;
        }
    }, [api, edits, onSave]);
    //#endregion

    //#region Error & Load
    const errorBanner = React.useMemo(() => {
        switch (status) {
            case "load": return <M.Loader />;
            case "error": return <C.ErrorBanner type="danger" textCode={TC.G_ERROR_FETCH} />;
            case "empty": return <C.ErrorBanner type="warning" textCode={TC.GLOBAL_NO_BUILD_CONTEXT} />;
            default: return null;
        }
    }, [status]);
    //#endregion

    //#region Render
    const renderBuild = React.useCallback((b: T.BuildingType) => <ActionRegUpdater
        key={b._id}
        roots={roots}
        elementId={b._id}
        category={FP.BUILDING_FORM}
        itemActions={findArray(b._id)}
        onChangeActions={actions => changeEdit(b._id, actions)}
        label={`${b.data.name} (${getStaticText(FP.BUILDING_FORM)})`}
    />, [changeEdit, findArray, getStaticText, roots]);

    const renderSite = React.useCallback((s: T.SiteType) => <ActionRegUpdater
        key={s._id}
        roots={s._id}
        elementId={s._id}
        category={FP.SITE_FORM}
        itemActions={findArray(s._id)}
        onChangeActions={actions => changeEdit(s._id, actions)}
        label={`${s.data.name} (${getStaticText(FP.SITE_FORM)})`}
    />, [changeEdit, findArray, getStaticText]);
    //#endregion

    //#region Array
    const allData = React.useMemo(() => {
        let buildArray = buildings.map(b => ({ obj: b, fn: renderBuild }));
        let siteArray = sites.map(s => ({ obj: s, fn: renderSite }));
        return buildArray.concat(siteArray);
    }, [buildings, sites, renderBuild, renderSite]);
    //#endregion

    return <>
        {errorBanner}
        {status === "ready" && <>
            {pagination}
            {getCurrentData(allData).map(d => d.fn(d.obj))}
        </>}
    </>;
}

export default SiteBuildRegActions;