import { T } from "../Constants";
import { store } from "../Redux";
import { v4 as uuid } from "uuid";

const alerts_reducer: T.ReduxFn<T.AlertRedux[], T.AlertActions> = (state = [], payload) => {
    if (payload.type === "ADD_ALERT") {
        const new_alert = payload.action as T.AlertRedux;
        // Check that there aren't two alerts for the same "InfoBubble"
        if (new_alert.bubble_id && state.some(alert => alert.bubble_id === new_alert.bubble_id)) return state;
        else return state.concat(payload.action as T.AlertRedux);
    }
    else if (payload.type === "DISMISS_ALERT") return state.filter(alert => alert.id !== (payload.action as T.AlertRedux["id"]));
    else return state;
}

export default alerts_reducer;

/** Dismiss an alert that is currently on-screen */
export const dismissAlert = (id: T.AlertRedux["id"]) => {
    store.then(s => s.dispatch({ type: "DISMISS_ALERT", action: id }));
};

/** Add a new alert to the list, and returns a function to dismiss that action */
export const renderAlert = (alert: Omit<T.AlertRedux, "id">): (() => void) => {
    const id = uuid();
    const delay = alert.delay === undefined ? 5 : alert.delay;
    store.then(s => s.dispatch({ type: "ADD_ALERT" as T.AlertActions, action: { ...alert, delay, id } }));
    return () => dismissAlert(id);
};