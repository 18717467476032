import _ from "lodash";
import React from "react";
import * as G from "../Grid";
import * as M from "../../Modal";
import { useSelector } from "react-redux";
import { Spinner } from "../../../Common";
import { MaintenanceActionColumns } from "./Columns";
import * as US from "../../../services/user.service";
import { FP, TC, TABS, RIGHTS, T, TB } from "../../../Constants";
import { ContextMenuItem, updateCellParams } from "../TableTypes";
import { useAuth, useCrumbs, useLanguage, useRights } from "../../../hooks";

//#region Types
type Resource = {
    gammes: T.EquipGammeType[];
    forms: { [key: string]: string };
    actions: T.ActionMaintenanceType[];
    status: "load" | "ready" | "error";
    categoryTree: { [key: string]: string[] };
    locOptions: T.FullOptionsLocationsTyped<T.SiteType>[];
}
//#endregion

//#region Constants
const TEXT_CODES = [
    TC.GLOBAL_SHOW, TC.GLOBAL_EDIT, TC.GLOBAL_DELET_N_ITEMS, TC.GLOBAL_DELETE, TC.P_ADD_TICKET, TC.GLOBAL_FAILED_LOAD,
    TC.TEMPLATE_NEW_ITEM_NEW_F, FP.MAINTENANCE_ACTION, TC.NEW_GEN_ACTION_MAINTENANCE, TC.GLOBAL_NO_X_CONTEXT, FP.SITE_FORM,
    TC.GLOBAL_DELET_N_ITEMS, TC.GLOBAL_DELETE
];

const DF_INIT: Resource = { actions: [], forms: {}, gammes: [], categoryTree: {}, locOptions: [], status: "load" };
//#endregion

const MaintenanceGamme: React.FC<{ rootId?: string | string[], portfolioId?: string }> = ({ rootId, portfolioId, ...props }) => {
    useCrumbs(TC.GAMME);
    const rights = useRights();
    const { getStaticText } = useLanguage(TEXT_CODES);
    const [{ isAdmin }] = useAuth({ tabName: TABS.GESTION_MAINTENANCE });
    const dataContext = useSelector((redux: T.ReduxSelector) => redux.dataContext);
    const [{ status, actions, locOptions, gammes, categoryTree, forms }, setResource] = React.useState<Resource>(DF_INIT);

    //#region Rights
    const canEdit = React.useMemo(() => rights.isRightAllowed(RIGHTS.GGM_EDIT), [rights]);
    const canCreate = React.useMemo(() => rights.isRightAllowed(RIGHTS.GGM_CREATE), [rights]);
    const canDelete = React.useMemo(() => rights.isRightAllowed(RIGHTS.GGM_DELETE), [rights]);
    //#endregion

    //#region Forms
    const actionFormId = React.useMemo(() => forms[FP.MAINTENANCE_ACTION], [forms]);
    const isAction = React.useCallback((obj: any): obj is T.ActionMaintenanceType => obj?.form === actionFormId, [actionFormId]);
    //#endregion

    //#region Fetch Data
    const rootsPortfolio = React.useMemo(() => {
        if (TB.mongoIdValidator(portfolioId)) return { portfolio: portfolioId };
        else if (TB.mongoIdValidator(rootId)) return { roots: [rootId] };
        else if (Array.isArray(rootId) && rootId.length > 0 && TB.multiMongoIdValidator(rootId)) return { roots: rootId };
        else if (TB.mongoIdValidator(dataContext.selectedPortfolio)) return { portfolio: dataContext.selectedPortfolio };
        else return { roots: dataContext.selectedItems };
    }, [dataContext.selectedItems, dataContext.selectedPortfolio, portfolioId, rootId]);

    React.useEffect(() => {
        let isSubscribed = true;

        const onError = () => setResource({ ...DF_INIT, status: "error" });

        if (rootsPortfolio !== null) US.getFullResources(FP.MAINTENANCE_ACTION, rootsPortfolio)
            .then(({ data }) => {
                if (isSubscribed) {
                    if (data.hasFailed) onError();
                    else {
                        let { contextName, ...resource } = data;
                        setResource({ ...resource, status: "ready" });
                    }
                }
            })
            .catch(onError);

        return () => { isSubscribed = false };
    }, [rootsPortfolio]);
    //#endregion

    //#region Row
    const getGammesRelated = React.useCallback((id?: string) => {
        if (!TB.mongoIdValidator(id) || !Array.isArray(categoryTree[id])) return {};
        let related = categoryTree[id];
        if (related.length === 0) return { 0: id };
        let orderedIds = [...new Array(6)].map((x, i) => {
            if (i === related.length) return [i, id];
            else if (i > related.length) return null;
            return [i, related[i]];
        }).filter(w => w !== null);
        return Object.fromEntries(orderedIds);
    }, [categoryTree]);

    const rows = React.useMemo(() => {
        let duplicatedActions = actions.map(a => {
            if (Array.isArray(a.data.gammes)) return a.data.gammes.filter(TB.mongoIdValidator).map((id, i) => ({ ...a, id: a._id + "_" + i, gammes: getGammesRelated(id) }));
            return { ...a, id: a._id };
        });
        return _.flatten(duplicatedActions);
    }, [actions, getGammesRelated]);
    //#endregion

    //#region Data Format
    const findSite = React.useCallback((id?: string) => {
        let loc = _.find(locOptions, lo => lo.submission._id === id);
        if (loc) return loc.submission.data.name;
        return "";
    }, [locOptions]);

    const formatResources = React.useCallback((resources?: string[]) => {
        let arrayCodes = TB.getArray(resources).filter(r => FP.RESOURCE_FORMS.includes(r));
        return arrayCodes.map(r => getStaticText(r)).join(", ");
    }, [getStaticText]);

    const formatGamme = React.useCallback((id?: string) => _.find(gammes, g => g._id === id)?.data?.name || "", [gammes]);
    //#endregion

    //#region Columns
    const colUpdates = React.useMemo(() => [
        { field: "data.site", formatFn: p => findSite(p?.value) },
        { field: "data.resources", formatFn: p => formatResources(p?.value) },
        ...[...new Array(6)].map((x, i) => ({ field: "gammes." + i, formatFn: p => formatGamme(p?.value) })),
    ], [findSite, formatResources, formatGamme]);

    const getHeaderCol = React.useCallback((code?: string) => getStaticText(code), [getStaticText]);
    const finalColumns = React.useMemo(() => updateCellParams(MaintenanceActionColumns, colUpdates, getHeaderCol), [colUpdates, getHeaderCol]);
    //#endregion

    //#region Actions
    const gammesIds = React.useMemo(() => gammes.map(g => g._id), [gammes]);

    const loadNewGammes = React.useCallback((gammes: string[]) => {
        let array = TB.getArray(gammes).filter(id => !gammesIds.includes(id));
        if (array.length > 0) US.getCategoryRelations(array, true)
            .then(({ data }) => setResource(p => ({
                ...p,
                gammes: _.uniqBy(p.gammes.concat(data.gammes), "_id"),
                categoryTree: _.merge(p.categoryTree, data.categoryTree),
            })))
            .catch(() => M.renderAlert({ type: "warning", message: TC.ERR_FAILED_LOC }));
    }, [gammesIds]);

    const actionEdit = React.useCallback((action: T.ActionMaintenanceType, readOnly = false) => {
        M.renderFormModal({ path: FP.MAINTENANCE_ACTION, submissionId: action._id, readOnly })
            .then((newAction) => {
                if (isAction(newAction)) {
                    loadNewGammes(TB.getArray(newAction.data.gammes));
                    setResource(p => ({ ...p, actions: p.actions.map(a => a._id === action._id ? newAction : a) }));
                }
            })
    }, [isAction, loadNewGammes]);

    const actionDelete = React.useCallback((action: T.ActionMaintenanceType | T.ActionMaintenanceType[]) => {
        let number = Array.isArray(action) && action.length;
        let title = Array.isArray(action) && action.length > 0 ? TC.GLOBAL_DELET_N_ITEMS : TC.GLOBAL_DELETE;

        M.askConfirm({ title: getStaticText(title, number || undefined) }).then(confirmed => {
            if (confirmed) {
                let ids = Array.isArray(action) ? action.map(t => t._id) : [action._id];
                US.removeManySubmissionsFromId(ids).then(({ data }) => {
                    if (data?.ok) setResource(p => ({ ...p, actions: p.actions.filter(a => !ids.includes(a._id)) }));
                    else M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_DELETE });
                });
            }
        });
    }, [getStaticText]);

    const actionCreate = React.useCallback((site?: string) => {
        M.renderFormModal({ path: FP.MAINTENANCE_ACTION, forcedSubmission: [{ prop: "site", value: site }] })
            .then(action => {
                if (isAction(action)) {
                    loadNewGammes(TB.getArray(action.data.gammes));
                    setResource(p => ({ ...p, actions: p.actions.concat(action) }));
                }
            })
    }, [isAction, loadNewGammes]);

    const askSiteCreate = React.useCallback(() => {
        if (locOptions.length === 0) M.renderAlert({ type: "warning", message: { ref: TC.GLOBAL_NO_X_CONTEXT, template: FP.SITE_FORM } });
        else if (locOptions.length === 1) actionCreate(locOptions[0].submission._id);
        else M.askSelect({
            isRequired: true,
            options: locOptions.map(lo => ({ label: lo.submission.data.name, value: lo.submission._id })),
        })
            .then(site => {
                if (TB.mongoIdValidator(site)) actionCreate(site);
            });
    }, [locOptions, actionCreate]);
    //#endregion

    //#region Context Menu
    const getContextMenu = React.useCallback<G.TableProps<any>["getContextMenuItems"]>((params) => {
        let action = params.node?.data;
        let selectedActions = params.api.getSelectedNodes().map(({ data }) => data).filter(isAction);
        let extraItems: (ContextMenuItem | string)[] = [], defaultItems = TB.getArray(params.defaultItems).filter(TB.validString);

        if (isAction(action)) {
            let isGeneral = !TB.mongoIdValidator(action.data.site);

            extraItems.push({ name: getStaticText(TC.GLOBAL_SHOW), icon: "<i class='fa fa-search'></i>", action: () => actionEdit(action, true) });

            if (!isGeneral && canEdit) extraItems.push({
                name: getStaticText(TC.GLOBAL_EDIT),
                icon: "<i class='fa fa-pencil-alt'></i>",
                action: () => actionEdit(action),
            });

            if (!isGeneral && canDelete) {
                if (selectedActions.length > 1) extraItems.push({
                    name: getStaticText(TC.GLOBAL_DELET_N_ITEMS, selectedActions.length),
                    icon: "<i class='fa fa-times text-danger'></i>",
                    action: () => actionDelete(selectedActions)
                });
                else extraItems.push({
                    name: getStaticText(TC.GLOBAL_DELETE),
                    icon: "<i class='fa fa-times text-danger'></i>",
                    action: () => actionDelete(action)
                });
            }
        }

        if (canCreate) extraItems.push({
            name: getStaticText(TC.TEMPLATE_NEW_ITEM_NEW_F, FP.MAINTENANCE_ACTION),
            icon: "<i class='fa fa-plus'></i>",
            action: askSiteCreate,
        });
        if (isAdmin) extraItems.push({
            name: getStaticText(TC.NEW_GEN_ACTION_MAINTENANCE),
            icon: "<i class='fa fa-tools'></i>",
            action: actionCreate,
        });

        if (extraItems.length > 0) extraItems.push("separator");
        return extraItems.concat(defaultItems);
    }, [actionCreate, actionDelete, actionEdit, askSiteCreate, getStaticText, isAction, isAdmin, canCreate, canDelete, canEdit]);
    //#endregion

    return <div className="w-100">
        <Spinner error={status === "error"}>
            <G.Table
                count
                sideBar
                rows={rows}
                columns={finalColumns}
                getRowId={r => r.data.id}
                getContextMenuItems={getContextMenu}
                adaptableId={TABS.TABLE_GAMMES_EQUIP}
                columns_base={React.useMemo(() => ["filterable", "grouped", "sortable"], [])}
            />
        </Spinner>
    </div>;
}

export default MaintenanceGamme;