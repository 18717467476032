import _ from "lodash";
import React from 'react';
import * as BS from 'react-bootstrap';
import { T } from "../../../Constants";
import NavbarNavLink from "./NavbarNavLink";
import NavbarDropdown from './NavbarDropdown';

type NavbarTopDropDownMenusProps = {
  routes: T.RoutesCollection[];
}

type FlatRoute = T.RoutesType & { isTitle?: boolean };

const flatRoutes = childrens => {
  const allChilds = [];

  const flatChild = childrens => {
    if (!Array.isArray(childrens)) return;

    childrens.forEach(child => {
      if (Array.isArray(child?.children) && child.children.length > 0) {
        allChilds.push({ ...child, isTitle: true });
        flatChild(child.children);
      }
      else allChilds.push(child);
    });
  };
  flatChild(childrens);

  return allChilds;
};

const NavbarTopDropDownMenus: React.FC<NavbarTopDropDownMenusProps> = ({ routes }) => {
  const validRoutes = React.useMemo(() => routes.map(r => ({ ...r, children: flatRoutes(r.children) as FlatRoute[] })), [routes]);
  const chunkedRoutes = React.useMemo(() => validRoutes.map(({ children, ...r }) => ({ ...r, children: _.chunk(children, 10) })), [validRoutes]);

  return <>
    {chunkedRoutes.map((route, i) => <NavbarDropdown key={i} title={route.label}>
      <BS.Row style={{ minWidth: (route.children.length * 14) + 'rem' }}>
        {[...new Array(route.children.length)].map((x, index) =>
          <BS.Col key={index} md={Math.floor(12 / route.children.length)}>
            <BS.Nav className='flex-column'>
              {route.children[index].map(({ isTitle, ...route }, i) => isTitle ?
                <NavbarNavLink key={i} title={route.name} route={route} />
                : <NavbarNavLink key={i} route={route} />)}
            </BS.Nav>
          </BS.Col>
        )}
      </BS.Row>
    </NavbarDropdown>)}
  </>
};

export default NavbarTopDropDownMenus;
