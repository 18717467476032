import * as T from "../Types";
import * as TC from "../textCode";
import { RoleIntervenantsOptions } from "./Missions";

export const OPTIONS = {
    responsible: RoleIntervenantsOptions,
    status: [
        { value: "memo", label: TC.REM_STATUS_MEMO },
        { value: "open", label: TC.REM_STATUS_OPEN },
        { value: "closed", label: TC.REM_STATUS_CLOSED },
    ] as T.Option<object, T.RemarqueDefault["status"]>[],
    cost_types: [
        { value: "GT", label: TC.REM_COST_GT },
        { value: "miniGT", label: TC.REM_COST_MINI_GT },
        { value: "consumable", label: TC.REM_COST_CONS },
        { value: "offContract", label: TC.REM_COST_OFF },
    ] as T.Option<object, T.RemarqueDefault["costType"]>[],
}