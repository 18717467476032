import React from "react";
import { Flex } from "../../Layout";
import * as H from "../../../hooks";
import * as S from "../../../services";
import { Modal as M } from "../../../Components";
import { LANG, T, TB, TC } from "../../../Constants";
import { ComponentWrapper, InputProps } from "../Input";
import { Form, InputGroup, ListGroup } from "react-bootstrap";
import { ButtonProps, Button, QuickInputProps2 } from "../../Item";

//#region Types
export type TextFieldProps<A = (SingleProps | MultiProps)> = InputProps & CommonProps & A;

type SingleTextFieldRef = {
    /** Get the controlled value of the input, if input is single */
    get_controlled_single?: () => string;
    /** Ref to the input */
    input: React.MutableRefObject<HTMLInputElement>;
    /** Ref to the textarea */
    textarea: React.MutableRefObject<HTMLTextAreaElement>;
}

export type TextFieldRef = {
    /** The ref to the single input */
    single?: React.MutableRefObject<SingleTextFieldRef>;
}

type CommonProps = {
    rows?: number;
    suffix?: string | ButtonProps;
    prefix?: string;
    isEmail?: boolean;
    multiple?: boolean;
    textArea?: boolean;
    autoFocus?: boolean;
    autoExpand?: boolean;
    spellcheck?: boolean;
    placeholder?: string;
    case?: T.FormTextCase;
    autocomplete?: string;
    uncontrolled?: boolean;
    /** The id to give to the input */
    id?: string;
    /** Should the content be hidden */
    password?: boolean;
    /** Maximum height the textarea can reach */
    max_height?: string;
    /** Minimum height the textarea can reach */
    min_height?: string;
    // inputRef?: React.MutableRefObject<HTMLInputElement>;
    textareaRef?: React.MutableRefObject<HTMLTextAreaElement>;
}

type SingleProps = {
    value?: string;
    useCalculatedError?: boolean;
    onChange?: (text: string) => void;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

type MultiProps = {
    value?: string[];
    onChange?: (texts: string[]) => void;
}
//#endregion

//#region Single
const TextFieldSingle = React.forwardRef<SingleTextFieldRef, TextFieldProps<SingleProps>>(({ onChange, set_translation, onKeyDown, disabled, noOverride, ...props }, ref) => {
    const lg = H.useLanguage();
    const [controlled, setControlled] = React.useState("");
    const textInputRef = React.useRef<HTMLInputElement>(null);
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const { error } = H.useFormConsumer(props.prop, props.contextKey);
    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);
    H.useAutoSizeTextArea(textAreaRef.current, props.autoExpand ? controlled : "", props.rows);

    const value = React.useMemo(() => {
        if (props.allow_translations) return props.translation?.[lg.prop] || props.value
        else return props.value;
    }, [props.allow_translations, props.translation, lg.prop, props.value]);

    React.useImperativeHandle(ref, () => ({
        input: textInputRef,
        textarea: textAreaRef,
        get_controlled_single: () => controlled,
    }), [controlled]);

    React.useEffect(() => {
        if (props.textareaRef) props.textareaRef.current = textAreaRef.current;
    });

    //#region Updates
    React.useEffect(() => setControlled(TB.getString(value)), [value]);

    const change_and_translate = React.useCallback<typeof onChange>((value: string) => {
        if (props.allow_translations) {
            let new_translation = props.translation || { prop: props.prop, _id: props.submissionId };
            new_translation[lg.prop] = value;
            let translation_key: Parameters<typeof set_translation>[0];
            // Creating a translation where none existed
            if (props.translation_key === "none") translation_key = "created";
            // Update a translation created this session
            else if (props.translation_key === "created") translation_key = "created";
            // Update an existing translation
            else if (props.translation_key === "loaded") translation_key = "edited";
            // Update a translation edited this session
            else if (props.translation_key === "edited") translation_key = "edited";
            // Update the context with the new translation
            set_translation(translation_key, new_translation as T.LanguageObj, props.prop);
        }
        onChange?.(value);
    }, [props.allow_translations, props.translation, props.translation_key, lg.prop, props.prop, props.submissionId, set_translation, onChange]);

    const onLostFocus = React.useCallback(() => !isDisabled && !props.uncontrolled && change_and_translate?.(controlled), [change_and_translate, controlled, isDisabled, props.uncontrolled]);

    const onChangeInput = React.useCallback((text: string) => {
        if (props.case === "lowercase" || props.isEmail) text = text.toLowerCase();
        else if (props.case === "uppercase") text = text.toUpperCase();
        if (props.uncontrolled) change_and_translate?.(text);
        else setControlled(text);
    }, [props.case, props.uncontrolled, props.isEmail, change_and_translate]);
    //#endregion

    //#region Input Style & Props
    const translation_component = React.useMemo(() => {
        const translate = () => {
            let rows = [props.translation || { [lg.prop]: controlled, prop: props.prop, _id: props.submissionId }];
            let modal_ref: QuickInputProps2<typeof rows[number]>["modal_ref"] = { current: null };
            let columns: QuickInputProps2<typeof rows[number]>["columns"] = LANG.ALL_LANGUAGES.map(l => ({ type: "text", prop: l.prop, label: l.lg }));

            const auto_translate = () => {
                let values = modal_ref.current?.get() || [];
                let complete_lang = LANG.ALL_PROPS.find(l => values.every(v => typeof v[l] === "string" && v[l].trim().length > 0));
                // No lang was had a translation for all rows, notify user
                if (!complete_lang) M.renderAlert({ type: "warning", message: TC.AUTO_TRANSLATE_NO_FULL_BASE });
                else {
                    const unmount = M.renderLoader();
                    let texts = values.map(v => v[complete_lang]);
                    S.translateManyText({ texts, lang: complete_lang }).then(({ data }) => {
                        let new_rows = values.map((value, index) => {
                            let result = data[index];
                            let clean_value = Object.fromEntries(
                                Object.entries(value).filter(([lg_prop, text]) => typeof text === "string" && text.trim().length > 0)
                            );

                            if (result === "failed") return value;
                            else return { ...result, ...clean_value, prop: value.prop, _id: value._id };
                        });
                        modal_ref.current?.set(new_rows);
                    })
                        .catch(M.Alerts.loadError)
                        .finally(unmount);
                }
            };

            let footer = <Button variant="info" icon="robot" text={TC.AUTO_TRANSLATE} onClick={auto_translate} />
            M.renderQuickInput<Partial<T.LanguageObj>>({ gel_rows: true, footer, columns, rows, modal_ref, modal: { title: TC.TAB_TRANSLATIONS, size: "lg" } }).then(labels => {
                if (labels?.[0]) {
                    let new_translation = labels[0] as T.LanguageObj;
                    // Recuperate the id if it exists
                    new_translation.id = props.translation?.id;
                    let translation_key: Parameters<typeof set_translation>[0];
                    // Creating a translation where none existed
                    if (props.translation_key === "none") translation_key = "created";
                    // Update a translation created this session
                    else if (props.translation_key === "created") translation_key = "created";
                    // Update an existing translation
                    else if (props.translation_key === "loaded") translation_key = "edited";
                    // Update a translation edited this session
                    else if (props.translation_key === "edited") translation_key = "edited";
                    // Update the context with the new translation
                    set_translation(translation_key, new_translation, props.prop);
                    // Update the current component if it's value changed
                    if (controlled !== new_translation[lg.prop]) onChange?.(new_translation[lg.prop]);
                }
            });
        }

        if (!props.allow_translations || props.multiple) return null;
        else return <Button size="sm" icon="language" variant="info" onClick={translate} />;
    }, [props.allow_translations, props.multiple, props.submissionId, props.prop, props.translation, props.translation_key, lg.prop, controlled, set_translation, onChange]);

    const vPrefix = React.useMemo(() => props.prefix && lg.getStaticText(props.prefix), [props.prefix, lg]);

    const vSuffix = React.useMemo(() => {
        if (TB.validString(props.suffix)) return <InputGroup.Text children={lg.getStaticText(props.suffix)} />;
        else if (props.suffix) return <Button {...props.suffix} />;
        else return null;
    }, [props.suffix, lg]);

    const vPlaceHolder = React.useMemo(() => props.placeholder && lg.getStaticText(props.placeholder), [props.placeholder, lg]);

    const inputProps = React.useMemo(() => ({
        id: props.id,
        placeholder: vPlaceHolder,
        spellCheck: props.spellcheck,
        autoComplete: props.autocomplete,
        type: props.isEmail ? "email" : (props.password ? "password" : undefined),
    }), [vPlaceHolder, props.isEmail, props.autocomplete, props.spellcheck, props.password, props.id]);

    const inputComponent = React.useMemo(() => <InputGroup>
        {vPrefix && <InputGroup.Text>{vPrefix}</InputGroup.Text>}
        <Form.Control
            {...inputProps}
            value={controlled}
            ref={textInputRef}
            onBlur={onLostFocus}
            disabled={isDisabled}
            onKeyDown={onKeyDown}
            readOnly={props.readonly}
            className={"input_control"}
            autoFocus={props.autoFocus}
            onChange={e => onChangeInput(e.target.value)}
        />
        {vSuffix}
        {translation_component}
    </InputGroup>, [inputProps, vPrefix, vSuffix, translation_component, controlled, isDisabled, onChangeInput, onLostFocus, onKeyDown, props.readonly, props.autoFocus]);

    const textAreaComponent = React.useMemo(() => <InputGroup>
        <Form.Control
            {...inputProps}
            as="textarea"
            ref={textAreaRef}
            rows={props.rows}
            value={controlled}
            onBlur={onLostFocus}
            disabled={isDisabled}
            readOnly={props.readonly}
            style={{ maxHeight: props.max_height, minHeight: props.min_height }}
            onChange={e => onChangeInput(e.target.value)}
        />
    </InputGroup>, [inputProps, controlled, isDisabled, onChangeInput, onLostFocus, props.rows, props.readonly, props.max_height, props.min_height]);

    const input = React.useMemo(() => props.textArea ? textAreaComponent : inputComponent, [textAreaComponent, inputComponent, props.textArea]);
    //#endregion

    return <ComponentWrapper {...props} error={props.useCalculatedError ? error : props.error} disabled={isDisabled} children={input} />;
});
//#endregion

const TextField = React.forwardRef<TextFieldRef, TextFieldProps>(({ multiple, disabled, noOverride, ...props }, ref) => {
    const singleRef = React.useRef<SingleTextFieldRef>(null);
    const onChange = React.useMemo(() => props.onChange, [props.onChange]);
    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);
    const values = React.useMemo(() => TB.arrayWrapper(props.value).filter(x => typeof x === "string"), [props.value]);

    React.useImperativeHandle(ref, () => ({ single: singleRef }), []);

    const editEntry = React.useCallback((str: string, index: number) => {
        if (values.length === 0 && index === 0) onChange?.([str]);
        else onChange?.(values.map((v, i) => i === index ? str : v));
    }, [onChange, values]);

    const addEntry = React.useCallback(() => onChange?.(values.concat("")), [onChange, values]);
    const removeEntry = React.useCallback((index: number) => onChange?.(values.filter((v, i) => i !== index)), [onChange, values]);

    if (!multiple) return <TextFieldSingle {...props} ref={singleRef} disabled={isDisabled} />;
    return <ListGroup className="mb-3">
        {values.map((v, i) => <ListGroup.Item key={i}>
            <Flex alignItems="center">
                <TextFieldSingle
                    {...props}
                    value={v}
                    useCalculatedError
                    disabled={isDisabled}
                    prop={props.prop + "." + i}
                    onChange={(s: string) => editEntry(s, i)}
                    customClass={TB.getString(props.customClass) + " flex-grow-1"}
                />
                <div className="ms-3">
                    <Button variant="danger" disabled={isDisabled} size="sm" onClick={() => removeEntry(i)}>
                        <i className="fa fa-times"></i>
                    </Button>
                </div>
            </Flex>
        </ListGroup.Item>)}

        {values.length === 0 && <ListGroup.Item>
            <TextFieldSingle {...props} value={""} disabled={isDisabled} onChange={(s: string) => editEntry(s, 0)} error={null} />
        </ListGroup.Item>}

        <ListGroup.Item className="text-end">
            <Button size="sm" onClick={addEntry} disabled={isDisabled}>
                <i className="fa fa-plus"></i>
            </Button>
        </ListGroup.Item>
    </ListGroup>;
});

TextField.displayName = "TextFieldPlural";
TextFieldSingle.displayName = "TextField";
export default TextField;