import React from "react";
import useRights from "./useRights";
import { T, TB } from "../Constants";
import { useSelector } from "react-redux";

//#region Types
type hookParams = {
    tabName?: string;
    onlyAdmin?: boolean;
    forcedLog?: boolean;
    neededRights?: {
        rights: string[] | string;
        validateEvery?: boolean;
    }
}

type Helper = {
    auth: Promise<T.UserRedux>;
    errorPageNavigate: () => void;
    navigate: (url: string) => void;
    setAuth: React.Dispatch<React.SetStateAction<T.UserRedux>>
}

type authHookFn = (params?: hookParams) => [T.UserRedux & { userId?: string }, Helper];
//#endregion

const defaultNavigate = (url: string) => window.location.replace(url);

const useAuth: authHookFn = params => {
    const rights = useRights();
    const [userAuth, setAuth] = React.useState<T.UserRedux>({});
    const auth = useSelector((redux: T.ReduxSelector) => redux.auth);
    const { isLoggedIn, user, isAdmin, admin_log } = React.useMemo(() => userAuth, [userAuth]);
    const { forcedLog = true, onlyAdmin = false } = React.useMemo(() => TB.validObject(params) ? params : {}, [params]);
    const [isNotLogged, isNotAdmin] = React.useMemo(() => [isLoggedIn, isAdmin].map(bool => typeof bool === "boolean" && !bool), [isLoggedIn, isAdmin]);

    const navigate = React.useCallback(defaultNavigate, []);
    const errorPageNavigate = React.useCallback(() => navigate("/errors/404"), [navigate]);

    React.useEffect(() => {
        // Forces the user to be logged in, otherwise go to login
        if (forcedLog && (user === null || isNotLogged)) navigate("/login");
    }, [isNotLogged, user, forcedLog, navigate]);

    React.useEffect(() => {
        // Forces the user to be logged and an admin, otherwise go to home or login
        if (onlyAdmin && (user === null || isNotLogged || isNotAdmin)) {
            if (user === null || isNotLogged) {
                localStorage.clear();
                navigate("/login");
            }
            else navigate("/home");
        }
    }, [onlyAdmin, user, isNotLogged, isNotAdmin, navigate]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (auth instanceof Promise) auth.then(user => isSubscribed && setAuth(user));
        return () => { isSubscribed = false };
    }, [auth]);

    //#region Forced Rights
    React.useEffect(() => {
        if (rights.loaded) {
            if (TB.validObject(params) && TB.validObject(params.neededRights)) {
                // Multiple rights need verification
                if (Array.isArray(params.neededRights.rights) && params.neededRights.rights.length === 0) {
                    let has_all_access = false;
                    let all_rights = params.neededRights.rights;
                    // Every single right has to be present of every asset of the context
                    if (params.neededRights.validateEvery) has_all_access = rights.context_ids.every(id => all_rights.every(r => rights.isRightAllowed(r, id)));
                    // Only one of the right has to be present in every asset of the context
                    else has_all_access = rights.context_ids.every(id => all_rights.some(r => rights.isRightAllowed(r, id)));
                    // Didn't pas the rights requirements
                    if (!has_all_access) navigate("/unavailable");
                }
                // A single right need verification
                else if (typeof params.neededRights.rights === "string") {
                    // Check that the right is present for every element of the current context
                    let right = params.neededRights.rights;
                    let has_right_everywhere = rights.context_ids.every(id => rights.isRightAllowed(right, id));
                    if (!has_right_everywhere) navigate("/unavailable");
                }

                if (Array.isArray(params.neededRights.rights) && params.neededRights.rights.length > 0) {
                    let redirect = false;
                    if (params.neededRights.validateEvery) redirect = params.neededRights.rights.every(r => rights.isRightAllowed(r));
                    else redirect = params.neededRights.rights.some(r => rights.isRightAllowed(r));
                    if (redirect) navigate("/unavailable");
                }
            }
        }
    }, [navigate, params, rights, rights.context_ids]);
    //#endregion

    React.useEffect(() => {
        // Remove access to the page if user doesn't have access to the page for every asset of the selected context
        if (TB.validString(params?.tabName) && rights.loaded) {
            let all_access = rights.context_ids.every(id => rights.isPageAllowed(params.tabName, id));
            if (!all_access) navigate("/unavailable");
        }
    }, [navigate, params, rights, rights.context_ids]);

    React.useEffect(() => {
        // Force a logout if user doesn't have a password anymore, and it's not an 'admin log'
        if (isLoggedIn && !user?.data?.password && !admin_log) {
            localStorage.clear();
            navigate("/login");
        }
    }, [user?.data?.password, isLoggedIn, admin_log, navigate]);

    return [{ ...userAuth, userId: userAuth.user?._id }, { errorPageNavigate, setAuth, navigate, auth }];
}

export default useAuth;