import React from "react";
import { Flex } from "../../Layout";
import { T, TB } from "../../../Constants";
import { useLanguage } from "../../../hooks";
import { Button, Table } from "react-bootstrap";
import { ComponentWrapper, InputProps, Input } from "../Input";

export type DataGridProps = InputProps & {
    addAnother?: string;
    layoutFixed?: boolean;
    components?: T.FormComponentsMain[];
    disableAddingRemovingRows?: boolean;
    addAnotherPosition?: "bottom" | "top" | "both";
}

const DataGrid: React.FC<DataGridProps> = ({ onChange, onChanges, ...props }) => {
    const lg = useLanguage();

    //#region Components & Value
    const vValue = React.useMemo(() => {
        let values = TB.getArray(props.value).filter(TB.validObject);
        return values.length === 0 ? [{}] : values;
    }, [props.value]);

    const vComponents = React.useMemo(() => TB.getArray(props.components).filter(TB.validObject), [props.components]);
    const componentsKeys = React.useMemo(() => vComponents.map(c => c.key), [vComponents]);
    //#endregion

    //#region Changes
    const addEntry = React.useCallback(() => {
        onChange?.(undefined, `${props.prop}.${vValue.length}.${componentsKeys[0] || "key"}`)
    }, [onChange, vValue, componentsKeys, props.prop]);

    const removeEntry = React.useCallback((index: number,) => {
        let updates: { value: any, prop: string, remove?: boolean }[] = [];
        for (let i = vValue.length - 1; i >= 0; i--) {
            if (i === vValue.length - 1) Object.keys(vValue[i]).forEach(prop => updates.push({ value: null, prop: `${props.prop}.${i}.${prop}`, remove: true }));
            else if (i >= index) Object.entries(vValue[i + 1]).forEach(([prop, value]) => updates.push({ value, prop: `${props.prop}.${i}.${prop}` }));
        }

        onChanges?.(updates);
    }, [onChanges, vValue, props.prop]);
    //#endregion

    //#region AddButton
    const addButton = React.useMemo(() => {
        if (props.hideAddButton) return null;
        return <Button onClick={addEntry} disabled={!Array.isArray(props.value)}>
            {lg.getStaticElem(props.addAnother)}
            <i className={`fa fa-plus ${TB.validString(props.addAnother) ? "ms-2" : ""}`}></i>
        </Button>
    }, [props.hideAddButton, props.addAnother, props.value, addEntry, lg]);

    const button = React.useMemo(() => {
        let top = props.addAnotherPosition === "both" || props.addAnotherPosition === "top";
        let bottom = props.addAnotherPosition === "both" || props.addAnotherPosition === "bottom";
        return { top, bottom, content: addButton };
    }, [props.addAnotherPosition, addButton]);
    //#endregion

    return <ComponentWrapper {...props}>
        <Table responsive bordered>
            <thead>
                <tr>
                    {vComponents.map((c, i) => <th key={c.key || i}>{lg.getStaticElem(c.label)}</th>)}
                    <td>{button.top && button.content}</td>
                </tr>
            </thead>
            <tbody>
                {vValue.map((v, i) => <tr key={i}>
                    {vComponents.map((c, iComponent) => <td key={c.key || iComponent}>
                        <Input
                            {...c}
                            type={c.type || "textfield"}
                            contextKey={props.contextKey}
                            submissionId={props.submissionId}
                            prop={`${props.prop}.${i}.${c.key}`}
                            set_translation={props.set_translation}
                            onAddCertification={props.onAddCertification}
                            onChange={(v, p) => onChange?.(v, `${props.prop}.${i}.${p || c.key}`)}
                        />
                    </td>)}
                    <td>
                        <Flex justifyContent="center">
                            <Button variant="danger" onClick={() => removeEntry(i)}>
                                <i className="fa fa-times"></i>
                            </Button>
                        </Flex>
                    </td>
                </tr>)}
            </tbody>

            {button.bottom && <tfoot>
                <tr>
                    <td colSpan={vComponents.length + 1}>
                        <Flex alignItems="center" justifyContent="end">
                            {button.content}
                        </Flex>
                    </td>
                </tr>
            </tfoot>}
        </Table>
    </ComponentWrapper>
}

export default DataGrid;