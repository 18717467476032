import React from 'react';
import { Dropdown } from 'react-bootstrap';
import * as H from "../../../hooks";
import { setLanguage } from '../../../reducers';
import { TC, T, LANG } from "../../../Constants";
import { useSelector, useDispatch } from "react-redux";
import { getFlagResources } from "../../../services/user.service";

type Resource = {
    forms: T.FormType[];
    languages: T.LanguageType[];
    loadingState: "done" | "error" | "load";
}

const LanguageDropdown: React.FC = () => {
    const lg = H.useLanguage();
    const dispatch = useDispatch();
    const language = useSelector((redux: T.ReduxSelector) => redux.language);
    const [{ languages, loadingState }, setLanguages] = React.useState<Resource>({ languages: [], forms: [], loadingState: "load" });

    React.useEffect(() => {
        let isSubscribed = true;
        getFlagResources().then(({ data }) => {
            if (isSubscribed) {
                if (data?.hasFailed) setLanguages(p => ({ ...p, loadingState: "error" }));
                else setLanguages({ languages: data?.flags, forms: data?.forms, loadingState: "done" });
            }
        });

        return () => { isSubscribed = false };
    }, []);

    const completedLanguages = React.useMemo(() => LANG.ALL_LANGUAGES.map(({ code, lg, index }) => {
        let icon = languages.filter(l => l.data.code === code)[0]?.data?.icon?.[0]?.url;
        return { icon, code, lg, index };
    }), [languages]);

    const flagsItem = React.useMemo(() => <>
        {completedLanguages.map(l => <Dropdown.Item className={l.index === language ? "active" : ""} key={l.code} onClick={() => dispatch(setLanguage(l.index))}>
            <img className='w-25 me-2' alt={l.code} title={l.lg} src={l.icon} />
            <span className='text-wrap'>{l.lg}</span>
        </Dropdown.Item>)}
    </>, [completedLanguages, dispatch, language]);

    const currentLanguage = React.useMemo(() => completedLanguages.find(l => l.index === language), [completedLanguages, language]);
    const loadItem = React.useMemo(() => <Dropdown.Item className="text-center" children={<i className="fa fa-spinner fa-spin" />} />, []);
    const errorItem = React.useMemo(() => <div className="alert alert-danger m-1" children={lg.getStaticElem(TC.GLOBAL_FAILED_LOAD)} />, [lg]);

    return <Dropdown navbar as="div">
        <Dropdown.Toggle name="lang_dropdown" bsPrefix="toggle" as="div" className="pe-0 ps-2 nav-link pointer" >
            <div className="p-1 border rounded bg-white">
                <img style={{ width: "30px" }} src={currentLanguage.icon} title={currentLanguage.lg} alt={currentLanguage.code} />
                <i className='fa fa-chevron-down ms-2'></i>
            </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
            <div className="bg-white rounded-2 py-2 dark__bg-1000">
                {loadingState === "done" && flagsItem}
                {loadingState === "load" && loadItem}
                {loadingState === "error" && errorItem}
            </div>
        </Dropdown.Menu>
    </Dropdown>
};

export default LanguageDropdown;