import axios from "axios";
import { T, APIS } from "../../Constants";

const { GRAPH_API } = APIS;

//#region Types
type GetEquipKPI = (context: T.ContextParams) => T.AxiosPromise<[]>;
type GetTicketGraphs = (params: T.API.Graph.TicketGraphParams) => T.AxiosPromise<T.API.Graph.TicketGraphResults>;
//#endregion

export const getEquipKPI: GetEquipKPI = context => axios.post(GRAPH_API + "equipment/kpi", context);
export const getTicketsGraph: GetTicketGraphs = params => axios.post(GRAPH_API + "tickets", params);