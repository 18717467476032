import React from "react";
import * as I from "../Input";
import { T, TB } from "../../../Constants";

export type ContainerProps = I.InputProps & {
    components?: T.FormComponentsMain[];
};

const Container: React.FC<ContainerProps> = ({ components, onChange, ...props }) => {

    const vComponents = React.useMemo(() => TB.getArray(components).filter(TB.validObject), [components]);
    const onChangeValue = React.useCallback((val: any, key: string) => onChange?.(val, props.prop + "." + key), [onChange, props.prop]);

    return <I.ComponentWrapper {...props}>
        {vComponents.map((c, i, a) => <I.Input
            {...c}
            key={c.key}
            formId={props.formId}
            prop={props.prop + "." + c.key}
            contextKey={props.contextKey}
            submissionId={props.submissionId}
            noBottomMargin={i === a.length - 1}
            set_translation={props.set_translation}
            onAddCertification={props.onAddCertification}
            onChange={(v, p) => onChangeValue(v, p || c.key)}
        />)}
    </I.ComponentWrapper>
}

export default Container;