import _ from "lodash";
import React from "react";
import useAuth from "./useAuth";
import { Flex } from "../Common";
import useLanguage from "./useLanguage";
import { useBoolean } from "./useHooks";
import { TC, T, TB } from "../Constants";
import { reloadUser } from "../reducers";
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { updateFromFilter } from "../services/user.service";
import { askConfirm, askPrompt, renderAlert } from "../Components/Modal";

//#region Types
type useFavoriteParams<A extends object = object> = {
    /** The name of the favorite */
    origin: string;
    /** A default state */
    initialState?: A;
    /** Variant of the favorite dropdown */
    variant?: T.ColorTypes,
    /** A function that is used to apply a favorite */
    applyFav?: (favorite: A) => void;
    /** Do not apply the default state */
    no_default?: boolean;
    /** The size of te dropdown button */
    size?: "sm" | "lg";
};

type DataFavorite<A extends object = object> = {
    /** The default value */
    defaultFav?: A;
    /** A dropdown */
    dropdown: React.ReactNode;
    /** The current favorite selected */
    currentFilters: A | null;
    /** The last updated filter */
    latestFilters: A;
    /** Was the default favorite applied ? */
    applied_default: boolean;
    favorites: T.BaseFavorite<A>[];
};

type ActionFavorite<A extends object = object> = {
    add: (filters: A) => void;
    remove: (name: string) => void;
    setFilters: (filters: A) => void;
    updateFilters: (filter: (prevState: A) => A) => void;
    updateInitial: (state?: A) => void;
    changeDefault: (name: string, isDefault: boolean) => void;
};
//#endregion

//#region Constants
const TEXT_CODES = [TC.ANNOT_NAME_USED, TC.SM_ADD_FAV, TC.FAV_ERROR_SET_DEFAULT, TC.GLOBAL_RESET];
//#endregion

const useFavorite = <A extends object = object>(params: useFavoriteParams<A>): [DataFavorite<A>, ActionFavorite<A>] => {
    const dispatch = useDispatch();
    const [{ user, preferences }] = useAuth();
    const lastDefaultFavRef = React.useRef<A | null>(null);
    const applied_default = useBoolean(false);
    const { getStaticText, getStaticElem } = useLanguage(TEXT_CODES);
    const [initialUpdate, setInitialUpdate] = React.useState<A | null>(null);
    const [latestFilters, setLatestFilters] = React.useState<A | null>(params.initialState);
    const [selectedFav, setSelectedFav] = React.useState<string | undefined | null | object>(null);

    //#region Props
    const { origin = "", initialState, variant = "light", applyFav } = React.useMemo(() => TB.getObject(params), [params]);
    const favorites = React.useMemo<T.BaseFavorite<A>[]>(() => TB.getArray(preferences?.[origin]).filter(f => TB.isFavorite<A>(f)), [preferences, origin]);
    //#endregion

    //#region Initial State
    const updateInitialState = React.useCallback((state?: A) => {
        if (TB.validObject(state) && TB.validObject(initialState)) setInitialUpdate(state);
    }, [initialState]);

    const updatedInitialState = React.useMemo(() => !TB.validObject(initialState) ? null : { ...initialState, ...TB.getObject(initialUpdate) }, [initialState, initialUpdate]);
    //#endregion

    //#region Actions
    const getFilters = React.useCallback((selected = selectedFav): A => {
        if (selected === null) return _.find(favorites, f => f.isDefault)?.filters || null;
        else if (TB.validString(selected)) return _.find(favorites, f => f.name === selected)?.filters || null;
        else if (TB.validObject(selected)) return selected as A;
        return null;
    }, [favorites, selectedFav]);

    const activeFilters = React.useMemo(getFilters, [getFilters]);

    const promptChecker = React.useCallback((str: string) => {
        if (favorites.some(f => f.name === str)) return { isValid: false, message: getStaticText(TC.ANNOT_NAME_USED) };
        return true;
    }, [favorites, getStaticText]);

    const updatePreferences = React.useCallback(async (newFilter: T.BaseFavorite | null, newFilterArray?: T.BaseFavorite[]) => {
        if (!TB.isUser(user)) return null;
        const newFavorites = TB.getArray(newFilterArray, favorites).concat(newFilter).filter(TB.isFavorite);

        // Update in database
        const update = {};
        update[`data.preferences.${origin}`] = newFavorites;
        const reply = await updateFromFilter({ _id: user._id }, update);
        if (!TB.mongoIdValidator(reply.data._id)) return null;
        else {
            // Update localStorage
            const newUser = _.cloneDeep(user);
            if (typeof newUser.data.preferences !== "object") newUser.data.preferences = {};
            newUser.data.preferences[origin] = newFavorites;
            localStorage.setItem('formioUser', JSON.stringify(newUser));

            // Update the promise from selector
            dispatch(reloadUser());
        }
    }, [user, favorites, dispatch, origin]);

    const removeFavorite = React.useCallback((favName: string) => {
        askConfirm().then(confirmed => {
            if (confirmed) {
                const newFilterArray = favorites.filter(({ name }) => name !== favName);
                updatePreferences(null, newFilterArray).then(result => {
                    if (result === null) renderAlert({ type: "error", message: TC.FAV_ERROR_DELETE });
                    else setSelectedFav(undefined);
                });
            }
        });
    }, [updatePreferences, favorites]);

    const addFavorite = React.useCallback((filters: A) => {
        askPrompt({ isRequired: true, valChecker: promptChecker, title: TC.SM_ASK_FAV_NAME }).then(name => {
            if (TB.validString(name)) {
                if (!TB.validObject(filters)) filters = {} as A;
                const newFilter = { name, filters, isDefault: false };
                updatePreferences(newFilter).then(result => {
                    if (result === null) renderAlert({ type: "error", message: TC.FAV_ERROR_CREATE });
                    else setSelectedFav(name);
                });
            }
        });
    }, [promptChecker, updatePreferences]);

    const changeDefault = React.useCallback((name: string, isDefault: boolean) => {
        const newArray = favorites.map(f => {
            if (f.isDefault) return isDefault && f.name === name ? f : { ...f, isDefault: false };
            if (f.name === name) return { ...f, isDefault };
            return f;
        });

        updatePreferences(null, newArray).then(results => {
            if (results === null) renderAlert({ type: "error", message: { ref: TC.FAV_ERROR_SET_DEFAULT, template: name } });
            else setSelectedFav(isDefault ? name : undefined);
        });
    }, [updatePreferences, favorites]);

    const applyFavorite = React.useCallback((name: string) => {
        const active = getFilters(name);
        applyFav?.(active);
        setSelectedFav(name);
        setLatestFilters(active);
    }, [applyFav, getFilters]);

    const setFilters = React.useCallback<ActionFavorite<A>["setFilters"]>(filters => {
        setLatestFilters(p => !TB.validObject(filters) || _.isEqual(filters, p) ? p : filters);
    }, []);

    const updateFilters = React.useCallback<ActionFavorite<A>["updateFilters"]>(setter => {
        setLatestFilters(p => {
            const newVal = setter(p);
            return !TB.validObject(newVal) || _.isEqual(newVal, p) ? p : newVal
        });
    }, []);
    //#endregion

    //#region Defaults
    const defaultFav = React.useMemo(() => {
        if (params?.no_default) return null;
        const fav = favorites.filter(f => f.isDefault)[0]?.filters;
        if (!_.isEqual(fav, lastDefaultFavRef.current)) lastDefaultFavRef.current = fav;
        return lastDefaultFavRef.current;
    }, [favorites, params?.no_default]);

    React.useEffect(() => {
        if (defaultFav && !applied_default.value) {
            applyFav?.(defaultFav)
            applied_default.setTrue();
        }
    }, [applyFav, defaultFav, applied_default]);
    //#endregion

    //#region DropDown && Selection
    React.useEffect(() => {
        if (TB.validObject(selectedFav) && TB.validObject(updatedInitialState) && !_.isEqual(latestFilters, updatedInitialState)) setSelectedFav(undefined);
    }, [updatedInitialState, latestFilters, selectedFav]);

    const { hasFavorites, hasFilters, canReset } = React.useMemo(() => {
        const hasFavorites = favorites.length > 0;
        const hasFilters = latestFilters !== null;
        const canReset = TB.validObject(updatedInitialState) && !TB.validObject(selectedFav) && !_.isEqual(latestFilters, updatedInitialState);
        return { hasFavorites, hasFilters, canReset };
    }, [favorites, latestFilters, updatedInitialState, selectedFav]);

    const favDropDrown = React.useMemo(() => (hasFavorites || canReset || hasFilters) && <Dropdown /* as="li" */>
        <Dropdown.Toggle size={params.size} variant={variant}>
            <i className="fa fa-star"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
            <div className="bg-white rounded-2 py-2 dark__bg-1000">
                {favorites.map(({ name, isDefault }) => <Dropdown.Item key={name}>
                    <Flex className="w-100">
                        <span onClick={() => applyFavorite(name)} className='flex-grow-1'>{name}</span>
                        {!params.no_default && <span onClick={() => changeDefault(name, !isDefault)} className="mx-2"><i className={`${isDefault ? "fa" : "far"} fa-bookmark`} /></span>}
                        <span onClick={() => removeFavorite(name)} className="mx-2"><i className="fa fa-times text-danger"></i></span>
                    </Flex>
                </Dropdown.Item>)}
                {hasFavorites && (latestFilters !== null || canReset) && <Dropdown.Divider />}
                {latestFilters !== null && <Dropdown.Item onClick={() => addFavorite(latestFilters)}>
                    <i className="fa fa-plus me-2"></i>
                    {getStaticElem(TC.SM_ADD_FAV)}
                </Dropdown.Item>}
                {canReset && <Dropdown.Item onClick={() => setSelectedFav(updatedInitialState)}>
                    <i className="fa fa-sync-alt me-2"></i>
                    {getStaticElem(TC.GLOBAL_RESET)}
                </Dropdown.Item>}
            </div>
        </Dropdown.Menu>
    </Dropdown>, [getStaticElem, addFavorite, removeFavorite, applyFavorite, changeDefault, favorites, variant, updatedInitialState, canReset, hasFavorites, latestFilters, hasFilters, params.no_default, params.size]);
    //#endregion

    return [
        { favorites, defaultFav, latestFilters, currentFilters: activeFilters, dropdown: favDropDrown, applied_default: applied_default.value },
        { add: addFavorite, remove: removeFavorite, updateInitial: updateInitialState, setFilters, changeDefault, updateFilters }
    ];
}

export default useFavorite;