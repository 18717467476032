import _ from "lodash";
import * as XLSX from "xlsx";
import React from "react";
import * as M from "../../Modal";
import { WorkSheet } from "xlsx";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import { REGEX, T, TB } from "../../../Constants";

//#region Types
type Format = Record<string, any>;
type FormatKey<A extends Format> = Exclude<keyof A, number | symbol>;

export type ExcelMapRef<A extends Format> = {
    /** Get the current state of the format */
    columns: ExcelMapProps<A>["format"];
}

export type ExcelMapProps<A extends Format> = {
    /** The Excel / CSV sheet to map */
    sheet: WorkSheet;
    /** Callback for confirmation of the mapping */
    validate: (rows: A[]) => void;
    /** Allow the user to swap the rows and the columns */
    swap?: boolean;
    /** Allow the user to ignore rows */
    ignore?: boolean;
    /** The user can apply a factor to the values found in the worksheet */
    factor?: boolean;
    /** Display the content in a modal ? */
    popup?: boolean;
    /** Extra parameters for the style of the modal */
    modal?: M.StyleModalProps;
    /** The list of properties to map */
    format: T.ImportFormat<FormatKey<A>>;
    /** The key to save the mapping under in the local storage */
    save?: string;
    /** Invert the table, and the mapping */
    inverted?: boolean;
}
//#endregion

const EXAMPLES = [...new Array(5)];
const FACTORS: T.Option[] = ["/10", "/100", "/1000", "x10", 'x100', "x1000"].map(label => ({ label, value: label })).concat({ label: "", value: "none" });

const RenderExcelMap = <A extends Format>({ validate, ...props }: ExcelMapProps<A>, ref: React.ForwardedRef<ExcelMapRef<A>>) => {
    const lg = H.useLanguage();
    const is_swapped = H.useBoolean(false);
    const [offset, set_offset] = React.useState(1);
    const [first_line, set_first_line] = React.useState(2);
    const [ignore_rows, set_ignore_rows] = React.useState("");
    const [columns, set_columns] = React.useState<ExcelMapProps<A>["format"]>(props.format || {} as any);

    //#region Ref
    React.useImperativeHandle(ref, () => ({
        columns,
    }), [columns]);
    //#endregion

    //#region Saved Mapping
    React.useEffect(() => {
        // Restore the previous mapping stored in local storage
        if (props.save) {
            let keys_str = localStorage.getItem(props.save);
            if (keys_str) {
                let keys = JSON.parse(keys_str) as Record<keyof A, string>;
                // Update the columns
                set_columns(p => {
                    let new_col = { ...p };
                    for (let prop of Object.keys(p)) {
                        let key = keys[prop];
                        if (key) new_col[prop].key = key;
                    }
                    return new_col;
                });
            }
        }
    }, [props.save]);
    //#endregion

    //#region Excel Data
    const sheet_data = React.useMemo(() => {
        // Return the values as they were read
        if (!is_swapped.value) return props.sheet;
        // Invert the values
        let aoa = XLSX.utils.sheet_to_json(props.sheet, { header: 1 });
        let nb_cols = aoa.length, nb_rows = (aoa[0] as any[]).length, new_array = [];
        for (let col = 0; col < nb_rows; col++) {
            new_array[col] = [];
            for (let row = 0; row < nb_cols; row++) {
                new_array[col][row] = aoa[row][col];
            }
        }
        return XLSX.utils.aoa_to_sheet(new_array, { cellDates: true });
    }, [props.sheet, is_swapped.value]);

    const excel = React.useMemo(() => {
        // List the different existing columns
        let col_keys = Object.keys(sheet_data)
            // Transform the key into a cell and remove those that do not match an excel column ('A', 'AC', ...)
            .map(cell => cell.match(REGEX.EXCEL_COL_REGEX_LOOSE)?.[0] || null)
            .filter(col => col !== null);
        // Only keep uniq columns, and sort them like they are in an excel
        let uniq_col_keys = _.uniq(col_keys).sort((col_a, col_b) => {
            let length_diff = col_a.length - col_b.length;
            if (length_diff !== 0) return length_diff;
            else if (col_a === col_b) return 0;
            return col_a > col_b ? 1 : -1;
        });
        // List the different existing rows
        let row_keys = Object.keys(sheet_data)
            // Transform the key into a row and remove those that do not match an excel row ('1', '25', ...)
            .map(cell => cell.match(REGEX.EXCEL_ROW_REGEX_LOOSE)?.[0] || null)
            .filter(row => row !== null);
        // Only keep uniq rows, and sort them in numerical order
        let uniq_row_keys = _.uniq(row_keys).sort((row_a, row_b) => parseInt(row_a) - parseInt(row_b));
        return { columns: uniq_col_keys, rows: uniq_row_keys };
    }, [sheet_data]);
    //#endregion

    //#region Property Options
    const affectation = React.useMemo(() => {
        let keys = {} as Record<string, typeof columns[any] & Record<"prop", FormatKey<A>>>;
        for (let [prop, params] of Object.entries(columns)) {
            if (params.key) keys[params.key] = { ...params, prop: prop as FormatKey<A> };
        }
        return keys;
    }, [columns]);

    const params_options = React.useMemo(() => {
        let options = [] as T.Option<Record<"required", boolean> & Record<"key", string>>[];
        for (let [key, params] of Object.entries(columns)) {
            let label = "";
            if (params.form_id) label = lg.getTextObj(params.form_id, key, params.label);
            else label = lg.getStaticText(params.label);
            if (params.required) label += " *";
            options.push({ value: key, label, required: params.required || false, key: params.key });
        }
        let sorted_options = _.sortBy(options, "label");
        return sorted_options;
    }, [columns, lg]);

    const get_options = React.useCallback((col: string) => {
        // Remove the already used options
        return params_options.filter(o => !o.key || o.key === col);
    }, [params_options]);
    //#endregion

    //#region Render
    const ignored_rows = React.useMemo(() => {
        let ignored_start = TB.getNumberBetween(0, first_line);
        let ignored = ignore_rows.split(",").map(num => parseInt(num)).filter(x => !isNaN(x));
        return ignored_start.concat(ignored);
    }, [first_line, ignore_rows]);

    const render_example = React.useCallback((index: number, col: string) => {
        let full_index = offset + index;
        let full_key = col + full_index;
        let value = props.sheet?.[full_key]?.v;
        let is_ignored = ignored_rows.includes(full_index);
        return <td key={index} style={{ backgroundColor: is_ignored ? "#AAAAAA22" : undefined }}>{value || ""}</td>;
    }, [props.sheet, offset, ignored_rows]);
    //#endregion

    const cb = React.useMemo(() => ({
        validate: () => {
            /* TODO if props.save save the key mapping to local storage */
        },
        set_factor: (prop: FormatKey<A>, factor?: string) => set_columns(p => ({ ...p, [prop]: { ...p[prop], factor } })),
        set_key: (key: string, old_prop: FormatKey<A>, new_prop?: FormatKey<A>) => set_columns(p => {
            let new_format = { ...p };
            if (new_prop) new_format[new_prop] = { ...new_format[new_prop], key, factor: undefined };
            if (old_prop) new_format[old_prop] = { ...new_format[old_prop], key: undefined, factor: undefined };
            return new_format;
        }),
        set_first_line: (first_line: number) => {
            let index = first_line || 0;
            if (index < 0) index = 0;
            set_first_line(index);
            set_offset(p => index > p ? index : p);
        }
    }), []);

    const tables = React.useMemo(() => {
        if (props.inverted) return <>
            <thead>

            </thead>
            <tbody>

            </tbody>
        </>;
        else return <>
            <thead>
                <tr>
                    <th>todo colonne</th>
                    {EXAMPLES.map((x, i) => <th key={i} style={{ backgroundColor: ignored_rows.includes(offset + i) ? "#AAAAAA22" : undefined }}>
                        todo line {offset + i}
                    </th>)}
                    <th>todo attribution</th>
                    {props.factor && <th>todo factor</th>}
                </tr>
            </thead>
            <tbody>
                {excel.columns.map(col => <tr key={col}>
                    <td style={{ width: "5%" }} className="fw-bold align-middle">
                        {col}
                    </td>
                    {EXAMPLES.map((x, i) => render_example(i, col))}
                    <td style={{ width: props.factor ? "20%" : "25%" }}>
                        <C.Form.Select
                            noBottomMargin
                            customClass="w-100"
                            value={affectation[col]?.prop}
                            options={get_options(col)}
                            onChange={prop => cb.set_key(col, affectation[col]?.prop, prop)}
                        />
                    </td>
                    {props.factor && <td style={{ width: "15%" }}>
                        <C.Form.Select
                            noBottomMargin
                            options={FACTORS}
                            customClass="w-100"
                            value={affectation[col]?.factor}
                            onChange={factor => cb.set_factor(affectation[col]?.prop, factor)}
                            disabled={!affectation[col]?.prop || affectation[col]?.type !== "number"}
                        />
                    </td>}
                </tr>)}
            </tbody>
        </>;
    }, [props.inverted, props.factor, excel.columns, offset, cb, affectation, ignored_rows, render_example, get_options]);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup
            ? {
                ...props.modal,
                footer: <>todo</>,
                disableEscapeKeyDown: true,
                onQuit: () => validate?.(null),
                title: props.modal?.title || "todo mapping",
                isFullScreen: typeof props.modal?.isFullScreen === "boolean" ? props.modal.isFullScreen : true,
            } as M.BlankModalProps
            : undefined,
        <div>
            <BS.Row className="mb-3">
                <BS.Col md={3}>
                    <C.Form.NumField
                        noBottomMargin
                        decimalLimit={0}
                        value={first_line}
                        label="todo 1st line"
                        onChange={cb.set_first_line}
                    />
                </BS.Col>
                <BS.Col md={3}>
                    {props.ignore && <C.Form.TextField
                        noBottomMargin
                        value={ignore_rows}
                        onChange={set_ignore_rows}
                        label="todo lines to ignore"
                    />}
                </BS.Col>
                <BS.Col md={1}>
                    {props.swap && <C.Button
                        icon="random"
                        text="todo swap"
                        variant="secondary"
                        onClick={is_swapped.toggle}
                    />}
                </BS.Col>
            </BS.Row>

            <BS.Table size="sm" hover bordered align="center" className="text-center" children={tables} />
        </div>
    );
}

const ExcelMap = React.forwardRef(RenderExcelMap) as <A>(props: ExcelMapProps<A> & { ref?: React.ForwardedRef<ExcelMapRef<A>> }) => React.ReactElement;
export default ExcelMap;

/* TODO */