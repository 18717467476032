import _ from "lodash";
import * as React from "react";
import * as M from "../../Modal";
import DOM from "react-dom/client";
import * as H from "../../../hooks";
import { useNavigate } from 'react-router-dom';
import { ReduxWrapper } from "../../../Common";
import * as US from "../../../services/user.service";
import Annotations from "../../Annotation/Annotations";
import TreeSelect from "../../popup/Accessibles/treeSelect";
import { GAMMES, RIGHTS, TB, EC, LT, FP, TC, T } from "../../../Constants";
import AttachMultipleObjParents from "../../../helpers/AttachMultipleObjParents";

const SitePanel = ({ site, children, childrenLinks, coproList, addEntity, syndicList, addNote, linkTypes, onChangeParcelLink, onUpdateDescendants, onUpdateLinks, onUpdateTree, equipGammes, ...props }) => {
    const lg = H.useLanguage();
    const rights = H.useRights();
    const forms = H.useFormIds();
    const navigate = useNavigate();
    const [{ user }] = H.useAuth();
    const modalRef = React.useRef(null);
    const secondModalRef = React.useRef(null);
    const [plans, setPlans] = React.useState<any[]>();
    const [ownership, setOwnerShip] = React.useState([]);
    const [activePanel, setActivePanel] = React.useState(0);

    //#region User
    const canCreate = React.useMemo(() => rights.isRightAllowed(RIGHTS.TECH.WRITE_OTHERS), [rights]);
    const showErrorModal = React.useCallback(errorCode => M.renderErrorModal({ errorCode }), []);
    //#endregion

    //#region Plans
    const siteId = React.useMemo(() => site?._id, [site]);
    const childrenIdString = React.useMemo(() => children?.map?.(({ _id }) => _id)?.join?.() ?? "", [children]);

    React.useEffect(() => {
        let isSubscribed = true;
        const getPlans = async () => {
            let ids = [siteId].concat(childrenIdString.split(",")).filter(TB.mongoIdValidator);
            let reply = await US.getPlansFromFilter({ location: ids });
            return Array.isArray(reply.data) ? reply.data : null;
        }
        // ChildrenIds went into a string to avoid to fetch when the array reference only has changed
        if (TB.mongoIdValidator(siteId)) getPlans().then(x => isSubscribed ? setPlans(x) : undefined);
        return () => {
            isSubscribed = false;
        }
    }, [siteId, childrenIdString]);
    //#endregion

    //#region Forms
    const clientFormId = React.useMemo(() => forms[FP.CLIENT_FORM], [forms]);
    const parcelsFormId = React.useMemo(() => forms[FP.PARCEL_FORM], [forms]);
    const buildFormId = React.useMemo(() => forms[FP.BUILDING_FORM], [forms]);
    const equipFormId = React.useMemo(() => forms[FP.EQUIPEMENT_FORM], [forms]);
    const ownerShipFormId = React.useMemo(() => forms[FP.OWNERSHIP_FORM], [forms]);
    const emplacementFormId = React.useMemo(() => forms[FP.EMPLACEMENT_FORM], [forms]);

    const locationForms = React.useMemo(() => [parcelsFormId, buildFormId, emplacementFormId], [parcelsFormId, buildFormId, emplacementFormId]);
    //#endregion

    //#region Gamme Equip
    const irveId = React.useMemo(() => equipGammes?.filter?.(({ data }) => data.omniclass === GAMMES.IRVE)?.[0]?._id, [equipGammes]);
    const parkingLightsId = React.useMemo(() => equipGammes?.filter?.(({ data }) => data.omniclass === GAMMES.PARKING_LIGHTS)?.[0]?._id, [equipGammes]);
    //#endregion

    //#region LinkTypes
    const ownLinkType = React.useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_OWN)?.[0]?._id, [linkTypes]);
    const coOwnLinkType = React.useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_CO_OWN)?.[0]?._id, [linkTypes]);
    const gestionLinkType = React.useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_GESTION)?.[0]?._id, [linkTypes]);
    //#endregion

    //#region Links
    const descendance = React.useMemo(() => Array.isArray(children) ? children : [], [children]);
    const links = React.useMemo(() => Array.isArray(childrenLinks) ? childrenLinks : [], [childrenLinks]);
    //#endregion

    //#region Plan
    const { planImg, src, name } = React.useMemo(() => plans?.filter?.(({ location }) => location === siteId)?.[0] ?? {}, [plans, siteId]);
    const planLocations = React.useMemo(() => children?.filter?.(({ form }) => locationForms.includes(form)) ?? [], [locationForms, children]);

    const showPlanEditor = React.useCallback(() => {
        let root = DOM.createRoot(modalRef.current as any);

        let planPromise = new Promise(resolve => root.render(<ReduxWrapper>
            {/* @ts-ignore */}
            <Annotations
                onSave={resolve}
                offerWiderOrigins
                defaultPlans={plans}
                close={() => resolve(null)}
                locations={[site].concat(planLocations).filter(x => TB.mongoIdValidator(x?._id))}
            />
        </ReduxWrapper>));

        planPromise.then(async toSavePlans => {
            root.unmount();
            if (Array.isArray(toSavePlans)) {
                let unmount = M.renderLoader();

                let [existingPlans, newPlans] = _.partition(toSavePlans, ({ _id }) => TB.mongoIdValidator(_id));
                let stillTherePlansIds = existingPlans.map(({ _id }) => _id);
                let [toUpdate, toDelete] = _.partition(plans, ({ _id }) => stillTherePlansIds.includes(_id));
                toUpdate = toUpdate.map(({ _id }) => toSavePlans.filter(p => p?._id === _id)?.[0]);

                let bulk = (newPlans.map(p => ({ insertOne: { document: p } })) as any[])
                    .concat(toDelete.map(({ _id }) => ({ deleteOne: { filter: { _id } } })))
                    .concat(toUpdate.map(({ _id, ...r }) => ({ updateOne: { filter: { _id }, update: { ...r }, upsert: false } })));

                let reply = await US.bulkPlans(bulk);
                let { ok, insertedIds } = reply?.data ?? {};
                if (ok === 1) {
                    if (Array.isArray(insertedIds) && insertedIds?.length > 0) {
                        let reply = await US.getPlansFromFilter({ _id: insertedIds });
                        if (Array.isArray(reply?.data)) setPlans(toSavePlans.filter(({ _id }) => !TB.mongoIdValidator(_id)).concat(reply.data));
                        else setPlans(toSavePlans.filter(({ _id }) => !TB.mongoIdValidator(_id)).concat(insertedIds.map((_id, i) => ({ ...newPlans?.[i] ?? {}, _id }))));
                    }
                    else setPlans(toSavePlans);
                    unmount();
                }
                else {
                    unmount();
                    M.Alerts.updateError();
                }
            }
        });
    }, [plans, planLocations, site]);

    const zoomPicture = React.useCallback(() => M.renderCarousel({ images: [{ src: planImg || src, title: name }] }), [name, src, planImg]);

    const planPanel = React.useMemo(() => {
        let hasImg = [planImg, src].some(TB.validString);
        let errorLoad = plans === null;
        let isLoading = plans === undefined;
        let allOk = Array.isArray(plans);

        return <div className="w-25 p-1 pt-3 d-inline-flex flex-column justify-content-center">
            <div className="bg-white border position-relative" style={{ height: "15rem" }}>
                {isLoading && <M.Loader isPopUp={false} />}
                {errorLoad && <span className="position-absolute top-50 start-50 translate-middle text-center">
                    <i className="fa fa-exclamation-triangle text-danger fa-2x"></i>
                    <p className="mt-1 text-danger">{lg.getStaticText(TC.ANNOT_ERROR_LOAD_PLANS)}</p>
                </span>}
                {allOk && hasImg && <img onClick={zoomPicture} className="w-100 h-100 pointer-zoom" alt="" src={planImg ?? src} onError={e => console.log(e)} />}
                {allOk && !hasImg && <span className="text-dark position-absolute top-50 start-50 translate-middle fs-5">{lg.getStaticText(TC.ANNOT_NO_PLAN)}</span>}
            </div>
            <div className="w-100 p-1">
                <button disabled={!allOk} onClick={showPlanEditor} className="btn btn-primary stop-hiding w-100">
                    <i className="fa fa-paint-brush mr-2"></i>
                    {lg.getStaticText(TC.ANNOT_DEFAULT_PLAN)}
                </button>
            </div>
        </div>
    }, [planImg, plans, lg, src, showPlanEditor, zoomPicture]);
    //#endregion

    //#region Buildings
    const buildings = React.useMemo(() => children?.filter?.(({ form }) => form === buildFormId) ?? [], [children, buildFormId]);
    const buildingsOptions = React.useMemo(() => buildings.map(({ _id, data }) => <option key={_id} value={_id}>{data?.name}</option>), [buildings]);
    //#endregion

    //#region Parcels
    const onChangeParcelBuild = React.useCallback((parcel, newBuild, oldBuild) => onChangeParcelLink?.(parcel, newBuild, oldBuild, siteId), [onChangeParcelLink, siteId]);
    const parcels = React.useMemo(() => children?.filter?.(({ form }) => form === parcelsFormId) ?? [], [children, parcelsFormId]);

    const onChangeMainParcel = React.useCallback(async (parcelId, isMain) => {
        let reply = await US.updateSubmission(parcelId, { "data.isMain": isMain });

        if (TB.mongoIdValidator(reply.data?._id)) onUpdateDescendants?.(prev => prev.map(p => {
            if (p?._id === parcelId) return { ...p, data: { ...p.data, isMain } };
            return p;
        }));
        else M.Alerts.updateError();
    }, [onUpdateDescendants]);

    const addParcel = React.useCallback(() => {
        M.renderFormModal({ readOnly: false, path: FP.PARCEL_FORM, title: TC.GLOBAL_LABEL_PARCEL }).then(async parcel => {
            if (TB.mongoIdValidator(parcel?._id)) {
                let newLink = { input: siteId, output: parcel._id, type: ownLinkType };

                let reply = await AttachMultipleObjParents([newLink], user);
                if (reply === true) onUpdateTree(({ descendance, links }) => ({
                    descendance: descendance.concat(parcel),
                    links: links.concat(newLink)
                }));
                else {
                    M.Alerts.updateError();
                    US.removeSubmission(parcel?._id);
                }
            }
        });
    }, [ownLinkType, siteId, user, onUpdateTree]);

    const editParcel = React.useCallback(id => {
        M.renderFormModal({ readOnly: false, path: FP.PARCEL_FORM, title: TC.GLOBAL_LABEL_PARCEL, submissionId: id }).then(async parcel => {
            if (TB.mongoIdValidator(parcel?._id)) onUpdateDescendants(prev => prev.map(node => node._id === parcel._id ? parcel : node));
        });
    }, [onUpdateDescendants]);

    const parcelsWithBuilds = React.useMemo(() => {
        let checkedIds = [];

        const getChildren = ids => {
            if (!Array.isArray(ids)) ids = [ids];
            let children = links.filter(({ input, output }) => ids.includes(input) && !checkedIds.includes(output)).map(({ output }) => output);
            checkedIds = checkedIds.concat(ids);
            if (children.length === 0) return [];
            return descendance.filter(({ _id }) => children.includes(_id)).concat(getChildren(children));
        }

        return parcels.map(({ _id, ...r }) => ({ _id, ...r, building: getChildren(_id).filter(({ form }) => form === buildFormId)?.[0]?._id }));
    }, [parcels, links, buildFormId, descendance]);

    const parcelTableHeader = React.useMemo(() => <thead>
        <tr>
            <th scope="col">#</th>
            <th className="text-start" scope="col">{lg.getStaticText(TC.GLOBAL_NAME)}</th>
            <th scope="col">isMain</th>
            <th scope="col">{lg.getStaticText(TC.GLOBAL_LABEL_BUILD)}</th>
            <th>Note</th>
            <th></th>
        </tr>
    </thead>, [lg]);

    const parcelPanel = React.useMemo(() => <div className="py-0 px-2 flex-grow-1 overflow-auto border-start d-flex flex-column">
        <div className="position-sticky top-0 bg-white"><h5 className="h-100 mb-0 p-2">{lg.getStaticText(TC.GLOBAL_LABEL_PARCEL)}</h5></div>
        <div className="flex-grow-1">
            <table className="table table-light text-center align-middle">
                {parcelTableHeader}
                <tbody>
                    {parcelsWithBuilds.map(({ _id, data, building }, i) => <tr key={_id}>
                        <th className="align-middle" scope="row">{i + 1}</th>
                        <td className="text-start align-middle">{data?.name}</td>
                        <td className="align-middle">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" checked={data?.isMain ?? false} onChange={e => onChangeMainParcel(_id, e.target.checked)} />
                            </div>
                        </td>
                        <td className="align-middle">
                            <select className="form-select" value={building ?? ""} onChange={e => onChangeParcelBuild(_id, e.target.value, building)}>
                                <option></option>
                                {buildingsOptions}
                            </select>
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={() => addNote(_id)}>
                                <i className="fa fa-comment-alt"></i>
                            </button>
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={() => editParcel(_id)}>
                                <i className="fa fa-pencil-alt"></i>
                            </button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
        <div className="position-sticky bottom-0 bg-white p-2">
            <button onClick={addParcel} className="btn btn-primary">
                <i className="fa fa-plus mr-2"></i> {lg.getStaticText(TC.GLOBAL_LABEL_PARCEL)}
            </button>
        </div>
    </div>, [parcelsWithBuilds, buildingsOptions, parcelTableHeader, lg, onChangeParcelBuild, onChangeMainParcel, addParcel, editParcel, addNote]);

    const parcelPlanPanel = React.useMemo(() => <div className="d-flex border-bottom h-50" style={{ maxHeight: "50vh" }}>
        {planPanel}
        {parcelPanel}
    </div>, [planPanel, parcelPanel]);
    //#endregion

    //#region Parkings
    const parkings = React.useMemo(() => children?.filter?.(c => c.form === emplacementFormId && c.data.type === "parking") ?? [], [emplacementFormId, children]);

    //#region Parking Lights
    const parkingWithLightsAndIRVE = React.useMemo(() => parkings.map(({ _id, ...r }) => {
        let children = links.filter(({ input }) => input === _id).map(({ output }) => output);
        let equipments = descendance.filter(({ _id, form }) => children.includes(_id) && form === equipFormId);

        let irves = equipments.filter(({ data }) => data.category === irveId).map(({ _id }) => _id);
        let lights = equipments.filter(({ data }) => data.category === parkingLightsId).map(({ _id }) => _id);

        return { _id, ...r, lights, irves };
    }), [parkings, descendance, links, equipFormId, parkingLightsId, irveId]);

    const parkingLightsAddEdit = React.useCallback((parkingId: string, category?: string, forceCreate = false, submissionId = undefined) => {
        let parking = parkingWithLightsAndIRVE.filter(({ _id }) => _id === parkingId)?.[0];
        let existingLights = TB.mongoIdValidator(category) ? (category === parkingLightsId ? parking?.lights : parking?.irves) : undefined;

        // Add Lights
        if (forceCreate || !Array.isArray(existingLights) || existingLights.length === 0) {
            M.renderFormModal({
                submissionId,
                path: FP.EQUIPEMENT_FORM,
                modalProps: { size: "md" },
                forcedSubmission: [{ prop: "category", value: category }],
            }).then(async equip => {
                if (TB.mongoIdValidator(equip?._id)) {
                    let unmount = M.renderLoader();
                    let newLink = { input: parkingId, output: equip._id, type: ownLinkType };
                    let reply = await AttachMultipleObjParents([newLink], user);
                    unmount();
                    if (reply === true) onUpdateTree?.(({ descendance, links }) => ({
                        descendance: descendance.concat(equip),
                        links: links.concat(newLink),
                    }));
                    else {
                        M.Alerts.updateError();
                        US.removeSubmission(equip._id);
                    }
                }
            });
        }
        // Edit lights
        else {
            let lightsObj = descendance.filter(({ _id }) => existingLights.includes(_id)).map(({ _id, data }) => ({ value: _id, label: data?.name }));

            let root = DOM.createRoot(modalRef.current as any);
            let selectionPromise = new Promise(resolve => root.render(<ReduxWrapper>
                <M.ModalSelect
                    options={lightsObj}
                    onValidate={resolve}
                    onQuit={() => resolve(null)}
                    defaultVal={existingLights?.[0]}
                    noSelectionText={TC.ADD}
                />
            </ReduxWrapper>));

            selectionPromise.then(val => {
                root.unmount();
                if (val === undefined) parkingLightsAddEdit(parkingId, category, true);
                else if (TB.mongoIdValidator(val)) parkingLightsAddEdit(parkingId, category, true, val);
            })

        }
    }, [parkingWithLightsAndIRVE, parkingLightsId, ownLinkType, descendance, user, onUpdateTree]);
    //#endregion

    const addParking = React.useCallback(() => {
        M.renderFormModal({ readOnly: false, path: FP.EMPLACEMENT_FORM, title: TC.GLOBAL_LABEL_PARKING, forcedSubmission: [{ prop: "type", value: "parking" }] }).then(async parking => {
            if (TB.mongoIdValidator(parking?._id)) {
                let newLink = { input: siteId, output: parking._id, type: ownLinkType };

                let reply = await AttachMultipleObjParents([newLink], user);
                if (reply === true) onUpdateTree(({ descendance, links }) => ({
                    descendance: descendance.concat(parking),
                    links: links.concat(newLink)
                }));
                else {
                    M.Alerts.updateError();
                    US.removeSubmission(parking?._id);
                }
            }
        });
    }, [user, siteId, ownLinkType, onUpdateTree]);

    const removeParking = React.useCallback(id => {
        M.askConfirm().then(async confirm => {
            if (confirm) {
                let unmount = M.renderLoader();
                let replyDelete = await US.deleteSubmissionsAndRelatives([id]);
                unmount();
                if (replyDelete.data?.ok === 1) onUpdateTree(({ descendance, links }) => ({
                    descendance: descendance.filter(({ _id }) => _id !== id),
                    links: links.filter(({ input, output }) => ![input, output].includes(id))
                }));
                else M.Alerts.updateError();
            }
        });
    }, [onUpdateTree]);

    const onChangeParking = React.useCallback(async (id, key, value) => {
        let update = {};
        update['data.' + key] = value;
        let replyUpdate = await US.updateSubmission(id, update);
        if (TB.mongoIdValidator(replyUpdate?.data?._id)) onUpdateDescendants?.(prev => prev.map(node => {
            if (node._id !== id) return node;
            node.data[key] = value;
            return node;
        }));
        else M.Alerts.updateError();
    }, [onUpdateDescendants]);

    const noParking = React.useMemo(() => <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column">
        <button onClick={addParking} className="btn btn-outline-secondary px-4 py-2">
            <i className="fa fa-plus"></i>
        </button>
        <span className="text-muted mt-2">{lg.getStaticText(TC.SF_NO_PARKINGS)}</span>
    </div>, [lg, addParking]);

    const parkingTableHeader = React.useMemo(() => <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">name</th>
            <th scope="col">nbPlaces</th>
            <th scope="col">Area</th>
            <th>Compteur</th>
            <th>Eclairage</th>
            <th>GAMMES.IRVE</th>
            <th>Autre</th>
            <th>Note</th>
            <th scope="col"></th>
        </tr>
    </thead>, []);

    const parkingNotesOptions = React.useMemo(() => [
        "Présence de compteur commun"
    ], []);

    const parkingPanel = React.useMemo(() => {
        if (parkingWithLightsAndIRVE.length === 0) return noParking;

        return <div className="flex-grow-1 d-flex flex-column overflow-auto">
            <table className="table table-light text-center align-middle">
                {parkingTableHeader}
                <tbody>
                    {parkingWithLightsAndIRVE.map(({ _id, data, lights, irves }, i) => <tr key={_id}>
                        <th scope="row" className="align-middle">{i + 1}</th>
                        <td className="w-50">
                            <input className="form-control" value={data?.name ?? ""} onChange={e => onChangeParking(_id, "name", e.target.value)} />
                        </td>
                        <td>
                            <input className="form-control" type="number" value={data?.capacity ?? ""} min={0} onChange={e => onChangeParking(_id, "capacity", parseInt(e.target.value) || "")} />
                        </td>
                        <td>
                            <input className="form-control" type="number" value={data?.area ?? ""} min={0} onChange={e => onChangeParking(_id, "area", parseInt(e.target.value) || "")} />
                        </td>
                        <td>
                            <button onClick={() => addEntity?.(siteId, _id)} className="btn btn-primary">
                                <i className="fa fa-plus"></i>
                            </button>
                        </td>
                        <td>
                            <button onClick={() => parkingLightsAddEdit(_id, parkingLightsId)} className="btn btn-primary">
                                <i className={"fa fa-" + (Array.isArray(lights) && lights.length > 0 ? "pencil-alt" : "plus")}></i>
                            </button>
                        </td>
                        <td>
                            <button onClick={() => parkingLightsAddEdit(_id, irveId)} className="btn btn-primary">
                                <i className={"fa fa-" + (Array.isArray(irves) && irves.length > 0 ? "pencil-alt" : "plus")}></i>
                            </button>
                        </td>
                        <td>
                            <button onClick={() => parkingLightsAddEdit(_id)} className="btn btn-primary">
                                <i className="fa fa-plus"></i>
                            </button>
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={() => addNote?.(_id, parkingNotesOptions)}><i className="fa fa-comment-alt"></i></button>
                        </td>
                        <td>
                            <button className="btn btn-danger" onClick={() => removeParking(_id)}>
                                <i className="fa fa-times"></i>
                            </button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
            <div className="position-sticky bottom-0 bg-white p-2 flex-shrink-1">
                <button className="btn btn-primary" onClick={addParking}>
                    <i className="fa fa-plus mr-2"></i> {lg.getStaticText(TC.GLOBAL_LABEL_PARKING)}
                </button>
            </div>
        </div>
    }, [lg, parkingWithLightsAndIRVE, noParking, parkingTableHeader, siteId, irveId, parkingLightsId, parkingNotesOptions, addParking, removeParking, onChangeParking, parkingLightsAddEdit, addNote, addEntity]);
    //#endregion

    //#region Copro & Syndic
    const [copro, syndic] = React.useMemo(() => [coproList, syndicList].map(a => Array.isArray(a) ? a : []), [coproList, syndicList]);
    const reverseCoproList = React.useMemo(() => _.flatten(copro.map(({ coOwned, ...coOwner }) => coOwned.map(item => ({ ...item, coOwner })))), [copro]);
    const reverseSyndicList = React.useMemo(() => _.flatten(syndic.map(({ managed, ...manager }) => managed.map(item => ({ ...item, manager })))), [syndic]);

    const linkedSyndic = React.useMemo(() => reverseSyndicList.map(({ _id, manager, ...r }) => {
        let link = links.filter(({ input, output, type }) => type === gestionLinkType && input === manager?._id && output === _id)?.[0]?._id;
        return { _id, manager, link, ...r };
    }), [gestionLinkType, links, reverseSyndicList]);

    const coproPercentage = React.useMemo(() => reverseCoproList.map(({ _id, coOwner, ...r }) => {
        let link = links.filter(({ input, output, type }) => type === coOwnLinkType && input === coOwner?._id && output === _id)?.[0]?._id;
        let ownerShipObj = ownership.filter(({ data }) => data.link === link)?.[0]
        let pct = ownerShipObj?.data?.pctOwnership;
        return { _id, coOwner, pct, ownerShipId: ownerShipObj?._id, link, ...r };
    }), [coOwnLinkType, links, ownership, reverseCoproList]);

    const linkFetched = React.useMemo(() => ownership.map(({ data }) => data?.link).filter(TB.mongoIdValidator), [ownership]);
    const linkToFetch = React.useMemo(() => reverseCoproList.map(({ _id, coOwner }) => ({ input: coOwner?._id, output: _id })).filter(({ input, output }) => TB.multiMongoIdValidator([input, output])), [reverseCoproList]);

    React.useEffect(() => {
        let isSubscribed = true;

        const getOwnerShips = async () => {
            let filter = { _id: { $nin: linkFetched }, type: coOwnLinkType, $or: linkToFetch };
            let replyLinks = await US.getLinksFromFilter(filter);
            if (!Array.isArray(replyLinks.data) || replyLinks.data.length === 0) return;
            let replyOwnership = await US.getManySubmissionsFromFilter({ form: ownerShipFormId, "data.link": replyLinks.data.map(({ _id }) => _id) });
            if (!Array.isArray(replyOwnership.data) || replyOwnership.data.length === 0) return;
            if (isSubscribed) setOwnerShip(prev => prev.concat(replyOwnership.data));
        }

        if (TB.multiMongoIdValidator([coOwnLinkType, ownerShipFormId]) && linkToFetch.length > 0) getOwnerShips();
        return () => {
            isSubscribed = false;
        }
    }, [coOwnLinkType, linkFetched, linkToFetch, ownerShipFormId]);

    const onAddRelation = React.useCallback(linkTypeId => {
        let title = linkTypeId === gestionLinkType ? "Syndicat" : "Co-propriétaire";
        let outTitle = linkTypeId === gestionLinkType ? "Gère" : "Possède";

        const getInput = () => new Promise(resolve => {
            const createNewEnterprise = () => {
                M.renderFormModal({ path: FP.CLIENT_FORM, title: TC.GLOBAL_LABEL_ENTERPRISE })
                    .then(obj => resolve({ input: obj?._id, newClient: obj }));
            }

            let root = DOM.createRoot(modalRef.current as any);

            new Promise(r => root.render(<M.BlankModal title={title} size="xl" onQuit={() => r(null)}>
                {/* @ts-ignore */}
                <TreeSelect
                    onValidate={r}
                    isPopUp={false}
                    close={() => r(null)}
                    fixedResource={FP.CLIENT_FORM}
                    createNew={canCreate ? createNewEnterprise : null}
                />
            </M.BlankModal>))
                .then(id => {
                    root.unmount();
                    if (!TB.mongoIdValidator(id)) resolve(null);
                    else resolve(id);
                })
        });

        const getOutput = () => new Promise(resolve => {
            M.renderLightTree({
                root: siteId,
                restrictOwnLinks: true,
                style: { title: outTitle },
                linkRestriction: { objForm: clientFormId, linkType: linkTypeId === gestionLinkType ? LT.LINK_TYPE_GESTION : LT.LINK_TYPE_CO_OWN },
            }).then(id => {
                if (!TB.mongoIdValidator(id)) resolve(null);
                else resolve(id);
            });
        });

        // Pick input (Tree Select only enterprises)
        getInput().then(results => {
            let { input, newClient } = (TB.validObject(results) ? results : { input: results }) as any;

            if (input === null) return;
            // Pick output (Light Tree root = site)
            else getOutput().then(async (output: string) => {
                if (output === null) return;
                else {
                    let replyData = await US.getManySubmissionsFromFilter({ _id: [input, output] });
                    if (!Array.isArray(replyData.data)) showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                    else {
                        let newNodes = [...replyData.data, newClient].filter(TB.validObject).filter(({ _id }) => TB.mongoIdValidator(_id));

                        let link: Omit<T.Link, "_id"> = { input, output, type: linkTypeId };
                        let reply = await AttachMultipleObjParents([link], user);
                        if (reply === true) onUpdateTree(({ links, descendance }) => ({
                            links: links.concat(link),
                            descendance: _.uniqBy(descendance.concat(newNodes), "_id")
                        }))
                        else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                    }
                }
            });
        });
    }, [siteId, clientFormId, gestionLinkType, canCreate, user, showErrorModal, onUpdateTree]);

    const onRemoveRelation = React.useCallback((linkId: string, objId?: string) => {
        let promises = [];
        if (TB.mongoIdValidator(linkId)) promises.push(new Promise(res => US.deleteLinks(linkId).then(({ data }) => res(data?.ok === 1))));
        if (TB.mongoIdValidator(objId)) promises.push(new Promise(res => US.removeSubmission(objId).then(({ data }) => res(TB.mongoIdValidator(data?._id)))));

        Promise.all(promises).then(async results => {
            if (!results.every(Boolean)) showErrorModal(EC.CODE_DB_DELETE_FAIL).then(() => navigate("/", { replace: true }));
            else onUpdateLinks(prev => prev.filter(({ _id }) => _id !== linkId));
        });
    }, [navigate, showErrorModal, onUpdateLinks]);

    //#region Syndic
    const syndicTableHeader = React.useMemo(() => <thead>
        <tr>
            <th>{lg.getStaticText(TC.GLOBAL_LABEL_ENTERPRISE)}</th>
            <th>Gere</th>
            <th></th>
        </tr>
    </thead>, [lg]);

    const syndicTableFooter = React.useMemo(() => <tfoot className="text-start">
        <tr>
            <td colSpan={3}>
                <button onClick={() => onAddRelation(gestionLinkType)} className="btn btn-primary">
                    <i className="fa fa-plus mr-2"></i> Syndic
                </button>
            </td>
        </tr>
    </tfoot>, [gestionLinkType, onAddRelation]);

    const syndicTable = React.useMemo(() => <table className="table table-light table-sm table-bordered">
        {syndicTableHeader}
        <tbody>
            {linkedSyndic.map(({ _id, data, manager, link }) => <tr key={_id}>
                <td>{manager?.data?.name}</td>
                <td>{data?.name}</td>
                <td>
                    <button onClick={() => onRemoveRelation(link)} className="btn btn-danger">
                        <i className="fa fa-times"></i>
                    </button>
                </td>
            </tr>)}
        </tbody>
        {syndicTableFooter}
    </table>, [linkedSyndic, syndicTableFooter, syndicTableHeader, onRemoveRelation]);
    //#endregion

    //#region Copro
    const onChangePct = React.useCallback(async (objId, linkId, value) => {
        let newValue = parseFloat(value);
        if (isNaN(newValue) || newValue > 100 || newValue < 0) return;

        if (TB.mongoIdValidator(objId)) {
            let update = { "data.pctOwnership": newValue };
            let reply = await US.updateSubmission(objId, update);
            if (TB.mongoIdValidator(reply?.data?._id)) setOwnerShip(prev => prev.map(o => {
                if (o?._id === objId) o.data.pctOwnership = newValue;
                return o;
            }));
            else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
        }
        else if (TB.mongoIdValidator(linkId)) {
            let obj = TB.blankSub(user?._id, ownerShipFormId);
            obj.data = { pctOwnership: newValue, link: linkId };
            let reply = await US.createManySubmissions([obj]);
            if (Array.isArray(reply?.data) && TB.mongoIdValidator(reply.data[0]?._id)) setOwnerShip(prev => prev.concat(reply.data));
            else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
        }
    }, [user, ownerShipFormId, showErrorModal]);

    const coproTableHeader = React.useMemo(() => <thead>
        <tr>
            <th>{lg.getStaticText(TC.GLOBAL_LABEL_ENTERPRISE)}</th>
            <th>Bien</th>
            <th>% possession</th>
            <th></th>
        </tr>
    </thead>, [lg]);

    const coproTableFooter = React.useMemo(() => <tfoot className="text-start">
        <tr>
            <td colSpan={4}>
                <button onClick={() => onAddRelation(coOwnLinkType)} className="btn btn-primary">
                    <i className="fa fa-plus mr-2"></i> Copropriété
                </button>
            </td>
        </tr>
    </tfoot>, [coOwnLinkType, onAddRelation]);

    const coproTable = React.useMemo(() => <table className="table table-light table-sm table-bordered">
        {coproTableHeader}
        <tbody>
            {coproPercentage.map(({ _id, data, coOwner, pct, ownerShipId, link }) => <tr key={_id}>
                <td>{coOwner?.data?.name}</td>
                <td>{data?.name}</td>
                <td>
                    <input type="number" value={pct ?? ""} min={0} max={100} onChange={e => onChangePct(ownerShipId, link, e.target.value)} className="form-control" />
                </td>
                <td>
                    <button onClick={() => onRemoveRelation(link, ownerShipId)} className="btn btn-danger">
                        <i className="fa fa-times"></i>
                    </button>
                </td>
            </tr>)}
        </tbody>
        {coproTableFooter}
    </table>, [coproTableFooter, coproTableHeader, coproPercentage, onChangePct, onRemoveRelation]);
    //#endregion

    const coproSyndicPanel = React.useMemo(() => <div className="row g-1 h-50 text-center">
        <div className="col-md-6 border overflow-auto p-1" style={{ maxHeight: "22.5rem" }}>
            {syndicTable}
        </div>
        <div className="col-md-6 border overflow-scroll p-1" style={{ maxHeight: "22.5rem" }}>
            {coproTable}
        </div>
    </div>, [coproTable, syndicTable]);
    //#endregion

    //#region Panels
    const panelsList = React.useMemo(() => [
        { label: "Parcel & Plan", content: parcelPlanPanel },
        { label: "Syndic & Copro", content: coproSyndicPanel },
    ], [parcelPlanPanel, coproSyndicPanel]);

    const panelNav = React.useMemo(() => <div className="w-100 px-2">
        <ul className="nav nav-pills nav-fill">
            {panelsList.map(({ label }, i) => <li key={i} className="nav-item">
                <button onClick={() => setActivePanel(i)} className={`nav-link ${i === activePanel ? "active" : ""}`}>{label}</button>
            </li>)}
        </ul>
    </div>, [panelsList, activePanel])

    const currentContent = React.useMemo(() => panelsList[activePanel].content, [panelsList, activePanel]);
    //#endregion

    return <div className="h-100">
        <div style={{ height: "3rem" }}>
            {panelNav}
        </div>
        <div className="h-100">
            <div className="p-2 h-100">
                {currentContent}

                <div className="h-50 w-100 p-2 d-flex flex-column shadow-sm position-relative" style={{ maxHeight: "40vh" }}>
                    <div className="flex-shrink-1"><h5>{lg.getStaticText(TC.GLOBAL_LABEL_PARKING)}</h5></div>
                    {parkingPanel}
                </div>
                <div ref={modalRef as any}></div>
                <div ref={secondModalRef as any}></div>
            </div>
        </div>
    </div>
}

export default SitePanel;