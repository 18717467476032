import React from "react";
import * as H from '../../../hooks';
import { Flex } from "../../Layout";
import * as S from "../../../services";
import * as M from '../../../Components/Modal';
import 'react-dropzone-uploader/dist/styles.css';
import { ComponentWrapper, InputProps } from "../Input";
import { DropzonePreview, GetPreviewUrl } from "../Misc";
import { Button, Card, Col, Row } from 'react-bootstrap';
import { APIS, RESOURCE as R, T, TB, TC, URL } from '../../../Constants';
import Dropzone, { IFileWithMeta, IMeta, StatusValue } from 'react-dropzone-uploader';

//#region Types
export type FileUploaderProps = InputProps & {
    /** Should this input only accept images */
    image?: boolean;
    /** The max size of the file, ex: 15MB. No file above 500MB will be accepted by NGinx though */
    fileMaxSize?: string;
    /** The min size of the file, ex: 1MB */
    fileMinSize?: string;
    /** The expected pattern for the file type */
    filePattern?: any;
    /** Can upload multiple file ? */
    multiple?: boolean;
    /** Ask for a confirmation modal when removing an existing file */
    confirm_remove?: boolean;
    /** The direction of the flex container */
    direction?: "row" | "column";
    /** Should the names and sizes of the files be shown ? */
    hide_info?: boolean;
    /** Provide a custom class for the columns containing the file examples */
    example_class?: string;
}

type OnStatusChange = (file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) => void;
//#endregion

const TEXT_CODES = [TC.GLOBAL_FILE_UPLOAD, TC.GLOBAL_NEW];

const FileUploader: React.FC<FileUploaderProps> = ({ onChange, lock_form, unlock_form, fileMaxSize = "500MB", ...props }) => {
    const lg = H.useLanguage(TEXT_CODES);
    const cancelled = React.useRef<string[]>([]);
    const dropzone_ref = React.useRef<Dropzone>(null);
    const api_uploaded_ref = React.useRef<string[]>([]);
    const [lock_status, set_lock_status] = React.useState<Record<string, boolean>>({});
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    //#region Load the files already submitted
    const vFiles = React.useMemo(() => TB.getArray(props.value).filter(TB.isFile), [props.value]);
    const dbFiles = React.useMemo(() => vFiles.filter(f => f.storage !== "temp"), [vFiles]);
    //#endregion

    //#region New Files Comeback
    const getIdName = React.useCallback((id: string, name: string) => {
        let splitted = name.split(".");
        let extension = splitted[splitted.length - 1];
        if (extension) extension = "." + extension;
        else extension = "";
        return id + extension;
    }, []);

    const getFileFormatted = React.useCallback((meta: IMeta, file: File): T.File => ({
        type: meta.type,
        size: file.size,
        storage: "temp",
        originalName: meta.name,
        name: getIdName(meta.id, meta.name),
        url: URL.APP_DOMAIN + APIS.FILE_API + "temp/" + meta.id,
    }), [getIdName]);

    React.useEffect(() => {
        if (Object.values(lock_status).every(v => v === false)) unlock_form?.();
    }, [lock_status, unlock_form]);

    React.useEffect(() => {
        // Find the files that were uploaded not through the component, but by an API or a value update
        let uploaded_files_through_api = vFiles.filter(f => f.storage === "fake_upload" && !api_uploaded_ref.current.includes(f.name));
        // Reduce the amount of files to one if the component is not multiple
        if (!props.multiple) uploaded_files_through_api = uploaded_files_through_api.slice(0, 1);
        // If there are files to upload, upload them
        if (uploaded_files_through_api.length > 0) {
            // Generate files for those uploaded files
            let file_promises = uploaded_files_through_api.map(file => new Promise<Record<"file", File> & Record<"info_file", T.File>>((resolve, reject) => {
                api_uploaded_ref.current.push(file.name);
                fetch(file.url)
                    .then(res => res.blob())
                    .then(blob => {
                        let js_file = new File([blob], file.originalName, { type: blob.type, lastModified: Date.now() });
                        resolve({ file: js_file, info_file: file });
                    })
                    .catch(reject);
            }));

            Promise.allSettled(file_promises).then(files => {
                let successful_uploads: string[] = [];
                files.forEach(result => {
                    if (result.status === "fulfilled") successful_uploads.push(result.value.info_file.name);
                });
                // Filter out the files that couldn't be uploaded
                let new_files_array = vFiles.filter(f => f.type !== "fake_upload" || successful_uploads.includes(f.name));
                // Update the state if some files were not successfully uploaded
                if (new_files_array.length !== vFiles.length) onChange?.(new_files_array);
                // Process the files that were successfully uploaded
                files.forEach(result => {
                    // Process the file through the component
                    if (result.status === "fulfilled") dropzone_ref.current?.uploadFile?.({
                        remove: null,
                        cancel: null,
                        restart: null,
                        file: result.value.file,
                        meta: {
                            percent: 100,
                            status: "done",
                            type: result.value.info_file.type,
                            size: result.value.info_file.size,
                            uploadedDate: new Date().toISOString(),
                            name: result.value.info_file.originalName,
                            lastModifiedDate: new Date().toISOString(),
                            id: result.value.info_file.name.split('.')[0],
                        },
                    }).catch(err => console.log("err", err));
                });
            });
        }
    }, [vFiles, onChange, props.multiple]);

    const onChangeStatus = React.useCallback<OnStatusChange>((file_meta, status, files) => {
        let all_files = files.map(f => getFileFormatted(f.meta, f.file));
        if (status === "uploading") {
            lock_form?.();
            set_lock_status(p => ({ ...p, [file_meta.meta.id]: true }));
        }
        else if (status === "error_upload") set_lock_status(p => ({ ...p, [file_meta.meta.id]: false }));
        else if (status === "rejected_file_type") M.renderAlert({ type: "warning", message: TC.FILE_UPLOAD_WRONG_TYPE });
        else if (status === "done") {
            let formatted_file = getFileFormatted(file_meta.meta, file_meta.file);
            // Check if file was correctly uploaded
            S.tempFileExists(formatted_file.name).then(({ data }) => {
                // Upload successful
                if (data) {
                    let new_files: T.File[] = [];

                    // Remove the old files
                    if (!props.multiple) files.forEach((f, i) => f.remove ? f.remove() : files.splice(i, 1));
                    // Update the component, so that it shows the file
                    if (!files.some(f => f.meta.id === file_meta.meta.id)) files.push(file_meta);
                    // Remove the files that were cancelled, and those that are considered "temp"
                    if (props.multiple) new_files = vFiles.filter(f => f.storage !== 'temp' && !cancelled.current.includes(f.name))
                        // Update the files that are "fake_upload" if they were successfully uploaded
                        .map(f => f.storage === "fake_upload" && file_meta.meta.name === f.originalName ? { ...f, storage: "temp" } : f)
                        .concat(all_files);
                    else new_files = [getFileFormatted(file_meta.meta, file_meta.file)];
                    // Update the state
                    onChange?.(new_files);
                }
                // Failed Upload
                else {
                    file_meta.remove?.();
                    M.Alerts.failedFileUpload();
                    cancelled.current.push(formatted_file.name);
                }
            })
                .catch(M.Alerts.failedFileUpload)
                .finally(() => set_lock_status(p => ({ ...p, [file_meta.meta.id]: false })));
        }
        else if (status === "removed") {
            let removedIdName = getIdName(file_meta.meta.id, file_meta.meta.name);
            onChange?.(vFiles.filter(f => f.storage !== "temp" || f.name !== removedIdName));
        }
    }, [getFileFormatted, onChange, getIdName, lock_form, vFiles, props.multiple]);
    //#endregion

    //#region Preview & ...
    const getPreviewUrl = React.useCallback<GetPreviewUrl>((meta, status) => status === "done" ? APIS.FILE_API + "temp/" + meta.meta.id : "", []);

    const size = React.useMemo(() => {
        let max = TB.humanSizeFormatToBytes(fileMaxSize);
        let min = TB.humanSizeFormatToBytes(props.fileMinSize);

        return {
            min: typeof min === "number" ? min : undefined,
            max: typeof max === "number" ? max : undefined,
        };
    }, [fileMaxSize, props.fileMinSize]);
    //#endregion

    //#region Existing Files
    const removedFiles = React.useMemo(() => vFiles.filter(f => f.storage === "removed").map(f => f.name).filter(TB.validString), [vFiles]);
    const getSrc = React.useCallback((file: T.File) => file.type.includes("image") ? file.url : R.RESOURCE_URL(R.NO_IMG_FOUND), []);

    const toggleExistingFile = React.useCallback((name: string) => {
        let toggle = () => {
            let new_files = vFiles.map(f => {
                // Not the file concerned
                if (f.name !== name) return f;
                // File was deleted
                if (f.storage === "removed") return { ...f, storage: "local" };
                // Delete the file
                else return { ...f, storage: "removed" };
            });
            onChange?.(new_files);
        }

        if (props.confirm_remove) M.Confirms.remove_file()
            .then(confirmed => confirmed && toggle());
        else toggle();
    }, [vFiles, onChange, props.confirm_remove]);

    const getRenderProps = React.useCallback((name: string) => {
        let removed = removedFiles.includes(name);

        return {
            opacity: removed ? 0.5 : 1,
            variant: removed ? "primary" : "danger",
            icon: removed ? "rotate-left" : "times",
        }
    }, [removedFiles]);

    const renderExistingItem = React.useCallback((file: T.File) => {
        let render_props = getRenderProps(file.name);
        let col_classes = props.example_class
            ? props.example_class
            : props.multiple
                ? "col-xl-3 col-md-4 col-sm-6"
                : "";

        return <Col className={col_classes} key={file.name}>
            <Card className='position-relative' style={{ opacity: render_props.opacity }}>
                <Card.Img
                    variant="top"
                    src={getSrc(file)}
                    className='pointer p-1'
                    style={{ objectFit: "contain" }}
                    height={props.multiple ? "100px" : "150px"}
                    onClick={() => window.open(file.url, "_blank")}
                />
                {!props.hide_info && <Card.Body>
                    <Card.Title
                        className='pointer'
                        onClick={() => window.open(file.url, "_blank")}
                    >
                        {file.originalName}
                    </Card.Title>
                    <Card.Text className='text-muted'>{TB.bytesToHumanSizeFormat(file.size)}</Card.Text>
                </Card.Body>}
                {!isDisabled && <Button size="sm" variant={render_props.variant} className='m-1 position-absolute top-0 start-0' onClick={() => toggleExistingFile(file.name)}>
                    <i className={'fa fa-' + render_props.icon}></i>
                </Button>}
            </Card>
        </Col>
    }, [isDisabled, getRenderProps, toggleExistingFile, getSrc, props.multiple, props.hide_info, props.example_class]);
    //#endregion

    const is_mobile = React.useMemo(() => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile Safari/i.test(navigator.userAgent), []);

    const accept = React.useMemo(() => {
        if (!props.image) return props.filePattern;
        else {
            let accept = "image/*";
            if (is_mobile) accept += ";capture=camera";
            return accept
        }
    }, [props.image, props.filePattern, is_mobile]);

    const allowNoMore = React.useMemo(() => !props.multiple && dbFiles.length !== 0 && removedFiles.length === 0, [props.multiple, dbFiles.length, removedFiles.length]);

    return <ComponentWrapper {...props} disabled={isDisabled} >
        <Flex direction={props.direction || "column"} className="flex-grow-1">

            {/* Pre-existing files */}
            {dbFiles.length > 0 && <Row className='p-2 mb-3' children={dbFiles.map(renderExistingItem)} />}

            {/* New Files */}
            <Dropzone
                multiple
                accept={accept}
                canCancel={false}
                ref={dropzone_ref}
                minSizeBytes={size.min}
                maxSizeBytes={size.max}
                SubmitButtonComponent={null}
                onChangeStatus={onChangeStatus}
                disabled={isDisabled || allowNoMore}
                maxFiles={props.multiple ? undefined : 1}
                inputContent={lg.getStaticText(TC.GLOBAL_FILE_UPLOAD)}
                inputWithFilesContent={lg.getStaticText(TC.GLOBAL_NEW)}
                PreviewComponent={(props) => <DropzonePreview {...props} getPreviewUrl={getPreviewUrl} />}
                getUploadParams={({ meta }) => ({ method: "POST", url: APIS.FILE_API + "temp/stream/" + meta.id, catch: M.Alerts.loadError })}
            />
        </Flex>
    </ComponentWrapper>
}

export default FileUploader;