import React from "react";
import * as Form from "../../Form"
import * as H from "../../../hooks";
import { T, TC } from "../../../Constants";
import { Flex } from "../../Layout";

//#region Types
export type Cell =
    Record<"type", "text"> & TextCellProps
    | Record<"type", "color"> & ColorCellProps
    | Record<"type", "select"> & SelectCellProps
    | Record<"type", "number"> & NumberCellProps
    | Record<"type", "boolean"> & BooleanCellProps;

type SelectCellProps = Form.SelectProps & {
    /** The other data currently in the row */
    rowObject?: Record<string, any>;
    /** A function to fetch the options to offer */
    getOptions?: (rowObject: Record<string, any>) => T.Option[] | Promise<T.Option[]>;
}

type ColorCellProps = Omit<Form.ColorProps, "type">;
type TextCellProps = Omit<Form.TextFieldProps, "type">;
type NumberCellProps = Omit<Form.NumFieldProps, "type">;
type BooleanCellProps = Omit<Form.CheckBoxProps, "type">;

export type CellRef = {
    /** Set the focus to this input */
    focus: () => void;
    /** Clean-up for when the focus is shifted to another element */
    clean?: () => void;
};
//#endregion

export const SelectCell = React.forwardRef<CellRef, SelectCellProps>((props, ref) => {
    const select = React.useRef<Form.SelectRef>(null);
    const [options, setOptions, status] = H.useAsyncState<T.Option[]>([]);

    React.useImperativeHandle(ref, () => ({
        focus: () => select.current?.focus?.(),
        clean: () => select.current?.hide_menu?.(),
    }), []);

    const error = React.useMemo(() => {
        if (status === "error") return { code: TC.GLOBAL_FAILED_LOAD };
        return props.error;
    }, [status, props.error]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (Array.isArray(props.options)) setOptions(props.options, "done");
        else if (typeof props.getOptions === "function") {
            let results_options = props.getOptions(props.rowObject);
            if (Array.isArray(results_options)) setOptions(results_options, "done");
            else {
                setOptions([], "load");
                results_options
                    .then(options => isSubscribed && setOptions(options, "done"))
                    .catch(() => isSubscribed && setOptions([], "error"));
            }
        }
        else setOptions([], "done");
        return () => {
            isSubscribed = false;
            setOptions([], "load");
        };
    }, [props, setOptions]);

    return <Form.Select
        {...props}
        hideLabel
        ref={select}
        error={error}
        noBottomMargin
        options={options}
        loading={props.loading || status === "load"}
    />;
});

export const NumberCell = React.forwardRef<CellRef, NumberCellProps>((props, ref) => {
    const numfield = React.useRef<Form.NumFieldRef>(null);
    React.useImperativeHandle(ref, () => ({ focus: () => numfield.current?.single?.current?.input?.current?.focus?.() }), []);
    return <Form.NumField {...props} hideLabel noBottomMargin ref={numfield} />
});

export const TextCell = React.forwardRef<CellRef, TextCellProps>((props, ref) => {
    const textfield = React.useRef<Form.TextFieldRef>(null);
    React.useImperativeHandle(ref, () => ({ focus: () => textfield.current?.single?.current?.input?.current?.focus?.() }), []);
    return <Form.TextField {...props} hideLabel noBottomMargin ref={textfield} />;
});

export const BooleanCell = React.forwardRef<CellRef, BooleanCellProps>((props, ref) => {
    const switch_ref = React.useRef<Form.CheckBoxRef>(null);
    React.useImperativeHandle(ref, () => ({ focus: () => switch_ref.current?.input?.focus?.() }), []);
    return <Flex className="h-100" alignItems="center" justifyContent="center">
        <Form.CheckBox {...props} check_type="switch" hideLabel noBottomMargin ref={switch_ref} />
    </Flex>;
});

export const ColorCell = React.forwardRef<CellRef, ColorCellProps>((props, ref) => {
    const color_ref = React.useRef<Form.ColorRef>(null);
    React.useImperativeHandle(ref, () => ({ focus: color_ref.current.focus }), []);
    return <Form.Color {...props} no_auto_focus ref={color_ref} />;
});

TextCell.displayName = "TextCell";
ColorCell.displayName = "ColorCell";
SelectCell.displayName = "SelectCell";
NumberCell.displayName = "NumberCell";
BooleanCell.displayName = "BooleanCell";