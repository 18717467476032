import React from "react";
import { Flex } from "../../Layout";
import { TextField } from "../Components";
import { T, TB } from "../../../Constants";
import { useLanguage } from "../../../hooks";
import { Button, Table } from "react-bootstrap";
import { ComponentWrapper, Input, InputProps } from "../Input";

export type DataMapProps = InputProps & {
    keyLabel?: string;
    addAnother?: string;
    disableAddingRemovingRows?: boolean;
    valueComponent?: T.FormComponentsMain;
}

const DataMap: React.FC<DataMapProps> = ({ onChange, onChanges, ...props }) => {
    const lg = useLanguage();

    //#region Value
    const valueObj = React.useMemo(() => {
        let obj = TB.getObject(props.value, { key: "" });
        return Object.keys(obj).length === 0 ? { key: "" } : obj;
    }, [props.value]);

    const keys = React.useMemo(() => Object.keys(valueObj), [valueObj]);
    const component = React.useMemo(() => TB.getObject(props.valueComponent), [props.valueComponent]);
    //#endregion

    //#region onChange
    const onChangeValue = React.useCallback((value: any, key: string) => onChange?.(value, `${props.prop}.${key}`), [onChange, props.prop]);
    const removeEntry = React.useCallback((key: string) => onChanges?.([{ value: null, remove: true, prop: `${props.prop}.${key}` }]), [onChanges, props.prop]);

    const onChangeKey = React.useCallback((newKey: string, oldKey?: string) => {
        onChanges?.([
            { value: null, prop: `${props.prop}.${TB.getString(oldKey)}`, remove: true },
            { value: valueObj[oldKey], prop: `${props.prop}.${newKey}` }
        ]);
    }, [valueObj, onChanges, props.prop]);

    const addEntry = React.useCallback(() => {
        let i = 0, key = "key";
        while (keys.includes(key)) {
            i++;
            key = "key" + i;
        }
        onChange?.("", `${props.prop}.${key}`);
    }, [onChange, keys, props.prop]);
    //#endregion

    return <ComponentWrapper {...props}>
        <Table responsive bordered >
            <thead>
                <tr>
                    <th>{lg.getStaticElem(props.keyLabel)}</th>
                    <th>{lg.getStaticElem(props.valueComponent?.label)}</th>
                    {!props.disableAddingRemovingRows && <th></th>}
                </tr>
            </thead>
            <tbody>
                {keys.map((k, i) => <tr key={i}>
                    <td style={{ width: "46%" }}>
                        <TextField value={k} onChange={(e: string) => onChangeKey(e, k)} />
                    </td>
                    <td style={{ width: "46%" }}>
                        <Input
                            {...component}
                            prop={`${props.prop}.${k}`}
                            contextKey={props.contextKey}
                            submissionId={props.submissionId}
                            type={component.type || "textfield"}
                            set_translation={props.set_translation}
                            onAddCertification={props.onAddCertification}
                            onChange={(v, p) => onChangeValue(v, p || k)}
                        />
                    </td>
                    {!props.disableAddingRemovingRows && <td>
                        <Button variant="danger" className="w-100" onClick={() => removeEntry(k)}>
                            <i className="fa fa-times"></i>
                        </Button>
                    </td>}
                </tr>)}
            </tbody>
            {!props.disableAddingRemovingRows && <tfoot>
                <tr>
                    <td colSpan={3}>
                        <Flex alignItems="center" justifyContent="end" className="">
                            <Button onClick={addEntry}>
                                {lg.getStaticElem(props.addAnother)}
                                <i className="fa fa-plus ms-2"></i>
                            </Button>
                        </Flex>
                    </td>
                </tr>
            </tfoot>}
        </Table>
    </ComponentWrapper>
}

export default DataMap;