import { TB, TC } from "../../../Constants";
import renderInContainer from "../getContainer";
import { Support, SupportProps } from "../../Profile";
import { StyleModalProps, default as Modal } from "../BlankModal";

export type AskSupportModal = (params: StyleModalProps & Omit<SupportProps, "onSubmit">) => Promise<void>;

const askSupport: AskSupportModal = params => new Promise(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};
    if (!params.size) params.size = "md";
    if (!params.title) params.title = TC.GLOBAL_SUPPORT;

    if (render && dismount) {
        let onClose = () => dismount?.(() => resolve());
        let onSubmit: SupportProps["onSubmit"] = () => dismount?.(() => resolve());
        render(<Modal {...params} onQuit={onClose}>
            <Support onSubmit={onSubmit} />
        </Modal>);
    }
    else resolve();
});

export default askSupport;