import * as S from "../../../services";
import Form, { FormProps } from "../form";
import { FP, RIGHTS, T, TC } from "../../../Constants";
import { Accordion, ButtonGroup } from "react-bootstrap";
import { Alerts, askConfirm, renderFormModal } from "../../Modal";
import { Spinner, CenterMessage, Flex, Button } from "../../../Common";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useAsyncState, useAuth, useLanguage, useRights } from "../../../hooks";

export type ActionsRemarquesPanelProps = {
    /** The id of the remarque */
    remarque: string;
    /** The remarque's description, if any */
    description?: string;
    /** The current selected context */
    context: T.ContextParams;
    /** Do not allow any edits */
    read_only?: boolean;
    /** The _id of the element linked to the remarque */
    elementId: string;
    /** Callback after the creation of a new action */
    onCreate?: (action: T.Submission<T.TicketData>) => void;
    /** Callback after the deletion of an action */
    onRemove?: (action: T.Submission<T.TicketData>) => void;
}

const ActionRemarquesPanel: React.FC<ActionsRemarquesPanelProps> = ({ onCreate, onRemove, ...props }) => {
    const rights = useRights();
    const [{ userId }] = useAuth();
    const lg = useLanguage([TC.NO_ITEM]);
    const [onEdit, setOnEdit] = useState("");
    const [actions, setActions, status] = useAsyncState<T.Submission<T.TicketData>[]>([]);

    //#region Load data
    useEffect(() => {
        let isSubscribed = true;
        S.getActionsForRemarque({ id: props.remarque, context: props.context })
            .then(({ data }) => isSubscribed && setActions(data, "done"))
            .catch(() => setActions([], "error"));
        return () => {
            isSubscribed = false;
            setActions([], "load");
        };
    }, [props.remarque, props.context, setActions]);
    //#endregion

    //#region Edit
    const events = useMemo(() => ({
        toEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id?: string) => {
            e.preventDefault();
            setOnEdit(id || "");
        },
        onEdit: (submission => {
            if (submission) setActions(p => p.map(r => r._id === submission._id ? submission : r));
        }) as FormProps<T.TicketData>["onSave"],
        onDelete: (submission: typeof actions[number]) => {
            askConfirm().then(confirmed => {
                if (confirmed) S.removeTickets({ ids: submission._id, removeEvents: false })
                    .then(() => {
                        onRemove?.(submission);
                        setActions(p => p.filter(a => a._id !== submission._id))
                    })
                    .catch(Alerts.deleteError);
            });
        },
        onAdd: () => {
            let forcedSubmission = [
                { prop: "remarque", value: props.remarque },
                { prop: "equipment", value: props.elementId },
                { prop: "description", value: props.description },
            ];
            renderFormModal<T.TicketData>({ path: FP.TICKET_FORM, forcedSubmission }).then(ticket => {
                if (ticket) {
                    onCreate?.(ticket);
                    setActions(p => p.concat(ticket));
                }
            });
        },
    }), [setActions, onCreate, onRemove, props.remarque, props.description, props.elementId]);

    const getTicketButton = useCallback((ticket: typeof actions[number]) => {
        let isOwn = ticket.owner === userId;
        let canEdit = isOwn
            ? rights.isRightAllowed(RIGHTS.ACTION.WRITE_MAINTENANCE_ACTION, ticket.data.equipment)
            : rights.isRightAllowed(RIGHTS.ACTION.WRITE_OTHER_MAINTENANCE_ACTION, ticket.data.equipment);

        if (!canEdit || props.read_only) return null;

        return <ButtonGroup>
            {onEdit === ticket._id
                ? <Button variant="secondary" icon="times" onClick={events.toEdit} text={TC.GLOBAL_CANCEL} />
                : <Button onClick={e => events.toEdit(e, ticket._id)} icon="pencil-alt" text={TC.GLOBAL_EDIT} />}
            <Button variant="danger" icon="trash" onClick={() => events.onDelete(ticket)} />
        </ButtonGroup>;
    }, [rights, userId, onEdit, events, props.read_only]);
    //#endregion

    return <Spinner status={status}>
        {actions.length === 0
            ? <CenterMessage>
                <div className="p-2 text-center">
                    <div className="mb-2">{lg.getStaticElem(TC.NO_ITEM)}</div>
                    {!props.read_only && <Button icon="plus" variant="link" onClick={events.onAdd} text={TC.GLOBAL_NEW} />}
                </div>
            </CenterMessage>
            : <>
                <Flex justifyContent="end" className="mb-2">
                    <Button disabled={props.read_only} icon="plus" onClick={events.onAdd} text={TC.GLOBAL_NEW} />
                </Flex>
                <Accordion className='mt-3'>
                    {actions.map(r => <Accordion.Item key={r._id} eventKey={r._id}>
                        <Accordion.Header>
                            {r.data.title}
                        </Accordion.Header>
                        <Accordion.Body>
                            <Flex className='w-100 me-3' alignItems='center' justifyContent='end'>
                                {getTicketButton(r)}
                            </Flex>
                            <Form<T.TicketData>
                                submissionId={r._id}
                                path={FP.TICKET_FORM}
                                onSave={events.onEdit}
                                readOnly={props.read_only || r._id !== onEdit}
                            />
                        </Accordion.Body>
                    </Accordion.Item>)}
                </Accordion>
            </>}
    </Spinner>;
}

export default ActionRemarquesPanel;