import axios from "axios";
import { getHeader } from "../Headers";
import { T, APIS } from "../../Constants";

const { MISSION_API } = APIS;

type GetMission = T.API.ToAxios<T.API.Utils.Missions.GetMission>;
type GetCoverage = T.API.ToAxios<T.API.Utils.Missions.GetCoverage>;
type SaveEpraView = T.API.ToAxios<T.API.Utils.Missions.SaveEpraView>;
type MissionCheck = T.API.ToAxios<T.API.Utils.Missions.MissionCheck>;
type FormResource = T.API.ToAxios<T.API.Utils.Missions.FormResource>;
type UpdateMission = T.API.ToAxios<T.API.Utils.Missions.UpdateMission>;
type DeleteMission = T.API.ToAxios<T.API.Utils.Missions.DeleteMission>;
type MagicDatasets = T.API.ToAxios<T.API.Utils.Missions.MagicDatasets>;
type SendReportMail = T.API.ToAxios<T.API.Utils.Missions.SendReportMail>;
type GetCoverageLfL = T.API.ToAxios<T.API.Utils.Missions.GetCoverageLfL>;
type DeleteEpraView = T.API.ToAxios<T.API.Utils.Missions.DeleteEpraView>;
type GetMissionLogs = T.API.ToAxios<T.API.Utils.Missions.GetMissionLogs>;
type SaveMissionEpra = T.API.ToAxios<T.API.Utils.Missions.SaveMissionEpra>;
type FinalReportKPIs = T.API.ToAxios<T.API.Utils.Missions.FinalReportKPIs>;
type MakeReportFinal = T.API.ToAxios<T.API.Utils.Missions.MakeReportFinal>;
type MissionResumeCDM = T.API.ToAxios<T.API.Utils.Missions.MissionResumeCDM>;
type SaveMissionExtra = T.API.ToAxios<T.API.Utils.Missions.SaveMissionExtra>;
type SaveMissionRSC01 = T.API.ToAxios<T.API.Utils.Missions.SaveMissionRSC01>;
type MissionResumeNRJ = T.API.ToAxios<T.API.Utils.Missions.MissionResumeNRJ>;
type GetPastStructures = T.API.ToAxios<T.API.Utils.Missions.GetPastStructures>;
type MissionResumeEpra = T.API.ToAxios<T.API.Utils.Missions.MissionResumeEpra>;
type GetMissionsReports = T.API.ToAxios<T.API.Utils.Missions.GetMissionsReports>;
type GetMissionDatasets = T.API.ToAxios<T.API.Utils.Missions.GetMissionDatasets>;
type SaveMissionRedFlag = T.API.ToAxios<T.API.Utils.Missions.SaveMissionRedFlag>;
type FinalReportRegKPIs = T.API.ToAxios<T.API.Utils.Missions.FinalReportRegKPIs>;
type MissionResumeRSC01 = T.API.ToAxios<T.API.Utils.Missions.MissionResumeRSC01>;
type SaveMissionTaxonomy = T.API.ToAxios<T.API.Utils.Missions.SaveMissionTaxonomy>;
type CreateReportVersion = T.API.ToAxios<T.API.Utils.Missions.CreateReportVersion>;
type MissionToggleControl = T.API.ToAxios<T.API.Utils.Missions.MissionToggleControl>;
type MissionSetControlled = T.API.ToAxios<T.API.Utils.Missions.MissionSetControlled>;
type MissionEquipmentsNRJ = T.API.ToAxios<T.API.Utils.Missions.MissionEquipmentsNRJ>;
type MissionResumeRedFlag = T.API.ToAxios<T.API.Utils.Missions.MissionResumeRedFlag>;
type GetEpraViewsSettings = T.API.ToAxios<T.API.Utils.Missions.GetEpraViewsSettings>;
type ControlListMissionCDM = T.API.ToAxios<T.API.Utils.Missions.ControlListMissionCDM>;
type AutoAssignRemsRedFlag = T.API.ToAxios<T.API.Utils.Missions.AutoAssignRemsRedFlag>;
type CheckContextForMission = T.API.ToAxios<T.API.Utils.Missions.CheckContextForMission>;
type SaveMissionConsumption = T.API.ToAxios<T.API.Utils.Missions.SaveMissionConsumption>;
type AutoAssignCapexRedFlag = T.API.ToAxios<T.API.Utils.Missions.AutoAssignCapexRedFlag>;
type AutoAssignRegDocRedFlag = T.API.ToAxios<T.API.Utils.Missions.AutoAssignRegDocRedFlag>;
type MissionResumeEuTaxonomy = T.API.ToAxios<T.API.Utils.Missions.MissionResumeEuTaxonomy>;
type GetDefaultReportMailBody = T.API.ToAxios<T.API.Utils.Missions.GetDefaultReportMailBody>;
type ControlListRemarquesData = T.API.ToAxios<T.API.Utils.Missions.ControlListRemarquesData>;
type SaveMissionDataStructure = T.API.ToAxios<T.API.Utils.Missions.SaveMissionDataStructure>;
type GetValueForMissionStructure = T.API.ToAxios<T.API.Utils.Missions.GetValueForMissionStructure>;
type GetValueForMissionStructurePartial = T.API.ToAxios<T.API.Utils.Missions.GetValueForMissionStructurePartial>;

/** Load the necessary resources for a mission form */
export const missionFormResources: FormResource = params => axios.post(MISSION_API + "resources", params);
/** Load an existing mission */
export const getMission: GetMission = id => axios.get(MISSION_API + "get", { params: { id } });
/** Create or update a Mission */
export const updateMission: UpdateMission = mission => axios.post(MISSION_API + "update", mission, getHeader("EDIT"));
/** Deletes a mission */
export const deleteMission: DeleteMission = id => axios.post(MISSION_API + "delete", { id }, getHeader("DELETE"));
/** Get basic info about a mission, for a CDM */
export const missionResumeCDM: MissionResumeCDM = () => axios.get(MISSION_API + "cdm/resume");
/** Get a Control List for a CDM Mission */
export const controlListMissionCDM: ControlListMissionCDM = (item_id?: string) => axios.get(MISSION_API + "cdm/control", { params: { item_id } });
/** Get the only the data about remarques for an item */
export const controlListRemarquesData: ControlListRemarquesData = item_id => axios.get(MISSION_API + "cdm/remarques", { params: { item_id } });
/** Toggle an an item's state of control for a mission */
export const missionToggleControl: MissionToggleControl = item => axios.post(MISSION_API + "cdm/toggle_control", { item }, getHeader("EDIT"));
/** Set many items as controlled for a mission */
export const missionSetControlled: MissionSetControlled = items => axios.post(MISSION_API + "cdm/set_control", { items }, getHeader("EDIT"));
/** Automatically creates datasets based on the category of an equipment */
export const cmdMagicDatasets: MagicDatasets = id => axios.post(MISSION_API + "cmd/datasets", { id }, getHeader("CREATE"));
/** Check if a context is available for the current mission's asset */
export const checkContextForMission: CheckContextForMission = id => axios.post(MISSION_API + "context/check", { id });
/** Get possible structures that have been used from previous missions */
export const getPastStructures: GetPastStructures = () => axios.post(MISSION_API + "cdm/structures");
/** Get a list of datasets and their elements for a mission's asset */
export const getMissionDatasets: GetMissionDatasets = () => axios.post(MISSION_API + "cdm/datasets");
/** Get Values for a structure */
export const getValueForMissionStructure: GetValueForMissionStructure = structure => axios.post(MISSION_API + "data/structure", structure);
/** Save a new data structure */
export const saveMissionDataStructure: SaveMissionDataStructure = structure => axios.post(MISSION_API + "data/save_structure", structure);
/** Sava a new Consumption structure */
export const saveMissionConsumption: SaveMissionConsumption = consumption => axios.post(MISSION_API + "data/save_consumption", consumption);
/** Save a new extra mission value */
export const saveMissionExtra: SaveMissionExtra = params => axios.post(MISSION_API + "data/save_extra", params);
/** To save the KPIs for the site / buildings when a final report for a mission is generated */
export const finalReportKPIs: FinalReportKPIs = mission => axios.post(MISSION_API + "report/final", { mission }, getHeader("EDIT"));
/** Get basic info about a mission, for a NRJ Report */
export const missionResumeNRJ: MissionResumeNRJ = () => axios.get(MISSION_API + "nrj/resume");
/** Get data about equipments of specific categories */
export const missionEquipmentsNRJ: MissionEquipmentsNRJ = () => axios.get(MISSION_API + "nrj/equipments");
/** Get basic info about a mission, for a Red Flag report */
export const missionResumeRedFlag: MissionResumeRedFlag = () => axios.get(MISSION_API + "red_flag/resume");
/** Save a new Red Flag structure */
export const saveMissionRedFlag: SaveMissionRedFlag = red_flag => axios.post(MISSION_API + "data/save_red_flag", red_flag);
/** Create and apply an auto-assignation of the remarques */
export const autoAssignRemsRedFlag: AutoAssignRemsRedFlag = () => axios.post(MISSION_API + "red_flag/rem/auto_assign", getHeader("EDIT"));
/** Create and apply an auto-assignation of the reg docs */
export const autoAssignRegDocRedFlag: AutoAssignRegDocRedFlag = () => axios.post(MISSION_API + "red_flag/reg_doc/auto_assign", getHeader("EDIT"));
/** Create and apply an auto-assignation of the capex rows */
export const autoAssignCapexRedFlag: AutoAssignCapexRedFlag = () => axios.post(MISSION_API + "red_flag/capex/auto_assign", getHeader("EDIT"));
/** Get a resume of who opened what missions */
export const getMissionLogs: GetMissionLogs = () => axios.get(MISSION_API + "logs");
/** Get basic info about a mission, for an EU Taxonomy report */
export const missionResumeEuTaxonomy: MissionResumeEuTaxonomy = () => axios.get(MISSION_API + "eu_taxonomy/resume");
/** Save a new Taxonomy structure */
export const saveMissionTaxonomy: SaveMissionTaxonomy = taxonomy => axios.post(MISSION_API + "data/save_taxonomy", taxonomy);
/** Get Values for a only a few datasets in a structure, will also reload the linked calculated datasets */
export const getValueForMissionStructurePartial: GetValueForMissionStructurePartial = params => axios.post(MISSION_API + "datasets/structure", params);
/** Get a resume of all the reports uploaded for all the missions a user has access to */
export const getMissionsReports: GetMissionsReports = asset => axios.post(MISSION_API + "reports", { asset });
/** To save the Reg Kpi for the site / building when a final report for a mission is generated */
export const finalReportRegKPIs: FinalReportRegKPIs = mission => axios.post(MISSION_API + "report/reg_kpi", { mission }, getHeader("EDIT"));
/** Get basic info about a mission, for a RSC01 report */
export const missionResumeRSC01: MissionResumeRSC01 = () => axios.get(MISSION_API + "rsc01/resume");
/** Save a new RSC01 structure */
export const saveMissionRSC01: SaveMissionRSC01 = params => axios.post(MISSION_API + "data/save_rsc01", params);
/** Check that the mandatory fields for a mission are completed, and return needed data for the report generation */
export const missionCheck: MissionCheck = mission_id => axios.post(MISSION_API + "check", { mission_id });
/** Get basic info for an EPRA mission's wizard */
export const missionResumeEpra: MissionResumeEpra = () => axios.get(MISSION_API + "epra/resume");
/** Save a new EPRA structure */
export const saveMissionEpra: SaveMissionEpra = epra => axios.post(MISSION_API + "data/save_epra", epra);
/** Get the coverage data for a collection of datasets */
export const getCoverage: GetCoverage = params => axios.get(MISSION_API + "coverage", { params });
/** Get the LFL coverage data for a collection of datasets */
export const getCoverageLfL: GetCoverageLfL = params => axios.get(MISSION_API + "lfl/coverage", { params });
/** Get the list of EPRA configurations available to the user, and the list of users he can create a configuration for */
export const getEpraViewsSettings: GetEpraViewsSettings = () => axios.get(MISSION_API + "epra/views");
/** Save a new EPRA view in the database */
export const saveEpraView: SaveEpraView = view => axios.post(MISSION_API + "epra/save_view", view);
/** Delete an EPRA view from the database */
export const deleteEpraView: DeleteEpraView = id => axios.post(MISSION_API + "epra/delete_view", { id }, getHeader("DELETE"));
/** Send a report to a few people */
export const sendReportMail: SendReportMail = params => axios.post(MISSION_API + "send/report", params);
/** Generate a default mail body for a mission */
export const getDefaultReportMailBody: GetDefaultReportMailBody = params => axios.post(MISSION_API + "send/default_body", params);
/** Create a new version of the report in the mission task array */
export const createReportVersion: CreateReportVersion = params => axios.post(MISSION_API + "version", params);
/** Turn a version of the report into the final one */
export const makeReportFinal: MakeReportFinal = params => axios.post(MISSION_API + "final", params);
