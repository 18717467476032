import React from "react";
import * as M from "../Modal";
import * as P from "./Panels";
import * as H from "../../hooks";
import * as C from "../../Common";
import { DataPanel } from "./Data";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import NotesManager from "./NoteManager";
import { ActionRegUpdater } from "../RegDoc";
import Form, { FormApi, FormProps } from "./form";
import { FP, RIGHTS, T, TB, TC } from "../../Constants";

//#region Types
type RegTabData = Partial<ReturnType<T.API.Reg.GetElemRegUpdaterData>>;
type SubEquipContext = ReturnType<T.API.Utils.Navigator.SubEquipContext>;
type FormPropsEdited<A extends object> = Omit<FormProps<A>, "modal" | "modalProps" | "onSave" | "hideSubmit" | "api">;
type EditedRemarques = Record<Parameters<P.RemarquesPanelProps["onChange"]>[0], Parameters<P.RemarquesPanelProps["onChange"]>[1][]>;
type FormPanel = { code: string, content: React.ReactNode, show: boolean, label: string, footer?: React.ReactNode, size?: T.ModalSizes };

export type PopUpFormProps<A extends object = object> = FormPropsEdited<A> & {
    /** Popup's title */
    title?: string;
    /** Do not show the form */
    hideForm?: boolean;
    /** The default tab open, can be "form", "note", "rem", "reg", "kpi" or "data" */
    defaultKey?: string;
    /** If there are some panels that should not be shown */
    hide_panels?: ("note" | "rem" | "reg" | "kpi" | "data")[];
    /** Callback after validation of the form */
    handleForm?: FormProps<A>["onSave"];
    /** Callback for the edition of remarques */
    updateRem?: (edits: EditedRemarques) => void;
    /** Callback if a sub-equipment was edited */
    updateSubEquip?: (equip: T.Submission<T.EquipmentData>) => void;/* TODO */
    /** Extra content to render in the footer */
    footer?: React.ReactNode | ((quit: () => void) => React.ReactNode);
};
//#endregion

//#region Constants
const DATA_PATHS = [FP.SITE_FORM, FP.BUILDING_FORM, FP.EMPLACEMENT_FORM, FP.EQUIPEMENT_FORM];
const PANELS_CODES = { form: "form", note: "note", remarques: "rem", reg: "reg", data: "data", kpi: "kpi" };

const TEXT_CODES = [
    TC.GLOBAL_SAVE, FP.NOTES_PATH, TC.POP_UP_FORM_PROP_PANEL, TC.DATASET_DATA,
    FP.REMARQUES_DEFAULTS, TC.GLOBAL_CANCEL, TC.GLOBAL_EDIT, FP.ACTION_REG_FORM,
];
//#endregion

const PopUpForms = <A extends object = object,>({ defaultKey, hideForm, handleForm, updateRem, updateSubEquip, ...props }: PopUpFormProps<A>) => {
    const rights = H.useRights();
    const [forms] = H.useFormIds();
    const lg = H.useLanguage(TEXT_CODES);
    const disable_save = H.useBoolean(false);
    const formRef = React.useRef<FormApi>({});
    const [active_key, set_active_key] = React.useState(props.submissionId || "");
    const [tabKey, setTabKey] = React.useState(defaultKey || PANELS_CODES.form);
    const [regTabData, setRegTabData, regTabStatus] = H.useAsyncState<RegTabData>({});
    const [equip_context, set_equip_context, equip_status] = H.useAsyncState<SubEquipContext>([]);
    const [editedRemarques, setEditedRemarques] = React.useState<EditedRemarques>({ edit: [], delete: [], add: [] });

    //#region Equipment Pseudo Context
    React.useEffect(() => set_active_key(props.submissionId || ""), [props.submissionId]);

    React.useEffect(() => {
        let isSubscribed = true;
        let is_existing_sub = TB.mongoIdValidator(props.submissionId);
        let is_equip = props.path === FP.EQUIPEMENT_FORM || props._id === forms[FP.EQUIPEMENT_FORM];

        if (is_existing_sub && is_equip) S.subEquipContext(props.submissionId)
            .then(({ data }) => isSubscribed && set_equip_context(data, "done"))
            .catch(() => isSubscribed && set_equip_context([], "error"));
        else set_equip_context([], "done");

        return () => {
            isSubscribed = false;
            set_equip_context([], "load");
        }
    }, [set_equip_context, forms, props._id, props.path, props.submissionId]);

    const sub_equip_context = React.useMemo(() => {
        let all_equip_rows = [] as (typeof equip_context[number] & Record<"level", number> & Record<"parent", string>)[];

        const recursive = (equip: SubEquipContext, parent = "", level: number) => {
            equip.forEach(e => {
                all_equip_rows.push({ ...e, level, parent });
                recursive(e.sub_equipments, parent + e.id, level + 1);
            });
        }
        recursive(equip_context, "", 0);
        let active_name = all_equip_rows.find(e => e.id === active_key)?.name;

        if (equip_status === "load") return <i className="fa fa-spinner fa-spin" />;
        // If there is only one equipment, do not show the dropdown
        else if (all_equip_rows.length <= 1) return null;
        return <BS.Dropdown className="ms-2">
            <BS.Dropdown.Toggle children={active_name} />
            <BS.Dropdown.Menu>
                {all_equip_rows.map(equip => <BS.Dropdown.Item
                    children={equip.name}
                    key={equip.parent + equip.id}
                    active={equip.id === active_key}
                    onClick={() => set_active_key(equip.id)}
                    style={{ paddingLeft: ((1 + equip.level) * 0.5) + "rem" }}
                />)}
            </BS.Dropdown.Menu>

        </BS.Dropdown>;
    }, [equip_status, active_key, equip_context]);
    //#endregion

    //#region Close
    const closeForm = React.useCallback<typeof handleForm>(submission => {
        // Fire the remarques callback if there was some edits
        if (Object.values(editedRemarques).some(a => a.length > 0)) updateRem?.(editedRemarques);
        // Fire the form callback if the original node was edited
        if (!submission) handleForm?.();
        else if (submission._id === props.submissionId) handleForm?.(submission);
        else {
            updateSubEquip(submission as T.Submission<T.EquipmentData>);
            // Reset the active key to the original submission
            set_active_key(props.submissionId);
        }
    }, [handleForm, updateRem, updateSubEquip, editedRemarques, props.submissionId]);
    //#endregion

    //#region Form
    const displayForm = React.useMemo(() => !hideForm, [hideForm]);

    const formContent = React.useMemo(() => <Form<A>
        {...props}
        hideSubmit
        api={formRef}
        onSave={closeForm}
        submissionId={active_key}
        on_saving_status_change={disable_save.setValue}
    />, [closeForm, disable_save, active_key, props]);

    const formFooter = React.useMemo(() => !props.readOnly && <C.Flex alignItems="center" justifyContent="end">
        <C.Button onClick={() => formRef.current.save?.()} disabled={disable_save.value} text={TC.GLOBAL_SAVE} icon="save" />
    </C.Flex>, [props.readOnly, disable_save.value]);

    const onQuit = React.useCallback(() => {
        if (formRef.current?.has_changed?.()) M.Confirms.quit_no_save()
            .then(confirmed => confirmed && closeForm());
        else closeForm();
    }, [closeForm]);
    //#endregion

    //#region Notes
    const displayNotes = React.useMemo(() => {
        if (!TB.mongoIdValidator(active_key)) return false;
        else if (props.hide_panels && props.hide_panels.includes("note")) return false;
        let is_note_form = props.path === FP.NOTES_PATH || props._id === forms[FP.NOTES_PATH];
        return !is_note_form;
    }, [active_key, props.path, props._id, props.hide_panels, forms]);

    const notesContent = React.useMemo(() => <NotesManager origin={active_key} />, [active_key]);
    //#endregion

    //#region Remarques
    const displayRemarques = React.useMemo(() => {
        if (props.hide_panels && props.hide_panels.includes("rem")) return false;
        // Has user access to remarques
        if (!rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_REMARQUES)) return false;
        // Must be an existing submission;
        if (!TB.mongoIdValidator(active_key)) return false;
        // Must be an equipment
        if (props._id === forms[FP.EQUIPEMENT_FORM] || props.path === FP.EQUIPEMENT_FORM) return true;
        // Or must be an emplacement
        else if (props._id === forms[FP.EMPLACEMENT_FORM] || props.path === FP.EMPLACEMENT_FORM) return true;
        // If Other do not show
        else return false;
    }, [active_key, props._id, props.path, props.hide_panels, forms, rights]);

    const remarquesContent = React.useMemo(() => ({
        show: displayRemarques,
        panel: <P.RemarquesPanel
            _id={active_key || ''}
            onChange={(type, remarque) => setEditedRemarques(p => {
                if (type === 'delete') return {
                    delete: p.delete.concat(remarque),
                    add: p.add.filter(r => r._id !== remarque._id),
                    edit: p.edit.filter(r => r._id !== remarque._id),
                };
                else if (type === "add") return {
                    ...p,
                    add: p.add.concat(remarque),
                }
                else return {
                    ...p,
                    add: p.add.map(r => r._id === remarque._id ? remarque : r),
                    edit: p.edit.map(r => r._id === remarque._id ? remarque : r),
                }
            })}
        />
    }), [displayRemarques, active_key]);
    //#endregion

    //#region Reg
    const showRegTab = React.useMemo(() => {
        if (props.hide_panels && props.hide_panels.includes("reg")) return false;
        let hasId = TB.mongoIdValidator(active_key);
        let hasRight = rights.isRightAllowed(RIGHTS.REG.READ_ELEMENT_REG, active_key);
        let isEquip = props.path === FP.EQUIPEMENT_FORM || props._id === forms[FP.EQUIPEMENT_FORM];
        return hasId && hasRight && isEquip;
    }, [forms, props._id, props.path, props.hide_panels, active_key, rights]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (showRegTab) S.getElemRegUpdaterData(active_key)
            .then(({ data }) => isSubscribed && setRegTabData(data, "done"))
            .catch(() => isSubscribed && setRegTabData({}, "error"));
        else setRegTabData({}, "done");

        return () => {
            isSubscribed = false;
            setRegTabData({}, "load");
        };
    }, [active_key, showRegTab, setRegTabData]);

    const regTabContent = React.useMemo(() => {
        if (regTabStatus === "load") return <M.Loader isPopUp={false} />;
        else if (regTabStatus === "error") return <C.ErrorBanner type="danger" message={TC.GLOBAL_FAILED_LOAD} />;
        else return <div className="p-3">
            <ActionRegUpdater
                elementId={active_key}
                roots={regTabData.site}
                disabled={props.readOnly}
                label={FP.ACTION_REG_FORM}
                category={regTabData.category}
                itemActions={regTabData.reglementations}
            />
        </div>;
    }, [props.readOnly, active_key, regTabStatus, regTabData]);
    //#endregion

    //#region Data
    const showDataTab = React.useMemo(() => {
        if (props.hide_panels && props.hide_panels.includes("data")) return false;
        // Existing submission
        if (TB.mongoIdValidator(active_key)) {
            // Has the right to read datasets
            if (rights.isRightAllowed(RIGHTS.NRJ.READ_NRJ_TAGS)) {
                // Valid type of submission
                if (DATA_PATHS.includes(props.path)) return true;
                if (DATA_PATHS.map(path => forms[path]).includes(props._id)) return true;
            }
        }
        return false;
    }, [forms, rights, props.hide_panels, active_key, props._id, props.path]);

    const dataTab = React.useMemo(() => <div style={{ height: "50vh" }}>
        <DataPanel origin={active_key} readOnly={props.readOnly} />
    </div>, [active_key, props.readOnly]);
    //#endregion

    //#region KPI
    const show_kpi_tab = React.useMemo(() => {
        if (props.hide_panels && props.hide_panels.includes("kpi")) return false;
        // Existing submission
        if (TB.mongoIdValidator(active_key)) {
            // Is a building
            if (props.path === FP.BUILDING_FORM || props._id === forms[FP.BUILDING_FORM]) return true;
        }
        return false;
    }, [forms, props._id, props.hide_panels, props.path, active_key]);

    const kpi_panel = React.useMemo(() => <P.KPIPanel building={active_key} />, [active_key]);
    //#endregion

    //#region BS.Tabs
    const title = React.useMemo(() => TB.getString(props.title, TC.FORMS), [props.title]);

    const panels = React.useMemo<FormPanel[]>(() => [
        { code: PANELS_CODES.form, label: TC.POP_UP_FORM_PROP_PANEL, content: formContent, show: displayForm, footer: formFooter },
        { code: PANELS_CODES.note, label: FP.NOTES_PATH, content: notesContent, show: displayNotes },
        { code: PANELS_CODES.remarques, label: FP.REMARQUES_DEFAULTS, content: remarquesContent.panel, show: remarquesContent.show },
        { code: PANELS_CODES.reg, label: FP.ACTION_REG_FORM, content: regTabContent, show: showRegTab, size: "lg" },
        { code: PANELS_CODES.data, label: TC.DATASET_DATA, content: dataTab, show: showDataTab },
        { code: PANELS_CODES.kpi, label: TC.POP_UP_FORM_PANEL_KPI, content: kpi_panel, show: show_kpi_tab },
    ], [displayForm, displayNotes, formFooter, dataTab, showDataTab, regTabContent, formContent, kpi_panel, show_kpi_tab, showRegTab, notesContent, remarquesContent]);

    const footer = React.useMemo(() => {
        let tab_footer = panels.find(p => p.code === tabKey && p.show)?.footer || null;
        if (!props.footer) return tab_footer;
        else {
            let extra_footer = null;
            if (typeof props.footer === "function") extra_footer = props.footer(onQuit);
            else extra_footer = props.footer;
            return <C.Flex justifyContent="end">
                <div className="me-2" children={extra_footer} />
                {tab_footer}
            </C.Flex>;
        }
    }, [onQuit, panels, tabKey, props]);

    const panelBar = React.useMemo(() => {
        let showPanels = panels.filter(p => p.show);
        if (showPanels.length === 0) return null;
        else if (showPanels.length === 1) return showPanels[0].content;
        return <BS.Tabs activeKey={tabKey} className="nav-fill" mountOnEnter onSelect={setTabKey}>
            {showPanels.map(p => <BS.Tab key={p.code} eventKey={p.code} title={lg.getStaticText(p.label)}>
                <div className="mt-3">
                    {p.content}
                </div>
            </BS.Tab>)}
        </BS.Tabs>
    }, [tabKey, panels, lg]);
    //#endregion

    const size = React.useMemo(() => panels.filter(p => p.code === tabKey)[0]?.size || "md", [panels, tabKey])

    return <M.BlankModal
        size={size}
        onQuit={onQuit}
        footer={footer}
        children={panelBar}
        maxBodyHeight="70vh"
        title={title || props.path}
        extra_header={sub_equip_context}
        only_extra_header={sub_equip_context !== null}
    />;
}

export default PopUpForms;