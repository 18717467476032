import React from "react";
import * as I from "../Input";
import * as IT from "../../Item";
import * as H from "../../../hooks";
import * as S from "../../../services";
import { Modal as M } from "../../../Components";
import { T, TC, TB, LANG } from "../../../Constants";

type Row = T.RegAction["status_list"][number];
type WorkingRow = Row & Record<"name_default", string>;
export type RegStatusesProps = I.InputProps;

const RegStatuses: React.FC<RegStatusesProps> = props => {
    const lg = H.useLanguage();
    const is_disabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    const working_rows = React.useMemo(() => {
        let value_row: Row[] = props.value || [{}];
        return value_row.map(r => ({ ...r, name_default: r?.lang?.[lg.prop] }));
    }, [props.value, lg.prop]);

    const set_labels = React.useCallback(() => {
        let properties: Row[] = props.value || [];
        let rows = properties.map(p => ({ ...p.lang, code: p.code, color: p.color }));

        let modal_ref: IT.QuickInputProps2<typeof rows[number]>["modal_ref"] = { current: null };
        let columns: IT.QuickInputProps2<typeof rows[number]>["columns"] = LANG.ALL_LANGUAGES.map(l => ({ type: "text", prop: l.prop, label: l.lg }));

        const auto_translate = () => {
            let values = modal_ref.current?.get() || [];
            let complete_lang = LANG.ALL_PROPS.find(l => values.every(v => typeof v[l] === "string" && v[l].trim().length > 0));
            // No lang was had a translation for all rows, notify user
            if (!complete_lang) M.renderAlert({ type: "warning", message: TC.AUTO_TRANSLATE_NO_FULL_BASE });
            else {
                const unmount = M.renderLoader();
                let texts = values.map(v => v[complete_lang]);
                S.translateManyText({ texts, lang: complete_lang }).then(({ data }) => {
                    let new_rows = values.map((value, index) => {
                        let result = data[index];
                        let clean_value = Object.fromEntries(
                            Object.entries(value).filter(([lg_prop, text]) => typeof text === "string" && text.trim().length > 0)
                        );
                        if (result === "failed") return value;
                        else return { ...result, ...clean_value } as any;
                    });
                    modal_ref.current?.set(new_rows);
                })
                    .catch(M.Alerts.loadError)
                    .finally(unmount);
            }
        };

        let footer = <IT.Button variant="info" icon="robot" text={TC.AUTO_TRANSLATE} onClick={auto_translate} />;

        M.renderQuickInput<typeof rows[number]>({ columns, gel_rows: true, rows, modal_ref, footer, modal: { size: "lg", title: TC.FORM_REG_STATUS_TRANSLATOR } }).then(labels => {
            if (labels) {
                let new_rows: Row[] = labels.map(l => {
                    let lang: Partial<Row["lang"]> = Object.fromEntries(
                        LANG.ALL_LANGUAGES.map(lg => [lg.prop, l[lg.prop] || ""])
                    );
                    return { color: l.color, code: l.code, lang: lang as Row["lang"] };
                });
                props.onChange?.(new_rows);
            }
        });
    }, [props]);

    const check_errors = React.useCallback<IT.QuickInputProps2<WorkingRow>["onCheck"]>(rows => {
        let errors: ReturnType<IT.QuickInputProps2<WorkingRow>["onCheck"]> = rows.map((row, index) => {
            let error: typeof errors[number] = {};
            // Invalid color
            if (!TB.isColor(row.color)) error.color = TC.GLOBAL_REQUIRED_FIELD;
            // Invalid name for the current language
            if (!row.name_default) error.name_default = TC.GLOBAL_REQUIRED_FIELD;
            // Missing translations
            else if (!LANG.ALL_LANGUAGES.every(l => row.lang[l.prop])) error.name_default = TC.FORM_REG_STATUS_MISSING_TRANSLATION;
            // Invalid code
            if (!row.code) error.code = TC.GLOBAL_REQUIRED_FIELD;
            // Duplicate code
            else if (rows.some((r, i) => r.code === row.code && i !== index)) error.code = TC.FORM_REG_STATUS_CODE_DUPLICATE;
            return error;
        });

        return errors;
    }, []);

    const submit_rows = React.useCallback<IT.QuickInputProps2<WorkingRow>["onSubmit"]>(rows => {
        let new_rows: Row[] = rows.map(r => ({ ...r, name_default: undefined, lang: { ...r.lang, [lg.prop]: r.name_default } }));
        props.onChange?.(new_rows);
    }, [props, lg.prop]);

    const columns = React.useMemo<IT.QuickInputProps2<Row>["columns"]>(() => [
        {
            type: "text",
            prop: "code",
            hideLabel: true,
            noBottomMargin: true,
            label: TC.FORM_REG_STATUS_CODE,
            tip_props: { icon: "question-circle", children: TC.FORM_REG_STATUS_CODE_TIP },
        },
        {
            type: "text",
            required: true,
            hideLabel: true,
            prop: "name_default",
            noBottomMargin: true,
            label: TC.FORM_REG_STATUS_NAME,
            suffix: { size: "sm", variant: "info", icon: "language", onClick: set_labels },
        },
        { prop: "color", type: "color", label: TC.FORM_REG_STATUS_COLOR, hideLabel: true, noBottomMargin: true },
    ], [set_labels]);

    return <I.ComponentWrapper {...props} disabled={is_disabled}>
        <IT.QuickInput2
            controlled
            no_init_focus
            columns={columns}
            rows={working_rows}
            onCheck={check_errors}
            onSubmit={submit_rows}
        />
    </I.ComponentWrapper>
}

export default RegStatuses;