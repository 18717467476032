import React from "react";
import { Flex } from "../Layout";
import { TB } from "../../Constants";
import { Button, OverlayTrigger, Popover, Row, Col } from "react-bootstrap";

type ImgOptions = {
    src: string;
    value: string;
    title?: string;
}

type ImgSelectProps = {
    disabled?: boolean;
    multiple?: boolean;
    defaultValue?: string;
    options: ImgOptions[];
    addNewImage?: () => void;
    selected?: string | string[];
    onSelect: (value?: string) => void;
}

const ImgSelect: React.FC<ImgSelectProps> = ({ options, defaultValue, selected, multiple = false, onSelect, addNewImage, ...props }) => {
    const optionsArray = React.useMemo(() => Array.isArray(options) ? options : [], [options]);

    const selectedItem = React.useMemo(() => {
        if (multiple || !TB.validString(selected)) return null;
        return optionsArray.filter(x => x?.value === selected)[0] ?? null;
    }, [optionsArray, multiple, selected]);

    const popOver = React.useMemo(() => <Popover className="container-lg " style={{ maxWidth: "250px", minHeight: "50px", zIndex: 10000 }}>
        <Row style={{ maxHeight: "35vh", overflowY: "auto" }}>
            {optionsArray.map(({ src, title, value }) => <Col key={value} className="p-1">
                <div onClick={() => onSelect?.(value)} className="border rounded text-center hover_select">
                    <ImgCard src={src} title={title} />
                </div>
            </Col>)}

            {typeof addNewImage === "function" && <Col className="p-1" onClick={() => addNewImage?.()}>
                <Flex className="border rounded text-center hover_select bg-emerald h-100" alignItems="center" justifyContent="center">
                    <i className="fa fa-plus my-auto text-white" style={{ fontSize: "2rem" }}></i>
                </Flex>
            </Col>}

        </Row>
    </Popover>, [optionsArray, onSelect, addNewImage]);

    React.useEffect(() => {
        if (!multiple && TB.validString(defaultValue)) {
            if ((!TB.validString(selected) || selectedItem === null) && defaultValue !== selected) onSelect?.(defaultValue);
        }
    }, [multiple, defaultValue, selected, selectedItem, onSelect]);

    if (multiple) return <MultipleImgSelector />

    return <Flex>
        <div className="border rounded me-1">
            <ImgCard src={selectedItem?.src} title={selectedItem?.title} />
        </div>
        {!props.disabled && <OverlayTrigger trigger="focus" placement="right" overlay={popOver}>
            <Button size="sm" className="px-2">
                <i className="fa fa-chevron-down" style={{ fontSize: "0.6rem" }}></i>
            </Button>
        </OverlayTrigger>}
    </Flex>
}

export default ImgSelect;

const MultipleImgSelector = ({ ...props }) => <div>TODO</div>;

type ImgCardProps = { src?: string, title?: string };
const ImgCardStyle = { height: "75px", width: "75px" };
const ImgCard: React.FC<ImgCardProps> = ({ src, title, ...props }) => <img {...{ src, title }} className="p-2 bg-white" alt="" style={{ ...ImgCardStyle, objectFit: "contain" }} />