import _ from "lodash";
import React from "react";
import './Annotations.scss';
import * as M from "../Modal";
import { Form } from "../Form";
import * as H from "../../hooks";
import { toPng } from "html-to-image";
import { Dialog } from "@material-ui/core";
import PolygonSelector from "./PolygonType";
import DrawingSelector from "./DrawingType";
import Annotation from "react-image-annotation";
import { TB, LT, FP, TC, T } from "../../Constants";
import * as US from "../../services/user.service";
import ImgUploader from "../FileUploader/ImgUploader";
import { editorStyle, renderHighlight, selectorRender } from "./SelectorRender";
import { PointSelector, RectangleSelector } from "react-image-annotation/lib/selectors";

const Annotations = ({ close, locations = [], defaultPlans, offerWiderOrigins = false, allowVentsEdit = false, ventsMeasure, setVents, onSave, ...props }) => {
    //#region State
    const [plans, setPlans] = React.useState(Array.isArray(defaultPlans) ? defaultPlans : []);
    const [pageActive, setPageActive] = React.useState(0);
    const [annotations, setAnnotations] = React.useState([]);
    const [type, setType] = React.useState(PointSelector.TYPE);
    const [annotation, setAnnotation] = React.useState<Record<string, any>>({});

    // Db Data
    const [forms, setForms] = React.useState<T.FormType[]>();
    const [descendants, setDescendants] = React.useState<T.Submission[]>();
    const [linkTypes, setLinkTypes] = React.useState<T.Submission<T.LinkTypeData>[]>();

    // Temp for Upload
    const [tempName, setTempName] = React.useState("");
    const [tempBase64, setTempBase64] = React.useState();
    const [tempLocation, setTempLocation] = React.useState();

    // If it is needed to ask for a save
    const [askForSave, setAskForSave] = React.useState(false);

    // Redux
    const lg = H.useLanguage();

    const loaderContainer = React.useRef(null);
    const successContainer = React.useRef(null);
    const confirmContainer = React.useRef(null);
    const ventPopUpContainer = React.useRef(null);
    const annotContainer = React.useRef<any>(null);
    //#endregion

    //#region Forms
    const formsPath = React.useMemo(() => [FP.EMPLACEMENT_FORM, FP.EQUIPEMENT_FORM, FP.LINK_TYPE_FORM], []);

    const empFormId = React.useMemo(() => forms?.filter?.(({ path }) => path === FP.EMPLACEMENT_FORM)?.[0]?._id, [forms]);
    const equipFormId = React.useMemo(() => forms?.filter?.(({ path }) => path === FP.EQUIPEMENT_FORM)?.[0]?._id, [forms]);
    const linkTypeFormId = React.useMemo(() => forms?.filter?.(({ path }) => path === FP.LINK_TYPE_FORM)?.[0]?._id, [forms]);

    React.useEffect(() => {
        const getForms = async () => {
            let reply = await US.getFormsFromFilter({ path: formsPath });
            return Array.isArray(reply.data) ? reply.data : null;
        }

        getForms().then(setForms);
    }, [formsPath]);

    React.useEffect(() => {
        const getMissingForms = async () => {
            let knownFormIds = forms.map(({ _id }) => _id);
            let missingIds = _.uniq(descendants.filter(({ form }) => !knownFormIds.includes(form)).map(({ form }) => form)).filter(TB.mongoIdValidator);
            if (missingIds.length === 0) return forms;
            let reply = await US.getFormsFromFilter({ _id: missingIds });
            return Array.isArray(reply.data) ? forms.concat(reply.data) : forms;
        }
        if ([descendants, forms].every(Array.isArray)) getMissingForms().then(setForms);
    }, [descendants, forms]);
    //#endregion

    //#region Link Types
    const linkTypesNames = React.useMemo(() => [LT.LINK_TYPE_OWN], []);
    const ownTypeLink = React.useMemo(() => linkTypes?.filter?.(({ data }) => data?.type === LT.LINK_TYPE_OWN)?.[0]?._id, [linkTypes]);

    React.useEffect(() => {
        const getLinkTypes = async () => {
            let reply = await US.getManySubmissionsFromFilter({ form: linkTypeFormId, "data.type": linkTypesNames });
            return Array.isArray(reply.data) ? reply.data : null;
        }
        if (TB.mongoIdValidator(linkTypeFormId)) getLinkTypes().then(setLinkTypes);
    }, [linkTypeFormId, linkTypesNames]);
    //#endregion

    //#region Current Plan
    const currentPlan = React.useMemo(() => plans?.[pageActive], [plans, pageActive]);
    const currentLocation = React.useMemo(() => currentPlan?.location, [currentPlan]);
    const currentImage = React.useMemo(() => currentPlan?.src || "", [currentPlan]);
    const currentPlanName = React.useMemo(() => typeof currentPlan?.name === "string" && currentPlan.name.length > 0 ? currentPlan.name : lg.getStaticText(TC.ANNOT_DEFAULT_PLAN), [currentPlan, lg]);
    //#endregion

    //#region Descendance
    const emplacements = React.useMemo(() => descendants?.filter?.(({ form }) => form === empFormId) || [], [descendants, empFormId]);
    const equipments = React.useMemo(() => descendants?.filter?.(({ form }) => form === equipFormId) || [], [descendants, equipFormId]);

    const descendanceOptions = React.useMemo(() => {
        let groups;
        if (!offerWiderOrigins) {
            if ([emplacements, equipments].every(x => x.length === 0)) return [];
            if ([emplacements, equipments].some(x => x.length === 0)) return (emplacements.length === 0 ? equipments : emplacements).map(({ _id, data }) => <option key={_id} value={_id}>{data?.name}</option>);
            groups = [
                { label: lg.getStaticText(TC.GLOBAL_LABEL_EQUIPMENT), values: equipments },
                { label: lg.getStaticText(TC.GLOBAL_LABEL_EMPLACEMENT), values: emplacements }
            ];
        }
        else if ([descendants, forms].every(Array.isArray)) groups = Object.entries(_.groupBy(descendants, "form")).map(([formId, values]) => ({
            values,
            label: forms.filter(({ _id }) => _id === formId)?.[0]?.title ?? "",
        }));
        else return [];

        return groups.filter(({ values }) => values.length > 0).map(({ label, values }, i) => <optgroup key={i} label={label}>
            {values.map(({ _id, data }) => <option key={_id} value={_id}>{data?.name}</option>)}
        </optgroup>);
    }, [emplacements, equipments, lg, descendants, forms, offerWiderOrigins]);

    React.useEffect(() => {
        const getDescendance = async () => {
            let reply = await US.advancedSearch(currentLocation, undefined, false, ownTypeLink, undefined, false);
            return Array.isArray(reply.data) ? reply.data : null;
        }
        if (TB.multiMongoIdValidator([currentLocation, ownTypeLink])) getDescendance().then(setDescendants);
        else setDescendants([]);
    }, [currentLocation, ownTypeLink]);
    //#endregion

    //#region Selectors
    const selectorsLabelled = React.useMemo(() => [
        { label: lg.getStaticText(TC.ANNOT_POINT_SELECTOR), s: PointSelector },
        { label: lg.getStaticText(TC.ANNOT_RECTANGLE_SELECTOR), s: RectangleSelector },
        { label: lg.getStaticText(TC.ANNOT_POLYGON_SELECTOR), s: PolygonSelector },
        { label: lg.getStaticText(TC.ANNOT_DRAW_SELECTOR), s: DrawingSelector },
    ], [lg]);

    const selectors = React.useMemo(() => selectorsLabelled.map(({ s }) => s), [selectorsLabelled]);

    const typeSelect = React.useMemo(() => <div className="form-floating">
        <select className="form-select" id="typeSelectAnnot" value={type} onChange={e => setType(e.target.value)}>
            {selectorsLabelled.map(({ label, s }) => <option value={s.TYPE} key={s.TYPE}>{label}</option>)}
        </select>
        <label htmlFor="typeSelectAnnot">{lg.getStaticText(TC.ANNOT_FORM_TYPE)}</label>
    </div>, [type, selectorsLabelled, lg]);
    //#endregion

    //#region Polygon Selector Specials
    const isTypePolygon = React.useMemo(() => type === PolygonSelector.TYPE, [type]);
    const enablePolygonSubmit = React.useMemo(() => isTypePolygon && annotation?.geometry?.points?.length > 2, [isTypePolygon, annotation]);
    const enablePolygonClearUndo = React.useMemo(() => isTypePolygon && annotation?.geometry?.points?.length > 0, [isTypePolygon, annotation]);

    React.useEffect(() => isTypePolygon ? console.log(annotation) : undefined, [isTypePolygon, annotation])

    const polygonTools = React.useMemo(() => !isTypePolygon ? undefined : <div className="polygonGrouping">
        <label>{lg.getStaticText(TC.ANNOT_POLYGON_ACTIONS)}</label>
        <div className="btn-group polygonGroup" role="group">
            <button className="btn btn-primary" disabled={!enablePolygonSubmit} onClick={() => setAnnotation(prev => PolygonSelector.methods.onSelectionComplete(prev))}>{lg.getStaticText(TC.ANNOT_COMPLETE_POLYGON)}</button>
            <button className="btn btn-primary" disabled={!enablePolygonClearUndo} onClick={() => setAnnotation(prev => PolygonSelector.methods.onSelectionUndo(prev))}>{lg.getStaticText(TC.GLOBAL_CANCEL)}</button>
            <button className="btn btn-danger" disabled={!enablePolygonClearUndo} onClick={() => setAnnotation({})}>{lg.getStaticText(TC.DIA_REINITIALIZE)}</button>
        </div>
    </div>, [isTypePolygon, enablePolygonSubmit, enablePolygonClearUndo, lg]);
    //#endregion

    //#region Data changes
    const onSubmit = React.useCallback((annotation) => {
        const { geometry, data } = annotation

        setAnnotation({});
        setAnnotations(prev => prev.concat({
            geometry,
            data: {
                ...data,
                id: Math.random()
            }
        }));
        setAskForSave(true);
    }, []);

    const onChangeExisting = React.useCallback(annotation => {
        let id = annotation?.data?.id;
        if (id) {

            setAnnotations(prev => prev.map(a => {
                if (a.data.id === id) return annotation;
                return a;
            }));
            setAskForSave(true);
        }
    }, []);

    const onChange = React.useCallback(setAnnotation, [setAnnotation]);
    React.useEffect(() => setAnnotation({}), [type]);
    //#endregion

    //#region Vent Detail Measure
    const currentVentMeasure = React.useMemo(() => {
        if (!Array.isArray(ventsMeasure)) return null;
        return ventsMeasure.filter(({ obj }) => obj === currentLocation)?.[0];
    }, [currentLocation, ventsMeasure]);

    const equipFilter = React.useMemo(() => "_id__in=" + equipments.map(({ _id }) => _id).join(), [equipments]);
    const showVentOptions = React.useMemo(() => allowVentsEdit && typeof setVents === "function", [allowVentsEdit, setVents]);

    const doesVentExists = React.useCallback(originId => {
        // No origin selected, no measure allowed
        if (!TB.mongoIdValidator(originId)) return null;
        let equip = equipments.filter(({ _id }) => _id === originId)?.[0]?._id;
        // Origin is an equipment, send back existing measure or undefined
        if (TB.mongoIdValidator(equip)) return currentVentMeasure?.vents?.vents?.filter?.(({ equipment }) => equipment === equip)?.[0];
        return null;
    }, [currentVentMeasure, equipments]);

    const editVentMeasure = React.useCallback((originId, measure) => {
        let submission: any = { equipmentAvailable: equipFilter };
        if (measure === undefined) submission.equipment = originId;
        else submission = Object.assign(submission, measure);

        let ventData = M.renderBlankModal<any>({
            size: "lg",
            title: "Vent measure",
            renderContent: resolve => <Form
                noDbSaving
                onSave={resolve}
                path={FP.VENT_DETAIL_PATH}
                forcedSubmission={TB.submissionToArrayUpdate(submission)}
            />
        });

        ventData.then(form => {
            // The form wasn't closed
            if (form !== null && TB.mongoIdValidator(form?.data?.equipment)) setVents?.(ventMeasures => {
                // Had nothing beforehand
                if (!Array.isArray(ventMeasures)) return [{ obj: currentLocation, vents: { total: {}, vents: [form.data] } }];

                // If the current Location didn't exists before
                if (ventMeasures.filter(({ obj }) => obj === currentLocation).length === 0) return ventMeasures.concat({
                    obj: currentLocation,
                    vents: { total: {}, vents: [form.data] }
                });

                // If the current location existed before
                return ventMeasures.map(({ obj, vents }) => {
                    if (obj !== currentLocation) return { obj, vents };

                    // If no vents measure before
                    if (!Array.isArray(vents?.vents)) return { obj, vents: { total: vents?.total || {}, vents: [form.data] } };

                    let updatedVent = [];

                    // That equipment didn't have an entry yet
                    if (vents.vents.filter(({ equipment }) => equipment === form?.data?.equipment).length === 0) updatedVent = vents.vents.concat(form.data);
                    else updatedVent = vents.vents.map(({ equipment, ...r }) => {
                        // Not the equipment we are looking for
                        if (equipment !== form?.data?.equipment) return { equipment, ...r };
                        // The equipment we are looking for
                        return form.data;
                    });

                    return { obj, vents: { total: vents?.total || {}, vents: updatedVent } };
                });
            });
        });
    }, [equipFilter, currentLocation, setVents]);
    //#endregion

    //#region Custom Components
    const validName = React.useCallback(name => typeof name === "string" && name.length > 0, []);

    React.useEffect(() => {
        let duplicates = Object.entries(_.groupBy(annotations, ({ data }) => data.text))
            .filter(([key, array]) => typeof key === "string" && key.length > 0 && array.length > 1)
            .map(([key, array]) => array);

        if (duplicates.length === 0) return;

        let textTransformed = annotations.map(({ data, ...a }) => ({ ...a, data: { ...data, text: data.text.replace(/\(\d+\)/g, "") } }))
        let duplicatesSimilar = Object.entries(_.groupBy(textTransformed, ({ data }) => data.text))
            .filter(([key, array]) => typeof key === "string" && key.length > 0 && array.length > 1)
            .map(([key, array]) => array);

        // Rename the duplicates
        let transformed = _.flatten(duplicatesSimilar.map(array => array.map(({ data }, i) => [data.id, (i === 0 ? data.text : `${data.text}(${i})`)])));
        let newNameObjects = Object.fromEntries(transformed);

        setAnnotations(prev => prev.map(a => {
            let val = newNameObjects?.[a?.data?.id];
            if (val !== undefined) a.data.text = val;
            return a;
        }));
    }, [annotations]);

    const descendanceSelect = React.useCallback((val, setter, id = undefined) => descendanceOptions.length === 0 ? undefined : <div className="form-floating">
        <select className="form-select valInput" value={val} onChange={setter}>
            <option></option>
            {descendanceOptions}
        </select>
        <label>{lg.getStaticText(TC.ANNOT_ORIGIN)}</label>
    </div>, [descendanceOptions, lg]);

    const itemForm = React.useCallback(({ geometry, selection, data = {} as any }, onChange, addMeasure = false) => {
        let { text, color, origin, ...other } = data;
        let ventMeasure = showVentOptions ? doesVentExists(origin) : null;

        return <div className="editorContent">
            <div className="form-floating valInput">
                <input autoFocus autoComplete="off" className="form-control" value={text || ""} id="annotName" placeholder="name" onChange={e => onChange({ geometry, selection, data: { ...data, ...other, text: e.target.value } })} />
                <label htmlFor="annotNam">{lg.getStaticText(TC.GLOBAL_NAME)}</label>
            </div>

            <div className="form-floating valInput">
                <input type="color" className="form-control" id="exampleColorInput" value={color || "#FFFFFF"} onChange={e => onChange({ geometry, selection, data: { ...data, ...other, color: e.target.value } })} />
                <label htmlFor="exampleColorInput">{lg.getStaticText(TC.GLOBAL_COLOR)}</label>
            </div>

            {descendanceSelect(origin, e => onChange({ geometry, selection, data: { ...data, ...other, origin: e.target.value } }))}

            {ventMeasure !== null && <div className="d-grid gap-2 mt-2">
                <button onClick={() => editVentMeasure(origin, ventMeasure)} className="btn btn-primary">
                    <i className={`fa fa-${ventMeasure === undefined ? "plus" : "pen"} mr-2`}></i>
                    {ventMeasure === undefined ? lg.getStaticText(TC.ANNOT_ADD_MEASURE) : lg.getStaticText(TC.ANNOT_EDIT_MEASURE)}</button>
            </div>}
        </div>;
    }, [showVentOptions, lg, descendanceSelect, doesVentExists, editVentMeasure]);

    const renderEditor = React.useCallback(({ annotation, onChange, onSubmit }) => {
        let { geometry, data } = annotation;
        if (typeof data !== "object" || data === null) data = {};
        let { text } = data;

        return <div className="editorWrapper" style={editorStyle(geometry)}>
            {itemForm(annotation, onChange)}
            {validName(text) && <div className="editorSubmit">
                <button onClick={() => onSubmit({ geometry, data: { ...data } })}>{lg.getStaticText(TC.ANNOT_SUBMIT).toUpperCase()}</button>
            </div>}
        </div>
    }, [lg, itemForm, validName]);
    //#endregion

    //#region Annotation Recap
    const toggleAnnotation = React.useCallback(id => {
        setAnnotations(prevState => prevState.map(a => {
            if (a?.data?.id === id) return { ...a, data: { ...a.data, hidden: !a.data.hidden } };
            return a;
        }));
        setAskForSave(true);
    }, []);

    const openAnnotation = React.useCallback(id => setAnnotations(prevState => prevState.map(a => {
        if (a?.data?.id === id) return { ...a, data: { ...a.data, open: !a.data.open } };
        return a;
    })), []);

    const deleteAnnotation = React.useCallback(id => {
        M.askConfirm({ title: TC.DELETE, text: TC.GLOBAL_CONFIRM_DELETE }).then(doDelete => {
            if (doDelete) {
                setAnnotations(prevState => prevState.filter(({ data }) => data.id !== id));
                setAskForSave(true);
            }
        });
    }, []);

    const activeAnnotation = React.useMemo(() => annotations.filter(({ data }) => !data?.hidden), [annotations]);
    const activeAnnotationIds = React.useMemo(() => activeAnnotation.map(({ data }) => data?.id), [activeAnnotation]);

    const openedAnnotationIds = React.useMemo(() => annotations.filter(({ data }) => data?.open).map(({ data }) => data.id), [annotations]);

    const annotationsRecap = React.useMemo(() => annotations.length === 0 ? undefined : <div className="annotationList">
        <label>{lg.getStaticText(TC.ANNOT_NAME)}</label>
        {annotations.map(({ data, ...annot }) => <div className="annotationItem" key={data.id}>
            <div className="annot_overview input-group">
                <div className="input-group-text">
                    <input type="checkbox" checked={activeAnnotationIds.includes(data.id)} onChange={() => toggleAnnotation(data.id)} />
                </div>
                <span className="input-group-text nameInput">{data.text}</span>
                <button className="btn btn-outline-primary" onClick={() => openAnnotation(data.id)}>
                    <i className={`fa fa-chevron-${openedAnnotationIds.includes(data.id) ? "up" : "down"}`}></i>
                </button>
                <button className="btn btn-outline-danger" onClick={() => deleteAnnotation(data.id)}><i className="fa fa-trash"></i></button>
            </div>
            {openedAnnotationIds.includes(data.id) && <div className="annot_edit">
                {itemForm({ data, ...annot }, onChangeExisting)}
            </div>}
        </div>)}
    </div>, [annotations, activeAnnotationIds, openedAnnotationIds, lg, toggleAnnotation, openAnnotation, deleteAnnotation, itemForm, onChangeExisting]);
    //#endregion

    //#region Saving
    const uploadTabIndex = React.useMemo(() => plans.length, [plans]);

    const saveSuccessPanel = React.useMemo(() => <div className="alert alert-success" role="alert">
        <i className="fa fa-check-circle"></i> {lg.getStaticText(TC.NOTIF_SAVE_SUCCESS)}
    </div>, [lg]);

    const loader = React.useMemo(() => <div className="loader_bg">
        <span>{lg.getStaticText(TC.ANNOT_SAVING)}...</span>
        <div className="loader"></div>
    </div>, [lg]);

    const savePlans = React.useCallback(async () => {
        if (pageActive !== uploadTabIndex) {
            let node = annotContainer.current?.children?.[0];

            if (node instanceof Element || node instanceof Document) {
                let unmount = M.renderLoader();
                let img = await toPng(node as any);
                unmount();
                onSave(plans.map((plan, i) => {
                    if (i === pageActive) return { ...plan, planImg: img, annotations };
                    return plan;
                }));
                setAskForSave(false);

                // Success Notification
                M.renderAlert({ message: TC.NOTIF_SAVE_SUCCESS, delay: 10 });
            }
        }
    }, [pageActive, uploadTabIndex, saveSuccessPanel, loader, plans, annotations, onSave]);
    //#endregion

    //#region Header
    const closePopUp = React.useCallback(() => {
        if (!askForSave) close?.();
        else M.askConfirm({ title: TC.GLOBAL_QUIT, noText: TC.ANNOT_NO_SAVE, text: TC.GLOBAL_SAVE_BEFORE_QUIT }).then(async save => {
            if (save !== null) {
                if (save) await savePlans();
                close?.();
            }
        });
    }, [close, askForSave, savePlans]);

    const changeTab = React.useCallback(async newPage => {
        // Update the plans we were working on
        if (pageActive !== uploadTabIndex && pageActive !== newPage) {
            let unmount = M.renderLoader();
            let img = await toPng(annotContainer.current);
            unmount();
            setPlans(prev => prev.map((plan, i) => {
                if (i === pageActive) return { ...plan, planImg: img, annotations };
                return plan;
            }));
        }

        setPageActive(newPage);
    }, [pageActive, uploadTabIndex, annotations, loader]);

    const plansTabs = React.useMemo(() => plans.map(({ name }, i) => <li key={i} className="nav-item">
        <button onClick={() => changeTab(i)} className={"nav-link " + (pageActive === i ? 'active' : '')}>{name}</button>
    </li>), [plans, pageActive, changeTab]);

    const uploadTab = React.useMemo(() => <li className="nav-item">
        <button className={"nav-link " + (pageActive === uploadTabIndex ? "active" : "")} onClick={() => changeTab(uploadTabIndex)}>
            <i className="fa fa-plus mr-2"></i>
            {lg.getStaticText(TC.GLOBAL_NEW)}
        </button>
    </li>, [uploadTabIndex, pageActive, lg, changeTab]);

    const navBar = React.useMemo(() => <div className="full_header">
        <div className="nav_header">
            <ul className="nav nav-tabs nav-justified">
                {plansTabs}
                {uploadTab}
            </ul>
        </div>
        <button onClick={closePopUp} className="quitButton"><i className="fa fa-times"></i></button>
    </div>, [plansTabs, uploadTab, closePopUp]);
    //#endregion

    //#region Plan Edition
    const deletePlan = React.useCallback(planName => {
        M.askConfirm({ title: TC.DELETE, text: TC.GLOBAL_CONFIRM_DELETE }).then(doDelete => {
            if (doDelete) {
                setPlans(prev => prev.filter(({ name }) => name !== planName));
                setAskForSave(true);
            }
        });
    }, []);

    const plansNameList = React.useMemo(() => plans?.map?.(({ name }) => name), [plans]);

    const validPrompt = React.useMemo(() => str => {
        if (str === currentPlanName) return true;
        if (plansNameList.includes(str)) return { isValid: false, message: lg.getStaticText(TC.ANNOT_NAME_USED) };
        return true;
    }, [plansNameList, currentPlanName, lg]);

    const renamePlan = React.useCallback(planName => {
        M.askPrompt({ isRequired: true, defaultVal: planName, label: TC.GLOBAL_NAME, valChecker: validPrompt })
            .then(val => {
                if (typeof val === "string") {
                    setAskForSave(true);
                    setPlans(prev => prev.map(plan => {
                        if (plan.name === planName) return { ...plan, name: val, annotations };
                        return plan;
                    }));
                }
            });
    }, [annotations, validPrompt]);

    const editPlanPanel = React.useMemo(() => <div className="btn-group mb-2" style={{ width: "100%" }}>
        <button className="btn btn-primary" onClick={() => renamePlan(currentPlanName)}>{lg.getStaticText(TC.GLOBAL_RENAME)}</button>
        <button className="btn btn-danger" onClick={() => deletePlan(currentPlanName)}><i className="fa fa-trash mr-2"></i>{lg.getStaticText(TC.GLOBAL_DELETE)}</button>
    </div>, [lg, currentPlanName, deletePlan, renamePlan]);
    //#endregion

    //#region ToolBar
    const hasError = React.useMemo(() => [descendants, forms].includes(null), [descendants, forms]);

    const errorPanel = React.useMemo(() => !hasError ? undefined : <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> {lg.getStaticText(TC.NOTIF_DIA_ERR_UPDATE_FETCH)}
    </div>, [hasError, lg]);

    const downloadImage = React.useCallback(() => {
        let node = annotContainer.current?.children?.[0];
        if (node instanceof Element || node instanceof Document) {

            let unmount = M.renderLoader();
            toPng(node as any)
                .then(res => TB.downloadPNG(res, currentPlanName))
                .catch(M.Alerts.loadError)
                .finally(unmount);
        }
    }, [currentPlanName, loader]);

    const toolbar = React.useMemo(() => <div className="toolBar">
        <div className="main_tools">
            {editPlanPanel}
            <div ref={successContainer as any}></div>
            {errorPanel}
            {typeSelect}
            {polygonTools}
            {annotationsRecap}
        </div>
        <div className="close_menu">
            <button className="btn btn-primary" disabled={!askForSave} onClick={savePlans} >{lg.getStaticText(TC.GLOBAL_SAVE)}</button>
            <button className="btn btn-primary" onClick={downloadImage} ><i className="fa fa-file-download mr-2"></i>{lg.getStaticText(TC.GLOBAL_DOWNLOAD)}</button>
            <button className="btn btn-danger" onClick={closePopUp}>{lg.getStaticText(TC.GLOBAL_QUIT)}</button>
        </div>
    </div>, [typeSelect, editPlanPanel, errorPanel, annotationsRecap, askForSave, polygonTools, lg, downloadImage, closePopUp, savePlans]);
    //#endregion

    //#region Update
    React.useEffect(() => setAnnotation({}), [pageActive]);
    React.useEffect(() => setAnnotations(Array.isArray(currentPlan?.annotations) ? currentPlan?.annotations : []), [currentPlan]);
    //#endregion

    //#region Body
    const showUpload = React.useMemo(() => pageActive === plans.length, [pageActive, plans]);
    const allowConfirm = React.useMemo(() => [tempName, tempBase64].every(x => typeof x === "string" && x.length > 0), [tempName, tempBase64]);

    const addPlan = React.useCallback(() => {
        let newPlans = plans.concat({ src: tempBase64, name: tempName, location: tempLocation });
        setAskForSave(true);
        setPlans(newPlans);
        setPageActive(newPlans.length - 1);
        setTempBase64(undefined);
        setTempLocation(undefined);
        setTempName('');
    }, [plans, tempBase64, tempName, tempLocation]);

    const locationsOptions = React.useMemo(() => (Array.isArray(locations) ? locations : []).map(({ _id, data }) => <option key={_id} value={_id}>{data?.name}</option>), [locations]);

    const locationSelect = React.useMemo(() => locationsOptions.length === 0 ? undefined : <div className="form-floating">
        <select value={tempLocation} onChange={e => setTempLocation(e.target.value as any)} className="form-select" id="locationSelectPlan">
            <option></option>
            {locationsOptions}
        </select>
        <label htmlFor="locationSelectPlan">{lg.getStaticText(TC.GLOBAL_LABEL_EMPLACEMENT)}</label>
    </div>, [locationsOptions, tempLocation, lg]);

    const uploadBody = React.useMemo(() => <>
        <div className="card uploadCard">
            <div className="card-body">
                <h5 className="card-title">{lg.getStaticText(TC.GLOBAL_UPLOAD_PLAN)}</h5>
                <div className="form-floating formInput mb-3">
                    <input value={tempName} id="floatingNamePlan" className="form-control" placeholder="name" onChange={e => setTempName(e.target.value)} />
                    <label htmlFor="floatingNamePlan">{lg.getStaticText(TC.GLOBAL_NAME_PLAN)}</label>
                </div>

                <div className="formInput mb-3">
                    <label htmlFor="filePlan" className="form-label">{lg.getStaticText(TC.ANNOT_DEFAULT_PLAN)}</label>
                    <ImgUploader
                        id="filePlan"
                        multiple={false}
                        setBase64={img => Array.isArray(img) && img.length > 0 ? setTempBase64(img[0]) : undefined}
                    />
                </div>

                {locationSelect}

                <div className="form-confirm">
                    <button disabled={!allowConfirm} onClick={addPlan} className="btn btn-primary">{lg.getStaticText(TC.GLOBAL_CONFIRM)}</button>
                </div>
            </div>
        </div>
    </>, [tempName, allowConfirm, locationSelect, lg, addPlan]);

    const annotBody = React.useMemo(() => showUpload ? undefined : <div className="annotationWrapper">
        <div ref={annotContainer} className="annotationEditor">
            <Annotation
                alt="alt"
                type={type}
                src={currentImage}
                value={annotation}
                onChange={onChange}
                onSubmit={onSubmit}
                selectors={selectors}
                renderEditor={renderEditor}
                annotations={activeAnnotation}
                renderSelector={selectorRender}
                renderHighlight={renderHighlight}
            />
        </div>
        {toolbar}
    </div>, [activeAnnotation, annotation, selectors, currentImage, showUpload, toolbar, type, onChange, onSubmit, renderEditor]);

    const body = React.useMemo(() => showUpload ? uploadBody : annotBody, [annotBody, showUpload, uploadBody]);
    //#endregion

    return <Dialog open={true} fullScreen={true}>
        <div style={{ display: "grid", gridTemplateRows: "45px auto", height: "100%" }}>
            {navBar}
            <div className="annot_body position-relative">
                {body}
            </div>
        </div>
        <div className="loadContainer" ref={loaderContainer as any}></div>
        <div ref={confirmContainer as any}></div>
        <div ref={ventPopUpContainer as any}></div>
    </Dialog>
}

export default Annotations;