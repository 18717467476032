import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import Openings from "./OpeningsTable";
import { T, TB, TC } from "../../../Constants";
import { Training, TIME_GROUPS } from "./Configuration";

//#region Types
export type QualityProps = {
  /** Disable re-training, validation and edit of the model */
  is_report?: boolean;
  /** The results of the training */
  training: Training;
  /** Update a quality params */
  update_params?: React.Dispatch<React.SetStateAction<Training>>;
  /** Callback after validation of the model */
  on_validate?: (training: Training) => void;
  /** Update the footer in the parent component */
  set_footer?: (footer: React.ReactElement) => void;
};

type Period = T.Prediction["training"]["periods"][number];
type GenErrors = Record<string, T.Errors<T.Prediction["training"]["quality_params"][string]>> & T.Errors<Record<"from_date" | "to_date", string>>;
//#endregion

//#region Constants
const COLORS: Record<T.Prediction["training"]["periods"][number]["type"], string> = {
  normal: "#AAAAAA55",
  outlier: "#FF000055",
  special: "#FF8C0055",
};

const PERIOD_TYPES = [
  { label: TC.PRED_QUALITY_TYPE_OUTLIER, value: "outlier" },
  { label: TC.PRED_QUALITY_TYPE_NORMAL, value: "normal" },
  { label: TC.PRED_QUALITY_TYPE_SPECIAL, value: "special" },
] as T.Option<object, Period["type"]>[];
//#endregion

const Quality: React.FC<QualityProps> = ({ update_params, on_validate, ...props }) => {
  const lg = H.useLanguage();
  const was_edited = H.useBoolean(false);
  const [errors, set_errors] = React.useState<GenErrors>({});
  const [periods_errors, set_periods_errors] = React.useState<T.Errors<Period>[]>([]);
  const [periods, set_periods] = React.useState(props.training?.prediction?.training?.periods || []);

  //#region Update periods
  React.useEffect(() => {
    if (!props.is_report) set_periods(props.training.prediction.training.periods);
  }, [props.training.prediction.training.periods, props.is_report]);
  //#endregion

  //#region Data updates & validation
  const change = React.useMemo(() => ({
    add_period: () => {
      was_edited.setTrue();
      set_periods(p => {
        let last_period = p[p.length - 1];
        return p.concat({ type: "normal", from: "", to: last_period?.to || "" });
      });
    },
    remove_period: (index: number) => {
      was_edited.setTrue();
      set_periods(prev => prev.filter((p, i) => i !== index));
      set_periods_errors(prev => prev.filter((p, i) => i !== index));
    },
    set_period: (index: number, prop: keyof Period, value: any) => {
      was_edited.setTrue();
      set_periods(prev => prev.map((p, i) => {
        if (i !== index) return p;
        return { ...p, [prop]: value };
      }));
      set_periods_errors(prev => prev.map((er, i) => {
        if (i !== index) return er;
        if (er === null) return null;
        return { ...er, [prop]: undefined };
      }));
    },
    set_quality_param: (object_id: string, prop: keyof T.Prediction["training"]["quality_params"][string], value: any) => {
      was_edited.setTrue();
      update_params?.(prev => ({
        ...prev,
        prediction: {
          ...prev.prediction,
          training: {
            ...prev.prediction.training,
            quality_params: {
              ...prev.prediction.training.quality_params,
              [object_id]: {
                ...prev.prediction.training.quality_params[object_id],
                [prop]: value,
              }
            }
          }
        }
      }));
    },
    set_pred: (prop: keyof T.Prediction, value: any) => {
      // Set the prediction as updated, to force a re-training
      was_edited.setTrue();
      // Update the property
      update_params?.(prev => ({ ...prev, prediction: { ...prev.prediction, [prop]: value } }));
      // If the date was changed, remove previous periods completely
      if (prop === "from_date" || prop === "to_date") set_periods([]);
    },
  }), [update_params, was_edited]);

  const validate_quality = React.useCallback((is_final = false) => {
    let new_errors = {} as typeof errors,
      new_periods_errors = [] as typeof periods_errors,
      end_date = TB.getDate(props.training?.prediction?.to_date),
      start_date = TB.getDate(props.training?.prediction?.from_date);

    // Check if the periods are ok
    for (let i = 0; i < periods.length; i++) {
      let period = periods[i];
      let to = TB.getDate(period.to),
        from = TB.getDate(period.from),
        previous_to = TB.getDate(periods[i - 1]?.to),
        next_from = TB.getDate(periods[i + 1]?.from),
        p_errors = {} as typeof new_periods_errors[number];

      // No 'from' defined
      if (!from) p_errors.from = TC.GLOBAL_REQUIRED_FIELD;
      // No 'to' defined
      if (!to) p_errors.to = TC.GLOBAL_REQUIRED_FIELD;
      // No period type defined
      if (!period.type) p_errors.type = TC.GLOBAL_REQUIRED_FIELD;
      // 'from' is higher than 'to' / 'to' is lower than 'from'
      if (from && to && from.getTime() >= to.getTime()) {
        p_errors.to = TC.ERR_DATE_TO_LOWER_DATE_FROM;
        p_errors.from = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
      }
      else {
        // Check that the current 'from' is higher than the next 'to'
        if (from && previous_to && from.getTime() < previous_to.getTime()) p_errors.from = TC.PRED_PERIOD_OVERLAP_TO;
        // Check that the current 'to' is lower than the next 'from'
        if (to && next_from && to.getTime() > next_from.getTime()) p_errors.to = TC.PRED_PERIOD_OVERLAP_FROM
      }

      if (Object.keys(p_errors).length > 0) new_periods_errors.push(p_errors);
      else new_periods_errors.push(null);
    }
    // Check if the quality params are ok
    for (let [object_id, quality_params] of Object.entries(props.training.prediction.training.quality_params)) {
      let q_errors = {} as T.Errors<typeof quality_params>;
      const is_number = (num: any): num is number => typeof num === "number" && !isNaN(num);

      if (!is_number(quality_params.min)) q_errors.min = TC.GLOBAL_REQUIRED_FIELD;
      if (!is_number(quality_params.max)) q_errors.max = TC.GLOBAL_REQUIRED_FIELD;
      if (!is_number(quality_params.min_days_plateau)) q_errors.min_days_plateau = TC.GLOBAL_REQUIRED_FIELD;
      else if (quality_params.min_days_plateau < 0) q_errors.min_days_plateau = TC.GLOBAL_NUM_SUP_0;
      if (!is_number(quality_params.min_days_plateau_of_zeros)) q_errors.min_days_plateau_of_zeros = TC.GLOBAL_REQUIRED_FIELD;
      else if (quality_params.min_days_plateau_of_zeros < 0) q_errors.min_days_plateau_of_zeros = TC.GLOBAL_NUM_SUP_0;
      if (Object.keys(q_errors).length > 0) new_errors[object_id] = q_errors;
    }
    // No start date
    if (!end_date) new_errors.to_date = TC.GLOBAL_REQUIRED_FIELD;
    // No end date
    if (!start_date) new_errors.from_date = TC.GLOBAL_REQUIRED_FIELD;
    // Check that from date is before end date
    if (end_date && start_date && start_date.getTime() >= end_date.getTime()) {
      new_errors.to_date = TC.ERR_DATE_TO_LOWER_DATE_FROM;
      new_errors.from_date = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
    }

    // There are some errors
    if (new_periods_errors.some(e => e !== null) || Object.keys(new_errors).length > 0) {
      set_errors(new_errors);
      set_periods_errors(new_periods_errors);
    }
    // Retrained and update training
    else if (!is_final) {
      const unmount = M.renderLoader(TC.PRED_TRAINING_MODEL);
      S.trainModel({ ...props.training.prediction, training: { ...props.training.prediction.training, periods } })
        .then(({ data }) => {
          if (data.training === "failure") {
            M.Alerts.updateError(data.reason);
            update_params?.(p => ({ ...p, prediction: data.prediction }));
          }
          else {
            was_edited.setFalse();
            update_params?.({ prediction: data.prediction, graphs: data.graphs });
          }
        })
        .catch(M.Alerts.updateError)
        .finally(unmount);
    }
    // Validate model
    else {
      const unmount = M.renderLoader(TC.PRED_TRAINING_MODEL);
      S.validateModel(props.training.prediction._id)
        .then(({ data }) => on_validate?.(data))
        .catch(M.Alerts.updateError)
        .finally(unmount);
    }
  }, [periods, was_edited, props.training.prediction, update_params, on_validate]);
  //#endregion

  //#region Rendering & data formatting
  const KPIs = React.useMemo(() => {
    return Object.entries(props.training.prediction.training.kpi)
      .map(([key, value]) => ({ key, value: value.toFixed(3) }));
  }, [props.training.prediction.training.kpi]);

  const features_list = React.useMemo(() => {
    let features = Object.entries(props.training.graphs)
      // Remove the 'main' dataset from the list of features
      .filter(([object_id]) => object_id !== props.training.prediction.dataset)
      // Format the data
      .map(([object_id, graphs]) => ({ graphs, object_id }))
      // Show the thermal features first, then the weather features, the the extra datasets
      .sort((f1, f2) => {
        let score_f1 = 0, score_f2 = 0;
        // Mongo _id comes last
        if (TB.mongoIdValidator(f1.object_id)) score_f1 = 3;
        // If it can be parsed as JSON, then it's a weather feature
        else try {
          let weather_tags = JSON.parse(f1.object_id);
          if (weather_tags) score_f1 = 2;
        }
        catch (error) { score_f1 = 1 };
        // Do the same with the second feature, Mongo _id comes last
        if (TB.mongoIdValidator(f2.object_id)) score_f2 = 3;
        // If it can be parsed as JSON, then it's a weather feature
        else try {
          let weather_tags = JSON.parse(f2.object_id);
          if (weather_tags) score_f2 = 2;
        }
        catch (error) { score_f2 = 1 };
        return score_f1 - score_f2;
      });
    return features;
  }, [props.training.graphs, props.training.prediction.dataset]);

  const outliers_specials = React.useMemo(() => {
    let mark_area = { data: [] };
    let v_periods = props.training.prediction.training?.periods || [];
    for (let i = 0; i < v_periods.length; i++) {
      let period = v_periods[i];
      let color = COLORS[period.type];

      if (period.from && period.to) mark_area.data.push([
        {
          name: "P" + (i + 1),
          xAxis: new Date(period.from).getTime(),
          itemStyle: { color },
          emphasis: { itemStyle: { color: color.substring(0, color.length - 2) + "22" } },
        },
        { xAxis: new Date(period.to).getTime() },
      ]);
    }
    return mark_area;
  }, [props.training.prediction.training?.periods]);

  const thermal_graph = React.useMemo(() => {
    let data = props.training?.prediction?.training?.thermal_graph;
    let params = data?.piecewise_params;
    if (!data || !params) return null;

    let options = {
      tooltip: { trigger: "axis" },
      xAxis: { type: 'value', name: "Temperature (°C)" },
      yAxis: { type: 'value', name: "Electricity (KWh)" },
      series: [{ symbolSize: 3, data: data.temperature_target_list, type: 'scatter' }] as any[],
    };

    let temp_list = data.temperature_target_list.map(([temp]) => temp);
    let min_temperature = Math.min(...temp_list), max_temperature = Math.max(...temp_list);

    // There is a left slope
    if (params.left_slope) {
      let y0 = params.base_consumption + (params.temp_neutral_start - min_temperature) * (-1 * params.left_slope);
      options.series.push({
        type: "line",
        name: "Hot Slope",
        lineStyle: { color: "red" },
        data: [[min_temperature, y0], [params.temp_neutral_start, params.base_consumption]],
      });
    }

    // There is a right slope
    if (params.right_slope) {
      let yMax = params.base_consumption + (max_temperature - params.temp_neutral_end) * params.right_slope;
      options.series.push({
        type: "line",
        name: "Cold Slope",
        lineStyle: { color: "blue" },
        data: [[params.temp_neutral_end, params.base_consumption], [max_temperature, yMax]],
      });
    }

    let base_start = params.left_slope ? params.temp_neutral_start : min_temperature;
    let base_end = params.right_slope ? params.temp_neutral_end : max_temperature;

    // The baseline
    options.series.push({
      type: "line",
      name: "Base Consumption",
      lineStyle: { color: "green" },
      data: [[base_start, params.base_consumption], [base_end, params.base_consumption]]
    });

    return <div className="mb-3">
      <C.Title level={3} children={"Thermal Model"} />

      <div style={{ height: "300px" }}>
        <C.Echarts
          option={options}
          ref={ref => {
            let chart = ref?.getEchartsInstance?.();
            chart?.dispatchAction?.({
              type: 'takeGlobalCursor',
              key: 'dataZoomSelect',
              dataZoomSelectActive: true,
            });
            chart?.on?.("restore", () => {
              chart?.dispatchAction?.({
                type: 'takeGlobalCursor',
                key: 'dataZoomSelect',
                dataZoomSelectActive: true,
              });
              chart?.setOption?.(options);
            });
            chart?.on?.('click', (params: any) => {
              if (params.componentType === 'markArea') {
                chart?.dispatchAction?.({
                  type: 'dataZoom',
                  dataZoomIndex: 0,
                  startValue: params.data.coord[0][0],
                  endValue: params.data.coord[1][0]
                })
              }
            });
          }}
        />
      </div>
    </div>;
  }, [props.training.prediction.training?.thermal_graph]);

  const make_report = React.useCallback((object_id: string, big_title = false) => {
    let graph_data = props.training?.graphs?.[object_id] || { columns: [], data: [] };
    let quality_params = props.training?.prediction?.training?.quality_params?.[object_id] || { allow_null: false, max: 0, min: 0, min_days_plateau: 0, min_days_plateau_of_zeros: 0 };
    let var_name = graph_data.columns[0] || "N/A";

    let options = {
      series: [],
      xAxis: { type: "time" },
      tooltip: { trigger: "axis", axisPointer: { type: "cross" } },
      yAxis: { type: "value", axisLabel: { formatter: '{value}' } },
      // dataZoom: [{ type: 'inside', start: 0, end: 100 }, { start: 0, end: 100 }],
      toolbox: {
        feature: {
          // dataView: { show: true, readOnly: false },
          restore: { show: true },
          dataZoom: {
            yAxisIndex: false,
            icon: {
              zoom: 'path://', // hack to remove zoom button
              back: 'path://', // hack to remove restore button
            }
          }
        }
      }
    };

    for (let i = 0; i < graph_data.columns.length; i++) {
      let name = graph_data.columns[i];
      let datapoints = graph_data.data[i];
      // Show the period on only the first set of data
      let markArea = i === 0 ? outliers_specials : undefined;

      // To not show the high and low curve, but instead create a colored area
      let stack: string, lineStyle: Record<"opacity", number>, areaStyle: Record<"color" | "opacity", string | number>, z: number, emphasis: Record<"disabled", boolean>;
      // Lower end curve
      if (i === 1) {
        // Do not display this line
        lineStyle = { opacity: 0 };
        emphasis = { disabled: true };
        // Add a start value series
        options.series.push({
          type: 'line',
          stack: 'area',
          "smooth": true,
          data: datapoints,
          showSymbol: false,
          name: 'Area Start',
          showInLegend: false,
          tooltip: { show: false },
          areaStyle: { opacity: 0 },
          lineStyle: { opacity: 0 },
        });
      }
      // Higher end curve
      else if (i === 3) {
        // Do not display this line
        lineStyle = { opacity: 0 };
        emphasis = { disabled: true };
        // Get the lower end data, to calculate the difference between the two
        let lower_end_curve_datapoints = graph_data.data[1];
        // Add an end value series
        options.series.push({
          type: 'line',
          stack: 'area',
          "smooth": true,
          showSymbol: false,
          showInLegend: false,
          name: 'Area Difference',
          tooltip: { show: false },
          lineStyle: { opacity: 0 },
          areaStyle: { color: 'rgb(0, 128, 0)', opacity: 0.2 },
          data: datapoints.map(([time, value], index) => [time, value - lower_end_curve_datapoints[index][1]]),
        });
      }
      // Add the current series to the list
      options.series.push({ name, markArea, type: 'line', smooth: true, data: datapoints, showSymbol: false, emphasis, stack, lineStyle, areaStyle, z });
    }

    return <div key={object_id} className="mb-3">
      <C.Title level={big_title ? 4 : 3} children={var_name} />

      <div style={{ height: "300px" }}>
        <C.Echarts
          option={options}
          ref={ref => {
            let chart = ref?.getEchartsInstance?.();
            chart?.dispatchAction?.({
              type: 'takeGlobalCursor',
              key: 'dataZoomSelect',
              dataZoomSelectActive: true,
            });
            chart?.on?.("restore", () => {
              chart?.dispatchAction?.({
                type: 'takeGlobalCursor',
                key: 'dataZoomSelect',
                dataZoomSelectActive: true,
              });
              chart?.setOption?.(options);
            });

            chart?.on?.('click', (params: any) => {
              if (params.componentType === 'markArea') {
                chart?.dispatchAction?.({
                  type: 'dataZoom',
                  dataZoomIndex: 0,
                  startValue: params.data.coord[0][0],
                  endValue: params.data.coord[1][0]
                })
              }
            });

          }}
        />
      </div>

      {!props.is_report && <BS.Row className="g-1 fs-85">
        <BS.Col>
          <C.Form.NumField
            noBottomMargin
            value={quality_params.min}
            error={errors[object_id]?.min}
            label={TC.PRED_QUALITY_PARAM_MIN}
            onChange={value => change.set_quality_param(object_id, "min", value)}
          />
        </BS.Col>
        <BS.Col>
          <C.Form.NumField
            noBottomMargin
            value={quality_params.max}
            error={errors[object_id]?.max}
            label={TC.PRED_QUALITY_PARAM_MAX}
            onChange={value => change.set_quality_param(object_id, "max", value)}
          />
        </BS.Col>
        <BS.Col>
          <C.Form.NumField
            noBottomMargin
            label={TC.PRED_QUALITY_PARAM_MIN_PLAT}
            value={quality_params.min_days_plateau}
            error={errors[object_id]?.min_days_plateau}
            onChange={value => change.set_quality_param(object_id, "min_days_plateau", value)}
          />
        </BS.Col>
        <BS.Col>
          <C.Form.NumField
            noBottomMargin
            label={TC.PRED_QUALITY_PARAM_MIN_PLAT_ZERO}
            value={quality_params.min_days_plateau_of_zeros}
            error={errors[object_id]?.min_days_plateau_of_zeros}
            onChange={value => change.set_quality_param(object_id, "min_days_plateau_of_zeros", value)}
          />
        </BS.Col>
        <BS.Col>
          <C.Flex alignItems="center" justifyContent="center" className="h-100">
            <C.Form.CheckBox
              noBottomMargin
              check_type="switch"
              labelPosition="top"
              value={quality_params.allow_null}
              error={errors[object_id]?.allow_null}
              label={TC.PRED_QUALITY_PARAM_ALLOW_NULL}
              onChange={value => change.set_quality_param(object_id, "allow_null", value)}
            />
          </C.Flex>
        </BS.Col>
      </BS.Row>}
    </div>;
  }, [props.training, props.is_report, change, outliers_specials, errors]);
  //#endregion

  React.useEffect(() => !props.is_report && props.set_footer?.(
    <C.Flex justifyContent="end">
      <C.Button
        className="me-2"
        icon="rotate-right"
        variant="outline-info"
        text={TC.PRED_QUALITY_UPDATE_MODEL}
        onClick={() => validate_quality(false)}
      />
      <C.Button
        disabled={was_edited.value}
        text={TC.PRED_QUALITY_VALIDATE}
        onClick={() => validate_quality(true)}
      />
    </C.Flex>,
  ), [validate_quality, was_edited.value, props]);

  return <>
    {!props.is_report && <>
      <BS.Row className="g-1 mb-3">
        <BS.Col md={3}>
          <C.Flex className="h-100" alignItems="center">
            {lg.getStaticText(TC.PRED_CONFIG_TIME_CONSIDERATION)}
          </C.Flex>
        </BS.Col>
        <BS.Col>
          <C.Form.DateTime
            enableTime
            noBottomMargin
            error={errors.from_date}
            value={props?.training?.prediction?.from_date}
            onChange={d => change.set_pred("from_date", d)}
          />
        </BS.Col>
        <BS.Col>
          <C.Form.DateTime
            enableTime
            noBottomMargin
            error={errors.to_date}
            value={props?.training?.prediction?.to_date}
            onChange={d => change.set_pred("to_date", d)}
          />
        </BS.Col>
      </BS.Row>

      <C.Form.Select
        no_clear_btn
        labelPosition="left"
        options={TIME_GROUPS}
        label={TC.PRED_CONFIG_TIME_GROUP}
        onChange={tg => change.set_pred("time_group", tg)}
        value={props?.training?.prediction?.time_group || "hour"}
      />
    </>}

    <div className="mb-2">
      <C.Title level={3} text={TC.PRED_QUALITY_GEN} />

      <div className="mb-3">
        <C.Title level={4} text={TC.PRED_QUALITY_KPI} />

        <BS.Row>
          {KPIs.map(k => <BS.Col key={k.key}>
            <C.Flex className="text-center" direction="column">
              <div className="fw-bold">{k.key}</div>
              <div>{k.value}</div>
            </C.Flex>
          </BS.Col>)}
        </BS.Row>
      </div>

      {make_report(props.training.prediction.dataset, true)}

      {!props.is_report && <div className="mb-3">
        <C.Flex alignItems="center" className="mb-2">
          <C.Title level={4} text={TC.PRED_QUALITY_PERIODS} />
          <C.Button className="ms-2" icon="plus" size="sm" onClick={change.add_period} />
        </C.Flex>

        {periods.length === 0
          ? <C.CenterMessage italics className="p-3" children={TC.PRED_QUALITY_NO_PERIOD} />
          : periods.map((p, i) => <BS.Row key={i} className="g-1 mb-2">
            <BS.Col md={1}>
              <C.Flex
                alignItems="center"
                justifyContent="center"
                title={TC.PRED_QUALITY_CHANGE_COLOR}
                style={{ backgroundColor: COLORS[p.type] }}
                className="h-100 w-100 fs-120 border rounded"
              >
                P{i + 1}
              </C.Flex>
            </BS.Col>
            <BS.Col md={3}>
              <C.Form.DateTime
                value={p.from}
                noBottomMargin
                error={periods_errors[i]?.from}
                onChange={from => change.set_period(i, "from", from)}
              />
            </BS.Col>
            <BS.Col md={3}>
              <C.Form.DateTime
                value={p.to}
                noBottomMargin
                error={periods_errors[i]?.to}
                onChange={to => change.set_period(i, "to", to)}
              />
            </BS.Col>
            <BS.Col md={4}>
              <C.Form.Select
                no_clear_btn
                value={p.type}
                noBottomMargin
                options={PERIOD_TYPES}
                error={periods_errors[i]?.type}
                onChange={type => change.set_period(i, "type", type)}
              />
            </BS.Col>
            <BS.Col md={1}>
              <C.Button
                icon="times"
                variant="danger"
                className="w-100 h-100"
                onClick={() => change.remove_period(i)}
              />
            </BS.Col>
          </BS.Row>)}
      </div>}
    </div>

    {features_list.length > 0 && <div className="mb-4">
      <C.Title level={3} text={TC.PRED_QUALITY_EXP_VAR} />
      {features_list.map(f => make_report(f.object_id, false))}
    </div>}

    {thermal_graph}

    {props.training?.prediction?.training?.openings && <div>
      <C.Title level={4} text={TC.PRED_QUALITY_OPENINGS} />
      <Openings openings={props.training.prediction.training.openings} />
    </div>}
  </>;
};

export default Quality;