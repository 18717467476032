import React from "react";
import * as L from "../Layout";
import * as CS from "../Custom";
import * as CT from "../Container";
import * as C from "../Components";
import { TB } from "../../../Constants";
import { FormInputProps } from "./InputTypes";
import { useFormConsumer } from "../../../hooks";

const Input: React.FC<FormInputProps> = props => {
    const context = useFormConsumer(props.prop, props.contextKey);
    const contextParams = React.useMemo(() => context.validContext ? context : {}, [context]);

    const component = React.useMemo(() => {
        switch (props.type) {
            // case "AnnotationComp" /* Not used currently + probably needs to be redone */

            case "time": return <C.Time {...props} {...contextParams} />;
            case "well": return <L.Well {...props} {...contextParams} />;
            case "tabs": return <L.Tabs {...props} {...contextParams} />;
            case "panel": return <L.Panel {...props} {...contextParams} />;
            case "select": return <C.Select {...props} {...contextParams} />;
            case "hidden": return <CT.Hidden {...props} {...contextParams} />;
            case "number": return <C.NumField {...props} {...contextParams} />;
            case "content": return <L.Content {...props} {...contextParams} />;
            case "columns": return <L.Columns {...props} {...contextParams} />;
            case "datamap": return <CT.DataMap {...props} {...contextParams} />;
            case "Actions": return <CS.Actions {...props} {...contextParams} />;
            case "RoleSelect": return <C.Select {...props} {...contextParams} />;
            case "file": return <C.FileUploader {...props} {...contextParams} />;
            case "datetime": return <C.DateTime {...props} {...contextParams} />;
            case "checkbox": return <C.CheckBox {...props} {...contextParams} />;
            case "password": return <C.Password {...props} {...contextParams} />;
            case "radio": return <C.RadioButtons {...props} {...contextParams} />;
            case "datagrid": return <CT.DataGrid {...props} {...contextParams} />;
            case "signature": return <C.Signature {...props} {...contextParams} />;
            case "radioBool": return <C.RadioBool {...props} {...contextParams} />;
            case "Frequency": return <C.Frequency {...props} {...contextParams} />;
            case "Materials": return <CS.Materials {...props} {...contextParams} />;
            case "container": return <CT.Container {...props} {...contextParams} />;
            case "selectboxes": return <C.SelectBox {...props} {...contextParams} />;
            case "StoreEquip": return <CS.StoreEquip {...props}{...contextParams} />;
            case "RegActions": return <CS.RegActions {...props} {...contextParams} />;
            case "GammeEquip": return <CS.GammeSelect {...props} {...contextParams} />;
            case "htmlelement": return <L.HTMLElement {...props} {...contextParams} />;
            case "TicketGroup": return <CS.TicketGroup {...props} {...contextParams} />;
            case "RegStatuses": return <CS.RegStatuses {...props} {...contextParams} />;
            case "RegProperties": return <CS.RegProperties {...props} {...contextParams} />;
            case "FormulaEditor": return <CS.FormulaEditor {...props} {...contextParams} />;
            case "ColorPicker": return <C.Color {...props} {...contextParams} no_auto_focus />;
            case "textfield": return <C.TextField {...props} {...contextParams} rows={TB.getNumber(props.rows)} />;
            case "email": return <C.TextField {...props} {...contextParams} rows={TB.getNumber(props.rows)} isEmail />;
            case "textarea": return <C.TextField {...props} {...contextParams} rows={TB.getNumber(props.rows)} textArea />;
            case "table": return <L.Table {...props} {...contextParams} rows={typeof props.rows === "number" ? [] : props.rows} />;
            default: return null;
        }
    }, [props, contextParams]);

    return component;
}

export default Input;