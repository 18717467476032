import React from "react";
import * as I from "../types";
import { Form } from "../../../../Common";
import { Cell as Button } from "./Button";
import { T, TC } from "../../../../Constants";

//#region Types
type FilterModel = Value["status"][];
type Value = ReturnType<T.API.Utils.Tables.GetEmplacementRows>[number]["remarques"];
//#endregion

//#region Constants
const Options: T.Option<object, Value["status"]>[] = [
    { value: "memo", label: TC.REM_BUTTON_FILTER_MEMO },
    { value: "open", label: TC.REM_BUTTON_FILTER_OPEN },
    { value: "none", label: TC.REM_BUTTON_FILTER_NONE },
];

export const Texts = Options.map(o => o.label).concat(TC.GLOBAL_ALL);
//#endregion

export const Cell = Button;
export const type = "agCustomRemButton";

export const sort = (a: Value, b: Value) => {
    let valueA = 0, valueB = 0;
    if (a?.remarque_color === "danger") valueA = 3;
    else if (a?.remarque_color === "warning") valueA = 2;
    else if (a?.remarque_color === "secondary") valueA = 1;
    if (b?.remarque_color === "danger") valueB = 3;
    else if (b?.remarque_color === "warning") valueB = 2;
    else if (b?.remarque_color === "secondary") valueB = 1;

    // Values have the same importance, sort on their number
    if (valueA === valueB) return (a?.nb_remarques || 0) - (b?.nb_remarques || 0);
    else return valueA - valueB;
};

export const Filter: React.FC<I.FilterProps<FilterModel>> = ({ onModelChange, getValue, ...props }) => {

    const doesFilterPass = React.useCallback<I.FilterAction["doesFilterPass"]>(params => {
        // Filter is null (= inactive), or nothing set as filtered, so everything pass
        if (!Array.isArray(props.model) || props.model.length === 0) return true;
        // Get the cell's value
        let value: Value = getValue(params.node);
        // Value is empty
        if (!value?.status || value.status === "none") return !props.model.includes("none");
        // Value is both memo and open
        else if (value.status === "both") return !props.model.includes("memo") && !props.model.includes("open");
        // Value is one of the selected status
        else return !props.model.includes(value.status);
    }, [props.model, getValue]);

    const toggleFilter = React.useCallback((status: Value["status"]) => {
        let new_model = props.model || [];
        if (new_model.includes(status)) new_model = new_model.filter(s => s !== status);
        else new_model = new_model.concat(status);
        // No statuses are selected, so filter is disabled
        if (new_model.length === 0) new_model = null;
        // Update the model
        onModelChange(new_model);
    }, [props.model, onModelChange]);

    const toggleAll = React.useCallback(() => {
        // Nothing selected, so select everything
        if (!Array.isArray(props.model) || props.model.length === 0) onModelChange(Options.map(o => o.value));
        // Some things are selected, so deselect everything
        else onModelChange([]);
    }, [props.model, onModelChange]);

    I.useGridFilter({ doesFilterPass });

    return <div className="p-2">

        <Form.CheckBox
            noBottomMargin
            labelFullWidth
            customClass="mb-1"
            onChange={toggleAll}
            label={TC.GLOBAL_ALL}
            labelPosition="right"
            value={(props.model?.length || 0) === 0}
        />

        {Options.map(o => <Form.CheckBox
            key={o.value}
            noBottomMargin
            labelFullWidth
            label={o.label}
            customClass="mb-1"
            labelPosition="right"
            onChange={() => toggleFilter(o.value)}
            value={!props.model?.includes?.(o.value)}
        />)}
    </div>;
};