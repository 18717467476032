import _ from 'lodash';
import React from "react";
import './SearchModule.scss';
import * as M from "../Modal";
import * as H from "../../hooks";
import * as R from "../../reducers";
import { TB, TC } from "../../Constants";
import { useDispatch } from "react-redux";
import * as US from "../../services/user.service";

const SearchModule = ({ refObj, searchList = [], testArray = false, minimalView = false, itemList = [], onSelect, onGrouping, allowGrouping = false, origin, allowFavorites = true, ...props }) => {
    const isDark = H.useDark();
    const lg = H.useLanguage();
    const dispatch = useDispatch();
    const [{ user, preferences }] = H.useAuth();
    const [openedCat, setOpenedCat] = React.useState([]);
    const [favorites, setFavorites] = React.useState([]);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const [appliedFilters, setAppliedFilters] = React.useState([]);

    //#region Normalization
    const normalizedSearch = React.useMemo(() => TB.normalizedString(searchString, lg.language), [searchString, lg.language]);
    const checkIncludes = React.useCallback((str1, str2) => [str1, str2].some(str => typeof str !== "string") ? false : str2.toLowerCase().includes(str1.toLowerCase()), []);
    const checkSimilar = React.useCallback((str1, str2) => [str1, str2].some(str => typeof str !== "string") ? false : TB.checkStringSimilarity(str1, str2) >= 0.5, []);
    //#endregion

    //#region Data memo
    const purgedSearchList = React.useMemo(() => {
        if (!Array.isArray(searchList)) return [];
        return searchList.filter(({ label, property }) => typeof label === "string" && typeof property === "string" && property !== "id" && property.length > 0)
    }, [searchList]);

    const normalizedValues = React.useMemo(() => {
        if (!Array.isArray(itemList)) return [];
        return itemList
            .filter(obj => typeof obj === "object" && obj !== null)
            .map(obj => Object.fromEntries(Object.entries(obj).map(([key, val]) => [key, (key === "id" ? val : TB.normalizedString(val, lg.language, true, null, testArray))])));
    }, [itemList, testArray, lg.language]);

    const filterNoGrouping = React.useMemo(() => appliedFilters.filter(({ isFilter }) => isFilter), [appliedFilters]);

    const usableValues = React.useMemo(() => {
        if (filterNoGrouping.length === 0) return normalizedValues;
        let filteredValues = [...normalizedValues];

        filterNoGrouping.forEach(({ property, search, isStrict }) => filteredValues = filteredValues.filter(val => {
            let testVal = val?.[property];

            const fnArray = isStrict ? [checkIncludes] : [checkIncludes, checkSimilar];
            const checker = str => fnArray.some(f => f(search, str));

            if (Array.isArray(testVal)) return testVal.some(str => checker(str));
            else return checker(testVal);
        }));

        return filteredValues;
    }, [normalizedValues, filterNoGrouping, checkIncludes, checkSimilar]);
    //#endregion

    //#region Selection
    React.useEffect(() => {
        onSelect?.(usableValues.map(({ id }) => id), usableValues)
    }, [usableValues, onSelect]);
    //#endregion

    //#region Grouping
    const groupAbleProperties = React.useMemo(() => purgedSearchList.filter(({ noGroup }) => !noGroup), [purgedSearchList]);
    const onApplyGrouping = React.useCallback(({ property, label }) => setAppliedFilters(prevState => prevState.concat({ label, property, isFilter: false })), []);
    const appliedGrouping = React.useMemo(() => appliedFilters.filter(({ isFilter }) => !isFilter).map(({ property }) => property), [appliedFilters]);

    React.useEffect(() => {
        onGrouping?.(appliedGrouping)
    }, [appliedGrouping, onGrouping]);

    const groupButtonClass = React.useMemo(() => `dropdown-toggle btn ${minimalView ? "w-100 btn-outline" : "btn"}-secondary`, [minimalView]);

    const groupingButton = React.useMemo(() => allowGrouping && <div className={`dropdown ${minimalView ? "flex-grow-1" : ""}`}>
        <a title="GROUPING" className={groupButtonClass} role="button" href='/#' id="groupingButton" data-bs-toggle="dropdown" aria-expanded="false" >
            <i className="fa fa-bars" > </i>
        </a>
        <ul className="dropdown-menu" aria-labelledby="groupingButton" >
            {groupAbleProperties.filter(({ property }) => !appliedGrouping.includes(property)).map(({ label, property }) => <li key={property} >
                <button onClick={() => onApplyGrouping({ property, label })} className="dropdown-item" > {label} </button>
            </li>)}
        </ul>
    </div >, [groupAbleProperties, appliedGrouping, groupButtonClass, onApplyGrouping, allowGrouping, minimalView]);
    //#endregion

    //#region Tree based on the search
    const emptySearchList = React.useMemo(() => purgedSearchList.length === 0 && <div className="noData"><span>{lg.getStaticText(TC.GLOBAL_NO_DATA_AVAILABLE)}</span></div >, [lg, purgedSearchList]);
    const emptyVal = React.useMemo(() => <span className="search" style={{ fontStyle: "italic" }}> none </span>, []);

    const sortByName = React.useCallback((a, b) => {
        if (a.value > b.value) return 1;
        if (b.value > a.value) return -1;
        return 0;
    }, []);

    const filteredValues = React.useMemo(() => purgedSearchList.map(({ label, property }) => {
        // Keep only the data that match the search
        let values = usableValues.map(({ id, ...nv }) => ({ value: nv?.[property] || "", id }))
            .filter(({ value }) => {
                if (TB.validString(value)) return value.length > 0 && [checkIncludes, checkSimilar].some(f => f(normalizedSearch, value))
                if (Array.isArray(value)) return value.filter(v => TB.validString(v) && [checkIncludes, checkSimilar].some(f => f(normalizedSearch, v)));
                return false;
            })
            .map(({ value, ...r }) => {
                if (Array.isArray(value)) return { ...r, value: value.filter(v => TB.validString(v) && [checkIncludes, checkSimilar].some(f => f(normalizedSearch, v))) };
                return { value, ...r };
            })
            .filter(({ value }) => Array.isArray(value) ? value.length > 0 : true);

        // Group the values to avoid duplicates
        let groupedValues = Object.entries(_.groupBy(values, "value")).map(([value, array]) => ({ value, ids: array.map(({ id }) => id) })).sort(sortByName);

        if (testArray && values.some(({ value }) => Array.isArray(value))) {
            let valueObj = {};
            values.forEach(({ value, id }) => {
                if (!Array.isArray(value)) value = [value];
                (value as any[]).forEach(v => {
                    if (!Array.isArray(valueObj[v])) valueObj[v] = [id];
                    else valueObj[v].push(id);
                });
            });
            groupedValues = Object.entries(valueObj).map(([value, ids]) => ({ value, ids: _.uniq(ids as string[]) })).sort(sortByName);
        }
        return { label, property, values: groupedValues };
    }), [checkIncludes, checkSimilar, sortByName, usableValues, purgedSearchList, normalizedSearch, testArray]);

    const toggleCategory = React.useCallback(property => {
        if (openedCat.includes(property)) setOpenedCat(openedCat.filter(prop => prop !== property));
        else setOpenedCat(openedCat.concat(property));
    }, [openedCat]);

    const applyFilter = React.useCallback((label, property, search, isStrict = false) => {
        setAppliedFilters(prevState => prevState.concat({ label, property, search, isFilter: true, isStrict }));
        // Empty the search and close the category
        setOpenedCat([]);
        setSearchString('');
    }, []);

    const searchListTree = React.useMemo(() => {
        if ([searchString.length, filteredValues.length].some(l => l === 0)) return;

        if (filteredValues.length === 1) return filteredValues.map(({ label, property, values }, i) => <ul style={{ paddingLeft: "0px" }} key={i} >
            {values.length > 0 && values.map(({ value }, i) => <li key={i} > <span className="valueItem" onClick={() => applyFilter(label, property, value)}> {value.length === 0 ? emptyVal : value} </span></li >)}
            {values.length === 0 && <li><span className="valueItem" > <em>{lg.getStaticText(TC.GLOBAL_NO_DATA_AVAILABLE)}</em></span > </li>}
        </ul>);

        return filteredValues.map(({ label, property, values }) => <div className="propertyContainer" key={property} >
            <div className="identifier" style={{ display: "flex" }}>
                <span className="toggler" style={{ position: "absolute", marginLeft: "8px", "color": isDark ? "grey" : "" }} onClick={() => { toggleCategory(property); setOpenMenu(oldState => oldState) }}>
                    {values.length > 5 && <i className={`fa fa-angle-double-${openedCat.includes(property) ? "down" : "right"}`}> </i>}
                    {values.length < 5 && values.length > 0 && <i className={`fa fa-caret-${openedCat.includes(property) ? "down" : "right"}`}> </i>}
                </span>
                < span className="px-card pt-0 pb-2 fw-medium dropdown-header" style={{ cursor: "pointer" }} onClick={() => applyFilter(label, property, searchString)}> {lg.getStaticText(TC.SM_LOOK_IN)} : <em style={{ fontWeight: "bold" }}> {label} </em></span >
            </div>
            {
                openedCat.includes(property) && <div className="valueList">
                    <ul>
                        {values.length > 0 && values.map(({ value }, i) => <li style={{ color: isDark ? "grey" : "" }} key={i} > <span className="valueItem" style={{ color: "rgba(23, 141, 226, 0.705)", cursor: "pointer" }} onClick={() => applyFilter(label, property, value, true)}> {value.length === 0 ? emptyVal : value} </span></li >)}
                        {values.length === 0 && <li><span className="valueItem" > <em>{lg.getStaticText(TC.GLOBAL_NO_DATA_AVAILABLE)} </em></span > </li>}
                    </ul>
                </div>}
        </div>);
    }, [searchString, filteredValues, lg, isDark, emptyVal, openedCat, toggleCategory, applyFilter]);
    //#endregion

    //#region Clear Search
    const clearSearches = React.useCallback(() => {
        setSearchString("");
        setOpenedCat([]);
        setAppliedFilters([]);
    }, []);

    const removeFilter = React.useCallback(index => setAppliedFilters(prevState => prevState.filter((f, i) => i !== index)), []);
    React.useEffect(() => {
        if (typeof refObj?.current === "object" && refObj?.current !== null) refObj.current.clearSearches = clearSearches;
    }, [refObj, clearSearches]);
    //#endregion

    //#region Preferences
    const searchFavorites = React.useMemo(() => preferences?.[origin], [preferences, origin]);
    const disabledAddFav = React.useMemo(() => appliedFilters.length === 0, [appliedFilters]);
    const currentDefaultFavName = React.useMemo(() => favorites?.filter?.(({ isDefault }) => isDefault)?.[0]?.name, [favorites]);
    const showFavorites = React.useMemo(() => allowFavorites && TB.validString(origin) && TB.mongoIdValidator(user?._id), [user, allowFavorites, origin]);
    const defaultFavoriteFilters = React.useMemo(() => Array.isArray(searchFavorites) ? searchFavorites.filter(({ isDefault }) => isDefault === true)?.[0]?.filters : undefined, [searchFavorites]);

    const selectedFavIndex = React.useMemo(() => {
        let equalFilters = favorites.map(({ filters }) => _.isEqual(filters, appliedFilters));
        return equalFilters.map((isEqual, i) => isEqual ? i : null).filter(index => index !== null);
    }, [favorites, appliedFilters]);

    React.useEffect(() => {
        if (Array.isArray(searchFavorites)) setFavorites(searchFavorites);
    }, [searchFavorites]);

    React.useEffect(() => {
        if (Array.isArray(defaultFavoriteFilters)) setAppliedFilters(defaultFavoriteFilters)
    }, [defaultFavoriteFilters]);

    const selectFavorite = React.useCallback((filters, i) => selectedFavIndex.includes(i) ? undefined : setAppliedFilters(filters), [selectedFavIndex]);

    const updatePreferences = React.useCallback(async (newFilter = null, newFilterArray = null) => {
        let newFavs = Array.isArray(newFilterArray) ? newFilterArray : favorites.concat(newFilter);

        // Update in database
        let update = {};
        update[`data.preferences.${origin}`] = newFavs;
        let reply = await US.updateFromFilter({ _id: user._id }, update);
        if (!TB.mongoIdValidator(reply.data._id)) return null;
        else {
            // Update localStorage
            let newUser = _.cloneDeep(user);
            if (typeof newUser.data.preferences !== "object") newUser.data.preferences = {};
            newUser.data.preferences[origin] = newFavs;
            localStorage.setItem('formioUser', JSON.stringify(newUser));

            // Update the promise from selector
            dispatch(R.reloadUser());
        }
    }, [user, favorites, dispatch, origin]);

    const favNames = React.useMemo(() => favorites?.map?.(({ name }) => name), [favorites]);

    const promptChecker = React.useMemo(() => str => {
        if (favNames.includes(str)) return { isValid: false, message: lg.getStaticText(TC.ANNOT_NAME_USED) };
        return true;
    }, [favNames, lg]);

    const addFavorite = React.useCallback(() => {
        M.askPrompt({ isRequired: true, label: TC.GLOBAL_NAME, valChecker: promptChecker, title: TC.SM_ASK_FAV_NAME })
            .then(name => {
                if (name !== null) {
                    let newFilter = { name, filters: appliedFilters, isDefault: false };
                    updatePreferences(newFilter).then(result => {
                        if (result === null) alert("ERROR : Failed to create a favorite");
                        else setFavorites(prev => prev.concat(newFilter));
                    });
                }
            });
    }, [appliedFilters, promptChecker, updatePreferences]);

    const removeFavorite = React.useCallback(favName => {
        M.askConfirm().then(doDelete => {
            if (doDelete) {
                let newFilterArray = favorites.filter(({ name }) => name !== favName);
                updatePreferences(null, newFilterArray).then(result => {
                    if (result === null) alert("ERROR : Failed to delete a favorite");
                    else setFavorites(newFilterArray);
                });
            }
        });
    }, [favorites, updatePreferences]);

    const selectDefaultFavorite = React.useCallback(favName => {
        let newArray = favorites.map(({ isDefault, name, ...rest }) => {
            // If is by default, unDefault it, even if it is the selected one
            if (isDefault) return { name, isDefault: false, ...rest };
            // 
            else if (name === favName) return { name, isDefault: true, ...rest };
            return { name, isDefault, ...rest };
        });

        updatePreferences(null, newArray).then(result => {
            if (result === null) alert("ERROR : Failed to set a default favorite");
            else setFavorites(newArray);
        });
    }, [favorites, updatePreferences]);

    const favButtonClass = React.useMemo(() => `dropdown-toggle btn ${minimalView ? "w-100 btn-outline" : "btn"}-secondary`, [minimalView]);

    const favoritesButton = React.useMemo(() => showFavorites && <div className={`dropdown favoritesMenu ${minimalView ? "flex-grow-1" : ""}`}>
        {/* Styles are defined in SearchModule.scss */}
        < button className={favButtonClass} type="button" id="favoritesButton" data-bs-toggle="dropdown" aria-expanded="false" >
            <i className="fa fa-star" > </i>
        </button>
        < ul className="dropdown-menu" aria-labelledby="favoritesButton" >
            {favorites.map(({ name, filters }, i) => <li key={i} className="favItem" >
                <button onClick={() => selectFavorite(filters, i)} className="dropdown-item selectFav" >
                    <div className="favLabels" >
                        <span>{name} </span>
                        < span > {
                            selectedFavIndex.includes(i) ? <i className="fa fa-check"> </i> : undefined}</span >
                    </div>
                </button>
                < span className="favDefault actionFav" title={lg.getStaticText(TC.SM_DEFAULT_FAV)} onClick={() => selectDefaultFavorite(name)}>
                    <i style={currentDefaultFavName !== name ? { opacity: "0.25" } : {}} className="fa fa-bookmark" />
                </span>
                <span className="favDeleter actionFav" onClick={() => removeFavorite(name)}> <i className="fa fa-times" > </i></span >
            </li>)}
            {favorites.length > 0 && <li><hr className="dropdown-divider" > </hr></li >}
            < li > <button disabled={disabledAddFav} onClick={addFavorite} className="dropdown-item" > <i className="fa fa-plus fa-xs" > </i>  {lg.getStaticText(TC.SM_ADD_FAV)}</button > </li >
        </ul >
    </div >, [lg, showFavorites, disabledAddFav, favButtonClass, favorites, selectedFavIndex, currentDefaultFavName, minimalView, removeFavorite, addFavorite, selectFavorite, selectDefaultFavorite]);
    //#endregion

    //#region Display items
    const renderSearchItem = React.useCallback(({ label, search, isFilter }, i) => {
        if (isFilter) return <div key={i} className="filterContainer" style={{ backgroundColor: "none" }
        } >
            <span><i style={{ color: "white" }} className="fa fa-filter" > </i></span >
            <span className="label" > {TB.shortenString(label, 15)} </span>
            {search.length === 0 ? emptyVal : <span className="search" > {TB.shortenString(search, 10)} </span>}
            <span className="remover" onClick={() => removeFilter(i)}> <i className="fa fa-times" > </i></span >
        </div>;

        return <div key={i} className="filterContainer" style={{ backgroundColor: "none" }}>
            <span><i style={{ color: "white" }} className="fa fa-bars" > </i></span >
            <span className="px-card pt-0 pb-2 label dropdown-header" > {TB.shortenString(label, 15)} </span>
            < span className="remover" onClick={() => removeFilter(i)}> <i className="fa fa-times" > </i></span >
        </div>
    }, [emptyVal, removeFilter]);

    const input = React.useMemo(() => <div className="inputSearch  form-control" style={{ ["--custom-background-color" as string]: isDark ? "black" : "white" }} onClick={() => setOpenMenu(oldState => !oldState)}>
        <div className="filtersList" >
            {appliedFilters.map(renderSearchItem)}
            <input
                type="text"
                value={searchString}
                className="searchInputFlex "
                onChange={e => setSearchString(e.target.value)}
                placeholder={lg.getStaticText(TC.GLOBAL_SEARCH_PLACEHOLDER)}
                style={{ fontFamily: 'Mulish', ["--custom-color" as string]: isDark ? "whitesmoke" : "#4c5054" }}
            />
        </div>
        < div className="clearSearches" >
            <button onClick={clearSearches}> <i className="fa fa-times" style={{ color: "grey" }}> </i></button >
        </div>
    </div>, [clearSearches, renderSearchItem, lg, searchString, appliedFilters, isDark]);
    //#endregion

    //#region View
    const inputView = React.useMemo(() => {
        if (minimalView) return <div>
            <div className="btn-group w-100" >
                {groupingButton}
                {favoritesButton}
            </div>
            {input}
        </div>

        return <div className={"inputGroup " + (allowGrouping ? "on" : "") + (showFavorites ? " fav" : "")}>
            {input}
            {groupingButton}
            {favoritesButton}
        </div>
    }, [allowGrouping, favoritesButton, groupingButton, input, minimalView, showFavorites]);
    //#endregion

    return <div className="searchModule" >
        {inputView}
        < div hidden={!openMenu || searchString.length === 0} className="dropdown-menu show" onClick={() => setOpenMenu(oldState => oldState)}>
            {searchListTree}
            {emptySearchList}
        </div>
    </div>
}

export default SearchModule;