import React from "react";
import moment from "moment";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { useSelector } from "react-redux";
import * as US from "../../services/user.service";
import { TB, T, TABS, TC, FP } from "../../Constants";

//#region Types
type Resource = {
    users: { value: string, label: string }[];
    status: "loading" | "ready" | "error" | "noContext";
    locOptions: T.FullOptionsLocationsTyped<T.BuildingType>[];
};

type TimePick = { timeInterval?: string, dateFrom?: string, dateTo?: string };
//#endregion

//#region Constants
const DF_INIT: Resource = { status: "loading", users: [], locOptions: [] };
const TEXT_CODES = [TC.GP_GROUP_DAY, TC.GP_GROUP_WEEK, TC.GP_GROUP_MONTH, TC.GP_GROUP_YEAR];
const getTimeGroup = (g: any): T.API.Graph.TimeGroups => ["day", "week", "month", "year"].includes(g) ? g : "day";
//#endregion

const TicketDashboard: React.FC<{ rootId?: string | string[], portfolioId?: string }> = ({ rootId, portfolioId }) => {
    const lg = H.useLanguage(TEXT_CODES);
    H.useAuth({ tabName: TABS.ACTION_DASHBOARD });
    const { resetCrumbs } = H.useCrumbs(TC.TAB_DASHBOARD_GMAO);
    const [currentBuild, setCurrentBuild] = React.useState<string>();
    const [selectedUsers, setSelectedUsers] = React.useState<string[]>([]);
    const dataContext = useSelector((redux: T.ReduxSelector) => redux.dataContext);
    const [timeGroup, setTimeGroup] = React.useState<T.API.Graph.TimeGroups>("day");
    const [{ status, users, locOptions }, setResource] = React.useState<Resource>(DF_INIT);
    const [graphData, setGraph] = React.useState<T.API.Graph.TicketGraphResults | null | undefined>();
    const [{ timeInterval, dateFrom, dateTo }, setTime] = React.useState<TimePick>({ timeInterval: "15 DAY" });

    //#region Context & Data Fetch
    const rootsPortfolio = React.useMemo(() => {
        if (TB.mongoIdValidator(portfolioId)) return { portfolio: portfolioId };
        else if (TB.mongoIdValidator(rootId)) return { roots: [rootId] };
        else if (Array.isArray(rootId) && rootId.length > 0 && TB.multiMongoIdValidator(rootId)) return { roots: rootId };
        else if (TB.mongoIdValidator(dataContext.selectedPortfolio)) return { portfolio: dataContext.selectedPortfolio };
        else return { roots: dataContext.selectedItems };
    }, [dataContext.selectedItems, dataContext.selectedPortfolio, portfolioId, rootId]);

    React.useEffect(() => {
        let isSubscribed = true;
        const onError = () => setResource({ ...DF_INIT, status: "error" });

        US.getFullResources(FP.TICKET_FORM, { dashboard: true, ...rootsPortfolio })
            .then(({ data }) => {
                if (isSubscribed) {
                    if (data?.hasFailed) onError();
                    else if (data.locOptions.length === 0) setResource({ ...DF_INIT, status: "noContext" });
                    else {
                        let { contextName, ...resources } = data;
                        setResource({ ...resources, status: "ready" });
                        setCurrentBuild(data.locOptions[0].submission._id);
                    }
                }
            })
            .catch(onError);

        return () => {
            isSubscribed = false;
            resetCrumbs();
        }
    }, [resetCrumbs, rootsPortfolio]);
    //#endregion

    //#region Time
    const timeSelector = React.useMemo(() => <C.TimeSelector
        to={dateTo}
        from={dateFrom}
        interval={timeInterval}
        onChangeInterval={timeInterval => setTime({ timeInterval })}
        onChangeDatePicker={({ from, to }) => setTime({ dateFrom: from, dateTo: to })}
    />, [dateFrom, dateTo, timeInterval]);

    const translatedInterval = React.useMemo(() => {
        if (typeof timeInterval !== "string") return null;
        let [number, time] = timeInterval.split(/\s/g);
        return { number: parseInt(number), time: time.toLowerCase() + 's' };
    }, [timeInterval]);

    const ISOTime = React.useMemo(() => {
        if ([dateFrom, dateTo].every(date => !isNaN(Date.parse(date || "")))) return { from: dateFrom, to: dateTo };
        if (translatedInterval !== null) return {
            /* @ts-ignore */
            from: moment().subtract(translatedInterval.number, translatedInterval.time).toISOString(),
            to: new Date().toISOString(),
        }
        // Default 2 weeks
        return {
            from: moment().subtract(2, 'w').toISOString(),
            to: new Date().toISOString(),
        }
    }, [translatedInterval, dateFrom, dateTo]);
    //#endregion

    //#region Fetch Graph Data
    React.useEffect(() => {
        let isSubscribed = true;
        if (TB.mongoIdValidator(currentBuild)) S.getTicketsGraph({ roots: currentBuild, ...ISOTime, groupBy: timeGroup, employees: selectedUsers })
            .then(({ data }) => isSubscribed && setGraph(data))
            .catch(() => isSubscribed && setGraph(null));

        return () => { isSubscribed = false };
    }, [currentBuild, ISOTime, timeGroup, selectedUsers]);
    //#endregion

    //#region Buildings
    const buildOptions = React.useMemo(() => locOptions.map(lo => ({
        value: lo.submission._id,
        label: lo.submission.data.name,
    })), [locOptions]);

    const buildGrid = React.useMemo(() => <C.ContextGrid
        roots={buildOptions}
        selected={currentBuild}
        changeSelection={buildId => setCurrentBuild(buildId)}
    />, [buildOptions, currentBuild]);
    //#endregion

    //#region Users
    const userSelect = React.useMemo(() => <C.TypeAhead
        multiple
        options={users}
        selectedItems={selectedUsers}
        onChange={opt => setSelectedUsers(opt.map(o => o.value))}
    />, [users, selectedUsers]);
    //#endregion

    //#region TimeGroup
    const timeGroupOptions = React.useMemo<{ label: string, value: T.API.Graph.TimeGroups }[]>(() => [
        { label: lg.getStaticText(TC.GP_GROUP_DAY), value: "day" },
        { label: lg.getStaticText(TC.GP_GROUP_WEEK), value: "week" },
        { label: lg.getStaticText(TC.GP_GROUP_MONTH), value: "month" },
        { label: lg.getStaticText(TC.GP_GROUP_YEAR), value: "year" },
    ], [lg]);

    const timeGroupSelect = React.useMemo(() => <C.TypeAhead
        selectedItems={timeGroup}
        options={timeGroupOptions}
        onChange={opt => setTimeGroup(getTimeGroup(opt[0]?.value))}
    />, [timeGroupOptions, timeGroup]);
    //#endregion

    //#region Error & Loading
    const errorBanner = React.useMemo(() => {
        if (graphData === undefined || status === "loading") return <M.Loader />;
        if (graphData === null || status === "error") return <C.ErrorBanner type="danger" textCode={''} />;
        if (status === "noContext") return <C.ErrorBanner type="warning" textCode={""} />;
        return null
    }, [status, graphData]);
    //#endregion

    //#region GraphData
    const formatData = React.useCallback((dataArray: T.API.Graph.GraphData<number>) => TB.getArray(dataArray).map(d => ({
        value: d.data,
        color: d?.color,
        name: lg.getStaticText(d.label),
    })), [lg]);
    //#endregion

    return <div className="w-100">
        {errorBanner}
        {status === "ready" && <>
            {buildGrid}
            <C.Flex className="my-3 align-items-center">
                <div>{timeSelector}</div>
                <div className="mx-2 flex-grow-1">{userSelect}</div>
                <div>{timeGroupSelect}</div>
            </C.Flex>
            <BS.Row className="mb-2 g-2">
                <BS.Col md={3}>
                    <BS.Card>
                        <BS.Card.Header>
                            <BS.Card.Title>{lg.getStaticText(graphData?.ticketState?.label)}</BS.Card.Title>
                        </BS.Card.Header>
                        <BS.Card.Body>
                            <C.DoughnutChart valuedLegend legend tooltip data={formatData(graphData?.ticketState?.graph)} />
                        </BS.Card.Body>
                    </BS.Card>
                </BS.Col>
                <BS.Col md={3}>
                    <BS.Card>
                        <BS.Card.Header>
                            <BS.Card.Title>{lg.getStaticText(graphData?.inTimeClosed?.label)}</BS.Card.Title>
                        </BS.Card.Header>
                        <BS.Card.Body>
                            <C.DoughnutChart valuedLegend legend tooltip data={formatData(graphData?.inTimeClosed?.graph)} />
                        </BS.Card.Body>
                    </BS.Card>
                </BS.Col>
                <BS.Col md={3}>
                    <BS.Card>
                        <BS.Card.Header>
                            <BS.Card.Title>{lg.getStaticText(graphData?.closePerUser?.label)}</BS.Card.Title>
                        </BS.Card.Header>
                        <BS.Card.Body>
                            <C.DoughnutChart valuedLegend legend tooltip data={formatData(graphData?.closePerUser?.graph)} />
                        </BS.Card.Body>
                    </BS.Card>
                </BS.Col>
                <BS.Col md={3}>
                    <BS.Card>
                        <BS.Card.Header>
                            <BS.Card.Title>{lg.getStaticText(graphData?.openPerUser?.label)}</BS.Card.Title>
                        </BS.Card.Header>
                        <BS.Card.Body>
                            <C.DoughnutChart valuedLegend legend tooltip data={formatData(graphData?.openPerUser?.graph)} />
                        </BS.Card.Body>
                    </BS.Card>
                </BS.Col>
            </BS.Row>
            <BS.Row className="mb-2 g-1">
                <BS.Col>
                    <BS.Card>
                        <BS.Card.Header>
                            <BS.Card.Title>{lg.getStaticText(graphData?.evolution?.label)}</BS.Card.Title>
                        </BS.Card.Header>
                        <BS.Card.Body>
                            <C.BarChart
                                legend
                                tooltip
                                categories={graphData?.evolution?.categories}
                                data={TB.getArray(graphData?.evolution?.graph).map(g => ({ name: lg.getStaticText(g.label), values: g.data, color: g.color }))}
                            />
                        </BS.Card.Body>
                    </BS.Card>
                </BS.Col>
            </BS.Row>
        </>}
    </div>;
}

export default TicketDashboard;