import React from "react";
import { T, TB } from "../Constants";
import { URLSearchParams } from "url";
import { useSelector } from "react-redux";

//#region Types
type UseRootsOptions = {
    rootUrlKey?: string;
    portfolioUrlKey?: string;
}

type GetUrlParams = (searchParams: URLSearchParams, options: UseRootsOptions) => void;
type UseRoots = (params?: T.ContextParams) => [T.ContextParams, string | null, (params?: T.ContextParams) => void, GetUrlParams];
//#endregion

//#region Utils
const getCorrectParams = (...params: T.ContextParams[]): T.ContextParams => {
    for (const p of params) {
        const { roots, portfolio } = TB.getObject(p);
        if (TB.mongoIdValidator(roots) || TB.multiMongoIdValidator(roots)) return { roots };
        if (TB.mongoIdValidator(portfolio)) return { portfolio };
    }
    return {};
}
//#endregion

const useRoots: UseRoots = params => {
    const dataContext = useSelector((redux: T.ReduxSelector) => redux.dataContext);
    const [{ portfolio, roots }, setRootsParams] = React.useState(getCorrectParams(params));

    const updateRootParams = React.useCallback((params?: T.ContextParams) => setRootsParams(TB.getObject(params)), []);

    const getUrlParams = React.useCallback((searchParams: URLSearchParams, options: UseRootsOptions) => setRootsParams(p => {
        const newContext: T.ContextParams = {};
        const { roots, portfolio } = p;
        const { rootUrlKey, portfolioUrlKey } = TB.getObject(options);

        if (TB.validString(rootUrlKey)) {
            const rootsStr = TB.getString(searchParams.get(rootUrlKey));
            const rootsSplit = rootsStr.split(",").filter(TB.mongoIdValidator);

            if (rootsSplit.length === 1) {
                if (Array.isArray(roots) && roots.length === 1 && roots[0] !== rootsSplit[0]) newContext.roots = rootsSplit[0];
                else if (roots !== rootsSplit[0]) newContext.roots = rootsSplit[0];
            }
            else if (rootsSplit.length > 1) {
                if (!Array.isArray(roots)) newContext.roots = rootsSplit;
                else if (!TB.arraySimilar(roots, rootsSplit)) newContext.roots = rootsSplit;
            }
        }
        if (TB.validString(portfolioUrlKey)) {
            const portfolioStr = searchParams.get(portfolioUrlKey);
            if (TB.mongoIdValidator(portfolioStr) && portfolioStr !== portfolio) newContext.portfolio = portfolioStr;
        }
        if (newContext.portfolio || newContext.roots) return newContext;
        return p;
    }), []);

    // eslint-disable-next-line
    const mission_id = React.useMemo(() => dataContext.active_mission, [dataContext.active_mission]);

    const rootsPortfolio = React.useMemo(() => {
        if (TB.mongoIdValidator(portfolio)) return { portfolio };
        else if (TB.mongoIdValidator(roots)) return { roots: [roots] };
        else if (Array.isArray(roots) && roots.length > 0 && TB.multiMongoIdValidator(roots)) return { roots };
        else if (TB.mongoIdValidator(dataContext.selectedPortfolio)) return { portfolio: dataContext.selectedPortfolio };
        else return { roots: dataContext.selectedItems };
    }, [dataContext.selectedItems, dataContext.selectedPortfolio, portfolio, roots]);

    return [rootsPortfolio, mission_id, updateRootParams, getUrlParams];
}

export default useRoots;