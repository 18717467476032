// API Paths
export const ML_API = "/api/ml/";
export const EXT_API = "/api/ext/";
export const REG_API = "/api/reg/";
export const NRJ_API = "/api/nrj/";
export const EDL_API = "/api/edl/";
export const API_URL = '/api/test/';
export const RENT_API = "/api/rent";
export const HTML_API = "/api/html/";
export const PRED_API = "/api/pred/";
export const FILE_API = "/api/file/";
export const LINK_API = "/api/link/";
export const TREE_API = "/api/tree/";
export const DATA_API = "/api/data/";
export const LANG_API = "/api/lang/";
export const FORM_API = "/api/forms/";
export const ICONS_API = "/api/icons/";
export const ADMIN_API = "/api/admin/";
export const EXCEL_API = "/api/excel/";
export const EDITS_API = "/api/edits/";
export const GRAPH_API = "/api/graphs/";
export const IMPORT_API = "/api/import";
export const API_COORDS = "/api/coords/";
export const AUTH_API_URL = "/api/auth/";
export const REPORT_URL = "/api/report/";
export const PARITY_API = "/api/parity/";
export const ACTION_API = "/api/action/";
export const MATRIX_API = "/api/matrix/";
export const TABLES_API = "/api/tables/";
export const NAV_API = "/api/navigator/";
export const OFFLINE_API = "/api/offline/";
export const MISSION_API = "/api/mission/";
export const DATASETS_API = "/api/datasets/";
export const DASHBOARD_API = "/api/dashboard/";
export const ITEM_ORDER_API = "/api/item_order/";
export const MAINTENANCE_API = "/api/maintenance/";

// Socket Paths
export const DIA_SOCKET = "/api/socket/dia/";
export const FORM_SOCKET = "/api/socket/form/";
export const GLOBAL_SOCKET = "/api/socket/global/";