import React from "react";
import { TC, TB } from "../../Constants";
import { useLanguage } from "../../hooks";
import { Row, Col, Button } from "react-bootstrap";

type GridOption = { label: string, value: string };

type ContextGridProps = {
    selected?: string;
    roots: GridOption[];
    changeSelection?: (value: string, root: GridOption) => void;
}

const ContextGrid: React.FC<ContextGridProps> = ({ selected, roots, changeSelection, ...props }) => {
    const [hideOptions, setHideOptions] = React.useState(false);
    const { getStaticText } = useLanguage([TC.ID_CARD_SHOW_OPTIONS, TC.ID_CARD_HIDE_OPTIONS]);

    const alphabeticalSort = React.useCallback((a: GridOption, b: GridOption) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
    }, []);

    const goodRoots = React.useMemo(() => TB.getArray(roots).filter(r => TB.validObject(r) && TB.validString(r.label) && TB.validString(r.value)), [roots]);

    const sortedRoots = React.useMemo(() => goodRoots.sort(alphabeticalSort), [goodRoots, alphabeticalSort]);

    const hideShowButton = React.useMemo(() => <Col md={3} sm={6} xl={2}>
        <Button style={{ textOverflow: "ellipsis" }} variant="secondary" onClick={() => setHideOptions(p => !p)} className="w-100 h-100">
            {getStaticText(hideOptions ? TC.ID_CARD_SHOW_OPTIONS : TC.ID_CARD_HIDE_OPTIONS)}
        </Button>
    </Col>, [hideOptions, getStaticText]);

    return <>
        {sortedRoots.length > 1 && <Row className="g-1">
            {hideShowButton}
            {!hideOptions && sortedRoots.map(rootObj => <Col key={rootObj.value} md={3} sm={6} xl={2}>
                <Button style={{ textOverflow: "ellipsis" }} className={`w-100 h-100 ${rootObj.value === selected ? "active" : ""}`} onClick={() => changeSelection(rootObj.value, rootObj)}>
                    {rootObj.label}
                </Button>
            </Col>)}
        </Row>}
    </>;
}

export default ContextGrid;