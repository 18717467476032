import React from "react";
import * as I from "../types";

export type Props<A = object> = {
    /** A function that will render the content of the cell */
    render?: (data: A, field: string, all_props: I.CellProps<Props>) => React.ReactElement;
};

export const type = "agCustomFreeRender";

export const Cell = React.forwardRef<object, I.CellProps<Props>>((props, ref) => {
    return props.colDef?.params?.render?.(props.data, props.colDef?.field, props) || null;
});

Cell.displayName = "AG_Free_Cell";