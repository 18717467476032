import axios from "axios";
import { getHeader } from "../Headers";
import { APIS, T } from "../../Constants";

const { DATA_API, API_URL } = APIS;

type MoveNode = T.API.ToAxios<T.API.Utils.Sub.MoveNode>;
type AttachNode = T.API.ToAxios<T.API.Utils.Sub.AttachNode>;
type RemoveNodes = T.API.ToAxios<T.API.Utils.Sub.RemoveNodes>;
type CreateSubmission = T.API.ToAxios<T.API.Utils.Sub.CreateSubmission>;
type GetSubmissionType = T.API.ToAxios<T.API.Utils.Sub.GetSubmissionType>;
type AutoCreateEmplacements = T.API.ToAxios<T.API.Utils.Sub.AutoCreateEmplacements>;
type AutoCreateBrandsModels = T.API.ToAxios<T.API.Utils.Sub.AutoCreateBrandsModels>;

type ApplyDataChange = (items: T.DataChange[]) => T.AxiosPromise<T.BulkResults>;
type DuplicateSubmissions = <A extends object>(id: string, multiple?: number) => T.AxiosPromise<T.Submission<A>[]>;

export const applyDataChange: ApplyDataChange = items => axios.post(DATA_API + "dataChange", { items }, getHeader("EDIT"));
export const duplicateSubmissions: DuplicateSubmissions = (id, multiple) => axios.post(API_URL + "sub/duplicate", { id, multiple }, getHeader("CREATE"));

/** Move a node from one point to another */
export const moveNode: MoveNode = params => axios.post(API_URL + "move", params, getHeader("EDIT"));
/** Attach a submission under a parent, or use a sibling to find his parents */
export const attachNode: AttachNode = params => axios.post(API_URL + "attach", params, getHeader("EDIT"));
/** Remove a nodes and it's links */
export const removeNodes: RemoveNodes = ids => axios.post(API_URL + "remove", { ids }, getHeader("DELETE"));
/** Get the type of submission for an existing submission */
export const getSubmissionType: GetSubmissionType = id => axios.get(API_URL + "sub/type", { params: { id } });
/** Create a new submission, and possibly attach it to another node */
export const createSubmission: CreateSubmission = params => axios.post(API_URL + "sub/create", params, getHeader("CREATE"));
/** Auto-Create all missing floors and locals form the emplacement mapper */
export const autoCreateEmplacements: AutoCreateEmplacements = params => axios.post(API_URL + "sub/auto_emplacements", params, getHeader("CREATE"));
/** Auto-Create all missing brands and models from the brand mapper */
export const autoCreateBrandsModels: AutoCreateBrandsModels = mapping => axios.post(API_URL + "sub/auto_brands_models", { mapping }, getHeader("CREATE"));