import _ from "lodash";
import moment from "moment";
import * as React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import { Form } from "../../../Common";
import * as S from "../../../services/user.service";
import { TB, EC, FP, LT, TC, T } from "../../../Constants";
import AttachMultipleObjParents from "../../../helpers/AttachMultipleObjParents";

const CellPanel = ({ client, site, bails, cells, addEntity, relatedLinks, linkTypes, forms, addNote, onUpdateDescendants, onUpdateTree, onUpdateBails, ...props }) => {
    const lg = H.useLanguage();
    const [{ user, isAdmin }] = H.useAuth();
    const [location, setLocations] = React.useState([]);
    const [activeCell, setActiveCell] = React.useState();
    const [isRentCell, setIsRentCell] = React.useState(true);
    const [developedBuild, setDevelopedBuild] = React.useState([]);

    //#region Forms
    const clientFormId = React.useMemo(() => forms?.filter?.(({ path }) => path === FP.CLIENT_FORM)?.[0]?._id, [forms]);
    const enseigneFormId = React.useMemo(() => forms?.filter?.(({ path }) => path === FP.ENSEIGNE_FORM)?.[0]?._id, [forms]);
    const emplacementFormId = React.useMemo(() => forms?.filter?.(({ path }) => path === FP.EMPLACEMENT_FORM)?.[0]?._id, [forms]);
    //#endregion

    //#region LinkTypes
    const ownLinkType = React.useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_OWN)?.[0]?._id, [linkTypes]);
    const rentalLinkType = React.useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_RENTAL)?.[0]?._id, [linkTypes]);
    // const enseigneLinkType = React.useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_ENSEIGNE)?.[0]?._id, [linkTypes]);
    const exploitationLinkType = React.useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_EXPLOITATION)?.[0]?._id, [linkTypes]);
    //#endregion

    //#region Utilities
    const cellsList = React.useMemo(() => Array.isArray(cells) ? cells : [], [cells]);
    const bailsList = React.useMemo(() => Array.isArray(bails) ? bails : [], [bails]);
    const links = React.useMemo(() => Array.isArray(relatedLinks) ? relatedLinks : [], [relatedLinks]);

    const cellsBailsClients = React.useMemo(() => cellsList.map(({ _id, children, parents, ...r }) => {
        let cellClients = parents.filter(({ form }) => form === clientFormId)
            .filter(client => links.filter(({ input, output, type }) => input === client?._id && output === _id && type === rentalLinkType).length > 0);

        let exploiter = parents.filter(({ form }) => form === clientFormId)
            .filter(ent => links.filter(({ input, output, type }) => input === ent?._id && output === _id && type === exploitationLinkType).length > 0)?.[0];

        let clients = cellClients.map(c => {
            let clientLinks = links.filter(({ input, output, type }) => input === c?._id && output === _id && type === rentalLinkType).map(({ _id }) => _id).filter(TB.mongoIdValidator);
            let bail = bailsList.filter(({ data }) => clientLinks.includes(data.link))?.[0];
            return { ...c, bail };
        });

        if (clients.length === 0) clients = [{}];
        return { _id, children, clients, exploiter, parents, ...r };
    }), [cellsList, links, bailsList, clientFormId, rentalLinkType, exploitationLinkType]);

    const showErrorModal = React.useCallback(errorCode => M.renderErrorModal({ errorCode }), []);
    //#endregion

    //#region Location
    const locationsEntityId = React.useMemo(() => location.filter(({ error }) => !error).map(({ id }) => id), [location]);

    const locationObj = React.useMemo(() => Object.fromEntries(
        location.map(({ id, building, emplacement, local, parking, error }) => [id, { building, emplacement, local, parking, error }])
            .map(([key, obj]) => {
                if (obj?.error) return [key, Object.fromEntries(Object.keys(obj).map(key => [key, { data: { name: "ERROR" } }]))];
                let filterObject = Object.fromEntries(Object.entries(obj).map(([key, array]) => [key, array?.[0] ?? array]));
                return [key, filterObject];
            })
    ), [location]);

    const getEmplacement = React.useCallback(id => {
        let keys = ["parking", "local", "emplacement"];
        let value = keys.map(key => locationObj?.[id]?.[key]?.data?.name).filter(TB.validString)?.[0];
        return TB.validString(value) ? value : "";
    }, [locationObj]);

    React.useEffect(() => {
        let isSubscribed = true;

        let toSearch = cellsList.map(({ _id }) => _id).filter(id => !locationsEntityId.includes(id));
        if (toSearch.length > 0) S.itemLocater(toSearch).then(({ data }) => {
            if (!isSubscribed) return;
            if (Array.isArray(data)) {
                let foundIds = data.map(({ id }) => id);

                if (data.length > 0) setLocations(prev => prev.filter(({ id }) => !foundIds.includes(id)).concat(data));
                else setLocations(prev => prev.concat(toSearch.filter(({ id }) => !foundIds.includes(id)).map(id => ({ id }))));
            }
            else setLocations(prev => _.uniqBy(prev.concat(toSearch.map(id => ({ id, error: true }))), "id"));
        })

        return () => {
            isSubscribed = false;
        }
    }, [cellsList, locationsEntityId]);
    //#endregion

    //#region Buildings
    const buildings = React.useMemo(() => _.uniqBy(cellsList.map(({ building }) => building).filter(b => TB.mongoIdValidator(b?._id)), "_id"), [cellsList]);
    const buildLabelObj = React.useMemo(() => Object.fromEntries(buildings.map(({ _id, data }) => [_id, data?.name])), [buildings]);
    //#endregion

    //#region Enseignes
    const enseignes = React.useMemo(() => _.uniqBy(cellsList.map(({ enseigne }) => enseigne).filter(e => TB.mongoIdValidator(e?._id)), "_id"), [cellsList]);
    const enseigneLabelObj = React.useMemo(() => Object.fromEntries(enseignes.map(({ _id, data }) => [_id, data?.name])), [enseignes]);
    //#endregion

    //#region Callbacks
    const siteId = React.useMemo(() => site?._id, [site]);
    const stringedDate = React.useCallback(date => isNaN(Date.parse(date)) ? '' : moment(date).format("DD/MM/YYYY"), [])

    const onChangeCell = React.useCallback(async (_id, key, val) => {
        let update = Object.fromEntries([["data." + key, val]]);
        let reply = await S.updateSubmission(_id, update);
        if (TB.mongoIdValidator(reply?.data?._id)) onUpdateDescendants?.(prev => prev.map(n => {
            if (n._id === _id) n.data[key] = val;
            return n;
        }));
        else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
    }, [onUpdateDescendants, showErrorModal]);

    const onClickPromptCell = React.useCallback((_id, key, oldVal) => {
        M.askPrompt({ isRequired: true, defaultVal: oldVal }).then(val => {
            if (TB.validString(val)) onChangeCell(_id, key, val);
        });
    }, [onChangeCell]);

    const onClickPromptOrSelect = React.useCallback((_id, formId, key, oldVal, cellId) => {
        let title = (formId === enseigneFormId ? TC.GLOBAL_LABEL_ENSEIGNE : TC.GLOBAL_LABEL_RENTER);

        // Select other or rename current
        if (TB.mongoIdValidator(_id)) {

            M.askConfirm({ text: "Renommer ou choisir autre déja existant", title, yesText: "renommer" }).then(async confirm => {
                // Rename
                if (confirm) onClickPromptCell(_id, key, oldVal);
                // Select other
                else if (confirm === undefined) {
                    let unmount = M.renderLoader();
                    let replyValues = await S.advancedSearch(client, undefined, false, undefined, formId, true);
                    unmount();

                    if (!Array.isArray(replyValues.data)) showErrorModal(EC.CODE_LOAD_DATA_FAIL);
                    else {
                        let options = replyValues.data.sort(TB.sortByNameSubmission).map(({ _id, data }) => ({ value: _id, label: data?.name }));
                        M.askSelect({ isRequired: true, defaultVal: _id, title, options }).then(async newVal => {
                            if (TB.mongoIdValidator(newVal) && newVal !== _id) {
                                let oldLink = { input: _id, output: cellId, type: rentalLinkType }; //OK
                                // if (formId === enseigneFormId) oldLink = { input: _id, output: cellId, type: enseigneLinkType }; //TODO update cellId par locataire id

                                let newLink = { input: newVal, output: cellId, type: rentalLinkType }; //OK
                                // if (formId === enseigneFormId) newLink = { input: newVal, output: cellId, type: enseigneLinkType }; //TODO update cellId par locataire id
                                let replyUpdate = await S.updateManyLinks(oldLink, newLink);
                                if (replyUpdate?.data?.ok === 1) onUpdateTree?.(({ descendance, links }) => ({
                                    descendance,
                                    links: links.map(link => {
                                        if (link.input === oldLink.input && link.output === oldLink.output && link.type === oldLink.type) return newLink;
                                        return link;
                                    })
                                }));
                                else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                            }
                        })
                    }
                }
            });
        }
        // Create a new one
        else M.renderFormModal({ title, _id: formId }).then(async submission => {
            if (TB.mongoIdValidator(submission?._id)) {
                let newLink = { input: submission?._id, output: cellId, type: rentalLinkType };

                let reply = await AttachMultipleObjParents([newLink], user);
                if (reply) onUpdateTree?.(({ descendance, links }) => ({
                    descendance: descendance.concat(submission),
                    links: links.concat(newLink)
                }));
                else {
                    S.removeSubmission(submission._id);
                    showErrorModal(EC.CODE_DB_UPDATE_FAIL)
                };
            }
        });
    }, [enseigneFormId, rentalLinkType, user, onClickPromptCell, showErrorModal, onUpdateTree, client]);

    const onClickExploiter = React.useCallback(async (id, oldVal, cellId) => {
        // Select other or rename current
        if (TB.mongoIdValidator(id)) M.askConfirm({
            text: "Renommer ou choisir autre déja existant",
            title: "Exploitant",
            yesText: "Renommer",
            extraOptions: resolve => <button onClick={() => resolve(undefined)} className="btn btn-primary">Select other</button>
        }).then(async confirm => {
            // Rename
            if (confirm) onClickPromptCell(id, "name", oldVal);
            // Select Other
            else if (confirm === undefined) {
                let unmount = M.renderLoader();
                let replyValues: any = {};
                if (isAdmin) replyValues = await S.getManySubmissionsFromFilter({ form: clientFormId }, 1000);
                else replyValues = await S.accessibleNodesUser(user._id, undefined, clientFormId, false, false);
                unmount();

                if (!Array.isArray(replyValues.data)) showErrorModal(EC.CODE_LOAD_DATA_FAIL);
                else {
                    let options = replyValues.data.sort(TB.sortByNameSubmission).map(({ _id, data }) => ({ value: _id, label: data?.name }));
                    M.askSelect({ isRequired: true, defaultVal: id, title: "Exploitant", options }).then(async newVal => {
                        if (TB.mongoIdValidator(newVal) && newVal !== id) {
                            let oldLink = { input: id, output: cellId, type: exploitationLinkType };
                            let newLink = { input: newVal, output: cellId, type: exploitationLinkType };

                            let replyUpdate = await S.updateManyLinks(oldLink, newLink);
                            if (replyUpdate?.data?.ok === 1) onUpdateTree?.(({ descendance, links }) => ({
                                descendance,
                                links: links.map(link => {
                                    if (link.input === oldLink.input && link.output === oldLink.output && link.type === oldLink.type) return newLink;
                                    return link;
                                })
                            }));
                            else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                        }
                    });
                }
            }
        });
        // Create a new one
        else if (TB.multiMongoIdValidator([activeCell, rentalLinkType, clientFormId])) {
            let unmount = M.renderLoader();
            let replyValues: any = {};
            if (isAdmin) replyValues = await S.getManySubmissionsFromFilter({ form: clientFormId }, 1000);
            else replyValues = await S.accessibleNodesUser(user?._id, undefined, clientFormId, false, false);
            unmount();

            if (Array.isArray(replyValues.data)) {
                let options = replyValues.data.map(({ _id, data }) => ({ label: data?.name, value: _id }));
                M.askSelect({ title: "Exploitant", noSelectionText: TC.CREAT_NEW, options }).then(async renter => {
                    // Link the existing renter to the cell
                    if (TB.mongoIdValidator(renter)) {
                        let link = { input: renter, output: activeCell, type: exploitationLinkType };
                        let unmount_2 = M.renderLoader();
                        let reply = await AttachMultipleObjParents([link], user);
                        let sub = await S.getSubmission(renter);
                        unmount_2();

                        if (reply === true && TB.mongoIdValidator(sub?.data?._id)) onUpdateTree(({ links, descendance }) => ({
                            links: links.concat(link),
                            descendance: descendance.filter(({ _id }) => _id !== renter).concat(sub.data),
                        }));
                        else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                    }
                    // Create a new renter
                    else if (renter === undefined) M.renderFormModal({ title: "Exploitant", path: FP.CLIENT_FORM }).then(async renter => {
                        if (TB.mongoIdValidator(renter?._id)) {
                            let link: Omit<T.Link, "_id"> = { input: renter._id, output: activeCell, type: exploitationLinkType };
                            let unmount = M.renderLoader();
                            let reply = await AttachMultipleObjParents([link], user);
                            unmount();
                            if (reply === true) onUpdateTree(({ links, descendance }) => ({
                                links: links.concat(link),
                                descendance: descendance.concat(renter)
                            }));
                            else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                        }
                    });
                });
            }
            else showErrorModal(EC.CODE_LOAD_DATA_FAIL);
        }
    }, [clientFormId, exploitationLinkType, isAdmin, activeCell, rentalLinkType, user, onClickPromptCell, showErrorModal, onUpdateTree]);

    const onClickBail = React.useCallback((cellId, clientId, bailId, key, oldVal) => {
        // Create a new Bail
        if (!TB.mongoIdValidator(bailId)) {
            let affectation = cellsList.filter(({ _id }) => _id === cellId)?.[0]?.data?.actifType ?? undefined;
            let link = links.filter(({ input, output, type }) => input === clientId && output === cellId && type === rentalLinkType)?.[0]?._id;

            M.renderFormModal({ path: FP.BAIL_FORM, title: TC.GLOBAL_LABEL_BAIL, forcedSubmission: TB.submissionToArrayUpdate({ affectation, link }) }).then(bail => {
                if (TB.mongoIdValidator(bail?._id)) onUpdateBails?.(prev => prev.concat(bail));
            });
        }
        // Update an existing one
        else {
            let promise;

            if (["start", "end"].includes(key)) promise = M.askTime({ required: true, date: oldVal });
            else if (key === "break") promise = M.askPrompt({
                isRequired: true,
                defaultVal: oldVal,
                isNumberPrompt: true,
                numberProps: { min: 0 },
            });
            else promise = M.askPrompt({ isRequired: true, defaultVal: oldVal });

            promise.then(async newVal => {
                if (newVal !== null) {
                    let update = Object.fromEntries([["data." + key, newVal]]);
                    let reply = await S.updateSubmission(bailId, update);
                    if (TB.mongoIdValidator(reply?.data?._id)) onUpdateBails?.(prev => prev.map(b => {
                        if (b._id === bailId) b.data[key] = newVal;
                        return b;
                    }))
                    else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                }
            });
        }
    }, [cellsList, links, rentalLinkType, showErrorModal, onUpdateBails]);

    const onAddRenter = React.useCallback(async () => {
        if (TB.multiMongoIdValidator([activeCell, rentalLinkType, clientFormId])) {
            let unmount = M.renderLoader();
            let replyValues: any = {};
            if (isAdmin) replyValues = await S.getManySubmissionsFromFilter({ form: clientFormId }, 1000);
            else replyValues = await S.accessibleNodesUser(user?._id, undefined, clientFormId, false, false);
            unmount();

            if (Array.isArray(replyValues.data)) {
                let options = replyValues.data.map(({ _id, data }) => ({ label: data?.name, value: _id }));
                M.askSelect({ title: "Locataire", noSelectionText: TC.CREAT_NEW, options }).then(async renter => {
                    // Link the existing renter to the cell
                    if (TB.mongoIdValidator(renter)) {
                        let link = { input: renter, output: activeCell, type: rentalLinkType };
                        let unmount_2 = M.renderLoader();
                        let reply = await AttachMultipleObjParents([link], user);
                        let sub = await S.getSubmission(renter);
                        unmount_2();

                        if (reply === true && TB.mongoIdValidator(sub?.data?._id)) onUpdateTree(({ links, descendance }) => ({
                            links: links.concat(link),
                            descendance: descendance.filter(({ _id }) => _id !== renter).concat(sub.data),
                        }));
                        else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                    }
                    // Create a new renter
                    else if (renter === undefined) M.renderFormModal({ title: "Locataire", path: FP.CLIENT_FORM }).then(async renter => {
                        if (TB.mongoIdValidator(renter?._id)) {
                            let link = { input: renter._id, output: activeCell, type: rentalLinkType };
                            let unmount = M.renderLoader();
                            let reply = await AttachMultipleObjParents([link], user);
                            unmount();
                            if (reply === true) onUpdateTree(({ links, descendance }) => ({
                                links: links.concat(link),
                                descendance: descendance.concat(renter)
                            }));
                            else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                        }
                    });
                });
            }
            else showErrorModal(EC.CODE_LOAD_DATA_FAIL);
        }
    }, [activeCell, rentalLinkType, clientFormId, isAdmin, user, showErrorModal, onUpdateTree]);

    const addCell = React.useCallback(() => {
        M.renderLightTree({
            root: siteId,
            restrictOwnLinks: true,
            style: { size: "md", title: "Select parent" },
            linkRestriction: { objForm: emplacementFormId, linkType: LT.LINK_TYPE_OWN, isInput: false },
        }).then(item => {
            if (TB.mongoIdValidator(item)) {
                let sub = { isRentable: isRentCell, type: "local" };
                M.renderFormModal({ title: TC.GLOBAL_LABEL_EMPLACEMENT, path: FP.EMPLACEMENT_FORM, forcedSubmission: TB.submissionToArrayUpdate(sub) }).then(async cell => {
                    if (TB.mongoIdValidator(cell?._id)) {
                        let unmount = M.renderLoader();
                        let newLink = { input: item, output: cell._id, type: ownLinkType };
                        let reply = await AttachMultipleObjParents([newLink], user);
                        unmount();
                        if (reply === true) onUpdateTree(({ descendance, links }) => ({
                            descendance: descendance.concat(cell),
                            links: links.concat(newLink),
                        }));
                        else {
                            S.removeSubmission(cell._id);
                            showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                        }
                    }
                })
            }
        });
    }, [siteId, emplacementFormId, isRentCell, ownLinkType, user, showErrorModal, onUpdateTree]);
    //#endregion

    //#region Rentable vs Private
    const sortByBuild = React.useCallback<(a: [string, any[]], b: [string, any[]]) => number>(([a], [b]) => {
        if (![a, b].every(TB.mongoIdValidator)) return TB.mongoIdValidator(a) ? -1 : 1;

        let buildA = buildLabelObj[a], buildB = buildLabelObj[b];
        if (buildA > buildB) return 1;
        if (buildA < buildB) return -1;
        return 0;
    }, [buildLabelObj]);

    const filteredCells = React.useMemo(() => cellsBailsClients.filter(({ data }) => Boolean(data.isRentable) === isRentCell), [cellsBailsClients, isRentCell]);
    const groupedByBuildingBis = React.useMemo(() => Object.entries(_.groupBy(filteredCells, "building._id")).sort(sortByBuild), [filteredCells, sortByBuild]);

    React.useEffect(() => {
        let filteredIds = cellsList.map(({ _id }) => _id);
        if (!filteredIds.includes(activeCell)) setActiveCell(filteredIds?.[0]);
    }, [cellsList, activeCell]);
    //#endregion

    //#region Nav Bar Callbacks
    const onClickCaret = React.useCallback(buildId => setDevelopedBuild(prev => {
        if (prev.indexOf(buildId) === -1) return prev.concat(buildId);
        return prev.filter(id => id !== buildId);
    }), []);
    //#endregion

    //#region Nav Bar
    const navHeader = React.useMemo(() => <div className="modal-header p-0 border-bottom-0">
        <ul className="nav nav-tabs nav-fill w-100">
            <li className="nav-item">
                <button onClick={() => setIsRentCell(true)} className={`nav-link ${isRentCell ? "active" : ""}`}>Locative</button>
            </li>
            <li className="nav-item">
                <button onClick={() => setIsRentCell(false)} className={`nav-link ${isRentCell ? "" : "active"}`}>Commune</button>
            </li>
        </ul>
    </div>, [isRentCell]);

    const navFooter = React.useMemo(() => <div className="modal-footer p-1 border-top-0">
        <button onClick={addCell} className="btn btn-primary">
            <i className="fa fa-plus mr-2"></i> Nouvelle Cellule
        </button>
    </div>, [addCell]);

    const emptyBody = React.useMemo(() => <div className="position-absolute top-50 start-50 translate-middle text-center">
        <div className="alert alert-secondary">
            {isRentCell ? "Aucune Cellules" : "Aucun Emplacement"}
        </div>
    </div>, [isRentCell]);

    const navBar = React.useMemo(() => <div className="modal-content h-100">
        {navHeader}
        <div className="modal-body flex-grow-1 overflow-scroll position-relative" style={{ maxHeight: "42.5rem" }}>
            {groupedByBuildingBis.length === 0 ? emptyBody : <ul className="list-group">
                {groupedByBuildingBis.map(([buildId, cells]) => <li className="list-group-item p-2" key={buildId} style={{ backgroundColor: "#EEEEEE" }}>
                    <div onClick={() => onClickCaret(buildId)} className="d-flex align-items-center pointer">
                        <div className="flex-shrink-1 p-1 mr-1">
                            <i className={`fa fa-caret-${developedBuild.includes(buildId) ? "down" : "right"}`}></i>
                        </div>

                        <div>{TB.mongoIdValidator(buildId) ? buildLabelObj[buildId] : <em>Sans Bâtiment</em>}</div>
                    </div>
                    <div hidden={!developedBuild.includes(buildId)} className="animated_swing_in">
                        <ul className="list-group">
                            {cells.map(({ _id, data, note }) => <li onClick={() => setActiveCell(_id)} className={`list-group-item p-2 pointer ${activeCell === _id ? "active" : ""}`} key={_id}>
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{data?.name}{typeof note === "number" ? ` (${note}%)` : ""}</div>
                                    {getEmplacement(_id)}
                                </div>
                            </li>)}
                        </ul>
                    </div>
                </li>)}
            </ul>}
        </div>
        {navFooter}
    </div>, [navFooter, activeCell, navHeader, emptyBody, groupedByBuildingBis, buildLabelObj, developedBuild, onClickCaret, getEmplacement]);
    //#endregion

    //#region Content
    const { clients, enseigne, exploiter, ...currentCell } = React.useMemo(() => cellsBailsClients.filter(({ _id }) => _id === activeCell)?.[0] ?? {}, [cellsBailsClients, activeCell]);
    const hasClients = React.useMemo(() => Array.isArray(clients) && clients.length > 0, [clients]);

    const cellPanel = React.useMemo(() => <div className="row">
        <div className="col-md-12">
            <p className="h4">Cellule</p>
        </div>
        <div className="col-md-4 pointer" onClick={() => onClickPromptCell(currentCell._id, "name", currentCell.data?.name)}>
            <label className="form-label mt-0">{lg.getStaticText(TC.GLOBAL_NAME)}</label>
            <input className="form-control pointer" value={currentCell?.data?.name ?? ""} />
        </div>
        <div className="col-md-3 d-flex align-items-center justify-content-center flex-column">
            <label className="form-label">Est une cellule locative</label>
            <Form.CheckBox
                hideLabel
                label="Est une cellule locative"
                value={currentCell?.data?.isRentable}
                onChange={val => onChangeCell(currentCell._id, "isRentable", val)}
            />
        </div>
        <div className="col-md-3 d-flex align-items-center justify-content-center flex-column">
            <div className="btn-group mt-2">
                <button onClick={() => addEntity?.(siteId, currentCell._id)} className="btn btn-primary">
                    <i className="fa fa-tachometer-alt mr-2"></i>{lg.getStaticText(TC.GLOBAL_LABEL_ENTITY)}
                </button>
                <button onClick={() => addNote(currentCell._id)} className="btn btn-secondary">
                    <i className="fa fa-comment-alt mr-2"></i>Notes
                </button>
            </div>
        </div>
    </div>, [currentCell, siteId, onClickPromptCell, onChangeCell, addEntity, addNote]);

    const enseignePanel = React.useMemo(() => <div className="row">
        <div className="col-md-12">
            <p className="h4">Enseigne</p>
        </div>
        <div className="col-md-4 pointer">
            <label className="form-label mt-0">{lg.getStaticText(TC.GLOBAL_NAME)}</label>
            <input disabled className="form-control pointer" value={enseigneLabelObj[enseigne?._id] ?? ""} />
        </div>
    </div>, [enseigneLabelObj, enseigne]);

    const renterPanel = React.useMemo(() => <div className="row">
        <div className="col-md-12">
            <span className="h4">Locataire</span>
            <button onClick={onAddRenter} className="btn btn-sm btn-secondary ml-3">
                <i className="fa fa-plus"></i>
            </button>
        </div>
        <div className="col-md-11 mt-2">
            {hasClients && clients.map(({ _id, data }, i) => <div key={_id} className={`row ${clients.length > 1 ? "border rounded p-2 bg-white" : ""} ${i === 0 ? "" : "border-top-0"}`}>
                <div className="col-md-4 pointer" onClick={TB.mongoIdValidator(_id) ? () => onClickPromptOrSelect(_id, clientFormId, "name", data?.name, currentCell?._id) : onAddRenter}>
                    <label className="form-label">Nom</label>
                    <input className="form-control pointer" value={data?.name ?? ""} />
                </div>
                <div className="col-md-4 pointer" onClick={() => onClickPromptCell(_id, 'numSiren', data?.numSiren)}>
                    <label className="form-label">TVA / SIREN</label>
                    <input className="form-control pointer" value={data?.numSiren ?? ""} />
                </div>
                <div className="col-md-2 d-flex align-items-center justify-content-center flex-column">
                    <label className="form-label">Est une franchise</label>
                    <button className="btn-none" onClick={() => onChangeCell(_id, 'isFranchise', !data?.isFranchise)}>
                        <i className={`fa fa-check text-success mr-2 ${data?.isFranchise ? "fa-2x" : ""}`}></i>
                        <i className={`fa fa-times text-danger ml-2 ${data?.isFranchise ? "" : "fa-2x"}`}></i>
                    </button>
                </div>
            </div>)}
            {!hasClients && <div className="alert alert-secondary text-center m-2">
                Pas de locataire
            </div>}
        </div>
    </div>, [clients, currentCell, clientFormId, hasClients, onClickPromptOrSelect, onClickPromptCell, onChangeCell, onAddRenter]);

    const bailPanel = React.useMemo(() => <div className="row">
        <div className="col-md-12">
            <p className="h4">Bail</p>
        </div>
        <div className="col-md-11">
            {hasClients && clients.map(({ _id, bail, data }, i) => <div key={_id} className={`row ${clients.length > 1 ? "p-2 border rounded bg-white" : ""} ${i === 0 ? "" : "border-top-0"}`}>
                <div className="col-md-4 d-flex justify-content-center flex-column">
                    <label className="form-label">Client</label>
                    <span className="h5">{data?.name}</span>
                </div>
                <div className="col-md-4 pointer" onClick={() => onClickBail(currentCell?._id, _id, bail?._id, "start", bail?.data?.start)}>
                    <label className="form-label">Date de début</label>
                    <input className="form-control" value={stringedDate(bail?.data?.start)} />
                </div>
                <div className="col-md-4 pointer" onClick={() => onClickBail(currentCell?._id, _id, bail?._id, "end", bail?.data?.end)}>
                    <label className="form-label">Date de fin</label>
                    <input className="form-control" value={stringedDate(bail?.data?.end)} />
                </div>
                <div className="col-md-2 pointer" onClick={() => onClickBail(currentCell?._id, _id, bail?._id, "break", bail?.data?.break)}>
                    <label className="form-label">Break</label>
                    <input className="form-control" value={bail?.data?.break ?? ""} />
                </div>
                <div className="col-md-5 pointer" onClick={() => onClickBail(currentCell?._id, _id, bail?._id, "affectation", bail?.data?.affectation)}>
                    <label className="form-label">Affectation</label>
                    <input className="form-control" value={bail?.data?.affectation ?? ""} />
                </div>
                <div className="col-md-5 pointer" onClick={() => onClickBail(currentCell?._id, _id, bail?._id, "loadDistribution", bail?.data?.loadDistribution)}>
                    <label className="form-label">Répartition des charges</label>
                    <input className="form-control" value={bail?.data?.loadDistribution ?? ""} />
                </div>
            </div>)}
            {!hasClients && <div className="alert alert-secondary text-center m-2">
                Pas de locataire
            </div>}
        </div>
    </div>, [clients, hasClients, currentCell, onClickBail, stringedDate]);

    const exploitersPanel = React.useMemo(() => <div className="row">
        <div className="col-md-12">
            <p className="h4">Exploitant</p>
        </div>
        <div className="col-md-4 pointer" onClick={() => onClickExploiter(exploiter?._id, exploiter?.data?.name, currentCell?._id)}>
            <label className="form-label">{lg.getStaticText(TC.GLOBAL_NAME)}</label>
            <input className="form-control" value={exploiter?.data?.name ?? ""} />
        </div>
        <div className="col-md-4 pointer" onClick={() => onClickPromptCell(exploiter?._id, 'numSiren', exploiter?.data?.numSiren)}>
            <label className="form-label">TVA / SIREN</label>
            <input className="form-control pointer" value={exploiter?.data?.numSiren ?? ""} />
        </div>
    </div>, [currentCell, exploiter, onClickExploiter, onClickPromptCell]);

    const noSelection = React.useMemo(() => <div className="position-absolute top-50 start-50 translate-middle text-center">
        <div className="alert alert-secondary">
            Aucune cellule selectionnée
        </div>
    </div>, []);

    const fullContent = React.useMemo(() => [
        { content: cellPanel },
        { content: enseignePanel, hide: !currentCell?.data?.isRentable },
        { content: renterPanel, hide: !currentCell?.data?.isRentable },
        { content: bailPanel, hide: !currentCell?.data?.isRentable },
        { content: exploitersPanel, hide: !currentCell?.data?.isRentable },
    ].filter(({ hide }) => !hide), [enseignePanel, bailPanel, exploitersPanel, cellPanel, currentCell, renterPanel])
    //#endregion

    return <div className="p-2 row g-1 h-100">
        <div className="col-md-3 h-100">
            {navBar}
        </div>
        <div className="col-md-9 h-100 position-relative overflow-scroll" style={{ maxHeight: "47.5rem" }}>
            {TB.mongoIdValidator(activeCell) ? fullContent.map(({ content }, i) => <div className="my-3" key={i}>{content}</div>) : noSelection}
        </div>
    </div>
}

export default CellPanel;