import axios from "axios";
import { T, APIS } from "../../Constants";

const { REPORT_URL } = APIS;

type HtmlReport = T.API.ToAxios<T.API.Report.HtmlReport>;
type FileReport = T.API.ToAxios<T.API.Report.FileReport>;
type MultipleFileReport = T.API.ToAxios<T.API.Report.MultipleFileReport>;
type EquipmentReport = T.API.ToAxios<T.API.Report.Equipment.EquipmentReport>;

/** Generate a list of equipments, and some useful properties translations, for an excel EDL */
export const equipmentReport: EquipmentReport = context => axios.post(REPORT_URL + "equipments/table", context);
/** Get an html page from jsReport */
export const html_report: HtmlReport = params => axios.post(REPORT_URL + "downloadReportHTML/" + (params?.template || "x"), params?.params);
/** Get multiple file generated from jsReport into a single zip */
export const multi_file_report: MultipleFileReport = templates => axios.post(REPORT_URL + "zip", { templates }, { responseType: "arraybuffer" });
/** Get a file generated from jsReport */
export const file_report: FileReport = params => axios.post(REPORT_URL + "downloadReport/" + (params?.template || "x") + "/" + (params?.filetype || "word"), params?.params, { responseType: "arraybuffer" });