import React from 'react';
import { TB } from "../../Constants";
import Background from './Background';
import * as BS from 'react-bootstrap';

type SectionProps = {
    bg?: string;
    image?: string;
    fluid?: boolean;
    video?: string[];
    className?: string;
    bgClassName?: string;
    overlay?: boolean | string;
    children?: React.ReactNode;
    position?: string | { x: string, y: string };
}

const Section: React.FC<SectionProps> = ({ fluid = false, bg, image, overlay, position, video, bgClassName, className, children, ...props }) => {
    const bgProps = React.useMemo(() => ({
        video,
        overlay,
        position,
        image: TB.validString(image) ? image : "",
        className: TB.validString(bgClassName) ? bgClassName : undefined
    }), [image, overlay, bgClassName, position, video]);

    const sectionClassName = React.useMemo(() => `${TB.validString(bg) ? "bg-" + bg : ""} ${TB.validString(className) ? className : ""}`, [className, bg]);

    return <section className={sectionClassName} {...props}>
        {image && <Background {...bgProps} />}

        <BS.Container fluid={fluid} children={children} />
    </section>;
};

export default Section;
