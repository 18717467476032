import React from "react";
import { Flex } from "../Layout";
import * as H from "../../hooks";
import { TB } from "../../Constants";
import { CloseButton } from "react-bootstrap";
import { CheckCircleOutline, ErrorOutline, Info, Warning, } from "@material-ui/icons";

export type ErrorBannerProps = {
    message?: string;
    textCode?: string;
    canHide?: boolean;
    size?: "sm" | "md";
    showIcon?: boolean;
    close?: () => void;
    children?: React.ReactNode;
    type: "danger" | "warning" | "info" | "success";
    /** Do remove all margins */
    no_margins?: boolean;
    /** Use the default styling for the banner to fit the main page */
    is_main_page?: boolean;
}

const ErrorBanner: React.FC<ErrorBannerProps> = ({ showIcon = true, size = "md", canHide, children, type = "danger", textCode, message, close, ...props }) => {
    const hidden = H.useBoolean(false);
    const lg = H.useLanguage();

    const acceptedTypes = React.useMemo(() => ["success", "danger", "info", "warning"], []);
    const validType = React.useMemo(() => acceptedTypes.includes(type) ? type : "error", [acceptedTypes, type]);
    const validMessage = React.useMemo(() => TB.validString(message) ? lg.getStaticElem(message) : null, [message, lg]);

    const icon = React.useMemo(() => {
        switch (validType) {
            case "info": return <Info />;
            case "warning": return <Warning />;
            case "error": return <ErrorOutline />;
            case "success": return <CheckCircleOutline />;
            default: return <Info />;
        }
    }, [validType]);

    const onClose = React.useCallback(() => {
        if (typeof close === "function") close();
        else if (canHide) hidden.setFalse();
    }, [close, hidden, canHide]);

    const content = React.useMemo(() => children || <div>
        {textCode && <p className="m-0" children={lg.getStaticElem(textCode ?? "")} />}
        {validMessage && <p className="m-0" children={validMessage} />}
    </div>, [children, validMessage, lg, textCode]);

    const class_name = React.useMemo(() => {
        let classes = ["alert", `alert-${validType}`];
        // The side padding and margin
        if (size === "sm") classes.push(props.no_margins ? "p-1 m-0" : "p-1 my-2");
        else if (props.no_margins) classes.push("m-0");
        else classes.push("my-3");
        // Take the full width of the page
        if (props.is_main_page) classes.push("flex-grow-1");
        return classes.join(" ");
    }, [validType, props.is_main_page, props.no_margins, size]);

    return !hidden.value && <Flex className={class_name} alignItems="center" style={props.is_main_page ? { minHeight: "100px" } : undefined}>
        {(canHide || close) && <div className="me-3" children={<CloseButton onClick={onClose} />} />}
        {showIcon && <div className="me-3">{icon}</div>}
        {content}
    </Flex>;
}

export default ErrorBanner;