import axios from "axios";
import { T, APIS } from "../../Constants";

const { ADMIN_API } = APIS;

//#region Types
type BuildSitesList = () => T.AxiosPromise<{ [key in "buildings" | "sites"]: { _id: string, name: string, client?: string, site?: string }[] }>
//#endregion

export const buildSitesList: BuildSitesList = () => axios.get(ADMIN_API + "sitesBuilds");
export const adminUpdates = (type: T.AdminUpdates, data?: any) => axios.post(ADMIN_API + "updates/" + type, { data });