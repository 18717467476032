import axios from "axios";
import * as H from "./Headers";
import { FP, APIS, LM } from "../Constants";
const { API_URL, ADMIN_API, PARITY_API, MATRIX_API, DATA_API } = APIS;

//#region TODO Reorganize
export const getSiteAndBuilds = (roots, portfolio) => axios.post(API_URL + "siteBuild", { roots, portfolio });
//#endregion

//#region ADMIN_API
export const setUserApiKey = (user) => axios.post(ADMIN_API + "setUserApiKey", { user }, H.getHeader("EDIT"));
//#endregion

//#region Reg Doc
export const updateRegDocElemItem = (regFormId, elemId, update, isFormer) => axios.post(API_URL + "updateRegDocElemItem", { regFormId, elemId, update, isFormer }, H.getHeader("EDIT"));
//#endregion

//#region Gammes
export const getCategoryRelations = (gammes, isSearchParent) => axios.post(API_URL + "gammes/categoryRelations", { gammes, isSearchParent });
//#endregion

//#region SUBMISSIONS
export const getSubmission = id => axios.post(API_URL + "getSubmission", { id });
export const addPerms = perm => axios.post(API_URL + "addPerms", { perm }, H.getHeader("CREATE"));
export const removeSubmission = id => axios.post(API_URL + "removeSubmission", { id }, H.getHeader("DELETE"));
export const getFlagResources = () => axios.post(API_URL + "getFlagResources", { resource: FP.FLAGS_FORM });
export const getSubmissionFromFilter = filter => axios.post(API_URL + "getSubmissionFromFilter", { filter });
export const getFullResources = (resource, params) => axios.post(API_URL + "getFullResources", { resource, params });
export const bulkSubmissionAction = bulk => axios.post(API_URL + "bulkSubmissionAction", { bulk }, H.getHeader("OTHER"));
export const createSubmission = submission => axios.post(API_URL + "createSubmission", { submission }, H.getHeader("CREATE"));
export const createManySubmissions = array => axios.post(API_URL + "createManySubmissions", { array }, H.getHeader("CREATE"));
export const updateSubmission = (id, submission) => axios.post(API_URL + "updateSubmission", { id, submission }, H.getHeader("EDIT"));
export const updateFromFilter = (filter, newObj) => axios.post(API_URL + "updateFromFilter", { filter, newObj }, H.getHeader("EDIT"));
export const copySubmissions = (arrayIds, nbCopy) => axios.post(API_URL + 'copySubmissions', { arrayIds, nbCopy }, H.getHeader("CREATE"));
export const deleteSubmissionsAndRelatives = ids => axios.post(API_URL + "deleteSubmissionsAndRelatives", { ids }, H.getHeader("DELETE"));
export const removeManySubmissionsFromId = idList => axios.post(API_URL + "removeManySubmissionsFromId", { idList }, H.getHeader("DELETE"));
export const removerManySubmissionsFromFilter = filter => axios.post(API_URL + "removerManySubmissionsFromFilter", { filter }, H.getHeader("DELETE"));
export const getManySubmissionsFromFilter = (filter, limit, restrict) => axios.post(API_URL + "getManySubmissionsFromFilter", { filter, limit, restrict });
export const conditionalSingleUpdate = (filter, prop, newValue, oldValue, extraUpdate) => axios.post(API_URL + "conditionalSingleUpdate", { filter, prop, newValue, oldValue, extraUpdate }, H.getHeader("EDIT"));
//#endregion

//#region Portfolios
export const getPortfolio = id => axios.post(API_URL + "getPortfolio", { id });
export const portfoliosBulk = bulk => axios.post(API_URL + "portfoliosBulk", { bulk }, H.getHeader("OTHER"));
export const removePortfolios = filter => axios.post(API_URL + "removePortfolios", { filter }, H.getHeader("DELETE"));
export const updatePortfolios = (filter, update) => axios.post(API_URL + "updatePortfolios", { filter, update }, H.getHeader("EDIT"));
//#endregion

//#region Advanced Updates
export const createGammeEquipment = (gamme, parentId) => axios.post(API_URL + "createGammeEquipment", { gamme, parentId }, H.getHeader("CREATE"));
//#endregion

//#region FORMS
export const getPermissionsFormId = () => axios.get(API_URL + "getPermissionsFormId");
export const getFormsFromId = idList => axios.post(API_URL + "getFormsFromId", { idList });
export const updateForm = form => axios.post(API_URL + "updateForm", form, H.getHeader("EDIT"));
export const getOneFormFromPath = path => axios.post(API_URL + "getOneFormFromPath", { path });
export const createForm = form => axios.post(API_URL + "createForm", form, H.getHeader("CREATE"));
export const getFormsFromFilter = filter => axios.post(API_URL + "getFormsFromFilter", { filter });
export const deleteFormFromId = id => axios.post(API_URL + "deleteFormFromId", { id }, H.getHeader("DELETE"));
export const formInfosAlreadyExists = (title, path) => axios.post(API_URL + "formInfosAlreadyExists", { title, path });
//#endregion

//#region ACTIONS
export const createAction = action => axios.post(API_URL + "createAction", action, H.getHeader("CREATE"));
//#endregion

//#region Matrix
export const getMatrixFormProps = () => axios.get(MATRIX_API + "formsProps");
export const addMatrix = form => axios.post(MATRIX_API + "addMatrix", { form }, H.getHeader("CREATE"));
export const getReducedMatrix = (form, prop) => axios.post(MATRIX_API + "getReducedMatrix", { form, prop });
export const updateSingleMatrix = (filter, update) => axios.post(MATRIX_API + "updateMatrix", { filter, update }, H.getHeader("EDIT"));
export const addPropToFormMatrix = (form, prop) => axios.post(MATRIX_API + "addPropToFormMatrix", { form, prop }, H.getHeader("EDIT"));
export const updatePropMatrix = (field, value, form, selectorId) => axios.post(MATRIX_API + "updatePropMatrix", { field, value, form, selectorId }, H.getHeader("EDIT"));
//#endregion

//#region LINKS
export const getLinkTypeId = type => axios.post(API_URL + "getLinkTypeId", { type });
export const deleteLinks = id => axios.post(API_URL + "deleteLinks", { id }, H.getHeader("DELETE"));
export const getLinksFromFilter = filter => axios.post(API_URL + "getLinksFromFilter", { filter });
export const createLink = link => axios.post(API_URL + "createLink", { link }, H.getHeader("CREATE"));
export const bulkLinkAction = bulk => axios.post(API_URL + "bulkLinkAction", { bulk }, H.getHeader("OTHER"));
export const upsertManyLinks = array => axios.post(API_URL + "upsertManyLinks", { array }, H.getHeader("EDIT"));
export const createManyLinks = links => axios.post(API_URL + "createManyLinks", { links }, H.getHeader("CREATE"));
export const updateManyLinks = (filter, update) => axios.post(API_URL + "updateManyLinks", { filter, update }, H.getHeader("EDIT"));
export const deleteManyLinksFromFilter = filter => axios.post(API_URL + "deleteManyLinksFromFilter", { filter }, H.getHeader("DELETE"));
//#endregion

//#region Actions
export const checkActionsConditions = (actionsIds, locationId, submissionId, data, path) => axios.post(API_URL + "checkActionsConditions", { actionsIds, locationId, data, path, submissionId });
//#endregion

//#region Plans
export const bulkPlans = bulk => axios.post(API_URL + "bulkPlans", { bulk }, H.getHeader("OTHER"));
export const getPlansFromFilter = filter => axios.post(API_URL + "getPlansFromFilter", { filter });
//#endregion

//#region Action Plan
export const createActionPlan = params => axios.post(API_URL + "createActionPlan", params);
//#endregion

//#region Tree Search
export const hasDescendance = (ids) => axios.post(API_URL + "hasDescendance", { ids });
export const listItemsAndLinks = (roots, types, linkTypes) => axios.post(API_URL + "listItemsAndLinks", { roots, types, linkTypes });
export const replaceLinkApi = (input, output, type, isChangedInput) => axios.post(API_URL + "replaceLinkApi", { input, output, type, isChangedInput }, H.getHeader("EDIT"));
export const getLocationOptionsFull = (roots, itemType) => axios.post(API_URL + "getLocationOptionsFull", { roots, itemType });
export const clientsContextWithSites = (ids, portfolio) => axios.post(API_URL + "clientsContextWithSites", { ids, portfolio });
export const getLocationList = (roots, itemType, allConcat, highestType) => axios.post(API_URL + "getLocationList", { roots, itemType, allConcat, highestType });
export const itemLocater = (items, restrictToOwnLinkType) => axios.post(API_URL + "itemLocater", { items, restrictToOwnLinkType });
export const accessibleNodesUser = (userId, linkTypes, formIds, getJustIds, returnLinks) => axios.post(API_URL + "accessibleNodesUser", { userId, linkTypes, formIds, getJustIds, returnLinks });
export const advancedSearch = (roots, searchRange, getJustIds, linkTypes, formId, includeNodeGiven) => axios.post(API_URL + "advancedSearch", { roots, searchRange, getJustIds, linkTypes, formId, includeNodeGiven });
//#endregion

//#region Grafana
export const getGrafanaGraphs = link => axios.get(API_URL + "getGrafanaGraphs", { params: { link }, headers: H.authHeader("GRAFANA"), responseType: 'arraybuffer' });
//#endregion

//#region Manual Reading
export const readManualReading = id => axios.post(API_URL + "readManualReading", { id });
export const updateManualReading = (entity, val, img, user) => axios.post(API_URL + "updateManualReading", { entity, val, img, user }, H.getHeader("EDIT"));
//#endregion

//#region new File management
export const deleteLocalFile = url => axios.delete(url, H.getHeader("FILE"));
export const uploadLocalFile = (_id, fileName, formData) => axios.post(LM.CRAFT_FILE_URL_NO_ORIGIN(_id, fileName), formData, H.getHeader("FILE"));
//#endregion

//#region Table States
export const createTableStates = (state, generic, currentUser, users, translations) => axios.post(API_URL + "createTableStates", { state, generic, currentUser, users, translations }, H.getHeader("CREATE"));
export const getTableStatesForOrigin = origin => axios.post(API_URL + "table_states/origin", { origin });
export const removeTableStateFromId = _id => axios.post(API_URL + "removeTableStateFromId", { _id }, H.getHeader("DELETE"));
export const updateTableStates = (_id, updates) => axios.post(API_URL + "updateTableStates", { _id, updates }, H.getHeader("EDIT"));
export const setTableStatesDefault = (_id) => axios.post(API_URL + "state/default", { _id }, H.getHeader("EDIT"));
//#endregion

//#region Translations
export const getTranslations = () => axios.post(API_URL + "getTranslations", {});
export const getLangTranslations = (lang) => axios.get(API_URL + "getLangTranslations", { params: { lang } });
export const removeLanguages = (ids) => axios.post(API_URL + "removeLanguages", { ids }, H.getHeader("DELETE"));
export const updateLanguage = (id, updates) => axios.post(API_URL + "updateLanguage", { id, updates }, H.getHeader("EDIT"));
export const createManyLanguages = languages => axios.post(API_URL + "createManyLanguages", { languages }, H.getHeader("CREATE"));
//#endregion

//#region Backend Helpers
export const getListTimeZones = () => axios.get(DATA_API + "getListTimeZones");
export const findBuildingRocketStateId = (buildingId, address, boxText) => axios.post(API_URL + "findBuildingRocketStateId", { buildingId, address, boxText }, H.getHeader("OTHER"));
//#endregion

//#region UnLoggedMethods
export const readSubUnLogged = filter => axios.post(API_URL + "readSubUnLogged", { filter });
export const getAncestryNodeUnLogged = (id, searchForms) => axios.post(API_URL + "getAncestryNodeUnLogged", { id, searchForms });
//#endregion

//#region Parity Export / Import
export const importForms = forms => axios.post(PARITY_API + "importForms", { forms }, H.getHeader("OTHER"));
export const importConstantData = data => axios.post(PARITY_API + "importData", { data }, H.getHeader("OTHER"));
export const exportForms = (isSrcDev = true) => axios.post(PARITY_API + "exportForms", { isSrcDev }, H.getHeader("OTHER"));
export const exportConstantData = (isSrcDev = true) => axios.post(PARITY_API + "exportData", { isSrcDev }, H.getHeader("OTHER"));
//#endregion