import _ from "lodash";
import React from "react";
import { IconTip } from "../Common";
import useFormIds from "./useFormIds";
import { forceLoadLg } from "../reducers";
import { T, TB, LANG } from "../Constants";
import { useSelector, useDispatch } from "react-redux";

const TEMPLATE = "%template%";

const useLanguage = (statics?: string[], force_load?: boolean) => {
    const [forms] = useFormIds();
    const dispatch = useDispatch();
    const language = useSelector((redux: T.ReduxSelector) => redux.language);
    const lg_object = useSelector((redux: T.ReduxSelector) => redux.storedLanguages.object);
    const lg_static = useSelector((redux: T.ReduxSelector) => redux.storedLanguages.static);

    //#region Fetch
    const fetchObjectTranslations = React.useCallback((ids: T.AllowArray<string>) => {
        // dispatch(getObjectsLg(ids))
    }, [/* dispatch */]);

    const fetchStaticTranslations = React.useCallback((statics: T.AllowArray<string>) => {
        // dispatch(getStaticsLg(statics))
    }, [/* dispatch */]);

    React.useEffect(() => {
        if (force_load) dispatch(forceLoadLg())
    }, [dispatch, force_load]);
    //#endregion

    //#region Language Prop
    const validLanguage = React.useMemo(() => TB.getValidLanguageCode(language), [language]);
    const languageProp = React.useMemo(() => LANG.ALL_LANGUAGES.find(l => l.index === validLanguage).prop, [validLanguage]);
    //#endregion

    //#region Text
    const strIsTemplate = React.useCallback((str?: string) => TB.getString(str).includes(TEMPLATE), []);
    const getLoadingElem = React.useCallback((text: string) => <IconTip icon="spinner" spin spinIcon="spinner" tipContent={text} />, []);

    const getStaticText = React.useCallback((reference: string, template?: string | number) => {
        // Find the correct translation
        const translation = lg_static.find(t => t.ref === reference);
        // No Translations found
        if (!translation) {
            // If not a text code, return the passed argument as a string
            if (!TB.isTextCode(reference)) return TB.getString(reference);
            else return "...";
        }
        // Find the correct text
        const text = TB.validString(translation[languageProp]) ? translation[languageProp] : reference;
        // Translation is a template
        if (strIsTemplate(text)) return TB.replaceStringPart(text, TEMPLATE, TB.isTextCode(template) ? getStaticText(template) : TB.getString(template));
        // Return translation
        return text;
    }, [lg_static, languageProp, strIsTemplate]);

    const getStaticElem = React.useCallback((reference: string, template?: string) => {
        // Find the correct translation
        const translation = lg_static.find(t => t.ref === reference);
        // No Translations found
        if (!translation) {
            // If not a text code, return the passed argument as a string
            if (!TB.isTextCode(reference)) return TB.getString(reference);
            else return getLoadingElem(reference);
        }
        // Find the correct text
        const text = TB.validString(translation[languageProp]) ? translation[languageProp] : reference;
        // Translation is a template
        if (strIsTemplate(text)) return TB.replaceStringPart(text, TEMPLATE, TB.isTextCode(template) ? getStaticText(template) : TB.getString(template));
        // Return translation
        return text;
    }, [lg_static, languageProp, getLoadingElem, getStaticText, strIsTemplate]);

    const getTextObj = React.useCallback((id: string, prop?: string, defaultText?: string) => {
        // Find the correct translation
        const translation = lg_object.find(t => t._id === id && t.prop === prop);
        // Find the text for the current language
        const text = translation?.[languageProp];
        // No valid translation found
        if (!TB.validString(text)) return TB.getString(defaultText);
        // Return the valid translation
        return text;
    }, [lg_object, languageProp]);

    const getElemObj = React.useCallback((id: string, prop?: string, defaultText?: string) => {
        // Find the correct translation
        const translation = lg_object.find(t => t._id === id && t.prop === prop);
        // Find the text for the current language
        const text = translation?.[languageProp];
        // No valid translation found
        if (!TB.validString(text)) return TB.validString(defaultText) ? defaultText : getLoadingElem(id + prop);
        // Return the valid translation
        return text;
    }, [lg_object, languageProp, getLoadingElem]);
    //#endregion

    //#region Helpers
    const getOptionsStatic = React.useCallback((options: T.Option[]) => {
        const [idsOptions, codesOptions] = _.partition(options, o => TB.mongoIdValidator(o.value) && TB.validString(o.prop));

        if (idsOptions.length > 0) fetchObjectTranslations(idsOptions.map(o => o.value));
        if (codesOptions.length > 0) fetchStaticTranslations(codesOptions.map(o => o.label));
    }, [fetchStaticTranslations, fetchObjectTranslations]);

    const loadForm = React.useCallback((paths: T.AllowArray<string>) => {
        const ids = TB.arrayWrapper(paths).map(p => forms[p]).filter(TB.mongoIdValidator);
        if (ids.length > 0) fetchObjectTranslations(ids);
    }, [forms, fetchObjectTranslations]);
    //#endregion

    return React.useMemo(() => ({
        loadForm,
        getElemObj,
        getTextObj,
        getStaticText,
        getStaticElem,
        getOptionsStatic,
        prop: languageProp,
        fetchStaticTranslations,
        fetchObjectTranslations,
        language: validLanguage,
    }), [fetchObjectTranslations, fetchStaticTranslations, getElemObj, getOptionsStatic, getStaticElem, getStaticText, getTextObj, loadForm, languageProp, validLanguage]);
}

export default useLanguage;