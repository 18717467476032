import _ from "lodash";
import { TB, T } from "../Constants";
import { MutableRefObject } from "react";

type PayloadObj = { id?: string, ref?: MutableRefObject<HTMLDivElement | null> };

const PortalReducer: T.ReduxFn<T.PortalRedux, T.PortalActions, PayloadObj> = (state = {}, payload) => {
    const { type = null, action = {} } = TB.getObject(payload);
    const { id = "", ref = null } = TB.getObject(action);

    const removePortal = () => !TB.validString(id) ? state : _.omit({ ...state }, id);
    const addPortal = () => !TB.validString(id) || !ref || !ref.current ? state : { ...state, [id]: ref };

    switch (type) {
        case "PORTAL_ADD": return addPortal();
        case "PORTAL_DEL": return removePortal();
        default: return state;
    }
}

export default PortalReducer;

export const REMOVE_PORTAL = (id: T.PortalIds) => ({ type: "PORTAL_DEL", payload: { id } });
export const ADD_PORTAL = (id: T.PortalIds, ref: MutableRefObject<HTMLDivElement | null>) => ({ type: "PORTAL_ADD", action: { id, ref } });