import React from "react";
import * as I from "../types";
import * as M from "../../../Modal";
import * as BS from "react-bootstrap";
import * as C from "../../../../Common";
import { T, TB } from "../../../../Constants";

export type Props = {
    /** How to show the picture */
    layout?: "profile" | "none";
}

export const type = "agCustomPictures";

export const sort = (a: T.AllowArray<string>, b: T.AllowArray<string>) => {
    let nb_pic_a = 0, nb_pic_b = 0;

    if (Array.isArray(a)) nb_pic_a = a.length;
    else if (TB.validString(a)) nb_pic_a = 1;

    if (Array.isArray(b)) nb_pic_b = b.length;
    else if (TB.validString(b)) nb_pic_b = 1;

    return nb_pic_a - nb_pic_b;
}

export const Cell = React.forwardRef<object, I.CellProps<Props>>((props, ref) => {
    const layout = React.useMemo(() => props.colDef?.params?.layout || "none", [props]);
    const links = React.useMemo(() => TB.arrayWrapper(props?.getValue?.()).filter(TB.validString), [props]);

    const render_tip = React.useCallback((p, link) => <BS.Tooltip
        {...p}
        placement="auto"
        className={TB.getString(p.className) + " z-index-higher-modal"}
    >
        <img src={link} alt="" style={{ maxHeight: "25vh", maxWidth: "100%" }} />
    </BS.Tooltip>, []);

    const render_img_layout = React.useCallback((link: string) => {
        const click_cb = () => M.renderCarousel({ images: [{ src: link, title: " " }] });
        if (layout === "none") return <img alt="" src={link} key={link} onClick={click_cb} className="pointer w-75" />;
        else return <img alt="" src={link} key={link} style={{ width: 35, height: 35 }} onClick={click_cb} className="pointer rounded-circle" />;
    }, [layout]);

    if (links.length === 0) return null;
    return <C.Flex justifyContent="evenly">
        {links.map(l => <BS.OverlayTrigger key={l} overlay={p => render_tip(p, l)}>
            {render_img_layout(l)}
        </BS.OverlayTrigger>)}
    </C.Flex>;
});

Cell.displayName = "AG_Pictures_Cell";