import DOM from 'react-dom/client';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class RegStatuses extends ReactComponent {
    static get builderInfo() {
        return {
            weight: 1,
            icon: "table",
            group: "Custom",
            documentation: "",
            title: "RegStatuses",
            schema: RegStatuses.schema(),
        };
    }

    static schema = () => ReactComponent.schema({ type: "RegStatuses", label: "RegStatuses" });
    attachReact = element => DOM.createRoot(element).render(<DefaultComp />);
    detachReact = element => element ? DOM.createRoot(element).unmount() : undefined;
}