import React from "react";
import { InputProps, ComponentWrapper } from "../Input";
import { ActionRegUpdater } from "../../../Components/RegDoc";
import { TB } from "../../../Constants";

export type RegActionsProps = InputProps;

const RegActions: React.FC<RegActionsProps> = ({ onChange, ...props }) => {

    const isDisabled = React.useMemo(() => props.disabled || props.noOverride || !TB.mongoIdValidator(props.submissionId), [props.disabled, props.noOverride, props.submissionId]);

    return <ComponentWrapper {...props} disabled={isDisabled}>
        <ActionRegUpdater
            itemActions={props.value}
            disabled={isDisabled}
            roots={props.extraData?.site}
            elementId={props.submissionId}
            category={props.fullSubmission?.category}
            onChangeActions={values => onChange?.(values)}
        />
    </ComponentWrapper>;
}

export default RegActions;