import _ from "lodash";
import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import { RegDoc } from "../../RegDoc";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import * as PM from "../../../PurposeModal";
import { CellsTypes as CT } from "../AgGridDefs";
import { useSearchParams } from "react-router-dom";
import { SetFilterModel } from "ag-grid-community";
import * as US from "../../../services/user.service";
import { Table, TableProps, TableRef } from "../Grid";
import { T, TC, TABS, TB, FP, RIGHTS } from "../../../Constants";

//#region Types
export type RegTableProps<A = object> = {
    /** Only show the reg documents */
    only_reg?: boolean;
    /** The context to load the reg docs from */
    context: T.ContextParams;
    /** The property to save the states under */
    origin?: string;
    /** Hide some of the default buttons */
    hide_buttons?: T.AllowArray<"toggle" | "zip" | "doc_type">;
    /** Extra classes names to give the main container */
    className?: string;
    /** Callback to notify if there has been a change in the table */
    hasChanged?: () => void;
    /** Do not allow any edits */
    read_only?: boolean;
    /** Extra columns */
    extra_columns?: TableProps<Row<A>>["columns"];
    /** Add extra data to the rows */
    process_rows?: (rows: Row[]) => Row<A>[];
    /** Callback for when the grid is ready */
    onReadyGrid?: TableProps<Row<A>>["onReadyGrid"];
    /** Buttons to show in the toolbar */
    buttons?: TableProps<Row<A>>["extra_buttons"];
    /** Callback for inline edits */
    onValueChange?: (params: Parameters<TableProps<Row<A>>["onValueChange"]>[0]) => "processed" | "to_process";
};

export type RegTableRef<A = object> = {
    /** Allows to reload the content of the table */
    reload: () => ReturnType<typeof S.getRegActionTableResource>;
    /** The AG-Grid / Adaptable reference */
    table: React.MutableRefObject<TableRef<Row<A>>>;
    /** The loaded rows */
    rows: Row<A>[];
}

type UrlParams = T.ContextParams;
type Row<A = object> = (Resource["items"][number]) & A;
type Resource = ReturnType<T.API.Reg.GetRegActionTableResource>;
//#endregion

const RenderRegDocs = <A extends object,>({ process_rows, onReadyGrid, onValueChange, ...props }: RegTableProps<A>, ref: React.ForwardedRef<RegTableRef<A>>) => {
    const lg = H.useLanguage();
    const rights = H.useRights();
    const [{ userId }] = H.useAuth();
    const isMounted = H.useIsMounted();
    const [searchParams] = useSearchParams();
    const show_most_recent = H.useBoolean(true);
    const grid = React.useRef<TableRef<Row<A>>>(null);
    const [context_info] = H.useContextInfos(props.context);
    const show_all = H.useBoolean(typeof props.only_reg === "boolean" ? !props.only_reg : true);
    const [resource, setResource, status] = H.useAsyncState<Resource>({ items: [], gammes: [], actions: [], elements: [], controllers: [] });

    //#region Url Params
    const params = React.useMemo<UrlParams>(() => {
        let roots = TB.findIds(searchParams.get("roots") || "");
        let portfolio = searchParams.get("portfolio") || undefined;
        return { roots, portfolio };
    }, [searchParams]);

    const active_params = React.useMemo(() => {
        // Priority given to the url params
        if (params.portfolio || (Array.isArray(params.roots) && params.roots.length > 0)) return params;
        // No url params, use the context found in the props
        return props.context;
    }, [params, props.context]);
    //#endregion

    //#region Load Data
    const load_resource = React.useCallback(() => {
        let promise = S.getRegActionTableResource(active_params);
        promise
            .then(({ data }) => isMounted() && setResource(data, "done"))
            .catch(() => isMounted() && setResource({ items: [], gammes: [], actions: [], elements: [], controllers: [] }, "error"));
        return promise;
    }, [active_params, setResource, isMounted]);

    React.useEffect(() => {
        // Load the resources on context change
        load_resource();
        // Set the state as Loading when context change
        return () => setResource({ items: [], gammes: [], actions: [], elements: [], controllers: [] }, "load");
    }, [load_resource, setResource]);
    //#endregion

    //#region Format
    const getStatus = React.useCallback((status?: Row["status"]) => {
        if (typeof status === "object") return status.lang[lg.prop] || Object.values(status.lang)[0] || "";
        else {
            let code = "";
            if (status === "notes") code = TC.REG_FORM_NOTE;
            else if (status === "missing") code = TC.REG_MISSING;
            else if (status === "conform") code = TC.REG_FORM_CONFORM;
            else if (status === "infraction") code = TC.REG_FORM_INFRACTION;
            return lg.getStaticText(code);
        }
    }, [lg]);

    const formatCategory = React.useCallback((category?: string) => {
        if (category === FP.BUILDING_FORM) return lg.getStaticText(TC.GLOBAL_LABEL_BUILD);
        if (category === FP.EMPLACEMENT_FORM) return lg.getStaticText(TC.GLOBAL_LABEL_EMPLACEMENT);
        if (TB.mongoIdValidator(category)) return _.find(resource.gammes, g => g._id === category)?.data?.name || "";
        return "";
    }, [lg, resource.gammes]);

    const getRegions = React.useCallback((x?: (Row<A>)["regions"]) => !x ? "" : x.map(x => x.name), []);
    const getActionName = React.useCallback((actionId?: string, action_name?: string) => lg.getTextObj(actionId, "name", action_name), [lg]);
    const getReference = React.useCallback((actionId?: string) => _.find(resource.actions, a => a._id === actionId)?.data?.reference || "", [resource.actions]);
    const getControllerType = React.useCallback((controllerId?: string) => _.find(resource.controllers, c => c._id === controllerId)?.data?.label || "", [resource.controllers]);
    //#endregion

    //#region Data Operations
    const events = React.useMemo(() => ({
        retrieve_elements: () => {
            props.hasChanged?.();
            setResource({ actions: [], controllers: [], elements: [], gammes: [], items: [] }, "load");
            load_resource();
        },
        add_ticket: (item: Row<A>) => {
            let title = getActionName(item.regAction, item.action_name);
            let submission = [{ prop: "title", value: title }, { prop: "equipment", value: item.element }];
            M.renderFormModal<T.TicketData>({ path: FP.TICKET_FORM, forcedSubmission: submission });
        },
        edit_reg: (regForm: string) => {
            let renderContent = (resolve: (r: T.RegDoc | null) => void) => <RegDoc
                regFormId={regForm}
                onSave={(regDoc, deletedDoc) => resolve(deletedDoc ? deletedDoc : regDoc)}
            />;

            M.renderBlankModal({ renderContent, size: "md", title: TC.GLOBAL_DOCUMENT }).then((regDoc: T.RegDoc | null) => {
                if (regDoc) events.retrieve_elements();
            });
        },
        add_reg: (action?: string, elem?: string, forceNew = false) => {
            let askAction = () => new Promise<string | null>((resolve) => {
                if (TB.mongoIdValidator(action) && TB.mongoIdValidator(elem)) resolve(action);
                else PM.renderSiteSelect({ context: active_params, isRequired: true }).then(site => {
                    if (site) {
                        let dismount = M.renderLoader();
                        let select_type: Resource["items"][number]["doc_cat"] = show_all.value ? undefined : "reg";
                        S.contextActions({ roots: site, type: select_type, onlyCheckRegions: true }).then(({ data }) => {
                            let options = data.map(a => ({ ...a, value: a._id, label: lg.getTextObj(a._id, 'name', a.name) })).sort(TB.sortOptions);
                            let renderExtraButton = (resolve) => <C.Button onClick={() => resolve("all")} text={TC.REG_TABLE_LOAD_ALL_ACTIONS} />

                            M.askSelect({
                                options,
                                size: "md",
                                isRequired: true,
                                renderExtraButton,
                                selectProps: {
                                    renderItem: (option: typeof options[number]) => <BS.Row key={option.value}>
                                        <BS.Col md={7} className="text-break" style={{ whiteSpace: "break-spaces" }}>{option.label}</BS.Col>
                                        <BS.Col md={4} className="text-break text-end" style={{ whiteSpace: "break-spaces" }}>
                                            {option.regions.length > 0 ? option.regions.map(r => r.name).join() : option.countries.join()}
                                        </BS.Col>
                                        <BS.Col md={1}>{option.noFreq ? "∞" : option.frequency || "X"}</BS.Col>
                                    </BS.Row>
                                }
                            }).then(resolve);
                        })
                            .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_FAILED_LOAD }))
                            .finally(dismount);
                    }
                    else resolve(null);
                });
            });

            askAction().then(actionId => {
                if (TB.mongoIdValidator(actionId)) {
                    let renderContent = (resolve: (r: T.RegDoc | null) => void) => <RegDoc
                        elemId={elem}
                        onSave={resolve}
                        action={actionId}
                        forceNewDoc={forceNew}
                        roots={active_params.roots}
                        portfolio={active_params.portfolio}
                    />;

                    M.renderBlankModal({ renderContent, size: "md", title: TC.GLOBAL_DOCUMENT }).then((regDoc: T.RegDoc | null) => {
                        if (regDoc) events.retrieve_elements();
                    });
                }
            })
        },
        create_ticket: (data: Row<A>) => {
            let title = getActionName(data.regAction, data.action_name);
            let submission = [
                { prop: "type", value: "reg" },
                { prop: "title", value: title },
                { prop: "equipment", value: data.element },
                { prop: "regAction", value: data.regAction },
            ];

            M.renderFormModal<T.TicketData>({ path: FP.TICKET_FORM, forcedSubmission: submission }).then(value => {
                if (value) US.updateRegDocElemItem(data.regForm, data.regItemId, { ticket: value._id }, false).then(() => {
                    setResource(p => ({
                        ...p,
                        items: p.items.map(i => i.regForm === data.regForm && i.regItemId === data.regItemId ? { ...i, action: "open" } : i)
                    }));
                }).catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_FAILED_LOAD }));
            });
        },
        zip: () => {
            grid.current.grid.selectAllFiltered();
            let rows = grid.current.grid.getSelectedNodes();
            grid.current.grid.deselectAll();

            let content: Parameters<typeof S.createZip>[0]["content"] = [];
            // To avoid re-grouping by reg actions if user has already grouped by reg actions
            let already_group_by_reg = false;
            // Is there any groups currently in the table
            let is_grouped = rows.some(r => r.group);

            // Treat the groups
            if (is_grouped) {
                const recursive = (sub_rows: typeof rows, parent: typeof content, level = 0) => {
                    let has_group = sub_rows.some(r => r.group && r.level === level);

                    if (has_group) {
                        sub_rows = sub_rows.filter(r => r.group && r.level === level);
                        for (let row of sub_rows) {
                            let sub_content = [] as typeof content;
                            if (row.field === "title") already_group_by_reg = true;
                            recursive(row.childrenAfterGroup, sub_content, level + 1);
                            if (sub_content.length > 0) parent.push({ type: "directory", label: row.key || "None", content: sub_content });
                        }
                    }
                    else for (let row of sub_rows) {
                        let note = row.data;
                        if (Array.isArray(note.files) && note.files.length > 0) {
                            // Table is not already grouped by element
                            if (!already_group_by_reg) parent.push({
                                type: "directory",
                                label: (note as any).title,
                                content: note.files.map(f => ({ type: "file", originalName: f.originalName, url: f.url })),
                            });
                            else note.files.forEach(f => parent.push({ type: "file", originalName: f.originalName, url: f.url }));
                        }
                    }
                }
                recursive(rows, content);
            }
            // Auto-group by element
            else for (let row of rows) {
                let note = row.data;

                if (Array.isArray(note.files) && note.files.length > 0) content.push({
                    type: "directory",
                    label: (note as any).title,
                    content: note.files.map(f => ({ type: "file", originalName: f.originalName, url: f.url })),
                });
            }
            // Create the zip archive
            let name = `${lg.getStaticText(TC.CRUMB_REG)} - ${context_info.name}`;
            // Create a loader screen
            const dismount = M.renderLoader();
            S.createZip({ content, name })
                .catch(M.Alerts.file_generation_fail)
                .finally(dismount);
        },
        edit_element: (data: Row<A>) => {
            let path = "";
            if (TB.mongoIdValidator(data.category)) path = FP.EQUIPEMENT_FORM;
            else path = data.category;

            // Find the right to edit the element
            let write_right = null;
            if (path === FP.SITE_FORM) write_right = RIGHTS.TECH.EDIT_SITE;
            else if (path === FP.BUILDING_FORM) write_right = RIGHTS.TECH.EDIT_BUILDING;
            else if (path === FP.EQUIPEMENT_FORM) write_right = RIGHTS.TECH.EDIT_EQUIPMENT;
            else if (path === FP.EMPLACEMENT_FORM) write_right = RIGHTS.TECH.EDIT_EMPLACEMENT;
            else write_right = RIGHTS.TECH.WRITE_OTHERS;
            // Check if the user has the right to edit the element
            let readOnly = !rights.isRightAllowed(write_right);
            M.renderPopUpFormModal({ path, submissionId: data.element, readOnly }).then(submission => {
                if (submission) events.retrieve_elements();
            });
        },
    }), [active_params, rights, lg, context_info.name, show_all.value, props, getActionName, load_resource, setResource]);
    //#endregion

    //#region Rows, Columns & Context Menu
    const render = React.useMemo(() => ({
        status: (data: Row<A>) => {
            let color: string, text = getStatus(data?.status);
            if (typeof data?.status === "object") color = data?.status?.color;
            else if (data?.status === "missing") color = "#CF1F31";
            else if (data?.status === "notes") color = "#FFA726";
            else if (data?.status === "conform") color = "#4EAE53";
            else if (data?.status === "infraction") color = "#FF7043";

            if (!text) return null;
            return <div className="text-center text-white fw-bold" style={{ backgroundColor: color }} children={text} />
        },
        ticket_state: (data: Row<A>) => {
            switch (data?.action) {
                case "open": return <div className="text-center text-info">{lg.getStaticText(TC.TICKET_OPEN)}</div>;
                case "closed": return <div className="text-center text-success">{lg.getStaticText(TC.TICKET_CLOSED)}</div>;
                case "assigned": return <div className="text-center text-primary">{lg.getStaticText(TC.TICKET_ASSIGNED)}</div>;
                default: {
                    if (TB.mongoIdValidator(data?.regForm)) return <div className="text-center">
                        <C.Button
                            icon="plus"
                            variant="link"
                            text={TC.P_ADD_TICKET}
                            disabled={props.read_only}
                            onClick={() => events.create_ticket(data)}
                        />
                    </div>;
                    else return null;
                }
            }
        },
    }), [lg, events, getStatus, props.read_only]);

    const get_options = React.useMemo(() => ({
        tags: (row: Row) => new Promise<T.Option[]>((resolve, reject) => {
            S.getNoteTags({ context: { roots: row._id }, type: "regDoc" })
                .then(r => resolve(r.data))
                .catch(reject);
        }),
        create_tag: (text: string, row: Row) => new Promise<T.Option>((resolve, reject) => {
            let submission = { name: text, users: [userId], sites: [], type: "regDoc" } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
    }), [userId]);

    const rows = React.useMemo<Row<A>[]>(() => {
        let rows = resource.items;
        if (!show_all.value) rows = rows.filter(i => i.doc_cat === "reg");
        if (show_most_recent.value) rows = rows.filter(i => i.isMostRecent);

        let processed_rows = rows.map(i => ({
            ...i,
            reference: getReference(i.regAction),
            tr_category: formatCategory(i.category),
            title: getActionName(i.regAction, i.action_name),
            element_name: resource.elements.filter(e => e.submission._id === i.element)[0]?.submission?.data?.name || "",
        }));

        if (typeof process_rows === "function") return process_rows(processed_rows);
        return processed_rows as any;
    }, [resource.items, resource.elements, show_most_recent.value, show_all.value, process_rows, getReference, getActionName, formatCategory]);

    const columns = React.useMemo<TableProps<Row<A>>["columns"]>(() => [
        {
            headerName: TC.REG_FORM_REGLEMENTATION,
            children: [
                { field: "title", hide: true, headerName: TC.GLOBAL_TITLE, rowGroup: true },
                { field: "reference", headerName: TC.REG_LEGAL_REF },
                { field: "controllerType", hide: true, headerName: TC.ACTION_CONTROL_ENTITY, valueFormatter: r => getControllerType(r?.value), filterValueGetter: r => getControllerType(r?.data?.controllerType) },
                { field: "countries", hide: true, headerName: TC.COUNTRY },
                { field: "regions", hide: true, headerName: TC.GLOBAL_REGIONS, valueFormatter: r => getRegions(r?.value), filterValueGetter: r => getRegions(r?.data?.regions as any) },
                { field: "frequency", hide: true, headerName: TC.REG_DEFAULT_PERIODICITY },
            ]
        },
        {
            headerName: TC.GLOBAL_DOCUMENT,
            children: [
                { field: "controller", headerName: TC.REG_FORM_CONTROLLER },
                { field: "date", headerName: TC.ACTION_REG_LAST_DATE, type: CT.TYPE_DATE },
                { field: "files", headerName: TC.G_FILE_LABEL, type: CT.TYPE_FILE },
                { field: "validityDate", headerName: TC.REG_FORM_VALIDITY, type: CT.TYPE_REG_VALIDITY_DATE, filter: undefined },
                { field: "docNotes", headerName: FP.NOTES_PATH },
                {
                    hide: true,
                    field: "tags_names",
                    type: CT.TYPE_SELECT,
                    headerName: TC.DATASET_TAGS,
                    editable: r => !props.read_only && TB.mongoIdValidator(r.data.regForm),
                    params: {
                        multiple: true,
                        field_value: "tags",
                        getValues: get_options.tags,
                        typeahead: { allowNewOption: true, onAddOption: get_options.create_tag },
                    }
                },
            ]
        },
        {
            headerName: TC.GLOBAL_ELEMENT_LABEL,
            children: [
                { field: "element_name", headerName: TC.GLOBAL_NAME, type: CT.TYPE_LINKED_ELEM, params: { render_form: events.edit_element } },
                { field: "tr_category", hide: true, headerName: TC.CONTRACT_CATEGORY },
                { field: "site", hide: true, headerName: TC.GLOBAL_LABEL_SITE },
                { field: "building", hide: true, headerName: TC.GLOBAL_LABEL_BUILD },
                { field: "emplacement", hide: true, headerName: TC.GLOBAL_FLOOR },
                { field: "local", hide: true, headerName: TC.GLOBAL_LOCAL },
                { field: "location", headerName: TC.GLOBAL_FULL_LOC },
            ]
        },
        {
            headerName: TC.REG_CONFORM_DOC,
            children: [
                {
                    field: "status",
                    type: CT.TYPE_FREE_RENDER,
                    headerName: TC.GLOBAL_STATUS,
                    params: { render: render.status },
                    valueFormatter: r => getStatus(r?.value),
                    filterValueGetter: r => getStatus(r?.data?.status),
                },
                { field: "notes", headerName: TC.BR_TAB_LABEL_DETAILS },
                { field: "action", hide: true, headerName: TC.G_ACTION, type: CT.TYPE_FREE_RENDER, params: { render: render.ticket_state } },
            ]
        },
        ...props.extra_columns || [],
    ], [getControllerType, getRegions, getStatus, render, get_options, events, props.read_only, props.extra_columns]);

    const context_menu = React.useCallback<TableProps<Row<A>>["getContextMenuItems"]>(event => {
        let row = event.node?.data;
        let items = [] as ReturnType<TableProps<Row<A>>["getContextMenuItems"]>;
        let edit_right = row?.owner === userId
            ? RIGHTS.REG.WRITE_OWN_REG_DOCS
            : RIGHTS.REG.WRITE_OTHER_REG_DOCS;
        let can_edit = row && rights.isRightAllowed(edit_right);

        if (!props.read_only) {
            if (row && TB.mongoIdValidator(row.regForm) && can_edit) items.push({
                name: lg.getStaticText(TC.UPDATE),
                icon: "<i class='fa fa-pencil-alt'></i>",
                action: () => events.edit_reg(row.regForm),
            });
            if (items.length > 0) items.push("separator");
            if (row && TB.mongoIdValidator(row.element)) {
                if (rights.isRightAllowed(RIGHTS.ACTION.WRITE_OTHER_MAINTENANCE_ACTION)) items.push({
                    icon: "<i class='fa fa-wrench'></i>",
                    action: () => events.add_ticket(row),
                    name: lg.getStaticText(TC.P_ADD_TICKET),
                });
                // Does the user have the right to create his own reg Docs
                if (rights.isRightAllowed(RIGHTS.REG.WRITE_OWN_REG_DOCS)) items.push({
                    icon: "<i class='fa fa-arrow-right'></i>",
                    name: lg.getStaticText(TC.REG_ADD_FROM_ACTION_ELEM),
                    action: () => events.add_reg(row.regAction, row.element, true),
                });
            }
            // Does the user have the right to create his own reg Docs
            if (rights.isRightAllowed(RIGHTS.REG.WRITE_OWN_REG_DOCS)) items.push({
                action: () => events.add_reg(),
                icon: "<i class='fa fa-plus'></i>",
                name: lg.getStaticText(TC.TEMPLATE_NEW_ITEM_M, TC.GLOBAL_DOCUMENT),
            });
        }

        if (items.length > 0 && event.defaultItems?.length > 0) items.push("separator");
        if (event.defaultItems?.length > 0) items.push(...event.defaultItems);
        return items;
    }, [events, lg, rights, userId, props.read_only]);

    const extra_buttons = React.useMemo<TableProps<Row<A>>["extra_buttons"]>(() => {
        let buttons = [] as T.OnlyArray<TableProps<Row<A>>["extra_buttons"]>;
        let all_hides = TB.arrayWrapper(props.hide_buttons);

        if (!all_hides.includes("doc_type")) buttons.push({
            onClick: show_all.toggle,
            icon: { element: '<i class="fa fa-eye me-2"></i>' },
            label: lg.getStaticText(show_all.value ? TC.REG_TABLE_REG_DOCS : TC.REG_TABLE_ALL_DOCS),
        });
        if (!all_hides.includes("toggle")) buttons.push({
            onClick: show_most_recent.toggle,
            icon: { element: '<i class="fa fa-sync me-2"></i>' },
            label: lg.getStaticText(show_most_recent.value ? TC.REG_TABLE_ALL : TC.REG_TABLE_MOST_RECENT),
        });
        if (!all_hides.includes("zip")) buttons.push({
            onClick: events.zip,
            label: lg.getStaticText(TC.GLOBAL_ZIP_EXPORT),
            icon: { element: "<i class='fa fa-file-archive me-2'></i>" },
        });

        if (Array.isArray(props.buttons)) buttons.push(...props.buttons);
        else if (props.buttons) buttons.push(props.buttons);
        return buttons;
    }, [lg, show_most_recent, show_all, events.zip, props.buttons, props.hide_buttons]);
    //#endregion

    //#region URL Filters
    const filters = React.useMemo(() => ({
        category: searchParams.get("category"),
        status: searchParams.get("status") as T.RegStatusML,
    }), [searchParams]);

    const apply_url_filters = React.useCallback<TableProps<Row<A>>["onReadyGrid"]>(grid => {
        let model_filters: Record<string, SetFilterModel> = {};
        // Expand all groups
        grid.expandAll();

        if (filters.category) model_filters.tr_category = {
            filterType: "set",
            values: [filters.category].map(formatCategory),
        }
        if (filters.status) {
            /* @ts-ignore */
            if (filters.status === "missing") model_filters.validityDate = { value: ["missing"] };
            /* @ts-ignore */
            else if (filters.status === "late") model_filters.validityDate = { value: ["expired"] };
            else {
                /* @ts-ignore */
                model_filters.validityDate = { value: ["less3months", "none", "ok"] };
                model_filters.status = { filterType: "set", values: [getStatus(filters.status)] };
            }
        }
        if (Object.keys(filters).length > 0 && grid) grid.setFilterModel(model_filters);
        if (typeof onReadyGrid === "function") onReadyGrid(grid);
    }, [filters, formatCategory, getStatus, onReadyGrid]);
    //#endregion

    React.useImperativeHandle(ref, () => ({ reload: load_resource, table: grid, rows }), [load_resource, rows]);

    const inline_edit = React.useCallback<TableProps<Row<A>>["onValueChange"]>(params => {
        if (props.read_only) return;
        let change = onValueChange?.(params) || "to_process";
        if (change === "to_process") {
            let row = params.data,
                field = params.colDef.field as keyof typeof rows[number];

            // This field can't be edited
            if (field !== "tags_names" || !row.regAction) M.Alerts.updateError();
            // All seems ok
            else S.setRegDocTags({ id: row.regAction, tags: params.newValue }).then(() => {
                get_options.tags(row).then(tags => {
                    let selected_tags = params.newValue || [];
                    let tags_names = tags.filter(t => selected_tags.includes(t.value)).map(t => t.label);
                    setResource(p => ({ ...p, items: p.items.map(i => i.regAction === row.regAction ? { ...i, tags_names, tags: selected_tags } : i) }));
                }).catch(M.Alerts.loadError);
            }).catch(M.Alerts.updateError);
        }
    }, [get_options, props.read_only, onValueChange, setResource]);

    return <div className={"w-100 " + (props.className || "")}>
        <C.Spinner error={status === "error"}>
            <C.Flex direction="column" className="h-100">
                <Table<Row<A>>
                    sideBar
                    ref={grid}
                    rows={rows}
                    autoExpandGroups
                    columns={columns}
                    getRowId={r => r.data._id}
                    loading={status === "load"}
                    onValueChange={inline_edit}
                    columns_base="all_but_edit"
                    extra_buttons={extra_buttons}
                    onReadyGrid={apply_url_filters}
                    getContextMenuItems={context_menu}
                    adaptableId={props.origin || TABS.REG_DOCS_TABLE}
                />
            </C.Flex>
        </C.Spinner>
    </div>;
};

export const RegDocs = React.forwardRef(RenderRegDocs) as <A>(props: RegTableProps<A> & Partial<Record<"ref", React.ForwardedRef<RegTableRef<A>>>>) => React.ReactElement;

export const RegDocsContext: React.FC = props => {
    const [roots] = H.useRoots();
    H.useCrumbs(TC.TAB_REG_DOCS);
    H.useAuth({ tabName: TABS.REG_DOCS_TABLE })

    return <RegDocs context={roots} origin={TABS.REG_DOCS_TABLE} />;
}