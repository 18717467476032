import { TB } from "../../../Constants";
import renderInContainer from "../getContainer";
import { TimeModal, TimeModalProps } from "../Components";

export type AskTimeModalProps = Omit<TimeModalProps, "onClose" | "onConfirm">;

const askLink = (params?: AskTimeModalProps) => new Promise<Parameters<TimeModalProps["onConfirm"]>[0]>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};

    if (render && dismount) {
        let onClose = () => dismount?.(() => resolve(null));
        let onConfirm: TimeModalProps["onConfirm"] = date => dismount?.(() => resolve(date));
        render(<TimeModal {...params} onClose={onClose} onConfirm={onConfirm} />);
    }
    else resolve(null);
});

export default askLink;