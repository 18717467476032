import React from "react";
import Main from "../../Main";
import * as H from "../../hooks";
import { Provider } from "react-redux";
import { store as async_store } from "../../Redux";

const ReduxWrapper: React.FC<Record<"children", any>> = props => {
    const [store, set_store, status] = H.useAsyncState<Awaited<typeof async_store>>(null);

    React.useEffect(() => {
        // Make sure the store is ready
        async_store.then(store => set_store(store, "done"))
    }, [set_store]);

    if (status !== "done") return null;
    return <Provider store={store} children={<Main children={props.children} />} />;
};

export default ReduxWrapper