import React from "react";
// import '@fullcalendar/react/dist/vdom'; // TODO File doesn't exists anymore
import { Draggable } from "@fullcalendar/interaction";
import ActionItem, { ActionItemProps } from "./ActionItem";

export type DraggableActionItemProps = ActionItemProps;

const DraggableActionItem: React.FC<DraggableActionItemProps> = props => {
    const container = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        new Draggable(container.current);
    }, []);

    const dataEvent = React.useMemo(() => JSON.stringify({ id: props.action.id }), [props.action.id]);

    return <div data-event={dataEvent} ref={container}>
        <ActionItem {...props} />
    </div>;
}

export default DraggableActionItem;