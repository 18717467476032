import * as S from "../services";
import { T, TB } from "../Constants";

//#region types
type ArrayElem = T.TakeArrayElement<T.FormReduxState>;
type FormIdsRedux = T.ReduxFn<T.FormReduxState, T.FormReduxActions, T.FormReduxState>;
//#endregion

//#region Get Init
var attempt = 0;
var startedRequest = false;
const WEEK = 1000 * 60 * 60 * 24 * 7;

const getInit = () => {
    try {
        const dataStr = localStorage.getItem("forms");
        if (!TB.validString(dataStr)) return [];

        const data = JSON.parse(dataStr);
        const date = TB.getDate(data?.date);

        if (date === null) return [];
        else if (new Date().getTime() - date.getTime() > WEEK) return [];

        startedRequest = true;
        return TB.getArray<ArrayElem>(data?.forms);
    }
    catch (error) {
        return [];
    }
}
//#endregion

const formIds: FormIdsRedux = (state = getInit(), payload) => {
    const { type, action, asyncDispatch } = TB.getObject(payload);

    const getForms = () => S.getAllForms()
        .then(({ data }) => {
            const localStorageData = { date: new Date().toISOString(), forms: data };
            const json = JSON.stringify(localStorageData);
            localStorage.setItem("forms", json);

            asyncDispatch?.({ type: "SET_FORMS", action: data })
        })
        .catch(() => {
            startedRequest = false;
            attempt++;
            if (attempt < 3) asyncDispatch?.({ type: "RELOAD_FORMS" });
        });

    if (!startedRequest && typeof asyncDispatch === "function") {
        getForms();
        startedRequest = true;
    }

    switch (type) {
        case "RELOAD_FORMS":
            getForms();
            return state;

        case "SET_FORMS": return TB.getArray(action);
        default: return state || [];
    }
}

export default formIds;