import React from "react";
import { TB } from "../../../Constants";
import { FormCheck } from "react-bootstrap";
import { useLanguage } from "../../../hooks";
import { ComponentWrapper, InputProps } from "../Input";

export type SelectBoxProps = InputProps & {
    inline?: boolean;
    values?: { label: string, value: string }[];
}

const RadioButtons: React.FC<SelectBoxProps> = ({ inline, values, onChange, ...props }) => {
    const lg = useLanguage();

    const vValue = React.useMemo(() => TB.getObject(props.value), [props.value]);
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);
    const vValues = React.useMemo(() => TB.getArray(values).filter(v => TB.getString(v?.label) && TB.getString(v?.value)), [values]);
    const onEdit = React.useCallback((prop: string, checked: boolean) => onChange?.({ ...vValue, [prop]: checked }), [vValue, onChange]);

    return <ComponentWrapper {...props} disabled={isDisabled}>
        {vValues.map(v => <FormCheck
            type="checkbox"
            key={v.value}
            inline={inline}
            disabled={isDisabled}
            checked={vValue[v.value]}
            label={lg.getStaticText(v.label)}
            onChange={e => onEdit(v.value, e.target.checked)}
        />)}
    </ComponentWrapper>
}

export default RadioButtons;