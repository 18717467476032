import _ from "lodash";
import * as S from "../services";
import { TB, T, TC } from "../Constants";
import { renderAlert } from "../Components/Modal";

const DEFAULT_STATE: T.RightsContext = { admin: false, access: [], loading: true, first: true, context_ids: [], search_context: null };

/** Variable that stores the latest context loaded */
var latest_context = null;

const fetchRights = (context: T.ContextParams) => new Promise<T.RightsContext>(resolve => {
    latest_context = context;
    S.getUserAccess(context)
        .then(({ data }) => resolve({ ...data, search_context: context, loading: false }))
        .catch(e => {
            renderAlert({ type: "error", message: TC.ERROR_LOAD_ACCESS })
            // Must change the object's reference to avoir constant trigger of the first render
            resolve({ ...DEFAULT_STATE, search_context: context, loading: false });
        });
});

const rightsContext: T.ReduxFn<T.RightsContextRedux, T.RightsContextActions> = (state = DEFAULT_STATE, payload) => {
    const { type = null, action = null, asyncDispatch } = TB.getObject(payload);

    // A function that will set the new accesses, after confirming the received results is indeed for the last queried context
    const set_context = (promise: ReturnType<typeof fetchRights>) => promise.then(access => {
        let is_same = true;

        // One of the context doesn't exists
        if (!latest_context || !access.search_context) is_same = false;
        // State is for a portfolio, but access isn't
        else if (latest_context.portfolio && !access.search_context.portfolio) is_same = false;
        // Access if for a portfolio, but state isn't
        else if (access.search_context.portfolio && !latest_context.portfolio) is_same = false;
        // State is for items, but access isn't
        else if (Array.isArray(latest_context.roots) && !Array.isArray(access.search_context.roots)) is_same = false;
        // Access is for items, but state isn't
        else if (Array.isArray(access.search_context.roots) && !Array.isArray(latest_context.roots)) is_same = false;
        // Both arrays of item ids are not the same
        else if (!_.isEqual(latest_context.roots, access.search_context.roots)) is_same = false;

        if (is_same) asyncDispatch?.({ type: "LOADED_CONTEXT", action: { ...access, loading: false } });
    });

    // The context has changed, load the new rights
    if (type === "UPDATED_CONTEXT" && action) {
        const context: T.ContextParams = {};
        const context_action = action as T.DataContextRedux;
        if (context_action.selectedPortfolio) context.portfolio = context_action.selectedPortfolio;
        else context.roots = context_action.selectedItems;

        const right_promise = fetchRights(context);
        set_context(right_promise);
        // Need to return a value in the meantime, while rights are loading
        return { ...DEFAULT_STATE, search_context: context, loading: true };
    }
    // The context has been loaded, dispatch it
    else if (type === "LOADED_CONTEXT") return action as T.RightsContextRedux;
    else return state;
}

export default rightsContext;