import _ from "lodash";
import React from "react";
import { TB } from "../Constants";
import { Pagination } from "react-bootstrap";

//#region Utils
const getPreviousEnabled = (currentPage: number): boolean => currentPage > 0;
const getStartIndex = (pageSize: number, currentPage: number): number => pageSize * currentPage;
const getTotalPages = (totalItems: number, pageSize: number): number => Math.ceil(totalItems / pageSize);
const getNextEnabled = (currentPage: number, totalPages: number): boolean => currentPage + 1 < totalPages;
const limitPageBounds = (totalItems: number, pageSize: number) => (page: number): number => Math.min(Math.max(page, 0), getTotalPages(totalItems, pageSize) - 1);

const getEndIndex = (pageSize: number, currentPage: number, totalItems: number): number => {
    const lastPageEndIndex = pageSize * (currentPage + 1);
    return lastPageEndIndex > totalItems ? totalItems - 1 : lastPageEndIndex - 1;
};

const getPaginationMeta = ({ totalItems, pageSize, currentPage }: PaginationState): PaginationMeta => {
    const totalPages = getTotalPages(totalItems, pageSize);
    return {
        totalPages,
        startIndex: getStartIndex(pageSize, currentPage),
        previousEnabled: getPreviousEnabled(currentPage),
        nextEnabled: getNextEnabled(currentPage, totalPages),
        endIndex: getEndIndex(pageSize, currentPage, totalItems),
    };
};
//#endregion

//#region Types
type GetCurrentDataFN = <A = unknown>(array: A[]) => A[];
type PaginationState = { totalItems: number, pageSize: number, currentPage: number };
type UsePaginationConfig = { totalItems?: number, initialPage?: number, initialPageSize?: number };
type UsePaginationHook = (config: UsePaginationConfig) => PaginationState & PaginationMeta & PaginationActions & Record<"pagination", React.ReactNode>;

type PaginationMeta = {
    endIndex: number;
    totalPages: number;
    startIndex: number;
    nextEnabled: boolean;
    previousEnabled: boolean;
};

type PaginationActions = {
    setNextPage: () => void;
    setPreviousPage: () => void;
    setPage: (page: number) => void;
    getCurrentData: GetCurrentDataFN;
    setPageSize: (pageSize: number, nextPage?: number) => void;
};
//#endregion

const ITEMS = 5;

export const usePagination: UsePaginationHook = (params = {}) => {
    const { totalItems = 0, initialPage = 0, initialPageSize = 10 } = React.useMemo(() => TB.getObject(params), [params]);
    const initialState = { totalItems, pageSize: initialPageSize, currentPage: initialPage };
    const [paginationState, setPaginationState] = React.useState(initialState);

    //#region Auto State Change
    React.useEffect(() => {
        setPaginationState(p => typeof totalItems === "number" && totalItems !== p.totalItems ? { ...p, totalItems } : p);
    }, [totalItems]);
    //#endregion

    //#region Pagination Meta
    const meta = React.useMemo(() => getPaginationMeta(paginationState), [paginationState]);

    React.useEffect(() => {
        if (meta.totalPages < paginationState.currentPage) setPaginationState(p => ({ ...p, currentPage: 0 }));
    }, [meta, paginationState.currentPage]);
    //#endregion

    //#region Callbacks
    const setPage = React.useCallback((page: number) => {
        setPaginationState(p => ({ ...p, currentPage: limitPageBounds(p.totalItems, p.pageSize)(page) }));
    }, []);

    const setNextPage = React.useCallback(() => {
        setPaginationState(p => ({ ...p, currentPage: limitPageBounds(p.totalItems, p.pageSize)(p.currentPage + 1) }));
    }, []);

    const setPreviousPage = React.useCallback(() => {
        setPaginationState(p => ({ ...p, currentPage: limitPageBounds(p.totalItems, p.pageSize)(p.currentPage - 1) }))
    }, []);

    const setPageSize = React.useCallback((pageSize: number | string, nextPage = 0) => {
        setPaginationState(p => {
            const pageSizeNum = TB.getNumber(pageSize) || p.pageSize;
            return {
                ...p,
                pageSize: pageSizeNum,
                currentPage: limitPageBounds(p.totalItems, pageSizeNum)(nextPage ?? p.currentPage)
            }
        });
    }, []);
    //#endregion

    //#region Get Array
    const getCurrentData = React.useCallback<GetCurrentDataFN>(array => {
        if (!Array.isArray(array)) return [];
        const startIndex = paginationState.currentPage * paginationState.pageSize;
        const endIndex = startIndex + paginationState.pageSize;
        return _.slice(array, startIndex, endIndex);
    }, [paginationState.currentPage, paginationState.pageSize]);
    //#endregion

    //#region Pagination Look
    const toShowPages = React.useMemo(() => {
        if (paginationState.currentPage < Math.floor(ITEMS / 2)) return [...new Array(_.min([ITEMS, meta.totalPages]))].map((x, i) => i);
        if (paginationState.currentPage >= (meta.totalPages - Math.floor(ITEMS / 2))) return [...new Array(_.min([ITEMS, meta.totalPages]))].map((x, i) => meta.totalPages + (i - ITEMS));
        return [...new Array(ITEMS)].map((x, i) => paginationState.currentPage + (i - Math.floor(ITEMS / 2))).filter(x => x >= 0 && x <= meta.totalPages - 1)
    }, [meta.totalPages, paginationState.currentPage]);

    const pagination = React.useMemo(() => paginationState.totalItems > paginationState.pageSize && <div>
        <Pagination className="mb-0">
            <Pagination.First disabled={paginationState.currentPage === 0} onClick={() => setPage(0)} />
            <Pagination.Prev disabled={!meta.previousEnabled} onClick={setPreviousPage} />
            {toShowPages[0] > 0 && <Pagination.Ellipsis />}

            {toShowPages.map(i => <Pagination.Item key={i} active={paginationState.currentPage === i} onClick={() => setPage(i)}>{i + 1}</Pagination.Item>)}

            {toShowPages[ITEMS - 1] + 1 < meta.totalPages && <Pagination.Ellipsis />}
            <Pagination.Next disabled={!meta.nextEnabled} onClick={setNextPage} />
            <Pagination.Last disabled={paginationState.currentPage === meta.totalPages} onClick={() => setPage(meta.totalPages)} />
        </Pagination>
    </div>, [meta.nextEnabled, meta.previousEnabled, meta.totalPages, paginationState, toShowPages, setPage, setNextPage, setPreviousPage]);
    //#endregion

    return { ...meta, ...paginationState, pagination, setPage, getCurrentData, setNextPage, setPreviousPage, setPageSize };
}

export default usePagination;