import _ from "lodash";
import React from "react";
import * as F from "../../Form";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { TC, T, TB } from "../../../Constants";
import { useNavigate } from "react-router-dom";
import Comments, { CommentsProps } from "./Comments";
import * as AR from "@adaptabletools/adaptable-react-aggrid";

//#region Constants
type Layout = Record<"style", AR.FormatColumn> & Record<"layout", AR.Layout>;
type MissionResumeRedFlag = ReturnType<T.API.Utils.Missions.MissionResumeRedFlag>;

type Tabs = {
    /** The label displayed in the tab and in the crumb */
    label: string;
    /** The key to know which tab is active */
    key: "mission" | "remarques" | "reg" | "capex" | "comments";
}

type RegDocExtra = Record<"in_red_flag", boolean>;
type RemarqueExtra = Record<"tr_flag_category", string> & Record<"flag_category", MissionResumeRedFlag["categories"][number]["value"]>;
type CapexExtra = Record<"tr_flag_category", string> & Record<"flag_category", MissionResumeRedFlag["categories"][number]["value"] | "reg">;;

const TABS = [
    { label: TC.CERT_HISTORY_MISSION, key: "mission" },
    { label: TC.MISSION_CDM_REMARQUES, key: "remarques" },
    { label: TC.CRUMB_REG, key: "reg" },
    { label: TC.TAB_CAPEX_PLAN, key: "capex" },
    { label: TC.GLOBAL_COMMENTS, key: "comments" },
] as Tabs[];
//#endregion

const RedFlag: React.FC = () => {
    const lg = H.useLanguage();
    const navigate = useNavigate();
    const [, mission_id] = H.useRoots();
    const updated_mission = H.useBoolean(false);
    // const show_group_tickets = H.useBoolean(false);
    // const capex_table = React.useRef<G.CapexRef<CapexExtra>>(null);
    const mission_ref = React.useRef<F.Missions.MissionFormRef>(null);
    const rem_table = React.useRef<G.RemarquesTableRef<RemarqueExtra>>(null);
    const { updateCrumbs, resetCrumbs } = H.useCrumbs(TC.MISSION_RED_FLAG);
    const table_layout = React.useRef<Layout>({ layout: null, style: null });
    const [current_tab, set_current_tab] = React.useState<Tabs["key"]>("mission");
    const [loaded_tabs, set_loaded_tabs] = React.useState<Tabs["key"][]>(["mission"]);
    const [mission_state, set_mission_state, status] = H.useAsyncState<MissionResumeRedFlag>({ asset_id: "", mission_id: "", categories: [], read_only: true, data: null, extra: {} });

    //#region Tabs & Crumbs
    React.useEffect(() => {
        let tab = TABS.filter(t => t.key === current_tab)[0];
        if (tab) updateCrumbs({ label: tab.label }, 1);
        return () => { resetCrumbs() };
    }, [updateCrumbs, resetCrumbs, current_tab]);

    const change_tab = React.useCallback((tab: typeof current_tab) => {
        const tab_setter = (keep_mission_loaded = true) => {
            // Update the current tab
            set_current_tab(tab);
            // Update the list of loaded tab
            set_loaded_tabs(p => {
                let new_loaded = p.includes(tab) ? p : p.concat(tab);
                if (!keep_mission_loaded) return new_loaded.filter(t => t !== "mission");
                else return new_loaded;
            });
        }

        // Ask to confirm the changes made in the mission
        if (current_tab === "mission" && updated_mission.value) M.askConfirm({ title: TC.MISSION_WIZARD_AUTO_SAVE_MISSION, text: TC.MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT }).then(confirmed => {
            if (confirmed) {
                let result = mission_ref.current?.save?.();
                if (result && result !== "errors") result.then(success => {
                    if (success) {
                        tab_setter();
                        updated_mission.setFalse();
                    }
                });
            }
            else tab_setter(false);
        });
        else tab_setter();
    }, [current_tab, updated_mission]);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Dependency is not necessary, and I don't want the warning to show up
    React.useEffect(() => updated_mission.setFalse(), [mission_id]);
    //#endregion

    //#region Load Data
    React.useEffect(() => {
        let isSubscribed = true;
        S.missionResumeRedFlag()
            .then(({ data }) => isSubscribed && set_mission_state(data, "done"))
            .catch(() => isSubscribed && set_mission_state({ asset_id: "", read_only: true, mission_id: "", data: null, extra: {}, categories: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_current_tab("mission");
            set_loaded_tabs(["mission"]);
            set_mission_state({ asset_id: "", read_only: true, mission_id: "", data: null, extra: {}, categories: [] }, "load");
        }
    }, [set_mission_state, mission_id]);

    const save_comment = React.useCallback<CommentsProps["set_comment"]>((prop, value) => {
        let previous_state = mission_state.extra;
        set_mission_state(p => ({ ...p, extra: { ...p.extra, [prop]: value } }));

        S.saveMissionExtra({ type: "red_flag", prop, value })
            .catch(e => {
                M.Alerts.updateError(e);
                set_mission_state(p => ({ ...p, extra: previous_state }));
            });
    }, [set_mission_state, mission_state.extra]);
    //#endregion

    //#region Table Props
    const context = React.useMemo<T.ContextParams>(() => ({ roots: mission_state.asset_id }), [mission_state.asset_id]);

    const remarques_props = React.useMemo<Omit<G.RemarquesTableProps<RemarqueExtra>, "context">>(() => ({
        ref: rem_table,
        status: ["open", "memo"],
        auto_fit: ["tr_flag_category"],
        criticity_code: ["high", "medium"],
        buttons: {
            label: lg.getStaticText(TC.RED_FLAG_REM_AUTO_ASSIGN),
            icon: { element: "<i class='fa fa-hat-wizard me-1'></i>" },
            onClick: () => {
                let has_entered_data = _.flatten(Object.values(mission_state.data?.remarques || {})).length > 0;
                let confirm_promise = new Promise<boolean>(resolve => {
                    // User already has data entered
                    if (has_entered_data) M.askConfirm({ title: TC.RED_FLAG_AUTO_REMS_OVERWRITE, text: TC.RED_FLAG_AUTO_REMS_OVERWRITE_TEXT }).then(resolve);
                    else resolve(true);
                });

                confirm_promise.then(confirmed => {
                    if (confirmed) {
                        const dismount = M.renderLoader();
                        S.autoAssignRemsRedFlag()
                            .then(({ data }) => set_mission_state(p => ({ ...p, data: { ...p.data, remarques: data } })))
                            .catch(M.Alerts.updateError)
                            .finally(dismount);
                    }
                });
            },
        },
        onReadyGrid: () => {
            let props = ["tr_flag_category"] as (keyof RemarqueExtra)[];
            // Update the table, leave it some time to update
            if (rem_table.current?.table?.current) {
                let grid = rem_table.current?.table?.current?.grid;
                let adaptable = rem_table.current?.table?.current?.adaptable;
                // Expand all the rows
                grid?.expandAll?.();
                // Remove previous layout
                if (table_layout.current.layout) adaptable?.layoutApi?.deleteLayout?.(table_layout.current.layout);
                if (table_layout.current.style) adaptable?.formatColumnApi?.deleteFormatColumn?.(table_layout.current.style);
                // Adapt the layout to the props under review

                let style = adaptable?.formatColumnApi?.addFormatColumn?.({
                    Source: "User",
                    Scope: { ColumnIds: props },
                    Rule: { Predicates: [{ Inputs: [], PredicateId: "Blanks" }] },
                    Style: { ClassName: "", FontStyle: "Normal", BackColor: "#FFA500", FontWeight: "Normal", ForeColor: "#FFFFFF", BorderColor: "#FF0000" },
                });

                table_layout.current.style = style;
            }
        },
        onValueChange: event => {
            if (event.colDef.field !== "tr_flag_category") return "to_process";
            else if (event.node.data.flag_category === event.newValue) return "processed";
            else {
                let new_data = { ...mission_state.data, remarques: { ...mission_state.data.remarques } };
                let old_value = event.node.data.flag_category;
                // Add the id to the new category
                if (event.newValue) new_data.remarques[event.newValue] = new_data.remarques[event.newValue].concat(event.data._id);
                // Remove the id from the old category
                if (old_value) new_data.remarques[old_value] = new_data.remarques[old_value].filter(id => id !== event.data._id);
                // Save the changes directly in database
                S.saveMissionRedFlag(new_data)
                    .then(() => set_mission_state(p => ({ ...p, data: new_data })))
                    .catch(M.Alerts.updateError);
                return "processed";
            }
        },
        process_rows: rows => {
            let id_records = {};
            for (let [code, ids] of Object.entries(mission_state.data?.remarques || {})) {
                for (let id of ids) id_records[id] = code;
            }

            return rows.map(row => {
                let code = id_records[row._id] as RemarqueExtra["flag_category"];
                let tr_code = lg.getStaticText(mission_state.categories.find(c => c.value === code)?.label || "");
                return { ...row, flag_category: code, tr_flag_category: tr_code || "" };
            });
        },
        extra_columns: [{
            editable: true,
            pinned: "right",
            field: "tr_flag_category",
            type: G.CellsTypes.TYPE_SELECT,
            headerName: TC.CATEGORIES_RED_FLAG,
            params: { values: mission_state.categories, field_value: "flag_category", empty_option: true, auto_fit: true },
        }],
    }), [lg, mission_state.categories, mission_state.data, set_mission_state]);

    const reg_docs_props = React.useMemo<Omit<G.RegTableProps<RegDocExtra>, "context">>(() => ({
        buttons: {
            label: lg.getStaticText(TC.RED_FLAG_REM_AUTO_ASSIGN),
            icon: { element: "<i class='fa fa-hat-wizard me-1'></i>" },
            onClick: () => {
                let has_entered_data = (mission_state.data?.reg || []).length > 0;
                let confirm_promise = new Promise<boolean>(resolve => {
                    // User already has data entered
                    if (has_entered_data) M.askConfirm({ title: TC.RED_FLAG_AUTO_REMS_OVERWRITE, text: TC.RED_FLAG_AUTO_REMS_OVERWRITE_TEXT }).then(resolve);
                    else resolve(true);
                });

                confirm_promise.then(confirmed => {
                    if (confirmed) {
                        const dismount = M.renderLoader();
                        S.autoAssignRegDocRedFlag()
                            .then(({ data }) => set_mission_state(p => ({ ...p, data: { ...p.data, reg: data } })))
                            .catch(M.Alerts.updateError)
                            .finally(dismount);
                    }
                });
            },
        },
        onValueChange: event => {
            if (event.colDef.field !== "in_red_flag") return "to_process";
            else {
                let new_data = { ...mission_state.data, reg: [...mission_state.data.reg] };
                // Add the id to the array if not in already
                if (!new_data.reg.includes(event.data._id)) new_data.reg = new_data.reg.concat(event.data._id);
                // Remove the id from the array if already in
                else new_data.reg = new_data.reg.filter(id => id !== event.data._id);
                // Save the changes directly in database
                S.saveMissionRedFlag(new_data)
                    .then(() => set_mission_state(p => ({ ...p, data: new_data })))
                    .catch(M.Alerts.updateError);
                return "processed";
            }
        },
        process_rows: rows => rows.map(r => ({ ...r, in_red_flag: mission_state.data?.reg?.includes(r._id) || false })),
        extra_columns: [{ editable: true, pinned: "right", headerName: TC.RED_FLAG_INCLUDE_REG_DOC, field: "in_red_flag", type: G.CellsTypes.TYPE_CHECKBOX }],
    }), [lg, mission_state.data, set_mission_state]);

    const capex_props = React.useMemo<Omit<G.CapexProps<CapexExtra>, "context">>(() => ({
        group_tickets_state: "both",
        hide_buttons: "toggle_ticket_group",
        buttons: [{
            label: lg.getStaticText(TC.RED_FLAG_REM_AUTO_ASSIGN),
            icon: { element: "<i class='fa fa-hat-wizard me-1'></i>" },
            onClick: () => {
                let has_entered_data = _.flatten(Object.values(mission_state.data?.capex || {})).length > 0;
                let confirm_promise = new Promise<boolean>(resolve => {
                    // User already has data entered
                    if (has_entered_data) M.askConfirm({ title: TC.RED_FLAG_AUTO_REMS_OVERWRITE, text: TC.RED_FLAG_AUTO_REMS_OVERWRITE_TEXT }).then(resolve);
                    else resolve(true);
                });

                confirm_promise.then(confirmed => {
                    if (confirmed) {
                        const dismount = M.renderLoader();
                        S.autoAssignCapexRedFlag()
                            .then(({ data }) => set_mission_state(p => ({ ...p, data: { ...p.data, capex: data } })))
                            .catch(M.Alerts.updateError)
                            .finally(dismount);
                    }
                });
            },
        }],
        process_rows: rows => {
            let id_records = {};
            for (let [code, ids] of Object.entries(mission_state.data?.capex || {})) {
                for (let id of ids) id_records[id.id] = code;
            }
            return rows.map(row => {
                let code = id_records[TB.mongoIdValidator(row._id) ? row._id : row.element_id] as CapexExtra["flag_category"];
                if (code === "reg") return { ...row, flag_category: code, tr_flag_category: lg.getStaticText(TC.RED_FLAG_CAT_REG) };
                else {
                    let tr_code = lg.getStaticText(mission_state.categories.find(c => c.value === code)?.label || "");
                    return { ...row, flag_category: code, tr_flag_category: tr_code || "" };
                }
            });
        },
        onValueChange: event => {
            if (event.colDef.field !== "tr_flag_category") return "to_process";
            else {
                /* If action has a parent action group, ask user to select that instead */
                let has_parent_group = event.data.parents_tickets.length > 0;

                if (has_parent_group) {
                    M.renderAlert({ type: "warning", message: TC.RED_FLAG_CAPEX_SELECT_GROUP });
                    return "processed";
                }
                else {
                    let is_not_element = TB.mongoIdValidator(event.data._id);
                    let new_data = { ...mission_state.data, capex: { ...mission_state.data.capex } };
                    let old_value = event.node.data.flag_category;

                    // Add the id to the new category
                    if (event.newValue) new_data.capex[event.newValue] = new_data.capex[event.newValue]
                        .concat({ id: is_not_element ? event.data._id : event.data.element_id, type: is_not_element ? "action" : "equipment" });
                    // Remove the id from the old category
                    if (old_value) new_data.capex[old_value] = new_data.capex[old_value].filter(id => id.type === "action" ? id.id !== event.data._id : id.id !== event.data.element_id);

                    // Save the changes directly in database
                    S.saveMissionRedFlag(new_data)
                        .then(() => set_mission_state(p => ({ ...p, data: new_data })))
                        .catch(M.Alerts.updateError);
                    return "processed";
                }
            }
        },
        extra_columns: [{
            editable: true,
            pinned: "right",
            field: "tr_flag_category",
            type: G.CellsTypes.TYPE_SELECT,
            headerName: TC.CATEGORIES_RED_FLAG,
            params: { values: (mission_state.categories as any[]).concat({ label: TC.RED_FLAG_CAT_REG, value: "reg" }), empty_option: true },
        }],
    }), [lg, mission_state, set_mission_state]);
    //#endregion

    return <C.Flex direction="column" className="flex-grow-1">
        <C.Spinner status={status}>

            <BS.Row className="g-1">
                {TABS.map(tab => <BS.Col key={tab.key}>
                    <C.Button
                        size="sm"
                        text={tab.label}
                        className="w-100"
                        onClick={() => change_tab(tab.key)}
                        variant={tab.key === current_tab ? "primary" : "link"}
                    />
                </BS.Col>)}
            </BS.Row>

            {current_tab === "mission" && <div className="flex-grow-1 my-3">
                <F.Missions.MissionForm
                    no_delete
                    ref={mission_ref}
                    navigate={navigate}
                    asset={mission_state.asset_id}
                    onSave={updated_mission.setFalse}
                    onChange={updated_mission.setTrue}
                    mission_id={mission_state.mission_id}
                />
            </div>}

            {/* Different handling than other tabs, because table still appears if flex is hidden */}
            {current_tab === "remarques" && <C.Flex className="flex-grow-1 my-3" children={<G.Remarques<RemarqueExtra> context={context} {...remarques_props} />} />}
            {/* Different handling than other tabs, because table still appears if flex is hidden */}
            {current_tab === "reg" && <C.Flex className="flex-grow-1 my-3" children={<G.RegDocs<RegDocExtra> context={context} {...reg_docs_props} />} />}
            {/* Different handling than other tabs, because table still appears if flex is hidden */}
            {current_tab === "capex" && <C.Flex className="flex-grow-1 my-3" children={<G.Capex<CapexExtra> context={context} {...capex_props} />} />}

            {loaded_tabs.includes("comments") && <div className="flex-grow-1 my-3" hidden={current_tab !== "comments"} children={<Comments state={mission_state} set_comment={save_comment} />} />}

        </C.Spinner>
    </C.Flex>
}

export default RedFlag;