import React from "react";
import { TB } from "../../Constants";
import { useLanguage } from "../../hooks";
import * as M from "../../Components/Modal";
import { Card, Col, Figure, Row } from "react-bootstrap";

//#region Types
type PictureFile = { src: string, title?: string };

type PictureGridProps = {
    title?: string;
    images?: PictureFile[];
}

const isPicture = (file?: any): file is PictureFile => TB.validObject(file) && TB.validString(file.src);
//#endregion

const PictureGrid: React.FC<PictureGridProps> = ({ title, images, ...props }) => {
    const { getStaticElem } = useLanguage();

    const imageArray = React.useMemo(() => TB.getArray(images).filter(isPicture), [images]);
    const popUpImages = React.useCallback((defaultSrc: string) => M.renderCarousel({ images: imageArray as any, defaultSrc }), [imageArray]);

    return <Row className="g-2">
        {title && <Col md={12}>
            <Card.Subtitle>{getStaticElem(title)}</Card.Subtitle>
        </Col>}
        {imageArray.map((img, i) => <Col sm={4} md={3} key={i} className="d-flex justify-content-center align-items-center">
            <Figure.Image className="pointer" onClick={() => popUpImages(img.src)} fluid {...img} />
        </Col>)}
    </Row>
}

export default PictureGrid;