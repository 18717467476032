import React from "react";
import * as H from "../../hooks";
import { TB } from "../../Constants";
import InnerHtml from "dangerously-set-html-content";

export type HtmlTextProps = {
    /** The html string content */
    html: string;
    /** Decides of the presence of 'h-100' in the className */
    no_full_height?: boolean;
    /** A list of variables to replace in the html string */
    variables?: Record<"code" | "value", string>[];
}

const HtmlText: React.FC<HtmlTextProps> = props => {
    const is_dark = H.useDark();

    const className = React.useMemo(() => {
        let classes = ["w-100"];
        if (!props.no_full_height) classes.push("h-100");
        return classes.join(" ");
    }, [props.no_full_height]);

    const variables = React.useMemo(() => TB.getArray(props.variables)
        .concat({ code: "@dark@", value: is_dark ? "true" : "false" })
        , [is_dark, props.variables]);

    const html = React.useMemo(() => {
        let updated_html = props.html || "";
        variables.forEach(v => updated_html = TB.replaceStringPart(updated_html, v.code, v.value));
        return updated_html;
    }, [props.html, variables]);

    return <InnerHtml className={className} html={html || "<div></div>"} />;
}

export default HtmlText;