import React from "react";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { FP, LT, T, TB, TC } from "../../../Constants";

//#region Types
export type DataOrganizerProps = {
    /** The point of origin */
    origin?: string;
    /** The context of origin */
    context?: T.ContextParams;
};

export type DataOrganizerRef = {
    /** The grid reference */
    grid: React.MutableRefObject<G.TableRef<Row>>;
}

type EnergyLinksData = T.API.Utils.Datasets.EnergyLinksData;

type Row = EnergyLinksData["links"][number] & {
    /** Options for input datasets */
    input_datasets: T.Option[];
    /** Options for output datasets */
    output_datasets: T.Option[];
    /** An error to show under the input select */
    error_input?: string;
    /** An error to show under the output select */
    error_output?: string;
    /** The name of the input dataset */
    input_dataset_name?: string;
    /** The name of the output dataset */
    output_dataset_name?: string;
}

type TextCodeDic = {
    /** Contains text code for the types of energy */
    type: Record<T.DataSet["type"], string>;
    /** Contains text code for the source of values */
    src: Record<T.DataSet["src"], string>;
}

export const TEXT_CODE_DIC: TextCodeDic = {
    type: {
        GAS: TC.GP_GAS,
        ELEC: TC.GP_ELEC,
        FUEL: TC.GP_FUEL,
        THFR: TC.GP_THFR,
        THCH: TC.GP_THCH,
        OTHER: TC.GP_OTHER,
        WATER: TC.GP_WATER,
        MONEY: TC.NRJ_MONEY,
        ELEC_PPV: TC.GP_ELEC_PPV,

        GAS_HEAD: TC.NRJ_HEAD_GAS,
        ELEC_HEAD: TC.NRJ_HEAD_ELEC,
        THFR_HEAD: TC.NRJ_HEAD_THFR,
        THCH_HEAD: TC.NRJ_HEAD_THCH,
        FUEL_HEAD: TC.NRJ_HEAD_FUEL,
        WATER_HEAD: TC.NRJ_HEAD_WATER,
        OTHER_HEAD: TC.NRJ_HEAD_OTHER,
        ELEC_PPV_HEAD: TC.NRJ_HEAD_ELEC_PPV,

        NB_START: TC.NRJ_NB_START,
        HOURS_WORK: TC.NRJ_WORK_HOURS,
        PRESSURE: TC.NRJ_PRESSURE,
        TEMPERATURE: TC.NRJ_TEMPERATURE,
        CONDUCTIVITY: TC.NRJ_CONDUCTIVITY,
        CO2: TC.NRJ_CO2,
        WASTE: TC.NRJ_WASTE,
        WATER_HARDNESS: TC.NRJ_WATER_HARDNESS,
    },
    src: {
        manual: TC.DATASET_SRC_MANUAL,
        automatic: TC.DATASET_SRC_AUTO,
        prediction: TC.DATASET_SRC_PRED,
        calculated: TC.DATASET_SRC_CALCULATED,
    }
};
//#endregion

const DataOrganizer = React.forwardRef<DataOrganizerRef, DataOrganizerProps>((props, ref) => {
    const lg = H.useLanguage();
    const grid = React.useRef<G.TableRef<Row>>(null);
    const [items, setItems, itemStatus] = H.useAsyncState<EnergyLinksData>({ links: [], datasets: [] });
    const [suggestions, set_suggestions] = React.useState<ReturnType<T.API.Utils.Datasets.SuggestEnergyLinks>["suggestions"]>({});

    React.useImperativeHandle(ref, () => ({ grid }), []);

    // Restricts modifications to links when opened from the tree
    const allowLinkEdit = React.useMemo(() => !TB.mongoIdValidator(props.origin), [props.origin]);

    React.useEffect(() => {
        let isSubscribed = true;

        if (TB.mongoIdValidator(props.origin)) S.findEnergyLinksOrigin(props.origin)
            .then(({ data }) => isSubscribed && setItems(data, "done"))
            .catch(() => isSubscribed && setItems({ links: [], datasets: [] }, "error"));
        else if (props.context) S.findEnergyLinksContext(props.context)
            .then(({ data }) => isSubscribed && setItems(data, "done"))
            .catch(() => isSubscribed && setItems({ links: [], datasets: [] }, "error"));
        else setItems({ links: [], datasets: [] }, "done");

        return () => { isSubscribed = false };
    }, [props.context, props.origin, setItems]);

    const datasets = React.useMemo(() => ({
        create: (origin: T.DataSet["origin"]) => M.renderDataFormModal({ origin }).then(dataset => {
            if (dataset) setItems(p => ({ ...p, datasets: p.datasets.concat(dataset) }));
        }),
        edit: (id: T.DataSet["_id"]) => {
            let dataset = items.datasets.filter(d => d._id === id)[0];
            if (dataset) M.renderDataFormModal({ dataset, origin: dataset.origin }).then(dataset => {
                if (dataset) setItems(p => ({ ...p, datasets: p.datasets.map(d => d._id === id ? dataset : d) }));
            });
        },
        change: (link: Row["id"], isInput?: boolean, dataset?: T.DataSet["_id"]) => {
            // Update link in db
            S.assignDataset({ link, dataset, flow: isInput ? "in" : "out" }).then(() => {
                // Update link in local
                setItems(p => ({
                    ...p,
                    links: p.links.map(l => {
                        if (l.id !== link) return l;
                        return {
                            ...l,
                            dataset_in: isInput ? dataset : l.dataset_in,
                            dataset_out: isInput ? l.dataset_out : dataset,
                        }
                    })
                }));
            }).catch(M.Alerts.updateError);
        }
    }), [setItems, items.datasets]);

    const render = React.useMemo(() => ({
        link_type: ((data, field) => <C.Flex
            alignItems="center"
            justifyContent="center"
            children={data[field]}
            style={{ backgroundColor: data.type_color, color: !TB.isColorWhite(data.type_color) ? "white" : undefined }}
        />) as G.ColDef<Row>["params"]["render"],
    }), []);

    const columns = React.useMemo<G.TableProps<Row>["columns"]>(() => [
        { field: "tr_code_type", headerName: TC.DATASET_LINK_TYPE, type: G.CellsTypes.TYPE_FREE_RENDER, params: { render: render.link_type } },
        { field: "input_label", headerName: TC.DATASET_LINK_INPUT },
        {
            headerName: " ",
            field: "error_input",
            type: G.CellsTypes.TYPE_FREE_RENDER,
            params: { render: (row, field) => row[field] && <C.IconTip icon="question-circle" className="text-danger" tipContent={row[field]} /> },
        },
        {
            editable: true,
            field: "input_dataset_name",
            type: G.CellsTypes.TYPE_SELECT,
            headerName: TC.DATASET_LINK_INPUT_D,
            params: { getValues: row => row.input_datasets, empty_option: true }
        },
        { field: "output_label", headerName: TC.DATASET_LINK_OUTPUT },
        {
            headerName: " ",
            field: "error_output",
            type: G.CellsTypes.TYPE_FREE_RENDER,
            params: { render: (row, field) => row[field] && <C.IconTip icon="question-circle" className="text-danger" tipContent={row[field]} /> },
        },
        {
            editable: true,
            field: "output_dataset_name",
            type: G.CellsTypes.TYPE_SELECT,
            headerName: TC.DATASET_LINK_OUTPUT_D,
            params: { getValues: row => row.output_datasets, empty_option: true }
        },
    ], [render]);

    const rows = React.useMemo<Row[]>(() => {

        const find_available_datasets = (isInput: boolean, item: typeof items["links"][number]): T.Option[] => {
            let origin = isInput ? item.input : item.output;
            let datasetsOrigin = items.datasets.filter(d => d.origin === origin);
            return datasetsOrigin.map(d => ({ label: d.name, value: d._id }));
        }

        return items.links.map(l => {
            let error_input: string, error_output: string;
            let tr_code_type = lg.getStaticText(l.code_type);
            // Get the suggestions for the link
            let suggested_input = suggestions[l.id]?.input;
            let suggested_output = suggestions[l.id]?.output;
            // Get the names of the datasets
            let input_dataset_name, output_dataset_name;
            if (l.dataset_in) input_dataset_name = items.datasets.find(d => d._id === l.dataset_in)?.name;
            if (l.dataset_out) output_dataset_name = items.datasets.find(d => d._id === l.dataset_out)?.name;
            // Find available datasets for the link
            let inputs = find_available_datasets(true, l);
            let outputs = find_available_datasets(false, l);
            // Filter the available datasets based on the suggestions, or the current value
            if (suggested_input) inputs = inputs.filter(o => suggested_input.includes(o.value) || o.value === l.dataset_in);
            if (suggested_output) outputs = outputs.filter(o => suggested_output.includes(o.value) || o.value === l.dataset_out);
            // If there is a value that is not in the suggestions, show an error
            if (l.dataset_in && suggested_input && !suggested_input.includes(l.dataset_in)) error_input = TC.LINK_MAP_AUTO_ASSIGN_ERROR;
            if (l.dataset_out && suggested_output && !suggested_output.includes(l.dataset_out)) error_output = TC.LINK_MAP_AUTO_ASSIGN_ERROR;
            // If no incorrect value assigned, check response for other errors
            error_input = error_input || suggestions[l.id]?.input_error || "";
            error_output = error_output || suggestions[l.id]?.output_error || "";
            // Return the row
            return { ...l, tr_code_type, input_datasets: inputs, output_datasets: outputs, error_input, error_output, input_dataset_name, output_dataset_name };
        });
    }, [suggestions, items, lg]);

    const links = React.useMemo(() => ({
        create: (link?: Partial<T.Link>) => {
            M.askLink({ link, types: LT.GROUP_ENERGY_NON_PRIMARY, paths: FP.GROUP_DATASETS }).then(link => {
                if (link) S.findEnergyLinksOrigin([link.input, link.output]).then(({ data }) => {
                    setItems(p => {
                        let existingLinks = p.links.map(l => l.id);
                        let existingDatasets = p.datasets.map(d => d._id);

                        return {
                            links: p.links.concat(data.links.filter(l => !existingLinks.includes(l.id))),
                            datasets: p.datasets.concat(data.datasets.filter(d => !existingDatasets.includes(d._id))),
                        }
                    });
                }).catch(M.Alerts.loadError);
            });
        },
        delete: (link: string) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) S.removeLinks(link)
                    .then(() => setItems(p => ({ ...p, links: p.links.filter(l => l.id !== link) })))
                    .catch(M.Alerts.deleteError);
            });
        },
        auto_assign: () => {
            const dismount = M.renderLoader(TC.LINK_MAP_AUTO_ASSIGN_LOADER);
            S.suggestEnergyLinks({ context: props.origin || props.context, is_origin: TB.mongoIdValidator(props.origin) }).then(({ data }) => {

                // Show a message to let the user know what's just happened
                M.renderAlert({
                    delay: 10,
                    type: "success",
                    title: TC.LINK_MAP_AUTO_ASSIGN_BUTTON,
                    message: TC.LINK_MAP_AUTO_ASSIGN_SUCCESS,
                });
                // Apply the suggestions to the state
                set_suggestions(data.suggestions);
                // Update to show the auto-assigned links in the table
                setItems(p => ({
                    ...p,
                    links: p.links.map(l => {
                        let assigned = data.auto_assigned.filter(a => a.link_id === l.id);
                        if (assigned.length === 0) return l;
                        else return {
                            ...l,
                            dataset_in: assigned.find(a => a.is_input)?.dataset_id || l.dataset_in,
                            dataset_out: assigned.find(a => !a.is_input)?.dataset_id || l.dataset_out,
                        }
                    }),
                }));
            })
                .catch(M.Alerts.updateError)
                .finally(dismount);
        },
        get_sankey: () => M.renderSankey({ context: props.context || { roots: props.origin } }),
    }), [setItems, props.context, props.origin]);

    const on_inline_change = React.useCallback<G.TableProps<Row>["onValueChange"]>(params => {
        let row = params.data;
        datasets.change(row.id, params.colDef.field.includes("input"), params.newValue);
    }, [datasets]);

    const getContextMenu = React.useCallback<G.TableProps<Row>["getContextMenuItems"]>(params => {
        if (!allowLinkEdit) return [];

        let row = params?.node?.data;
        let actions: ReturnType<G.TableProps<Row>["getContextMenuItems"]> = [
            // Create a link
            { name: lg.getStaticText(TC.DATASET_LINK_ADD), icon: "<i class='fa fa-plus'></i>", action: () => links.create() },
        ];
        // There is a link selected, offer shortcuts to create links with the same input or output
        if (row) actions.push(
            { name: lg.getStaticText(TC.DATASET_LINK_ADD_SAME_IN), icon: "<i class='fa fa-plus'></i>", action: () => links.create({ input: row.input }) },
            { name: lg.getStaticText(TC.DATASET_LINK_ADD_SAME_OUT), icon: "<i class='fa fa-plus'></i>", action: () => links.create({ output: row.output }) },
            "separator",
            { name: lg.getStaticText(TC.DATASET_LINK_REMOVE), icon: "<i class='fa fa-trash'></i>", action: () => links.delete(row.id) }
        );
        // Add a separator
        actions.push("separator");
        // Add option to create/edit datasets
        if (row) {
            // Edit the input dataset
            if (row.dataset_in) actions.push({
                icon: "<i class='fa fa-edit'></i>",
                action: () => datasets.edit(row.dataset_in),
                name: lg.getStaticText(TC.DATA_ORGANISER_EDIT_DATASET_INPUT, row.input_dataset_name),
            });
            // Create an input dataset
            else actions.push({
                icon: "<i class='fa fa-plus'></i>",
                action: () => datasets.create(row.input),
                name: lg.getStaticText(TC.DATA_ORGANISER_CREATE_DATASET_INPUT, row.input_label),
            });
            // Edit the output dataset
            if (row.dataset_out) actions.push({
                icon: "<i class='fa fa-edit'></i>",
                action: () => datasets.edit(row.dataset_out),
                name: lg.getStaticText(TC.DATA_ORGANISER_EDIT_DATASET_OUTPUT, row.output_dataset_name),
            });
            // Create an output dataset
            else actions.push({
                icon: "<i class='fa fa-plus'></i>",
                action: () => datasets.create(row.output),
                name: lg.getStaticText(TC.DATA_ORGANISER_CREATE_DATASET_OUTPUT, row.input_label),
            });
        }
        return actions;
    }, [links, allowLinkEdit, lg, datasets]);

    const extra_buttons = React.useMemo<G.TableProps<Row>["extra_buttons"]>(() => [
        { label: lg.getStaticText(TC.DATA_ORGANIZER_CREATE_SANKEY), icon: { element: "<i class='me-2 fa fa-project-diagram'></i>" }, onClick: links.get_sankey },
        { label: lg.getStaticText(TC.LINK_MAP_AUTO_ASSIGN_BUTTON), icon: { element: "<i class='me-2 fa fa-magic'></i>" }, onClick: links.auto_assign },
        { label: lg.getStaticText(TC.DATASET_LINK_ADD), icon: { element: "<i class='me-2 fa fa-plus'></i>" }, onClick: () => links.create() },
    ], [links, lg]);

    const auto_fit = React.useMemo(() => ["error_input", "error_output"], []);

    return <C.Spinner error={itemStatus === "error"}>
        <G.Table<Row>
            ref={grid}
            rows={rows}
            columns={columns}
            autoFit={auto_fit}
            getRowId={r => r.data.id}
            sideBar="filters_columns"
            columns_base="all_but_edit"
            adaptableId="data_organizer"
            extra_buttons={extra_buttons}
            loading={itemStatus === "load"}
            onValueChange={on_inline_change}
            getContextMenuItems={getContextMenu}
        />
    </C.Spinner>;
});

export default DataOrganizer;