import * as M from "../BlankModal";
import * as D from "../../Dashboard";
import { TC, TB } from "../../../Constants";
import renderInContainer from "../getContainer";

export type RenderAlertDashboards = (params: D.AlertDashboardsProps & M.StyleModalProps) => Promise<void>;
export type RenderEnergyDashboards = (params: D.EnergyProps & Partial<Record<"modal", M.StyleModalProps>>) => Promise<void>;

/** 
 * Render a modal containing the alert Dashboard
 */
export const renderAlertDashboards: RenderAlertDashboards = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const { datasets, ...props } = params || { datasets: [] };

        render(<M.default
            {...props}
            onQuit={() => dismount(() => resolve(null))}
            title={props?.title || TC.DASHBOARD_MODAL_DATASETS}
            isFullScreen={typeof props?.isFullScreen === "boolean" ? props.isFullScreen : true}
        >
            <D.AlertDashboards datasets={datasets} />
        </M.default>);
    }
    else resolve(null);
});

/**
 * Render a modal containing the energy Dashboard
 */
export const renderEnergyDashboards: RenderEnergyDashboards = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const { modal, ...props } = params || {};
        render(<M.default
            {...modal}
            children={<D.EnergyDashboard {...props} />}
            onQuit={() => dismount(() => resolve(null))}
            title={modal?.title || TC.DASHBOARD_MODAL_DATASETS}
            isFullScreen={typeof modal?.isFullScreen === "boolean" ? modal.isFullScreen : true}
        />);
    }
});