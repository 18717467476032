import _ from "lodash";
import moment from "moment";
// import Form from "../../Formio/Form";
import { Form } from "../../Form";
import { TB } from '../../../Constants';
import { useAuth } from "../../../hooks";
import { renderAlert } from "../../Modal";
import { Link, useParams } from 'react-router-dom';
import * as US from '../../../services/user.service';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';

const SubmissionManager = ({ ...props }) => {
    //#region State
    const modalRef = useRef();
    const params = useParams();
    const copyInputRef = useRef();
    const [{ isAdmin }] = useAuth({});
    const [form, setForm] = useState();
    const [onEdit, setOnEdit] = useState(false);
    const [submissions, setSubmissions] = useState();
    const [submissionChange, setSubmissionChange] = useState();
    //#endregion

    //#region Form
    const formId = useMemo(() => [form?._id, params?.formId].filter(TB.mongoIdValidator)[0], [form, params]);

    useEffect(() => {
        const getForm = async () => {
            let reply = await US.getFormsFromFilter({ _id: formId });
            return TB.mongoIdValidator(reply?.data?.[0]?._id) ? reply.data[0] : null;
        }
        if (TB.mongoIdValidator(formId)) getForm().then(setForm);
    }, [formId]);
    //#endregion

    //#region Submissions
    useEffect(() => {
        const getSubmissions = async () => {
            let reply = await US.getManySubmissionsFromFilter({ form: formId });
            return Array.isArray(reply.data) ? reply.data : null;
        }
        if (TB.mongoIdValidator(formId)) getSubmissions().then(setSubmissions);
    }, [formId]);
    //#endregion

    //#region TableView keys
    const adminFormKeys = useMemo(() => isAdmin ? [{ label: "_id", api: "_id" }, { label: "created", api: "created" }] : [], [isAdmin]);

    const getListFormKeys = useMemo(() => {
        let keys = [];

        const recursive = (components) => {
            for (let field of TB.getArray(components)) {
                if (field?.tableView) keys.push({ label: field.label, api: field.key });
                if (Array.isArray(field?.columns)) field.columns.forEach(col => recursive(col.components));
            }
        }

        recursive(form?.components);
        return adminFormKeys.concat(keys);
    }, [form, adminFormKeys]);
    //#endregion

    const onClickSubmission = useCallback(id => {
        let submission = submissions?.filter?.(({ _id }) => _id === id)?.[0];
        setSubmissionChange(submission);
        setOnEdit(true);
    }, [submissions]);

    const deleteSubmission = useCallback(id => {
        try {
            if (TB.mongoIdValidator(id) && window.confirm("Do you really want to delete this entry ?")) {
                US.removeSubmission(id).then(reply => {
                    if (reply.status !== 200) alert("Couldn't delete submission");
                    else setSubmissions(prev => prev.filter(({ _id }) => _id !== id));
                });
            }
        }
        catch (err) { console.log(err); }
    }, []);

    const getValueRow = useCallback(value => {
        if (Array.isArray(value)) return value.length + " elements";
        else if (value instanceof Object) return "Object";
        else if (typeof value === "boolean") return <i className={`fa text-center fa-${value ? "check text-success" : "times text-danger"}`}></i>;
        else if (TB.mongoIdValidator(value)) return `ObjectId(${value})`;
        else return value || "-";
    }, []);

    const formSubmitted = useCallback(sub => {
        if (TB.mongoIdValidator(sub?._id)) {
            if (submissions.filter(({ _id }) => _id === sub?._id).length === 0) setSubmissions(prev => prev.concat(sub));
            else setSubmissions(submissions.map(submission => {
                if (submission?._id === sub?._id) return sub;
                return submission;
            }));
        }

        setSubmissionChange(undefined);
        setOnEdit(false);
    }, [submissions]);

    const cancelEdit = useCallback(() => {
        setSubmissionChange(undefined);
        setOnEdit(false);
    }, []);

    const copyId = useCallback((event, id) => {
        event?.preventDefault?.();

        if (TB.isDomOrReactElement(copyInputRef.current)) {
            copyInputRef.current.value = id;
            // Wider browser support but deprecated
            if (navigator.clipboard) {
                copyInputRef.current.type = "text";
                copyInputRef.current.select?.();
                document?.execCommand?.("copy");
                copyInputRef.current.type = 'hidden';
            }
            // Non-deprecated but not widespread
            else navigator.clipboard.writeText(copyInputRef.current.value);
            renderAlert({ type: "success", message: "Lien copié avec succès!" })
        }
    }, []);

    const createTableHeaders = useMemo(() => getListFormKeys.map(({ label, api }) => <th key={api}>{label}</th>), [getListFormKeys]);

    const getListingSubmissions = useMemo(() => {
        if (!Array.isArray(submissions)) return;
        return submissions.map(({ _id, data, created }, i) => <tr key={i} data-index={i} >
            {getListFormKeys.map(({ api }, i) => <td
                key={api}
                className={i === 0 ? "btn-none btn-link" : "text-break"}
                onContextMenu={i === 0 ? e => copyId(e, _id) : undefined}
                onClick={i === 0 ? () => onClickSubmission(_id) : undefined}
            >
                {api === "_id" ? _id : (api === "created" ? moment(created).format("DD/MM/YY") : getValueRow(_.get(data, api)))}
            </td>)}
            <td>
                <button onClick={() => deleteSubmission(_id)} className='btn btn-sm btn-danger'>
                    <i className="fa fa-trash" />
                </button>
            </td>
        </tr>);
    }, [submissions, getListFormKeys, getValueRow, onClickSubmission, deleteSubmission, copyId]);

    return <div className='w-100'>
        {!onEdit && <div className='w-100'>

            <div className='p-2 w-25 mx-auto'>
                <button className='btn btn-primary' onClick={() => setOnEdit(true)}>Create a new Submission</button>
            </div>

            <div className='w-100 text-dark'>
                <table>
                    <thead>
                        <tr>
                            {createTableHeaders}
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody className='w-100'>
                        {getListingSubmissions}
                    </tbody>
                </table>
            </div>

            <div className='p-2 w-25 mx-auto'>
                <Link to={"/formBuilding"}>
                    <button className='btn btn-primary'>Back to list of forms</button>
                </Link>
            </div>
        </div>}

        {onEdit && <div className="position-relative">
            <Form _id={formId} submissionId={submissionChange?._id} onSave={formSubmitted} />
            {/* <Form formId={formId} submissionId={submissionChange?._id} onSubmit={formSubmitted} /> */}

            <div className="position-absolute bottom-0 start-0">
                <button className="btn btn-danger" onClick={cancelEdit}>Cancel</button>
            </div>
        </div>}

        <input ref={copyInputRef} type="hidden" />
        <div ref={modalRef}></div>

    </div>
}

export default SubmissionManager;