import React from "react";
import * as I from "../Input";
import * as H from "../../../hooks";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import { T, TB } from "../../../Constants";
import "react-quill/dist/quill.bubble.css";

type Tools =
    "font"
    | "math"
    | "code"
    | "media"
    | "quote"
    | "crossed"
    | "underline"
    | "highlight"
    | "checklist"
    | "direction"
    | "huge_font"
    | "heading_select"
    | "heading_buttons";

export type RichTextProps = I.InputProps & {
    /** The theme variant of the editor */
    theme?: "snow" | "bubble";
    /** A placeholder value */
    placeholder?: string | Record<"_id" | "prop" | "text", string> | Record<"ref" | "template", string>;
    /** Configure the tools to provide in the text editor */
    tools?: T.AllowArray<Tools>;
    /** Fire the onChange callback on each update, not on focus lost event */
    uncontrolled?: boolean;
    /** Custom CSS Styling for the whole container (textarea + toolbox) */
    style?: React.CSSProperties;
    /** Restric the 'bubble' to a certain div */
    bounds?: ReactQuill.ReactQuillProps["bounds"];
    /** Style to apply to the content area (the part without the toolbox) */
    editor_style?: Partial<Record<"height" | "maxHeight" | "minHeight", string>>;
}

export type RichTextRef = ReactQuill;

/**
 * A rich text editor
 * @description https://quilljs.com/docs/api
 */
const RichText = React.forwardRef<RichTextRef, RichTextProps>(({ onChange, ...props }, ref) => {
    const lg = H.useLanguage();
    const quill_ref = React.useRef<ReactQuill>(null);
    const [controlled, set_controlled] = React.useState("");
    const force_blur_sync_timeout = React.useRef<ReturnType<typeof setTimeout>>(null);
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    React.useEffect(() => {
        // Set the controlled value when the provided value changes
        set_controlled(TB.getString(props.value));
        // Clear the timeout if the provided value changes
        clearTimeout(force_blur_sync_timeout.current);
    }, [props.value]);

    const onLostFocus = React.useCallback(() => {
        if (isDisabled || props.uncontrolled) return;
        else {
            onChange?.(controlled);
            // If the timeout exists, clear it and update the parent immediately
            if (force_blur_sync_timeout.current) clearTimeout(force_blur_sync_timeout.current);
        }
    }, [onChange, controlled, isDisabled, props.uncontrolled]);

    const placeholder = React.useMemo(() => {
        if (typeof props.placeholder === "string") return lg.getStaticText(props.placeholder);
        else if (props.placeholder) {
            if ("_id" in props.placeholder && props.placeholder._id) return lg.getTextObj(props.placeholder._id, props.placeholder.prop, props.placeholder.text);
            else if ("ref" in props.placeholder && props.placeholder.ref) return lg.getStaticText(props.placeholder.ref, props.placeholder.template);
        }
    }, [props.placeholder, lg]);

    const toolbar = React.useMemo(() => {
        let tools = TB.arrayWrapper(props.tools).filter(t => typeof t === "string");
        let toolbar_options: any[] = [['bold', 'italic']];

        // Make a list of available fonts
        let font_list = ['small', false, 'large'];
        if (tools.includes("huge_font")) font_list.push('huge');
        // Insert the underline option if needed
        if (tools.includes("underline")) toolbar_options[0].push('underline');
        // Insert the code or quote option if needed
        if (tools.includes("quote") || tools.includes("code")) {
            let sub_tools = [];
            if (tools.includes("quote")) sub_tools.push('blockquote');
            if (tools.includes("code")) sub_tools.push('code-block');
            toolbar_options.push(sub_tools);
        }
        // Insert the crossed option if needed
        if (tools.includes("crossed")) toolbar_options[0].push('strike');
        // Insert the media options if needed
        if (tools.includes("media")) toolbar_options.push(['link', 'image', 'video', 'formula']);
        // Insert the heading buttons if needed
        if (tools.includes("heading_buttons")) toolbar_options.push([{ header: 1 }, { header: 2 }]);
        // Insert the text direction toggler if needed
        if (tools.includes("direction")) toolbar_options.push([{ direction: 'rtl' }]);
        // Insert the list options
        let list_options = [{ list: 'ordered' }, { list: 'bullet' }];
        if (tools.includes("checklist")) list_options.push({ list: 'check' });
        toolbar_options.push(list_options);
        // Insert the second part of the options
        toolbar_options.push(
            // Outdent / Indent
            [{ indent: '-1' }, { indent: '+1' }],
            // Custom dropdown
            [{ size: font_list }],
        );
        // Insert the color and highlight option if needed
        let color_tools: any[] = [{ color: [] }];
        if (tools.includes("highlight")) color_tools.push({ background: [] });
        toolbar_options.push(color_tools);
        // Insert the heading options if needed
        if (tools.includes("heading_select")) toolbar_options.push([{ header: [1, 2, 3, 4, 5, 6, false] }]);
        // Insert the font options if needed
        if (tools.includes("font")) toolbar_options.push([{ font: [] }]);
        // Insert the exponants and indices options if needed
        if (tools.includes("math")) toolbar_options.push([{ script: 'sub' }, { script: 'super' }]);

        // Insert the rest of the options
        toolbar_options.push(
            // Align buttons
            [{ align: [] }],
            // Remove formatting button
            ['clean']
        );
        return toolbar_options;
    }, [props.tools]);

    const input_change = React.useCallback((value: string) => {
        if (props.uncontrolled) onChange?.(value);
        else {
            // Clear the previous timeout if input_change is fired again
            if (force_blur_sync_timeout.current) clearTimeout(force_blur_sync_timeout.current);
            // Set a new timeout to call the parent's callback after 2 seconds
            force_blur_sync_timeout.current = setTimeout(() => onChange?.(value), 2000);
            // Update the controlled value
            set_controlled(value);
        }
    }, [props.uncontrolled, onChange]);

    React.useEffect(() => {
        if (quill_ref.current?.editor?.container) {
            if (props.editor_style?.height) quill_ref.current.editor.container.style.height = props.editor_style?.height;
            if (props.editor_style?.maxHeight) quill_ref.current.editor.container.style.maxHeight = props.editor_style?.maxHeight;
            if (props.editor_style?.minHeight) quill_ref.current.editor.container.style.minHeight = props.editor_style?.minHeight;
        }
    }, [props.editor_style]);

    React.useImperativeHandle(ref, () => quill_ref.current, []);

    const style = React.useMemo<React.CSSProperties>(() => ({
        ...props.style,
        opacity: props.style?.opacity || (isDisabled ? 0.5 : 1),
    }), [props.style, isDisabled]);

    H.useEventListener("keydown", event => {
        if (event.key === "Enter") {
            // Reset the font size to the default one, when user presses enter
            quill_ref.current.editor.format("size", false, "user");
        }
        else if (event.key === "Tab") {
            // Undo the "tab" inserted by pressing the tab key
            quill_ref.current.editor.history.undo();
            // Format the current line to be indented
            quill_ref.current.editor.format("indent", "+1", "user");
        }
    }, { current: quill_ref.current?.editor?.container });

    return <I.ComponentWrapper {...props} labelPosition={props.labelPosition || "top"}>
        <ReactQuill
            style={style}
            ref={quill_ref}
            value={controlled}
            className="bg-white"
            onBlur={onLostFocus}
            readOnly={isDisabled}
            bounds={props.bounds}
            modules={{ toolbar }}
            onChange={input_change}
            placeholder={placeholder}
            theme={props.theme || "snow"}
        />
    </I.ComponentWrapper>
})

RichText.displayName = "RichText";
export default RichText;