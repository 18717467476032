import _ from "lodash";
import Button from "./Button";
import { Flex } from "../Layout";
import { T, TB, TC } from "../../Constants";
import * as TA from "react-bootstrap-typeahead";
import { useBoolean, useLanguage } from "../../hooks";
import { FormControl, InputGroup } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

//#region Types
export type TypeaheadRef = {
    /** Clear the search input */
    removeText: () => void;
    /** The current search text */
    search: string;
    /** Focus the select input */
    focus: () => void;
    /** Hide the dropdown menu */
    hide_menu: () => void;
    /** Select the currently active item */
    select_active_item: () => void;
    /** Select all active items for multiple select */
    select_all_items: () => void;
}

export type TypeAheadProps = {
    /** For multiple input, restrict the height it can grow to */
    height?: "sm" | "md" | "lg";
    /** Are the options still loading ? */
    loading?: boolean;
    /** Is the selected option(s) invalid ? */
    invalid?: boolean;
    /** Id of the typeahead */
    id?: string;
    /** Size of the input */
    size?: "sm" | "lg";
    /** The list of options to present in the dropdown */
    options: T.Option[];
    /** Specify menu alignment. The default value is justify */
    align?: 'justify' | 'left' | 'right';
    /** Callback to determine the color of the input text (for single select) */
    colorSelected?: (selected: Model[]) => string;
    /** Callback to get an extra component to render next to the input */
    renderInputExtra?: (selected: Model[]) => React.ReactNode;
    /** Flag to check if a new option can be created */
    allowNewOption?: boolean | ((text: string, options: Model[]) => boolean);
    /** Callback to handle the search */
    filterBy?: (value: Model, text: string) => boolean;
    /** The precision of the search, between 0 and 1 */
    precision?: number;
    /** The current selection */
    selectedItems?: T.AllowArray<string>;
    /** Specify whether the menu should appear above the input. */
    dropup?: boolean;
    /** Placeholder text for the input. */
    placeholder?: string;
    /** Whether to disable the input */
    disabled?: boolean;
    /** Class transmitted to the Typeahead component */
    className?: string;
    /** Callback after the selection changes */
    onChange?: (selection: Model[]) => void;
    /** Whether or not the select should be closed after a selection */
    keepOpenOnSelect?: boolean;
    /** Allows to select multiple values */
    multiple?: boolean;
    /** Callback for the creation of a new option */
    onAddOption?: (text: string) => void;
    /** Callback for a custom render of the dropdown options */
    renderItem?: (option: Model, props?: TA.TypeaheadMenuProps, index?: number) => React.ReactNode;
    /** Do not show the clear button */
    hideClearButton?: boolean;
    /** Make the dropdown the size of the largest menu item ? */
    dropdownFit?: boolean;
    /** The text to show before the new option suggestion */
    new_option_hint_label?: string;
    /** Extra properties to run the search by */
    extra_search_props?: T.AllowArray<string>;
    /** The amount of options to display */
    maxResults?: number;
    /** Whether to use fixed positioning for the menu */
    positionFixed?: boolean;
    /** Use this to take control of the search into another component */
    search?: string;
    /** Use this to make the search controlled */
    set_search?: (search: string) => void;
    /** If some options are not-editable in a multi-select */
    fixed_values?: string[];
}

type OG_TypeaheadRef = {
    blur: () => void;
    /** Clear the search text entered */
    clear: () => void;
    focus: () => void;
    /** The options currently displayed and filtered */
    items: T.Option[];
    hideMenu: () => void;
    toggleMenu: () => void;
    getInput: () => HTMLInputElement;
    /** The html input element */
    inputNode: HTMLInputElement;
    /** The current state of the typeahead */
    state: {
        /** The element currently active in the dropdown */
        activeItem?: Model;
    }
}

type Model = T.Option;
type OnChange = TA.TypeaheadComponentProps["onChange"];
type RenderMenu = TA.TypeaheadComponentProps["renderMenu"];
type RenderInput = TA.TypeaheadComponentProps["renderInput"];
type RenderToken = TA.TypeaheadComponentProps["renderToken"];
type AllowNew = Exclude<TA.TypeaheadComponentProps["allowNew"], boolean>;
type FilterBy = Exclude<TA.TypeaheadComponentProps["filterBy"], string[]>;
//#endregion

const TEXT_CODES = [TC.GLOBAL_TYPEAHEAD_SHOW_MORE, TC.GLOBAL_TYPEAHEAD_ADD_NEW, TC.GLOBAL_TYPEAHEAD_NO_OPTIONS];

const Typeahead = React.forwardRef<TypeaheadRef, TypeAheadProps>(({ colorSelected, renderItem, filterBy, renderInputExtra, onChange, onAddOption, set_search, allowNewOption, ...props }, ref) => {
    const lg = useLanguage(TEXT_CODES);
    const hideSearch = useBoolean(false);
    const [search, search_setter] = useState("");
    const pressed_key = React.useRef<string>("");
    const typeahead_ref = React.useRef<OG_TypeaheadRef>(null);

    const setText = React.useCallback((str: string) => {
        if (typeof set_search === "function") set_search(str);
        else search_setter(str);
    }, [set_search]);

    const text = React.useMemo(() => props.search || search, [props.search, search]);

    // Remove the search when value has changed, if single select
    React.useEffect(() => {
        if (!props.multiple) {
            setText(pressed_key.current || "");
            pressed_key.current = "";
        }
    }, [props.multiple, props.selectedItems, setText]);

    //#region Languages
    useEffect(() => lg.getOptionsStatic(props.options), [lg, props.options]);
    useEffect(() => lg.fetchStaticTranslations(props.placeholder), [lg, props.placeholder]);

    const translateOption = useCallback((option: T.Option) => {
        if (TB.mongoIdValidator(option.value) && option.prop) return lg.getTextObj(option.value, option.prop, option.label);
        else return lg.getStaticText(option.label);
    }, [lg]);
    //#endregion

    //#region Options
    const { options, selected } = React.useMemo(() => {
        if (!Array.isArray(props.options)) return { selected: [], options: [] };
        let translated = props.options.map(o => ({ ...o, label: translateOption(o) }));
        let selected_values = TB.arrayWrapper(props.selectedItems)//.filter(TB.validString);

        let options = translated;
        let selected = translated.filter(o => selected_values.includes(o.value));

        // If multiple, do not offer the same values again
        if (props.multiple) options = translated.filter(o => !props.selectedItems?.includes?.(o.value));

        return { selected, options };
    }, [translateOption, props.options, props.multiple, props.selectedItems]);

    const filterOptions = useCallback<FilterBy>((option: Model, state) => {
        let search = props.multiple ? TB.getString(state.text) : text;
        if (search.length === 0 || hideSearch.value) return true;
        if (typeof filterBy === "function") return filterBy(option, search);
        if (props.extra_search_props) {
            let search_props = ["label"].concat(TB.arrayWrapper(props.extra_search_props));
            return search_props.some(prop => TB.areStringSimilar(search, option?.[prop] || "", { precision: props.precision }))
        }
        else return TB.areStringSimilar(search, option.label, { precision: props.precision });
    }, [props.precision, props.multiple, props.extra_search_props, text, hideSearch.value, filterBy]);
    //#endregion

    //#region Change/Add Options
    const allowNew = React.useCallback<AllowNew>((options: Model[], ref) => {
        let text_content = text || ref?.text || "";
        if (typeof allowNewOption === "function") return allowNewOption(text, options);
        else if (!allowNewOption) return false;
        else return !options.some(o => TB.areStringSimilar(text_content, o.label, { precision: 0.99 }));
    }, [allowNewOption, text]);

    const onSelectionChange = React.useCallback<OnChange>((selected: (Model & Record<"customOption", boolean>)[]) => {
        // Options that do not exists, that the user wants to create
        let new_option = selected.filter(s => s.customOption === true)[0];

        // Keep dropdown open
        if (props.keepOpenOnSelect) typeahead_ref.current?.toggleMenu?.();
        // Leave focus
        else typeahead_ref.current?.blur?.();

        // If not multiple, update the search text to reflect the value
        if (!props.multiple) setText(selected[0]?.label || "");

        if (TB.validString(new_option?.label)) onAddOption?.(new_option.label);
        else onChange?.(selected);
        hideSearch.setFalse();
    }, [props.keepOpenOnSelect, props.multiple, hideSearch, onAddOption, onChange, setText]);

    const removeSelection = React.useCallback((option: Model) => {
        let new_selection = selected.filter(s => s.value !== option.value);
        onSelectionChange(new_selection);
    }, [selected, onSelectionChange]);
    //#endregion

    //#region Renders
    const renderMenu = useCallback<RenderMenu>((results: Model[], menuProps) => {
        let minWidth = typeahead_ref.current.inputNode.parentElement.clientWidth || "100%";
        return <TA.TypeaheadMenu
            {...menuProps}
            text={text}
            labelKey="label"
            options={results}
            key={results.length}
            newSelectionPrefix={<>{lg.getStaticElem(props.new_option_hint_label || TC.GLOBAL_TYPEAHEAD_ADD_NEW)} : </>}
            style={props.dropdownFit ? { ...menuProps?.style, width: "fit-content", minWidth } : menuProps?.style}
        >
            {results.map((result, index) => <TA.MenuItem key={index} option={result} position={index} children={result.label} />)}
        </TA.TypeaheadMenu >
    }, [text, lg, props.dropdownFit, props.new_option_hint_label]);

    const renderInput = useCallback<RenderInput>((inputProps, state) => {
        let color = null, extra = null;
        let cleanProps = _.omit(inputProps, ["inputRef", "referenceElementRef"]);
        let selection = TB.arrayWrapper(state.selected).filter(TB.validObject) as Model[];

        if (selection.length > 0) {
            if (typeof colorSelected === "function") color = colorSelected(selection);
            if (typeof renderInputExtra === "function") extra = renderInputExtra(selection);
        }

        return <InputGroup size={props.size}>
            {/* @ts-ignore Just some input types differences */}
            <FormControl
                {...cleanProps}
                style={{ color }}
                disabled={props.disabled}
                onBlur={() => {
                    typeahead_ref.current?.toggleMenu?.();
                    hideSearch.setFalse();
                }}
                onMouseDown={hideSearch.setTrue}
                value={hideSearch.value ? "" : text}
                onChange={e => {
                    hideSearch.setFalse();
                    /* @ts-ignore Update the component's state, to show the 'add new option' */
                    cleanProps.onChange(e);
                    pressed_key.current = e.target.value;
                    setText(e.target.value || "");
                }}
                ref={ref => {
                    if (typeof inputProps?.inputRef === "function") inputProps.inputRef(ref);
                    /* @ts-ignore Type doesn't consider 'referenceElementRef' but it is present */
                    if (typeof inputProps?.referenceElementRef === "function") inputProps.referenceElementRef(ref);
                }}
            />

            {extra && <InputGroup.Text children={extra} />}

            {!props.hideClearButton && <Button
                size="sm"
                icon="times"
                variant="secondary"
                onClick={() => onSelectionChange([])}
                disabled={props.disabled || selected.length === 0}
            />}
        </InputGroup>
    }, [props.size, text, hideSearch, selected.length, props.disabled, props.hideClearButton, colorSelected, renderInputExtra, onSelectionChange, setText]);

    const renderToken = useCallback<RenderToken>((selected: Model, token_props, index) => {
        return <TA.Token option={selected} key={index} className="rbt-token-removeable" disabled={props.fixed_values?.includes(selected.value)} >
            <div className="rbt-token-label">{selected.label}</div>
            <button onClick={() => removeSelection(selected)} className="close btn-close rbt-close rbt-token-remove-button">
                <i className="fa fa-times ms-2"></i>
            </button>
        </TA.Token>
    }, [removeSelection, props.fixed_values]);

    useEffect(() => {
        // Set the content of the input when first opening with a selected value
        if (!props.multiple && selected.length > 0) {
            setText(selected[0].label || pressed_key.current);
            pressed_key.current = "";
        }
    }, [props.multiple, selected, setText]);

    const classNames = React.useMemo(() => {
        let classes = ["rbt-custom-grab", props.className];
        if (props.multiple && !props.hideClearButton) classes.push("w-100");
        if (props.multiple && props.height) classes.push("typeahead-multiple-restrain-" + props.height);
        return classes.filter(TB.validString).join(" ");
    }, [props.className, props.height, props.multiple, props.hideClearButton]);
    //#endregion

    //#region Key Listener
    const select_all_items = React.useCallback(() => {
        if (props.multiple) {
            let new_selection = ((typeahead_ref.current as any)?.state?.selected || [])
                .concat(typeahead_ref.current.items)
            onSelectionChange(new_selection);
            // Clear the search text
            typeahead_ref.current?.clear?.();
        }
    }, [onSelectionChange, props.multiple]);

    const onKeyDown = React.useCallback<TA.TypeaheadComponentProps["onKeyDown"]>(event => {
        if (props.multiple && (event as any).key === "Enter") select_all_items();
    }, [select_all_items, props.multiple]);
    //#endregion

    const clear_button = React.useMemo(() => {
        if (props.multiple && !props.hideClearButton) return {
            element: Flex,
            props: { className: "w-100 input-group" },
            content: <Button
                size="sm"
                icon="times"
                variant="secondary"
                disabled={props.disabled}
                onClick={() => onSelectionChange([])}
            />,
        }
        else return { element: React.Fragment, props: null };
    }, [onSelectionChange, props.disabled, props.hideClearButton, props.multiple]);

    //#region Ref
    React.useImperativeHandle(ref, () => ({
        search: text,
        select_all_items: select_all_items,
        focus: () => typeahead_ref.current?.focus?.(),
        hide_menu: () => typeahead_ref.current?.hideMenu?.(),
        removeText: () => {
            setText("");
            hideSearch.setFalse();
        },
        select_active_item: () => {
            let active = typeahead_ref.current?.state?.activeItem;
            onSelectionChange(active ? [active] : []);
        },
    }), [select_all_items, hideSearch, text, onSelectionChange, setText]);
    //#endregion

    return React.createElement(clear_button.element, clear_button.props, <>
        <TA.Typeahead
            // Adjust the dropdown's position based on available boundaries
            flip
            // Make sure the dropdown always sticks out of a modal
            positionFixed={typeof props.positionFixed === "boolean" ? props.positionFixed : true}

            options={options}
            size={props.size}
            selected={selected}
            allowNew={allowNew}
            align={props.align}
            onKeyDown={onKeyDown}
            dropup={props.dropup}
            className={classNames}
            renderMenu={renderMenu}
            filterBy={filterOptions}
            renderToken={renderToken}
            multiple={props.multiple}
            disabled={props.disabled}
            isLoading={props.loading}
            isInvalid={props.invalid}
            onChange={onSelectionChange}
            id={props.id || "typeahead"}
            renderMenuItemChildren={renderItem as any}
            ref={ref => typeahead_ref.current = ref as any}
            renderInput={props.multiple ? undefined : renderInput}
            emptyLabel={lg.getStaticElem(TC.GLOBAL_TYPEAHEAD_NO_OPTIONS)}
            maxResults={props.multiple ? 10000 : props.maxResults || 100}
            paginationText={lg.getStaticText(TC.GLOBAL_TYPEAHEAD_SHOW_MORE)}
            placeholder={props.placeholder ? lg.getStaticText(props.placeholder) : ""}
        />
        {clear_button.content}
    </>);
});

Typeahead.displayName = "Typeahead";
export default Typeahead;