import React, { useMemo } from "react";

export type ColorBoxProps = {
    /** The color of the box */
    color?: string;
    /** The width of the box, if none, same as height */
    width?: number;
    /** The height of the box, if none, same as width */
    height?: number;
    /** Shapes the radius of the corners */
    shape?: "square" | "circle"
}

const ColorBox = React.forwardRef<HTMLDivElement, ColorBoxProps>((props, ref) => {

    const size = useMemo(() => {
        let [height, width] = [props.height, props.width].map(n => typeof n !== "number" || isNaN(n) || n < 0 ? NaN : n);

        if (isNaN(height) && isNaN(width)) return { h: 20, w: 20 };
        else if (isNaN(height)) return { h: height, w: height };
        else if (isNaN(width)) return { h: width, w: width };
        else return { h: height, w: width };
    }, [props.height, props.width]);

    const style = useMemo<React.HTMLAttributes<HTMLDivElement>["style"]>(() => ({
        width: size.w + "px",
        height: size.h + "px",
        display: "inline-block",
        backgroundColor: props.color || "#FFFFFF",
        borderRadius: props.shape === "circle" ? "50%" : "0%",
    }), [size, props.color, props.shape]);

    return <div ref={ref} style={style}></div>;
});

ColorBox.displayName = "ColorBox";
export default ColorBox;