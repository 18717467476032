import React from "react";
import * as BS from "react-bootstrap";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import * as S from "../../../../services";
import { FP, T, TB, TC } from "../../../../Constants";
import { StyleModalProps, default as Modal } from "../../BlankModal";

//#region Types
export type ReportCellsProps = {
    /** Id of the building */
    building: string;
    /** Extra Modal params */
    modal?: StyleModalProps;
    /** Callback for cancelation */
    onQuit?: () => void;
    /** Callback for confirmation */
    onValidate?: (data: SentData) => void;
}

type Data = {
    /** The type of group to do */
    group: string;
    /** The cells to focus on */
    cells: string[];
    /** The start of the period of time */
    from: string;
    /** The end of the period of time */
    to: string;
}

type SentData = Omit<Data, "cells"> & {
    /** The complete data of the cells */
    cells: ReturnType<T.API.Report.Dashboard.GetCellsData>["cells"];
    /** The full name of the building */
    name: string;
}
//#endregion

//#region Constants
const TIME_GROUPS = [
    { label: TC.GP_GROUP_MIN, value: "minute" },
    { label: TC.GP_GROUP_HOUR, value: "hour" },
    { label: TC.GP_GROUP_DAY, value: "day" },
    { label: TC.GP_GROUP_WEEK, value: "week" },
    { label: TC.GP_GROUP_MONTH, value: "month" },
    { label: TC.GP_GROUP_QUARTER, value: "quarter" },
    { label: TC.GP_GROUP_YEAR, value: "year" },
] as T.Option[];

const get_default_date = (): Pick<Data, "from" | "to"> => {
    // Get the current date
    var currentDate = new Date();
    // Calculate the first day of the last month
    var from = new Date(currentDate);
    // Set the month to the previous month and day to 1
    from.setMonth(currentDate.getMonth() - 1, 1);
    // Set hours, minutes, seconds, and milliseconds to zero
    from.setHours(0, 0, 0, 0);
    // Calculate the last day of the previous month
    var to = new Date(from);
    // Set the month to the current month - 1 and day to the last day of that month
    to.setMonth(from.getMonth() + 1, 0);
    // Set hours to 23, minutes to 59, seconds to 59, and milliseconds to 999
    to.setHours(23, 59, 59, 999);
    return { from: from.toISOString(), to: to.toISOString() };
}
//#endregion

const ReportCells: React.FC<ReportCellsProps> = props => {
    const lg = H.useLanguage();
    const show_only_loc = H.useBoolean(true);
    const [errors, set_errors] = React.useState<T.Errors<Data>>({});
    const [data, set_data] = React.useState<Data>({ cells: [], group: "day", ...get_default_date() });
    const [resources, set_resources, status] = H.useAsyncState<ReturnType<T.API.Report.Dashboard.GetCellsData>>({ cells: [], name: "" });

    React.useEffect(() => {
        let isSubscribed = true;
        S.getCellsData(props.building)
            .then(({ data }) => isSubscribed && set_resources(data, "done"))
            .catch(() => isSubscribed && set_resources({ cells: [], name: "" }, "error"));
        return () => {
            isSubscribed = false;
            set_resources({ cells: [], name: "" }, "load");
        }
    }, [props.building, set_resources]);

    const cells = React.useMemo(() => {
        if (show_only_loc.value) return resources.cells.filter(c => c.is_loc);
        else return resources.cells;
    }, [resources.cells, show_only_loc.value]);

    React.useEffect(() => console.log(data), [data]);

    //#region Save
    const save = React.useCallback(() => {
        let new_errors = {} as typeof errors;

        let from = TB.getDate(data.from), to = TB.getDate(data.to);
        if (to === null) new_errors.to = TC.GLOBAL_REQUIRED_FIELD;
        if (!data.group) new_errors.group = TC.GLOBAL_REQUIRED_FIELD;
        if (from === null) new_errors.from = TC.GLOBAL_REQUIRED_FIELD;
        if (data.cells.length === 0) new_errors.cells = TC.GLOBAL_REQUIRED_FIELD;
        if (from && to && from.getTime() >= to.getTime()) {
            new_errors.to = TC.ERR_DATE_TO_LOWER_DATE_FROM;
            new_errors.from = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
        }

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else props.onValidate?.({
            to: data.to,
            from: data.from,
            group: data.group,
            name: resources.name,
            cells: resources.cells.filter(c => data.cells.includes(c.value)),
        });
    }, [data, resources, props]);

    const save_button = React.useMemo(() => <C.Flex justifyContent="end">
        <C.Button onClick={save} icon="save" text={TC.GLOBAL_CONFIRM} />
    </C.Flex>, [save]);
    //#endregion

    //#region Errors
    React.useEffect(() => set_errors(p => ({ ...p, to: undefined })), [data.to]);
    React.useEffect(() => set_errors(p => ({ ...p, from: undefined })), [data.from]);
    React.useEffect(() => set_errors(p => ({ ...p, cells: undefined })), [data.cells]);
    React.useEffect(() => set_errors(p => ({ ...p, group: undefined })), [data.group]);
    //#endregion

    return <Modal
        {...props.modal}
        footer={save_button}
        onQuit={props.onQuit}
        size={props.modal?.size || "sm"}
        title={props.modal?.title || TC.CELLS_REPORT_TILE}
    >
        <C.Form.Select
            required
            multiple
            noBottomMargin
            options={cells}
            value={data.cells}
            loading={status === "load"}
            label={FP.EMPLACEMENT_FORM}
            error={{ code: errors.cells }}
            onChange={cells => set_data(p => ({ ...p, cells }))}
            typeahead={React.useMemo(() => ({
                renderItem: (option: SentData["cells"][number]) => <div key={option.value}>
                    <i className={`fa fa-${option.is_loc ? "hand-holding-usd" : "times"} me-2`}></i>
                    {option.label}
                    {option.renter
                        ? " - (" + option.renter + (
                            option.enseigne
                                ? " / " + option.enseigne + ")"
                                : ")")
                        : ""
                    }
                </div>
            }), [])}
        />

        <C.Flex direction="row" alignItems="center" justifyContent="between" className="fs-85">
            <div>
                <i className="fa fa-hand-holding-usd me-2"></i> = {lg.getStaticText(TC.CELL_IS_RENTABLE)}
            </div>
            <div>
                <i className="fa fa-times me-2"></i> = {lg.getStaticText(TC.CELL_IS_NOT_RENTABLE)}
            </div>
        </C.Flex>

        <div className="fs-85">
            <C.Form.CheckBox
                check_type="switch"
                value={show_only_loc.value}
                onChange={show_only_loc.toggle}
                label={TC.LOC_REPORT_SHOW_ONLY_RENT_LOC}
            />
        </div>

        <BS.Row>
            <BS.Col>
                <C.Form.DateTime
                    required
                    value={data.from}
                    label={TC.GLOBAL_FROM}
                    error={{ code: errors.from }}
                    onChange={from => set_data(p => ({ ...p, from }))}
                />
            </BS.Col>
            <BS.Col>
                <C.Form.DateTime
                    required
                    value={data.to}
                    label={TC.GLOBAL_TO}
                    error={{ code: errors.to }}
                    onChange={to => set_data(p => ({ ...p, to }))}
                />
            </BS.Col>
        </BS.Row>

        <C.Form.Select
            required
            value={data.group}
            options={TIME_GROUPS}
            label={TC.GLOBAL_TIME_GROUP}
            error={{ code: errors.group }}
            onChange={group => set_data(p => ({ ...p, group }))}
        />
    </Modal>;
}

export default ReportCells;