import React from 'react';
import classNames from 'classnames';
import * as C from '../../../Common';
import ToggleButton from './ToggleButton';
import { AppContext } from '../../../Context';
import { T, TB, RESOURCE } from "../../../Constants";
import NavbarVerticalMenu from './NavbarVerticalMenu';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import NavbarTopDropDownMenus from '../top/NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../../config.js';

//#region Types
type NavbarVerticalProps = {
  logoSrc?: string;
  loading?: boolean;
  routes: T.RoutesCollection[];
}
//#endregion

const NavbarVertical: React.FC<NavbarVerticalProps> = ({ routes, logoSrc, loading }) => {
  const { config: { navbarStyle, navbarPosition, isNavbarVerticalCollapsed, showBurgerMenu, isDark } } = React.useContext(AppContext);

  const HTMLClassList = React.useMemo(() => document.getElementsByTagName('html')[0].classList, []);
  const validLogoSrc = React.useMemo(() => TB.validString(logoSrc) ? logoSrc : RESOURCE.RESOURCE_URL(RESOURCE.AISET_LOGO), [logoSrc]);

  React.useEffect(() => {
    if (isNavbarVerticalCollapsed) HTMLClassList.add('navbar-vertical-collapsed');
    else HTMLClassList.remove('navbar-vertical-collapsed');
    return () => HTMLClassList.remove('navbar-vertical-collapsed-hover');
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time: NodeJS.Timeout | null = null;

  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) time = setTimeout(() => HTMLClassList.add('navbar-vertical-collapsed-hover'), 100);
  };

  const handleMouseLeave = () => {
    if (time) clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const mainNavClasses = React.useMemo(() => classNames('navbar-vertical', { [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent' }), [navbarStyle]);

  return <Navbar expand={navbarBreakPoint} className={mainNavClasses} variant="light" >

    <C.Flex alignItems="center">
      <ToggleButton />
      <C.Logo at="navbar-vertical" source={validLogoSrc} width={100} />
    </C.Flex>

    <Navbar.Collapse
      in={showBurgerMenu}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ backgroundImage: !isDark ? (navbarStyle === 'vibrant' ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2)` : 'none') : 'none' }}
    >

      <div className="navbar-vertical-content scrollbar">
        {loading
          ? <C.Flex className='h-100' alignItems='center' justifyContent='center'>
            <i className='fs-150 fa fa-spinner fa-spin text-white'></i>
          </C.Flex>
          : <>
            <Nav className="flex-column" as="ul">
              {routes.map((route, i) => <React.Fragment key={i}>
                {!route.labelDisable && <NavbarLabel label={TB.capitalizeFirstLetter(route.label)} />}
                <NavbarVerticalMenu routes={route.children} />
              </React.Fragment>)}
            </Nav>
          </>}

        {navbarPosition === 'combo' && <div className={`d-${topNavbarBreakpoint}-none`}>
          <div className="navbar-vertical-divider">
            <hr className="navbar-vertical-hr my-2" />
          </div>
          <Nav navbar>
            <NavbarTopDropDownMenus routes={routes} />
          </Nav>
        </div>}
      </div>
    </Navbar.Collapse>
  </Navbar >
};

export default NavbarVertical;

const NavbarLabel: React.FC<{ label: string }> = ({ label }) => <Nav.Item as="li">
  <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
    <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
      {label}
    </Col>
    <Col className="ps-0">
      <hr className="mb-0 navbar-vertical-divider"></hr>
    </Col>
  </Row>
</Nav.Item>