import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import { T, TC, TB } from "../../Constants";
import { useSearchParams } from "react-router-dom";

type Accesses = T.AwaitAxios<typeof S.getEquipmentSheetAccess>;

export type SheetProps = {
    /** The qrCode of the equipment */
    qrCode?: string;
    /** The Id of the equipment */
    equipmentId?: string;
    /** Callback that fires when the name of the equipment is loaded */
    onEquipmentNamed?: (equipment: string) => void;
}

export const Sheet: React.FC<SheetProps> = ({ onEquipmentNamed, ...props }) => {
    const lg = H.useLanguage();
    const [{ userId }] = H.useAuth();
    const [html, setHtml, htmlStatus] = H.useAsyncState("");
    const [access, setAccess, status] = H.useAsyncState<Accesses>(null);

    //#region Load data
    React.useEffect(() => {
        let isSubscribed = true;
        S.getEquipmentSheetAccess({ qrCode: props.qrCode, equipmentId: props.equipmentId })
            .then(({ data }) => isSubscribed && setAccess(data, "done"))
            .catch(() => isSubscribed && setAccess(null, "error"));
        return () => { isSubscribed = false };
    }, [setAccess, props.qrCode, props.equipmentId]);

    React.useEffect(() => {
        if (access?.name) onEquipmentNamed(access.name);
    }, [onEquipmentNamed, access?.name]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (TB.mongoIdValidator(access?._id) && TB.mongoIdValidator(userId) && access?.accessible) {
            S.html_report({ template: "Fiche_Equipment", params: { id: access?._id, user: userId, lang: lg.prop } })
                .then(({ data }) => isSubscribed && setHtml(data, "done"))
                .catch(() => isSubscribed && setHtml("", "error"));
        }
        return () => {
            isSubscribed = false;
            setHtml("", "load");
        };
    }, [access?._id, access?.accessible, setHtml, userId, lg.prop]);
    //#endregion

    //#region Errors
    const state = React.useMemo(() => {
        if (htmlStatus === "error" || status === "error") return { error: true };
        else if (htmlStatus === "load" || status === "load") return { loading: true };
        else if (!access?.accessible) return { error: TC.LAYOUT_UNAVAILABLE };
    }, [access?.accessible, htmlStatus, status]);
    //#endregion

    return <div className="container-md my-4" children={<C.Spinner {...state} children={<C.HtmlText html={html} />} />} />;
}

export const SheetPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const crumbs = H.useCrumbs("todo fiche equip");

    const info = React.useMemo(() => ({
        qrCode: searchParams.get("qr"),
        equipmentId: searchParams.get("id"),
    }), [searchParams]);

    return <C.Flex direction="column" className="flex-grow-1 mb-3">
        <Sheet {...info} onEquipmentNamed={label => crumbs.updateCrumbs({ label, isContext: true }, 1)} />
    </C.Flex>;
}