import React from "react";
import moment from "moment";
import { TB } from "../../../Constants";
import { FormControl } from "react-bootstrap";
import { ComponentWrapper, InputProps } from "../Input";

//#region Type
export type DateTimeProps = InputProps & {
    /** Do not keep a local state, and fire change callback on each change */
    uncontrolled?: boolean;
    /** Keep a local state, and fire change callback on each change */
    fire_controlled?: boolean;
    /** Allows the edit hours and minutes */
    enableTime?: boolean;
    /** A ref */
    api?: React.RefObject<{
        /** Get the current controlled value */
        getValue?: () => string;
    }>
}

export type DateTimeRef = {
    /** Get the current controlled value */
    getValue?: () => string;
    /** Force the focus into the input */
    focus?: () => void;
    /** Set the value */
    setValue?: (value: string) => void;
}
//#endregion

const DateTime = React.forwardRef<DateTimeRef, DateTimeProps>(({ onChange, disabled, noOverride, api, ...props }, ref) => {
    const input = React.useRef<HTMLInputElement>(null);
    const [controlled, setControlled] = React.useState<Date>(null);

    React.useEffect(() => setControlled(TB.getDate(props.value)), [props.value]);
    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);

    const formattedValue = React.useMemo(() => {
        if (!controlled) return "";
        if (!props.enableTime) return moment(controlled).format("yyyy-MM-DD");
        return moment(controlled).format("yyyy-MM-DDTHH:mm");
    }, [controlled, props.enableTime]);

    const onBlur = React.useCallback((date = controlled) => {
        let isoDate = date?.toISOString?.() || "";
        onChange?.(isoDate);
    }, [controlled, onChange]);

    const dataChange = React.useCallback((dateStr: string) => {
        let date = TB.getDate(dateStr);
        if (props.uncontrolled || props.fire_controlled) onChange?.(date?.toISOString?.() || "");
        if (!props.uncontrolled) setControlled(date);
    }, [props.uncontrolled, props.fire_controlled, onChange]);

    React.useEffect(() => {
        if (api && api.current) api.current.getValue = () => controlled?.toISOString?.() || "";
    }, [controlled, api]);

    React.useImperativeHandle(ref, () => ({
        focus: () => input.current?.focus?.(),
        setValue: v => setControlled(TB.getDate(v)),
        getValue: () => controlled?.toISOString?.() || "",
    }), [controlled]);

    const to_string = React.useCallback(() => {
        let format = props.enableTime ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY";
        return moment(controlled)?.format?.(format);
    }, [controlled, props.enableTime]);

    return <ComponentWrapper {...props} disabled={isDisabled} get_str_value={to_string}>
        <FormControl
            ref={input}
            disabled={isDisabled}
            value={formattedValue}
            onBlur={() => onBlur()}
            readOnly={props.readonly}
            onChange={e => dataChange(e.target.value)}
            type={props.enableTime ? "datetime-local" : "date"}
        />
    </ComponentWrapper>
});

DateTime.displayName = "DateTime";
export default DateTime;