import _ from "lodash";
import * as XLSX from "xlsx";
import React from "react";
import moment from "moment";
import { Dialog } from "@material-ui/core";
import { TB, REGEX, TC } from "../../Constants";
import { Button, Form, TipContainer } from "../../Common";
import { useLanguage } from "../../hooks";

const ExcelMapping = ({ onAddColumns, sheet, close, columnFormat, onValidate, invert = false, allowSwapping = false, allowIgnoring = false, factorChange = false, isPopUp = true, ...props }) => {
    const lg = useLanguage();
    const [columns, setColumns] = React.useState();
    const [isSwap, setIsSwap] = React.useState(false);
    const [rowOffset, setRowOffset] = React.useState(1);
    const [toIgnoreRows, setToIgnoreRows] = React.useState("");
    const [dataFirstLine, setDataFirstLine] = React.useState(2);

    // const inputIgnoreRef = React.useRef();

    //#region Swap
    React.useEffect(() => setRowOffset(0), [isSwap]);
    React.useEffect(() => !allowSwapping && isSwap ? setIsSwap(false) : undefined, [isSwap, allowSwapping]);

    const swappingButton = React.useMemo(() => !allowSwapping ? undefined : <div className="position-absolute m-3 end-0 top-0">
        <button onClick={() => setIsSwap(prev => !prev)} className="btn btn-primary">
            <i className="fa fa-sync mr-2"></i>Swap rows
        </button>
    </div>, [allowSwapping]);
    //#endregion

    //#region Sheet
    const validSheet = React.useMemo(() => {

        if (!TB.validObject(sheet)) return {};
        if (!isSwap) return sheet;

        let aoa = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        var nbCols = aoa.length;
        var nbRows = aoa[0].length;
        var newArray = [];
        for (let col = 0; col < nbRows; col++) {
            newArray[col] = [];
            for (let row = 0; row < nbCols; row++) newArray[col][row] = aoa[row][col];
        }

        return XLSX.utils.aoa_to_sheet(newArray, { cellDates: true })
    }, [isSwap, sheet]);

    const indexedSheet = React.useMemo(() => {
        let obj = {};

        Object.entries(validSheet).forEach(([key, val]) => {
            let col = key.match(REGEX.EXCEL_COL_REGEX_LOOSE)?.[0];
            // No column found
            if (!TB.validString(col)) return null;
            if (!Array.isArray(obj[col])) obj[col] = [];

            let index = parseInt(key.replace(col, ""));
            if (isNaN(index)) return null;


            if (["number", "string"].includes(typeof val?.v)) {
                obj[col][index] = val?.v ?? "";
            }
            // else if (val?.t === "d") obj[col][index] = val?.w || val?.v;
            else if (!isNaN(Date.parse(val?.v))) {
                obj[col][index] = moment(val?.v).format("DD/MM/YYYY HH:mm:ss");
            }
        });

        return Object.fromEntries(Object.entries(obj).map(([key, array]) => [key, _.drop(array, 1)]));
    }, [validSheet]);

    const sortCol = React.useCallback((a, b) => {
        if (a.length > b.length) return 1;
        if (a.length < b.length) return -1;
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }, []);

    const dataLastLine = React.useMemo(() => _.max(Object.values(indexedSheet).map(array => Array.isArray(array) ? array.length : 0)), [indexedSheet]);
    const columnList = React.useMemo(() => _.uniq(_.flatten(Object.keys(validSheet).map(col => col.match(REGEX.EXCEL_COL_REGEX_LOOSE)))).filter(TB.validString).sort(sortCol), [validSheet, sortCol]);
    //#endregion

    //#region Column Format
    React.useEffect(() => setColumns(TB.validObject(columnFormat) ? _.cloneDeep(columnFormat) : null), [columnFormat]);

    const validColumns = React.useMemo(() => TB.validObject(columns) ? columns : {}, [columns]);

    const columnOptions = React.useMemo(() => {
        return Object.entries(validColumns)
            .map(([prop, { label, key, required }]) => ({ value: prop, label, key, required }))
            .sort((a, b) => {
                let alphabet_order = 0;
                if (a.label > b.label) alphabet_order = 1;
                else if (a.label < b.label) alphabet_order = -1;

                if (a.required) return b.required ? alphabet_order : -1;
                else if (b.required) return 1;
                else return alphabet_order;
            });
    }, [validColumns]);

    const get_options = React.useCallback((col) => {
        return columnOptions.filter(c => c.key === col || !TB.validString(c.key))
            .map(c => ({ label: (c.required ? "* " : "") + c.label, value: c.value }))
    }, [columnOptions]);

    const pairedCol = React.useMemo(() => Object.entries(validColumns).filter(([prop, { pair }]) => TB.validString(pair)).map(([prop, { key, pair, label }]) => ({ label, key, pair })), [validColumns]);

    const onSelectChange = React.useCallback((col, val) => setColumns(columns => {
        let toIgnoreKeys = ["key"].concat(val === "timestamp" ? ["factor"] : undefined).filter(TB.validString);
        if (!TB.validString(val)) return Object.fromEntries(
            Object.entries(columns).map(([prop, data]) => {
                if (data.key === col) return [prop, _.omit(data, toIgnoreKeys)];
                return [prop, data];
            })
        );

        return Object.fromEntries(
            Object.entries(columns)
                .map(([prop, data]) => {
                    if (prop === val) return [prop, { ...data, key: col }];
                    if (data?.key === col) return [prop, _.omit(data, toIgnoreKeys)];
                    return [prop, data];
                })
        );
    }), []);

    const valuePerKey = React.useMemo(() => Object.fromEntries(
        Object.entries(validColumns)
            .filter(([prop, { key }]) => TB.validString(key))
            .map(([prop, { key }]) => [key, prop])
    ), [validColumns]);
    //#endregion

    //#region Factor Change
    const factorOption = React.useMemo(() => {
        return _.flatten([true, false]
            .map(isDivide => [...new Array(3)]
                .map((x, i, array) => ({
                    label: (isDivide ? "/" : "x") + ` 1${'0'.repeat(isDivide ? array.length - i : i + 1)}`,
                    value: (isDivide ? "/" : "*") + ` 1${'0'.repeat(isDivide ? array.length - i : i + 1)}`,
                }))
            )
        )
    }, []);

    const onChangeFactor = React.useCallback((col, val) => setColumns(columns => {
        return Object.fromEntries(
            Object.entries(columns)
                .map(([prop, data]) => {
                    if (valuePerKey[col] === prop) return [prop, { ...data, factor: TB.validString(val) ? val : null }];
                    return [prop, data];
                })
        )
    }), [valuePerKey]);

    const factorPerKey = React.useMemo(() => Object.fromEntries(
        Object.entries(validColumns)
            .filter(([prop, { factor }]) => TB.validString(factor))
            .map(([prop, { key, factor }]) => [key, factor])
    ), [validColumns]);
    //#endregion

    //#region Column Table
    const nbExampleValuesShown = React.useMemo(() => invert ? 16 : 5, [invert]);
    const maxOffset = React.useMemo(() => dataLastLine - nbExampleValuesShown - 1, [dataLastLine, nbExampleValuesShown]);
    const examplesWidth = React.useMemo(() => ({ width: `${(factorChange ? 60 : 70) / nbExampleValuesShown}%` }), [nbExampleValuesShown, factorChange]);
    const exampleHeaderCols = React.useMemo(() => [...new Array(nbExampleValuesShown)].map((x, i) => <th key={i}>{lg.getStaticText(TC.MAPPER_ROW, i + 1 + rowOffset)}</th>), [nbExampleValuesShown, lg, rowOffset]);

    const ignoredRows = React.useMemo(() => {
        let parts = toIgnoreRows.split(/,/g).map(x => x.trim());
        let firstLines = typeof dataFirstLine === "number" && dataFirstLine > 0 ? [...new Array(dataFirstLine - 1)].map((x, i) => i + 1) : [];

        for (let part of parts) {
            // Parse the text into number
            let [start, end] = part.split("-").map(x => parseInt(x));

            // Must have a start value
            if (!isNaN(start) && start !== undefined) {
                // If no end value, add only 1 row
                if (isNaN(end) || end === undefined) firstLines.push(start);
                // We received a range, add start and end as well because function does not include the limits
                else firstLines.push(start, end, ...TB.getNumberBetween(start, end));
            }
        }
        return firstLines;
    }, [dataFirstLine, toIgnoreRows]);

    const getExampleValues = React.useCallback((col, set_width = true) => {
        let values = _.take(_.drop(indexedSheet[col], rowOffset), nbExampleValuesShown);
        if (values.length < nbExampleValuesShown) values = values.concat([...new Array(nbExampleValuesShown - values.length)].map(x => ""));
        let factor = factorChange ? factorPerKey[col] ?? null : null;

        return values.map((val, i) => <td className={`fs-85 align-middle text-break ${ignoredRows.includes(i + rowOffset + 1) ? "bg-white" : ""}`} style={set_width ? examplesWidth : undefined} key={i}>
            {TB.validString(val) || typeof val == "number" ? (!TB.validString(val) ? applyFactor(val, factor)?.toString?.() ?? "" : val) : <em>Empty</em>}
        </td>);
    }, [indexedSheet, nbExampleValuesShown, factorPerKey, rowOffset, ignoredRows, examplesWidth, factorChange]);

    const factorColHeader = React.useMemo(() => !factorChange ? undefined : <th>
        <span>Facteur pour unité standardisées  </span>
        <TipContainer tipContent="Kw/h, m³, ..." />
    </th>, [factorChange]);

    const add_column = React.useCallback(async (text, col) => {
        if (typeof onAddColumns === "function") {
            let new_columns = await onAddColumns(text);
            if (new_columns) {
                let [new_col_key, new_col_value] = Object.entries(new_columns)[0];
                if (new_col_key) setColumns(p => ({ ...p, [new_col_key]: { ...new_col_value, key: col } }));
            }
        }
    }, [onAddColumns]);

    const tableMapping = React.useMemo(() => {
        let content = <></>;
        if (invert) {
            const cols = columnList.map((col, i) => {
                return {
                    col,
                    header: <th key={i}>{col}</th>,
                    examples: getExampleValues(col, false),
                    attribution: <td key={i}>
                        <Form.Select
                            noBottomMargin
                            customClass="w-100"
                            value={valuePerKey[col]}
                            options={get_options(col)}
                            onChange={v => onSelectChange(col, v === "none" ? undefined : v)}
                            typeahead={{
                                dropdownFit: true,
                                positionFixed: false,
                                onAddOption: text => add_column(text, col),
                                allowNewOption: typeof onAddColumns === "function",
                            }}
                        />
                    </td>,
                    factor: factorChange && <td key={i}>
                        <select disabled={!TB.validString(valuePerKey[col]) || valuePerKey[col] === "timestamp"} value={factorPerKey[col] ?? ""} className="form-select" onChange={e => onChangeFactor(col, e.target.value)}>
                            <option value={""}></option>
                            {factorOption.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
                        </select>
                    </td>
                }
            });
            content = <tbody>
                <tr>
                    <th>Colonne</th>
                    {cols.map(c => c.header)}
                </tr>
                {[...new Array(nbExampleValuesShown)].map((x, i) => <tr key={"line_" + (i + rowOffset)}>
                    <th>{lg.getStaticText(TC.MAPPER_ROW, 1 + i + rowOffset)}</th>
                    {cols.map(c => <React.Fragment key={"ex_" + c.col + (i + rowOffset)}>{c.examples[i]}</React.Fragment>)}
                </tr>)}
                <tr>
                    <th>Attribution</th>
                    {cols.map(c => c.attribution)}
                </tr>
                {factorColHeader && <tr>
                    {factorColHeader}
                    {cols.map(c => c.factor)}
                </tr>}
            </tbody>;
        }
        else content = <>
            <thead>
                <tr>
                    <th>Colonne</th>
                    {exampleHeaderCols}
                    <th>Attribution</th>
                    {factorColHeader}
                </tr>
            </thead>
            <tbody>
                {columnList.map(col => <tr key={col}>
                    <td style={{ width: "5%" }} className="fw-bold align-middle">{col}</td>
                    {getExampleValues(col)}
                    <td style={{ width: factorChange ? "20%" : "25%" }}>
                        <Form.Select
                            noBottomMargin
                            customClass="w-100"
                            value={valuePerKey[col]}
                            options={get_options(col)}
                            onChange={v => onSelectChange(col, v === "none" ? undefined : v)}
                        />
                    </td>
                    {factorChange && <td style={{ width: "15%" }}>
                        <select disabled={!TB.validString(valuePerKey[col]) || valuePerKey[col] === "timestamp"} value={factorPerKey[col] ?? ""} className="form-select" onChange={e => onChangeFactor(col, e.target.value)}>
                            <option value={""}></option>
                            {factorOption.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
                        </select>
                    </td>}
                </tr>)}
            </tbody>
        </>;

        return <table className="mt-2 table table-light table-sm table-hover table-bordered align-middle text-center" children={content} />;
    }, [onAddColumns, add_column, columnList, valuePerKey, exampleHeaderCols, rowOffset, nbExampleValuesShown, factorColHeader, factorOption, factorPerKey, lg, getExampleValues, get_options, onChangeFactor, onSelectChange, factorChange, invert]);
    //#endregion

    //#region Body Content
    const onChangeNumField = React.useCallback((e, setter) => {
        let value = e?.target?.value;
        let parsed = parseInt(value);
        setter?.(isNaN(parsed) ? "" : parsed);
    }, []);

    const onChangeOffset = React.useCallback(newOffset => {
        if (newOffset > maxOffset) setRowOffset(maxOffset < 0 ? 0 : maxOffset);
        else if (newOffset < 0) setRowOffset(0);
        else setRowOffset(newOffset);
    }, [maxOffset]);

    /* const onBlurIgnoreInput = React.useCallback(event => {
        let value = event.target.value;
        let ignoreArray = toIgnoreRows;
    
        if (typeof value === "string") ignoreArray = value
            .split(/,/g)
            .map(val => parseInt(val))
            .filter(x => !isNaN(x) && x > 0 && x <= dataLastLine);
    
        setToIgnoreRows(ignoreArray);
    }, [toIgnoreRows, dataLastLine]); */

    /* React.useEffect(() => [TB.isDomElement, React.isValidElement].some(f => f(inputIgnoreRef.current)) ? inputIgnoreRef.current.value = toIgnoreRows.join() : undefined, [toIgnoreRows]); */

    const invalidDataFirstLine = React.useMemo(() => typeof dataFirstLine !== "number" || dataFirstLine < 0, [dataFirstLine]);

    const pagination = React.useMemo(() => ({
        can_previous: rowOffset > 0,
        start: () => onChangeOffset(0),
        can_next: rowOffset < maxOffset,
        next: () => onChangeOffset(rowOffset + 1),
        previous: () => onChangeOffset(rowOffset - 1),
        end: () => onChangeOffset(dataLastLine - nbExampleValuesShown),
    }), [dataLastLine, maxOffset, nbExampleValuesShown, onChangeOffset, rowOffset])

    const offsetButtons = React.useMemo(() => <div className="position-absolute start-50 top-0 mt-3">
        <div className="btn-group">
            <button disabled={!pagination.can_previous} onClick={pagination.start} className="btn btn-outline-primary">
                <i className="fa fa-angle-double-left"></i>
            </button>
            <button disabled={!pagination.can_previous} onClick={pagination.previous} className="btn btn-outline-primary">
                <i className="fa fa-angle-left"></i>
            </button>
            <button disabled={!pagination.can_next} onClick={pagination.next} className="btn btn-outline-primary">
                <i className="fa fa-angle-right"></i>
            </button>
            <button disabled={!pagination.can_next} onClick={pagination.end} className="btn btn-outline-primary">
                <i className="fa fa-angle-double-right"></i>
            </button>
        </div>
    </div>, [pagination]);

    const otherInfos = React.useMemo(() => <div className="row g-3 p-1">
        <div className="col-md-2">
            <label className="form-label">1ère ligne de donnée</label>
            <input type="number" className={`form-control ${invalidDataFirstLine ? "invalid" : ""}`} value={dataFirstLine} onChange={e => onChangeNumField(e, setDataFirstLine)} />
            {invalidDataFirstLine && <span className="invalid-feedback shown">value must be greater than 0</span>}
        </div>
        {allowIgnoring && <div className="col-md-3">
            {/* <label className="form-label"></label> */}
            <Form.TextField value={toIgnoreRows} label="Lignes à ignorer" noBottomMargin onChange={setToIgnoreRows} tooltip="Ex: 1,4,10-15,30-40" />
        </div>}
    </div>, [dataFirstLine, invalidDataFirstLine, toIgnoreRows, onChangeNumField, allowIgnoring]);

    const bodyContent = React.useMemo(() => <div className="position-relative w-100">
        {otherInfos}
        {tableMapping}
        {offsetButtons}
        {swappingButton}
    </div>, [tableMapping, offsetButtons, otherInfos, swappingButton]);
    //#endregion

    //#region Errors
    const isInvalidSheet = React.useMemo(() => TB.validObject(sheet) ? null : "Sheet is invalid", [sheet]);
    const isInvalidColumns = React.useMemo(() => columns === null ? "Columns format is invalid" : null, [columns]);
    const errorsList = React.useMemo(() => [isInvalidSheet, isInvalidColumns].filter(TB.validString), [isInvalidSheet, isInvalidColumns]);

    const errorMessage = React.useMemo(() => errorsList.length === 0 ? undefined : <div className="alert alert-danger">
        {errorsList.map((str, i) => <p className="m-0" key={i}>{str}</p>)}
    </div>, [errorsList]);
    //#endregion

    //#region Validation
    const isExcelColumn = React.useCallback(str => TB.validString(str) && str.match(REGEX.EXCEL_COL_REGEX_STRICT), []);

    const invalidPairsLabels = React.useMemo(() => {
        return Object.values(_.groupBy(pairedCol, "pair"))
            .filter(arrayPairs => arrayPairs.some(({ key }) => isExcelColumn(key)) && !arrayPairs.every(({ key }) => isExcelColumn(key)))
            .map(arrayPairs => arrayPairs.map(({ label }) => label).join(" / "));
    }, [pairedCol, isExcelColumn]);

    const arePairsRespected = React.useMemo(() => invalidPairsLabels.length === 0, [invalidPairsLabels]);
    const allRequiredFilled = React.useMemo(() => Object.values(validColumns).filter(({ required, key }) => required && !TB.validString(key)).length === 0, [validColumns]);

    const disableValidate = React.useMemo(() => invalidDataFirstLine || !allRequiredFilled || !arePairsRespected, [invalidDataFirstLine, allRequiredFilled, arePairsRespected]);
    const validate = React.useCallback(() => disableValidate ? undefined : onValidate?.({ columns, dataFirstLine, dataLastLine, ignoredRows: ignoredRows.map(i => i - dataFirstLine) }), [columns, dataLastLine, dataFirstLine, ignoredRows, disableValidate, onValidate]);
    //#endregion

    //#region Modal Parts
    const invalidPairsErrorMessage = React.useMemo(() => invalidPairsLabels.length === 0 ? undefined : <span className="text-danger fs-85 mr-2 position-absolute start-0">
        <span className="mx-1">Certaines propriété vont de pairs, et tout les éléments doivent être sélectionné</span>
        <TipContainer tipContent={invalidPairsLabels.map((str, i) => <span key={i} className="d-block">{str}</span>)} />
    </span>, [invalidPairsLabels]);

    const modalHeader = React.useMemo(() => <div className="modal-header">
        <h5 className="modal-title">Excel Mapping</h5>
        <button className="btn-close" onClick={close}></button>
    </div>, [close]);

    const modalBody = React.useMemo(() => <div className="modal-body">
        {errorMessage === undefined ? bodyContent : errorMessage}
    </div>, [errorMessage, bodyContent]);

    const modalFooter = React.useMemo(() => <div className="modal-footer position-relative">
        {invalidPairsErrorMessage}
        {!allRequiredFilled && <span className="text-danger fs-85 mr-2">Certains champs obligatoires n&apos;ont pas été attribué</span>}
        <Button onClick={validate} disabled={disableValidate} text={TC.GLOBAL_CONFIRM} />
    </div>, [allRequiredFilled, disableValidate, invalidPairsErrorMessage, validate]);
    //#endregion

    if (isPopUp) return <Dialog open fullScreen onClose={close}>
        <div className="modal-content h-100">
            {modalHeader}
            {modalBody}
            {modalFooter}
        </div>
    </Dialog>

    return <div className="modal-content h-100">
        {modalHeader}
        {modalBody}
        {modalFooter}
    </div>
}

export default ExcelMapping;

export const applyFactor = (value, factor) => {
    if (!TB.validString(factor)) return value;
    if (typeof value !== "number") return NaN;

    let isDivide = factor.includes("/");
    let factorValue = parseInt(factor.replaceAll(/\D/g, ""));
    if (isNaN(factorValue)) return NaN;

    if (isDivide) return value / factorValue;
    return value * factorValue;
};