import * as S from "../services";
import * as M from "../Components/Modal";
import { FP, T, TB, TC } from "../Constants";

//#region Types
type PromiseResults = null | undefined | string;
type MultiSelectModal<A = object> = RenderMultiSelectModal & A;
type RenderMultiSelectModal = Omit<M.AskMultiSelectModalProps, "options">;
type GetContextOriginsWithDataset = T.API.Utils.Datasets.GetContextOriginsWithDataset;

type MultiUserModalParams = MultiSelectModal<{
    /** Should the current user appear in the options ? */
    includeSelf?: boolean;
    /** Should Admins appear in the options ? */
    includeAdmin?: boolean;
}>

type MultiOriginContextParams = MultiSelectModal<{
    /** The context to search for Origins */
    context: Parameters<T.API.Utils.Datasets.GetContextOrigins>[0];
}>;

type DatasetsManyPickFromContextModal = MultiSelectModal<{
    /** The context to find datasets from */
    context: T.ContextParams;
}>;
//#endregion

/**
 * Render a MultiSelect modal
 */
const renderMultiSelectModal = <A extends object = object>(params: RenderMultiSelectModal, dataPromise: Promise<T.Option<A>[]>) => new Promise<PromiseResults[]>(resolve => {
    let dismount = M.renderLoader();
    let vParams = TB.getObject(params);

    dataPromise
        .then(options => {
            if (options.length === 1 && params.required && options[0]) resolve([options[0].value]);
            else M.askMultiSelect({ ...vParams, options })
                .then(value => resolve(value as PromiseResults[]));
        })
        .catch((error: T.AxiosError) => {
            M.Alerts.loadError(error);
            resolve(null);
        })
        .finally(dismount);
});

/**
 * Render a modal of the users below the current user, but to pick multiple users
 */
export const renderMultipleUsersSelect = (params: MultiUserModalParams) => {
    const optionPromise = new Promise<T.Option[]>((resolve, reject) => {
        S.getUsers({ includeAdmin: params?.includeAdmin, includeSelf: params?.includeSelf })
            .then(({ data }) => resolve(data.map(u => ({ label: u.data.name, value: u._id }))))
            .catch(reject);
    });
    return renderMultiSelectModal(params, optionPromise);
}

/**
 * Render a modal to let the user pick an origin out of a context
 */
export const renderOriginWithDatasetContextPickModal = (params: MultiOriginContextParams) => {
    const optionPromise = new Promise<ReturnType<GetContextOriginsWithDataset>>((resolve, reject) => {
        S.getContextOriginsWithDatasets(params?.context)
            .then(({ data }) => resolve(data))
            .catch(reject);
    });
    return renderMultiSelectModal({ ...params, title: params?.title || TC.ALARM_PICK_ORIGIN }, optionPromise);
}

/**
 * Render a modal to pick multiple datasets from a context
 */
export const renderDatasetsManyPickFromContextModal = (params: DatasetsManyPickFromContextModal) => {
    type Options = T.Awaited<ReturnType<typeof S.getContextDatasetsOptions>>["data"];
    const optionPromise = new Promise<Options>((resolve, reject) => {
        S.getContextDatasetsOptions(params?.context)
            .then(({ data }) => resolve(data))
            .catch(reject);
    });

    let icon_list = {
        [FP.SITE_FORM]: "city",
        [FP.EQUIPEMENT_FORM]: "cog",
        [FP.BUILDING_FORM]: "building",
        [FP.EMPLACEMENT_FORM]: "door-closed",
    };

    return renderMultiSelectModal({
        ...params,
        label: TC.DATASET_PANEL_TAB_DATA,
        title: params?.title || TC.ALARM_PICK_DATASET,
        typeahead: {
            renderItem: (option: Options[number]) => <span>
                <i className={"me-2 fa fa-" + icon_list[option.origin_type] || "question"}></i>
                <span>{option.label}</span> - <span className="fst-italic">{option.origin}</span>
            </span >,
        }
    }, optionPromise);
}