import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import { TB, T } from "../../Constants";
import { Dialog } from "@material-ui/core";
import renderInContainer from "./getContainer";

//#region Types
export type StyleModalProps = {
    autofocus?: boolean;
    isFullScreen?: boolean;
    /** Title of the modal, shown in the header */
    title?: string;
    /** If true, the focus trap will not prevent focus from leaving the focus trap while open. */
    disableEnforceFocus?: boolean
    /** Fixed size the modal body cannot outgrow */
    maxBodyHeight?: string;
    /** If true, hitting escape will not fire the onClose callback. */
    disableEscapeKeyDown?: boolean;
    /** Fixed height for the body of the modal */
    height?: string;
    /** Width size of the modal */
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    /** Callback for quitting the modal */
    onQuit?: () => void;
    /** Custom class to the root Modal component */
    className?: string;
    /** Should the popup be hidden, but still active */
    hidden?: boolean;
    /** Extra content to display in the header */
    extra_header?: React.ReactNode;
    /** Only show the extra header, and ignore the title ? */
    only_extra_header?: boolean;
}


export type BlankModalFnProps = StyleModalProps & {
    showHeader?: boolean;
    footer?: React.ReactNode;
    children?: React.ReactNode;
    renderFooter?: (resolve: T.PromiseResolve) => React.ReactNode;
    renderContent?: (resolve: T.PromiseResolve) => React.ReactNode | undefined;
}

export interface BlankModalProps extends Omit<BlankModalFnProps, "renderContent"> {
    onQuit?: () => void;
}
//#endregion

const BlankModal: React.FC<BlankModalProps> = ({ title, showHeader, children, footer, isFullScreen = false, size = "sm", onQuit, autofocus = true, maxBodyHeight, ...props }) => {
    const lg = H.useLanguage();

    //#region Modal Size
    const modalSizes = React.useMemo(() => ["xs", "sm", "md", "lg", "xl"], []);
    const actualSize = React.useMemo(() => isFullScreen ? undefined : (modalSizes.includes(size) ? size : "sm"), [modalSizes, size, isFullScreen]);
    const bodyHeight = React.useMemo(() => isFullScreen ? {} : { maxHeight: maxBodyHeight, height: props.height }, [maxBodyHeight, props.height, isFullScreen]);
    //#endregion

    //#region Modal parts
    const isHtml = React.useCallback(val => TB.isDomElement(val) || React.isValidElement(val), []);

    const modalHeader = React.useMemo(() => (title || showHeader) && <div className="modal-header">
        {!props.only_extra_header && <h5 className="modal-title">{lg.getStaticText(title)}</h5>}
        {props.extra_header && <div className="ms-2" children={props.extra_header} />}
        <button className="btn-close" onClick={() => onQuit()}></button>
    </div>, [lg, showHeader, title, props.extra_header, props.only_extra_header, onQuit]);

    const hasFooter = React.useMemo(() => isHtml(footer), [isHtml, footer]);
    const modalBody = React.useMemo(() => <div className="modal-body position-relative overflow-scroll" style={bodyHeight}>{children}</div>, [children, bodyHeight]);

    const modalFooter = React.useMemo(() => !isHtml(footer) ? undefined : <div className="modal-footer" children={footer} />, [footer, isHtml]);
    //#endregion

    return <Dialog
        open
        fullWidth
        hidden={props.hidden}
        maxWidth={actualSize}
        onClose={() => onQuit()}
        fullScreen={isFullScreen}
        className={props.className}
        disableAutoFocus={!autofocus}
        disableEnforceFocus={props.disableEnforceFocus}
        PaperProps={{ className: "animated_scale_in" }}
        disableEscapeKeyDown={props.disableEscapeKeyDown}
    >
        <div className={`modal-content ${hasFooter ? "" : "border-bottom-0"} ${isFullScreen ? "h-100" : ""}`}>
            {modalHeader}
            {modalBody}
            {modalFooter}
        </div>
    </Dialog>
}

const BlankModalReduxWrapper: React.FC<BlankModalProps> = ({ ...props }) => <C.ReduxWrapper children={<BlankModal {...props} />} />;

export default BlankModalReduxWrapper;

export const renderBlankModal = <A,>(params?: BlankModalFnProps) => new Promise<A | null>(resolve => {
    const [render, dismount] = renderInContainer();

    if (!TB.validObject(params)) params = {};

    if (render && dismount) {
        let onQuit = () => dismount(() => resolve(null));
        let onResolve = value => dismount(() => resolve(value));

        let footer = params.footer;
        let children = params.children;
        if (typeof params.renderFooter === "function") footer = params.renderFooter(onResolve);
        if (typeof params.renderContent === "function") children = params.renderContent(onResolve);

        render(<BlankModalReduxWrapper {...params} onQuit={onQuit} children={children} footer={footer} />);
    }
    else resolve(null);
});