import _ from "lodash";
import React from "react";
import General from "./General";
import * as F from "../../Form";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { T, TC } from "../../../Constants";
import { useNavigate } from "react-router-dom";
import Equipments, { EquipmentsProps } from "./Equipments";
import Consumption, { ConsumptionProps, ConsumptionRef } from "./Consumption";

//#region Types & Constants
type RemExtra = Record<"ignore", boolean>;
type MissionResumeNRJ = ReturnType<T.API.Utils.Missions.MissionResumeNRJ>;

type Tabs = {
    /** The label displayed in the tab */
    label: string;
    /** The label displayed in the crumb */
    crumb: string;
    /** The key to know which tab is active */
    key: "general" | "mission" | "consumption" | "equipments" | "datasets" | "remarques";
}

const TABS = [
    { label: TC.CERT_HISTORY_MISSION, key: "mission" },
    { label: TC.NRJ_MISSION_TAB_GENERAL, key: "general" },
    { label: TC.NRJ_MISSION_TAB_CONS, key: "consumption" },
    { label: TC.NRJ_MISSION_TAB_EQUIPMENTS, key: "equipments" },
    { label: TC.NRJ_MISSION_TAB_DATASETS, key: "datasets" },
    { label: TC.CDM_MISSION_REM_PANEL, key: "remarques" },
] as Tabs[];
//#endregion

const NRJ: React.FC = () => {
    const lg = H.useLanguage();
    const navigate = useNavigate();
    const [roots, mission_id] = H.useRoots();
    const updated_mission = H.useBoolean(false);
    const consumption = React.useRef<ConsumptionRef>(null);
    const ignored_rems_ids_ref = React.useRef<string[]>([]);
    const mission_ref = React.useRef<F.Missions.MissionFormRef>(null);
    const rem_ref = React.useRef<G.RemarquesTableRef<RemExtra>>(null);
    const { updateCrumbs, resetCrumbs } = H.useCrumbs(TC.NRJ_MISSION_CRUMB);
    const [current_tab, set_current_tab] = React.useState<Tabs["key"]>("mission");
    const [loaded_tabs, set_loaded_tabs] = React.useState<Tabs["key"][]>(["mission"]);
    const [mission_state, set_mission_state, status] = H.useAsyncState<MissionResumeNRJ>({ asset_id: "", mission_id: "", read_only: true, general: null, crrem: [], consumption: null });

    //#region Tabs & Crumbs
    React.useEffect(() => {
        let tab = TABS.filter(t => t.key === current_tab)[0];
        if (tab) updateCrumbs({ label: tab.label }, 1);
        return () => { resetCrumbs() };
    }, [updateCrumbs, resetCrumbs, current_tab]);

    const change_tab = React.useCallback((tab: typeof current_tab) => {
        const tab_setter = (keep_mission_loaded = true) => {
            // Update the current tab
            set_current_tab(tab);
            // Update the list of loaded tab
            set_loaded_tabs(p => {
                let new_loaded = p.includes(tab) ? p : p.concat(tab);
                if (!keep_mission_loaded) return new_loaded.filter(t => t !== "mission");
                else return new_loaded;
            });
        }

        // Ask to confirm the changes made in the mission
        if (current_tab === "mission" && updated_mission.value) M.askConfirm({ title: TC.MISSION_WIZARD_AUTO_SAVE_MISSION, text: TC.MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT }).then(confirmed => {
            if (confirmed) {
                let result = mission_ref.current?.save?.();
                if (result && result !== "errors") result.then(success => {
                    if (success) {
                        tab_setter();
                        updated_mission.setFalse();
                    }
                });
            }
            else tab_setter(false);
        });
        else if (tab === "remarques") {
            tab_setter();
            let ignore_ids = mission_state.consumption?.ignore_rems || [];
            // Update the ref
            ignored_rems_ids_ref.current = ignore_ids;
        }
        // Update the local state when leaving the remarques tab
        else if (current_tab === "remarques") {
            set_mission_state(p => ({ ...p, consumption: { ...p.consumption, ignore_rems: [...ignored_rems_ids_ref.current] } }));
            tab_setter();
        }
        else tab_setter();
    }, [current_tab, updated_mission, mission_state.consumption]);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Dependency is not necessary, and I don't want the warning to show up
    React.useEffect(() => updated_mission.setFalse(), [mission_id]);

    React.useEffect(() => {
        if (current_tab === "remarques") {
            rem_ref.current.table.current.grid.forEachNode(node => {
                if (node.data) {
                    node.updateData({ ...node.data, ignore: ignored_rems_ids_ref.current.includes(node.data._id) })
                }
            });
        }
    }, [current_tab]);
    //#endregion

    //#region Load Data
    React.useEffect(() => {
        let isSubscribed = true;
        S.missionResumeNRJ()
            .then(({ data }) => isSubscribed && set_mission_state(data, "done"))
            .catch(() => isSubscribed && set_mission_state({ asset_id: "", read_only: true, mission_id: "", general: null, crrem: [], consumption: null }, "error"));
        return () => {
            isSubscribed = false;
            set_current_tab("mission");
            set_loaded_tabs(["mission"]);
            set_mission_state({ asset_id: "", mission_id: "", read_only: true, general: null, crrem: [], consumption: null }, "load");
        }
    }, [set_mission_state, mission_id]);
    //#endregion

    //#region Data Setter
    const set_consumption = React.useCallback<ConsumptionProps["set_consumption"]>((cons, apply_db) => {
        if (apply_db) S.saveMissionConsumption(cons)
            .then(() => set_mission_state(p => ({ ...p, consumption: cons })))
            .catch(M.Alerts.loadError);
        else set_mission_state(p => ({ ...p, consumption: cons }));
    }, [set_mission_state]);

    const set_extra = React.useCallback<EquipmentsProps["set_extra"]>(extra => {
        S.saveMissionExtra(extra)
            .then(({ data }) => set_mission_state(p => ({ ...p, extra: data.nrj })))
            .catch(M.Alerts.updateError);
    }, [set_mission_state]);
    //#endregion

    //#region Remarque Table
    const mission_consumption_ref = React.useRef(mission_state.consumption);
    React.useImperativeHandle(mission_consumption_ref, () => mission_state.consumption, [mission_state.consumption]);

    const rem_table_props = React.useMemo(() => ({
        columns: [{
            pinned: "right",
            field: "ignore",
            headerName: " ",
            editable: false,
            type: G.CellsTypes.TYPE_SELECT_BUTTON,
            params: {
                invert: true,
                use_is_selected_filters_label: true,
                action: (row, node) => {
                    let is_ignored_after_change = !row.ignore;
                    // Deselect the row
                    if (ignored_rems_ids_ref.current.includes(row._id)) ignored_rems_ids_ref.current.splice(ignored_rems_ids_ref.current.indexOf(row._id), 1)
                    // Select the row
                    else ignored_rems_ids_ref.current.push(row._id);
                    // Update the row in the grid
                    node.updateData({ ...row, ignore: is_ignored_after_change });
                    // Create the updated consumption object
                    const new_consumption: T.Mission["consumption"] = { ...mission_consumption_ref.current, ignore_rems: ignored_rems_ids_ref.current };
                    // Update the change in the database
                    S.saveMissionConsumption(new_consumption)
                        // Update the mission in the form
                        .then(() => mission_ref.current?.update?.({ consumption: new_consumption }, false))
                        .catch(e => {
                            M.Alerts.updateError(e);
                            // Reset the state of the row in the grid
                            node.updateData({ ...row, ignore: !is_ignored_after_change });
                            // Reset the list of ignored remarques
                            if (is_ignored_after_change) ignored_rems_ids_ref.current.splice(ignored_rems_ids_ref.current.indexOf(row._id), 1);
                            else ignored_rems_ids_ref.current.push(row._id);
                        });
                },
                buttonProps: row => row && !row.ignore
                    ? { variant: "success", icon: "check-circle", size: "sm", text: TC.EPRA_DATASET_SELECTED }
                    : { variant: "primary", icon: "times-circle", size: "sm", text: TC.EPRA_DATASET_TO_SELECT },
            },
        }] as G.RemarquesTableProps<RemExtra>["extra_columns"],
        buttons: [
            {
                icon: { element: "<i class='fa fa-check me-2'></i>" },
                label: lg.getStaticText(TC.EPRA_TABLE_SELECT_DISPLAYED_ROWS),
                onClick: () => {
                    let og_list = ignored_rems_ids_ref.current;
                    // Select the filtered rows of the grid
                    rem_ref.current.table.current.grid.selectAllFiltered();
                    let all_current_rows = rem_ref.current.table.current.grid.getSelectedRows();
                    rem_ref.current.table.current.grid.deselectAll();
                    // Remove all those rows from the list of ignored remarques
                    let selected_ids = all_current_rows.map(r => r._id);
                    ignored_rems_ids_ref.current = ignored_rems_ids_ref.current.filter(id => !selected_ids.includes(id));
                    // Update the state of the now selected rows
                    let updated_state = all_current_rows.map(r => ({ ...r, ignore: false }));
                    // Apply the changes to the grid
                    rem_ref.current.table.current.grid.applyTransaction({ update: updated_state });
                    // Create the updated consumption object
                    const new_consumption: T.Mission["consumption"] = { ...mission_consumption_ref.current, ignore_rems: ignored_rems_ids_ref.current };
                    // Save the changes in the database
                    S.saveMissionConsumption(new_consumption)
                        // Update the mission in the form
                        .then(() => mission_ref.current?.update?.({ consumption: new_consumption }, false))
                        .catch(e => {
                            M.Alerts.updateError(e);
                            // Reset the state of the row in the grid
                            rem_ref.current.table.current.grid.applyTransaction({ update: all_current_rows });
                            // Reset the list of ignored remarques
                            ignored_rems_ids_ref.current = og_list;
                        });
                },
            },
            {
                icon: { element: "<i class='fa fa-times me-2'></i>" },
                label: lg.getStaticText(TC.EPRA_TABLE_UNSELECT_DISPLAYED_ROWS),
                onClick: () => {
                    let og_list = ignored_rems_ids_ref.current;
                    // Select the filtered rows of the grid
                    rem_ref.current.table.current.grid.selectAllFiltered();
                    let all_current_rows = rem_ref.current.table.current.grid.getSelectedRows();
                    rem_ref.current.table.current.grid.deselectAll();
                    // Add all those rows to the list of ignored remarques
                    let selected_ids = all_current_rows.map(r => r._id);
                    ignored_rems_ids_ref.current = _.uniq(ignored_rems_ids_ref.current.concat(selected_ids));
                    // Update the state of the now selected rows
                    let updated_state = all_current_rows.map(r => ({ ...r, ignore: true }));
                    // Apply the changes to the grid
                    rem_ref.current.table.current.grid.applyTransaction({ update: updated_state });
                    // Create the updated consumption object
                    const new_consumption: T.Mission["consumption"] = { ...mission_consumption_ref.current, ignore_rems: ignored_rems_ids_ref.current };
                    // Save the changes in the database
                    S.saveMissionConsumption(new_consumption)
                        // Update the mission in the form
                        .then(() => mission_ref.current?.update?.({ consumption: new_consumption }, false))
                        .catch(e => {
                            M.Alerts.updateError(e);
                            // Reset the state of the row in the grid
                            rem_ref.current.table.current.grid.applyTransaction({ update: all_current_rows });
                            // Reset the list of ignored remarques
                            ignored_rems_ids_ref.current = og_list;
                        });
                },
            }
        ] as G.RemarquesTableProps<RemExtra>["buttons"],
        value_change: (params => {
            if (params.colDef.field === "ignore") return "processed";
            else return "to_process";
        }) as G.RemarquesTableProps<RemExtra>["onValueChange"],
    }), [lg]);
    //#endregion

    return <C.Flex direction="column" className="flex-grow-1">
        <C.Spinner status={status}>

            <BS.Row className="g-1">
                {TABS.map(tab => <BS.Col key={tab.key}>
                    <C.Button
                        size="sm"
                        text={tab.label}
                        className="w-100"
                        onClick={() => change_tab(tab.key)}
                        variant={tab.key === current_tab ? "primary" : "link"}
                    />
                </BS.Col>)}
            </BS.Row>

            {loaded_tabs.includes("mission") && <div className="flex-grow-1 my-3" hidden={current_tab !== "mission"}>
                <F.Missions.MissionForm
                    no_delete
                    ref={mission_ref}
                    navigate={navigate}
                    asset={mission_state.asset_id}
                    onSave={updated_mission.setFalse}
                    onChange={updated_mission.setTrue}
                    mission_id={mission_state.mission_id}
                />
            </div>}

            {loaded_tabs.includes("general") && <div className="flex-grow-1 my-3" hidden={current_tab !== "general"}>
                <General
                    data={mission_state.general}
                    asset_id={mission_state.asset_id}
                    read_only={mission_state.read_only}
                    update_data={data => set_mission_state(p => ({ ...p, general: data }))}
                />
            </div>}

            {loaded_tabs.includes("consumption") && <div className="flex-grow-1 my-3" hidden={current_tab !== "consumption"}>
                <Consumption
                    ref={consumption}
                    crrem={mission_state.crrem}
                    set_consumption={set_consumption}
                    asset_id={mission_state.asset_id}
                    read_only={mission_state.read_only}
                    consumption={mission_state.consumption}
                />
            </div>}

            {loaded_tabs.includes("equipments") && <div className="flex-grow-1 my-3" hidden={current_tab !== "equipments"}>
                <Equipments
                    set_extra={set_extra}
                    extra={mission_state.extra}
                    asset_id={mission_state.asset_id}
                    read_only={mission_state.read_only}
                />
            </div>}

            {/* Different handling than other tabs, because table still appears if flex is hidden */}
            {current_tab === "datasets" && <div className="flex-grow-1 my-3">
                <F.DataSets.DataPanel
                    no_link_panel
                    add_data_now_default
                    readOnly={mission_state.read_only}
                    context={{ roots: mission_state.asset_id }}
                    has_new_datasets={consumption.current?.reload_datasets}
                />
            </div>}

            <G.Remarques<RemExtra>
                ref={rem_ref}
                context={roots}
                className="flex-grow-1 my-3"
                buttons={rem_table_props.buttons}
                read_only={mission_state.read_only}
                hidden={current_tab !== "remarques"}
                extra_columns={rem_table_props.columns}
                onValueChange={rem_table_props.value_change}
            />

        </C.Spinner>
    </C.Flex>;
}

export default NRJ;