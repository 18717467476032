import { TB, TC } from "../../Constants";
import { Menu, MenuItem } from "@mui/material";
import { Alerts } from "../../Components/Modal";
import { useBoolean, useLanguage } from "../../hooks";
import { Divider, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { DataGrid as DG, DataGridProps as DGProps, GridPagination } from "@mui/x-data-grid";

//#region Types
export type { GridRenderCellParams } from "@mui/x-data-grid";
export type ColValueSetter<Row, Value = any> = (params: { row: Row, value: Value }) => Row;

type ActionItemProps = {
    /** The label of the action */
    name: string;
    /** An icon for the action */
    icon?: string;
    /** Callback for when the action is clicked */
    action?: () => void;
}

export type DataGridProps<Row extends { id: string } = { id: string }> = Omit<DGProps, "processRowUpdate"> & {
    /** The items of the table */
    rows: Row[];
    /** A list of actions to display in a context menu */
    getContextMenu?: (row: Row) => ("separator" | ActionItemProps)[];
    /** Callback for a changed value */
    processRowUpdate?: (newRow: Row, oldRow: Row) => Promise<Row> | Row;
    /** A callback to render the footer */
    footer?: (pagination: React.ReactElement) => React.ReactElement;
    /** A Callback to render content in a header */
    header?: React.ReactElement;
}

export type DataGridPaginationProps = {
    /** The current active page */
    page?: number;
    /** The number of items to show in a page */
    rowsPerPage?: number;
    /** The label of the pagination */
    labelRowsPerPage?: string;
    /** Callback for when the number of rows per page changes */
    onRowsPerPageChange?: (rows: number) => void;
    /** Callback for when the active page changes */
    onPageChange?: (page: number) => void;
    /** Options of rows per page */
    rowsPerPageOptions?: (number | { value: number; label: string; })[];
}

export type DataGridRef = {
    /** Manage the loading state */
    loading?: ReturnType<typeof useBoolean>;
}
//#endregion

/**
 * MUI DataGrid extension
 * @description A customizable data grid
 */
const DataGrid = React.forwardRef(<Row extends { id: string },>({ getContextMenu, onProcessRowUpdateError, footer, ...props }: DataGridProps<Row>, ref: React.ForwardedRef<DataGridRef>) => {
    const lg = useLanguage();
    const loading = useBoolean(false);
    const [selectedRow, setSelectedRow] = useState<Row["id"]>();
    const [tableState, setTableState] = useState({ rows: 100, page: 0 });
    const [contextMenu, setContextMenu] = useState<{ mouseX: number, mouseY: number }>(null);

    //#region Ref
    useEffect(() => {
        // Ref is a function
        if (typeof ref === "function") ref({ loading });
        else if (ref) {
            // Ref is already created
            if (ref.current) {
                ref.current.loading = loading;
            }
            // Ref needs to init
            else ref.current = { loading };
        }
    }, [loading, ref]);
    //#endregion

    //#region Language
    const columns = useMemo(() => (props.columns || []).map(c => ({ ...c, headerName: lg.getStaticText(c.headerName) })), [props.columns, lg]);
    //#endregion

    //#region Context Menu
    const onAction = useCallback((action: ReturnType<typeof getContextMenu>[number]) => {
        // Close context menu to avoid disturbance between menu and a potential modal
        setContextMenu(null);
        if (typeof action !== "string" && typeof action?.action === "function") action.action();
    }, []);

    const handleContextMenu = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        setSelectedRow(event.currentTarget.getAttribute('data-id'));
        setContextMenu({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    }, []);

    const contextMenuContent = useMemo(() => {
        if (!contextMenu) return [];
        let item = (props.rows || []).filter(r => r.id === selectedRow)[0];
        if (!item) return [];
        let actions = TB.getArray(getContextMenu?.(item));

        let key = 0;
        let content = [] as React.ReactElement[];

        for (let action of actions) {
            if (action === "separator") content.push(<Divider key={key} />);
            else content.push(<ActionItem {...action} key={key} action={() => onAction(action)} />);
            key++;
        }
        return content;
    }, [contextMenu, selectedRow, getContextMenu, onAction, props.rows]);
    //#endregion

    //#region Process row
    const onProcessUpdateError = useCallback(error => {
        if (typeof onProcessRowUpdateError === "function") onProcessRowUpdateError(error);
        else if (typeof error === "string") Alerts.updateError({ message: error });
        else if (TB.validObject(error)) Alerts.updateError(error);
    }, [onProcessRowUpdateError]);
    //#endregion

    //#region Pagination
    const pagination = useMemo(() => <DataGridPagination
        page={tableState.page}
        rowsPerPage={tableState.rows}
        onPageChange={page => setTableState(p => ({ ...p, page }))}
        onRowsPerPageChange={rows => setTableState(p => ({ ...p, rows }))}
    />, [tableState]);
    //#endregion

    return <>
        <DG
            {...props}
            columns={columns}
            loading={props.loading || loading.value}
            onProcessRowUpdateError={onProcessUpdateError}
            paginationModel={{ page: tableState.page, pageSize: tableState.rows }}
            slotProps={{
                ...props.slotProps,
                row: {
                    ...props.slotProps?.row,
                    onContextMenu: handleContextMenu,
                    style: {
                        ...props.slotProps?.row?.style,
                        cursor: 'context-menu'
                    },
                },
            }}
            slots={{
                ...props.slots,
                toolbar: props.header ? () => props.header : undefined,
                footer: () => typeof footer === "function" ? footer(pagination) : pagination,
            }}
        />

        {contextMenuContent.length > 0 && <Menu
            open={contextMenu !== null}
            anchorReference="anchorPosition"
            onClose={() => setContextMenu(null)}
            anchorPosition={contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
            slotProps={{
                root: {
                    onContextMenu: (e) => {
                        e.preventDefault();
                        setContextMenu(null);
                    },
                },
            }}
        >
            {contextMenuContent}
        </Menu>}
    </>;
});

const ActionItem: React.FC<ActionItemProps> = props => {
    const lg = useLanguage();

    return <MenuItem style={{ minWidth: "300px" }} onClick={props.action}>
        {props.icon && <ListItemIcon children={<i className={`fa fa-${props.icon}`} />} />}
        <ListItemText children={lg.getStaticElem(props.name)} />
    </MenuItem>;
}

export const DataGridPagination: React.FC<DataGridPaginationProps> = props => {
    const lg = useLanguage();

    return <GridPagination
        {...props}
        onPageChange={(event, page) => props.onPageChange?.(page)}
        labelRowsPerPage={lg.getStaticText(props.labelRowsPerPage || TC.DATAGRID_PAGINATION_LABEL)}
        onRowsPerPageChange={event => props.onRowsPerPageChange?.(TB.getNumber(event.target.value))}
    />;
}

DataGrid.displayName = "DataGrid";
export default DataGrid;