import React from "react";
import * as C from "../../Common";
import * as S from "../../services";
import { T, TB, TC } from "../../Constants";

//#region Types
type States = "none" | "sending" | "sended" | "failed";
type Idea = Parameters<T.API.Access.User.ContactSupport>[0];

export type SupportProps = {
    /** Callback that fires once the feedback has been processed */
    onSubmit?: () => void;
}
//#endregion

//#region Constants
const TYPE_OPTIONS: T.Option<object, Idea["type"]>[] = [
    { label: TC.SUPPORT_CAT_REQUEST, value: "request" },
    { label: TC.SUPPORT_CAT_ENERGY_PLAN, value: "energy_plan" },
    { label: TC.SUPPORT_CAT_BUG, value: "bug" },
    { label: TC.SUPPORT_CAT_OTHER, value: "other" },
];
//#endregion

const Support: React.FC<SupportProps> = ({ onSubmit }) => {
    const [state, setState] = React.useState<States>("none");
    const [error, setError] = React.useState<T.Errors<Idea>>({});
    const [idea, setIdea] = React.useState<Idea>({ type: "request", description: "", files: [] });

    React.useEffect(() => {
        // 2 seconds after validation, send submit
        if (state === "sended") setTimeout(onSubmit, 2000);
    }, [state, onSubmit]);

    const reach_support = React.useCallback(() => {
        let errors = {} as typeof error;

        // Needs at least a description
        if (idea.files.length > 4) errors.files = TC.SUPPORT_ERROR_MAX_FILES;
        if (!TB.validString(idea.description)) errors.description = TC.GLOBAL_REQUIRED_FIELD;
        // Found some errors
        if (Object.keys(errors).length > 0) setError(errors);
        // No Errors
        else {
            // Set loading state
            setState("sending");
            S.contact_support(idea)
                .then(() => setState("sended"))
                .catch(() => setState("failed"));
        }
    }, [idea]);

    const banner = React.useMemo(() => {
        if (state !== "sended" && state !== "failed") return <C.ErrorBanner type="info" textCode={TC.SUPPORT_FEEDBACK_INFO} />;
        else if (state === "failed") return <C.ErrorBanner type="danger" textCode={TC.GLOBAL_ERROR_OCCURRED} />;
        else return <C.ErrorBanner type="success" textCode={TC.SUPPORT_SUCCESS} />;
    }, [state]);

    return <div className="flex-grow-1">
        {banner}

        {state !== "sended" && <>
            <C.Form.Select
                value={idea.type}
                options={TYPE_OPTIONS}
                error={{ code: error.type }}
                label={TC.SUPPORT_FEEDBACK_TYPE}
                onChange={type => setIdea(p => ({ ...p, type }))}
            />
            <C.Form.TextField
                textArea
                rows={5}
                autoExpand
                value={idea.description}
                validate={{ required: true }}
                label={TC.SUPPORT_DESCRIPTION}
                error={{ code: error.description }}
                onChange={description => setIdea(p => ({ ...p, description }))}
            />
            <C.Form.FileUploader
                multiple
                value={idea.files}
                fileMaxSize="15MB"
                label={TC.SUPPORT_FILES}
                tooltip={TC.SUPPORT_FILE_TIP}
                error={{ code: error.files }}
                onChange={files => setIdea(p => ({ ...p, files }))}
            />

            <C.Flex justifyContent="end">
                <C.Button
                    text={TC.GLOBAL_SEND}
                    onClick={reach_support}
                    icon={{
                        icon: "paper-plane",
                        spinIcon: "spinner",
                        spin: state === "sending",
                    }}
                />
            </C.Flex>
        </>}
    </div>;
}

export default Support;