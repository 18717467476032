import React from "react";
import * as I from "../types";
import * as C from "../../../../Common";
import { TB } from "../../../../Constants";

export type Props = {
    /** The unit to display next to the number */
    unit?: string;
    /** Format the value */
    formatted?: boolean | "money";
    /** The max amount of digits to show */
    maxDigit?: number;
    /** The minimum value accepted when edited */
    min?: number;
    /** The maximum value accepted when edited */
    max?: number;
}

export const sort = TB.sortNumbers;
export const type = "agCustomNumber";

export const Cell = React.forwardRef<object, I.CellProps<Props>>((props, ref) => {
    const content = React.useMemo(() => {
        let is_group = props?.node?.group,
            params = props.colDef?.params || {},
            number = TB.getNumber(props.getValue?.()),
            max_digits = TB.getNumber(params.maxDigit);

        if (is_group) {
            // Maybe show nothing when grouped
            if (!params?.show_grouped) {
                // There might be an aggregate active
                if (typeof props.value === "number" && !isNaN(props.value)) number = props.value;
                else return "";
            }
            else {
                // Sum up all the values found in the group
                let field = props.colDef.field;
                let values = props.node.allLeafChildren.map(c => TB.getNumber(c?.data?.[field], 0));
                number = values.reduce((prev, current) => prev + current, 0);
            }
        }
        // Fix the amount of digits after the comma
        if (number && max_digits) number = parseFloat(number.toFixed(max_digits));
        // Format the number
        if (params.formatted === "money") return TB.moneyFormat(number) || null;
        else if (params.formatted) return TB.numberFormat(number, params?.unit) || null;
        else if (isNaN(number)) return null;
        else return number;
    }, [props]);

    return <div className="text-center" children={content} />;
});

export const EditCell = React.forwardRef<I.EditorRef<number>, I.EditorProps<Props>>(({ onValueChange, ...props }, ref) => {
    const input = React.useRef<C.Form.NumFieldRef>(null);
    React.useEffect(() => input?.current?.single?.current?.input?.current?.focus?.(), []);

    const change = React.useCallback((value?: number) => {
        let params = props.colDef?.params || {};
        if (typeof value !== "number" || isNaN(value)) onValueChange(NaN);
        else if (typeof params?.min === "number" && !isNaN(params.min) && value < params.min) onValueChange(params.min);
        else if (typeof params?.max === "number" && !isNaN(params.max) && value > params.max) onValueChange(params.max);
        else onValueChange(value);
    }, [props.colDef, onValueChange]);

    return <>
        <C.Form.NumField
            ref={input}
            noBottomMargin
            customClass="w-100 h-100"
            onChangeInput={change}
            value={props.value}
        />
    </>;
});

Cell.displayName = "AG_Number_Cell";
EditCell.displayName = "AG_Number_EditCell";