import _ from "lodash";
import React from "react";
import * as F from "../Form";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import * as DOM from "react-router-dom";
import Event from "../Calendar/Event/Event";
import * as US from "../../services/user.service";
import { RIGHTS, TC, FP, T, TB } from "../../Constants";
import { TicketSigner, TicketSignerProps } from "./TicketSigner";

//#region Types
type Params = { id?: string };

type Resource = {
    users: T.UserType[];
    forms: T.FormType[];
    tickets: T.TicketType[];
    event: T.EventType | null;
    locations: T.LocationType[];
    equipments: T.EquipmentType[];
    regActions: T.Submission<T.RegAction>[];
    status: "load" | "invalid" | "error" | "ready";
}
//#endregion

//#region Constants
const TEXT_CODES = [
    TC.GLOBAL_BEFORE, TC.GLOBAL_AFTER, TC.ER_CLOSED_BY, TC.ER_CLOSED_THE, TC.GLOBAL_SERVICE_WORKER,
    TC.P_CLOSE_TICKETS, TC.GLOBAL_LABEL_EQUIPMENT, TC.GLOBAL_LABEL_BUILD, TC.FLOOR, TC.LOCAL, TC.DURATION, TC.GT_CLOSE_TICKET,
    TC.GLOBAL_NAME, TC.GLOBAL_LABEL_SITE, TC.GLOBAL_LABEL_BUILD, TC.FLOOR, TC.GLOBAL_LABEL_EMPLACEMENT, TC.GLOBAL_LABEL_PARKING,
    TC.GLOBAL_START, TC.GLOBAL_END, TC.GLOBAL_DELETE, TC.ER_DATES, TC.ER_HOST, TC.ER_PARTICIPANTS, TC.GLOBAL_LOCATION, TC.TICKETS,
    TC.ER_EQUIP_FRAME, TC.ER_NOTE_FRAME, TC.ER_PICTURES, TC.ER_CLOSE_TICKET_FRAME, TC.ER_NO_TICKET_SELECTED, TC.ER_NO_ASSOCIATED_EQUIPMENT,
];

const DATE_FORMAT = "DD/MM/YY HH:mm";
const DF_RESOURCE: Resource = { status: "load", regActions: [], equipments: [], event: null, forms: [], locations: [], tickets: [], users: [] };
//#endregion

const EventCloser: React.FC = () => {
    const rights = H.useRights();
    H.useCrumbs(TC.P_CLOSE_TICKETS);
    const [{ userId }] = H.useAuth();
    const navigate = DOM.useNavigate();
    const { id } = DOM.useParams<Params>();
    const showAllTicketModal = H.useBoolean(false);
    const [idTicket, setIdTicket] = React.useState<string>();
    const [activeKey, setActiveKey] = React.useState("equip");
    const { getStaticElem, getStaticText } = H.useLanguage(TEXT_CODES);
    const [docList, setDocList] = React.useState<TicketSignerProps["docList"]>([]);
    const [{ status, event, regActions, users, locations, tickets, equipments }, setResource] = React.useState<Resource>(DF_RESOURCE);

    //#region Param Validation
    React.useEffect(() => {
        if (!TB.mongoIdValidator(id)) navigate("/errors/404");
    }, [id, navigate]);
    //#endregion

    //#region Rights
    const canDelete = React.useMemo(() => {
        if (event?.owner === userId) return rights.isRightAllowed(RIGHTS.ACTION.WRITE_EVENTS);
        return rights.isRightAllowed(RIGHTS.ACTION.WRITE_OTHER_EVENTS);
    }, [rights, userId, event?.owner]);

    const canClose = React.useMemo(() => canDelete, [canDelete]);
    //#endregion

    //#region Data Fetch
    React.useEffect(() => {
        let isSubscribed = true;
        if (TB.mongoIdValidator(id)) US.getFullResources(FP.EVENTS_FORM, { eventId: id }).then(({ data }) => {
            if (isSubscribed) {
                if (data?.hasFailed) {
                    if (data?.error?.isInvalid) setResource({ ...DF_RESOURCE, status: "invalid" })
                    else setResource({ ...DF_RESOURCE, status: "error" });
                }
                else setResource({ ...data, status: "ready" });
            }
        }).catch(() => alert("error"));
        return () => { isSubscribed = false; }
    }, [id]);
    //#endregion

    //#region Error Banner
    const errorBanner = React.useMemo(() => {
        if (status === "invalid") return <C.ErrorBanner type="warning" textCode={TC.ER_EVENT_NOT_EXISTS} />
        if (status === "error") return <C.ErrorBanner type="danger" textCode={TC.GLOBAL_FAILED_LOAD} />
        if (status === "load") return <M.Loader />
        return null;
    }, [status]);
    //#endregion

    //#region Data find
    const findUserName = React.useCallback((id?: string) => _.find(users, u => u._id === id)?.data?.name, [users]);
    const buildingList = React.useMemo(() => _.flatten(locations.map(l => TB.getArray(l.building).map(b => b?.data?.name))).filter(TB.validString), [locations]);

    const getTicketStruct = React.useCallback((ticket: T.TicketType) => {
        let equipment = _.find(equipments, e => e._id === ticket.data.equipment);
        let location = _.find(locations, l => l.id === equipment?._id);
        let findLoc = (prop: string) => _.get(location, prop + ".0.data.name");

        return [
            { label: TC.GLOBAL_LABEL_EQUIPMENT, value: equipment?.data?.name, isName: true },
            { label: TC.GLOBAL_LABEL_BUILD, value: findLoc("building") },
            { label: TC.FLOOR, value: findLoc("emplacement") },
            { label: TC.LOCAL, value: findLoc("local") },
            { label: TC.DURATION, value: ticket.data.duration },
            { label: TC.GLOBAL_SERVICE_WORKER, value: TB.getArray(ticket.data.technicians).map(findUserName).filter(TB.validString) }
        ].map(col => ({ ...col, label: getStaticText(col.label) }));
    }, [equipments, locations, findUserName, getStaticText]);
    //#endregion

    //#region Selected Ticket
    const selectedTicket = React.useMemo(() => _.find(tickets, t => t._id === idTicket), [tickets, idTicket]);
    const ticketEquip = React.useMemo(() => _.find(equipments, e => e._id === selectedTicket?.data?.equipment), [equipments, selectedTicket]);

    const [beforePic, afterPic] = React.useMemo(() => {
        return ["before", "after"].map(prop => TB.getArray(selectedTicket?.data?.[prop]).filter(TB.isFile).map(f => ({ src: f.url, title: f.originalName })));
    }, [selectedTicket]);

    const ticketHasPictures = React.useMemo(() => beforePic.length > 0 || afterPic.length > 0, [beforePic, afterPic]);

    const updateTickets = React.useCallback((tickets: T.TicketType[]) => {
        let newTicketsIds = tickets.map(t => t._id);
        setResource(p => ({
            ...p,
            tickets: p.tickets.map(t => newTicketsIds.includes(t._id) ? _.find(tickets, ti => ti._id === t._id) || t : t)
        }))
    }, []);

    React.useEffect(() => {
        if (!TB.mongoIdValidator(idTicket) && tickets.length > 0) setIdTicket(_.find(tickets, t => TB.mongoIdValidator(t._id))?._id);
    }, [idTicket, tickets]);
    //#endregion

    //#region Event Panel
    const deleteEvent = React.useCallback(() => {
        M.askConfirm({ text: TC.EVENT_DELETE_TICKETS_WARNING }).then(confirmed => {
            if (confirmed) S.deleteEvents(id)
                .then(() => navigate("/home"))
                .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_DELETE }));
        });
    }, [navigate, id]);

    const eventPanel = React.useMemo(() => <BS.Card className="h-100">
        <BS.Card.Header>
            <BS.Card.Title>{event?.data?.name}</BS.Card.Title>
        </BS.Card.Header>
        <BS.Card.Body>
            <BS.Card.Subtitle>{getStaticElem(TC.ER_DATES)}</BS.Card.Subtitle>
            <div className="mt-1 mb-3 fs-85">
                {TB.getArray(event?.data?.calendar).map((cal, i) => <C.Flex key={i} wrap="wrap" className="flex-grow-1" justifyContent="between" alignItems="center">
                    <div>
                        <label className="mb-0">{getStaticElem(TC.GLOBAL_START)}</label>
                        <div>{TB.formatDate(cal.start, DATE_FORMAT)}</div>
                    </div>
                    <div>
                        <label className="mb-0">{getStaticElem(TC.GLOBAL_END)}</label>
                        <div>{TB.formatDate(cal.end, DATE_FORMAT)}</div>
                    </div>
                </C.Flex>)}
            </div>

            <BS.Card.Subtitle>{getStaticElem(TC.ER_HOST)}</BS.Card.Subtitle>
            <BS.Card.Text>{findUserName(event?.data?.host)}</BS.Card.Text>

            <BS.Card.Subtitle>{getStaticElem(TC.ER_PARTICIPANTS)}</BS.Card.Subtitle>
            <BS.Stack className="mt-1 mb-3">
                {TB.getArray(event?.data?.attendees).map(findUserName).filter(TB.validString).map((name, i) => <BS.Card.Text key={i}>{name}</BS.Card.Text>)}
            </BS.Stack>

            <BS.Card.Subtitle>{getStaticElem(TC.GLOBAL_LOCATION)}</BS.Card.Subtitle>
            <BS.Stack className="mt-1 mb-3">
                {buildingList.map((name, i) => <BS.Card.Text key={i}>{name}</BS.Card.Text>)}
            </BS.Stack>
        </BS.Card.Body>
        {canDelete && <BS.Card.Footer>
            <C.Button onClick={deleteEvent} className="w-100" variant="danger" icon="trash" text={TC.GLOBAL_DELETE} />
        </BS.Card.Footer>}
    </BS.Card>, [event, buildingList, canDelete, getStaticElem, deleteEvent, findUserName]);
    //#endregion

    //#region Ticket Panel
    const nonSignedTicket = React.useMemo(() => tickets.filter(t => !TB.mongoIdValidator(t.data.signedBy)), [tickets]);

    const signAllTicket = React.useCallback((newTickets: Parameters<TicketSignerProps["onSubmit"]>[0]) => {
        if (newTickets !== null) updateTickets(newTickets);
        showAllTicketModal.setFalse();
    }, [showAllTicketModal, updateTickets]);

    const ticketPanel = React.useMemo(() => <BS.Card className="h-100">
        <BS.Card.Header>
            <BS.Card.Title>{getStaticElem(TC.TICKETS)}</BS.Card.Title>
        </BS.Card.Header>
        <BS.Card.Body className="overflow-auto">
            <BS.Stack>
                {/* @ts-ignore */}
                {tickets.map((t, i) => <Event
                    key={i}
                    draggable={false}
                    structs={getTicketStruct(t)}
                    onClick={() => setIdTicket(t._id)}
                    color={t._id === idTicket ? "#2C7BE5" : undefined}
                    title={(TB.mongoIdValidator(t.data.signedBy) ? "[V] " : "") + t.data.title}
                />)}
            </BS.Stack>
        </BS.Card.Body>
        {canClose && nonSignedTicket.length > 0 && <BS.Card.Footer>
            <C.Button className="w-100" onClick={showAllTicketModal.setTrue} icon="signature" text={TC.P_CLOSE_TICKETS} />
        </BS.Card.Footer>}
    </BS.Card>, [getStaticElem, getTicketStruct, showAllTicketModal, tickets, nonSignedTicket, canClose, idTicket])
    //#endregion

    //#region SubPanels
    const equipmentPanel = React.useMemo(() => {
        if (!TB.mongoIdValidator(selectedTicket?._id) || !ticketEquip) {
            let errorMessage = TB.mongoIdValidator(selectedTicket?._id) ? TC.ER_NO_ASSOCIATED_EQUIPMENT : TC.ER_NO_TICKET_SELECTED;

            return <C.Flex className="mt-5" alignItems="center" justifyContent="center">
                <span className="h5">{getStaticElem(errorMessage)}</span>
            </C.Flex>;
        }

        let location = _.find(locations, l => l.id === ticketEquip._id);
        let findLoc = (prop: string) => _.get(location, prop + ".0.data.name");

        let pictures = TB.getArray(ticketEquip.data.pictures).filter(TB.isFile);

        let equipData = [
            { label: TC.GLOBAL_NAME, value: ticketEquip.data.name },
            { label: TC.GLOBAL_LABEL_SITE, value: findLoc("site") },
            { label: TC.GLOBAL_LABEL_BUILD, value: findLoc("building") },
            { label: TC.FLOOR, value: findLoc("emplacement") },
            { label: TC.GLOBAL_LABEL_EMPLACEMENT, value: findLoc("local") },
            { label: TC.GLOBAL_LABEL_PARKING, value: findLoc("parking") },
        ].filter(d => TB.validString(d.value));

        return <>
            {equipData.map(({ label, value }) => <React.Fragment key={label}>
                <BS.Card.Subtitle>{getStaticElem(label)}</BS.Card.Subtitle>
                <BS.Card.Text>{value}</BS.Card.Text>
            </React.Fragment>)}
            {pictures.length > 0 && <C.PictureGrid title={TC.OBST_PIC} images={pictures.map(f => ({ src: f.url, title: f.originalName }))} />}
        </>
    }, [selectedTicket, ticketEquip, locations, getStaticElem]);

    const notePanel = React.useMemo(() => <F.NoteManager origin={idTicket} />, [idTicket]);

    const picturePanel = React.useMemo(() => {
        let dataRender = [{ value: beforePic, label: TC.GLOBAL_BEFORE }, { value: afterPic, label: TC.GLOBAL_AFTER }].filter(({ value }) => value.length > 0);
        return <>{dataRender.map(({ value, label }) => <C.PictureGrid images={value} title={label} key={label} />)}</>
    }, [afterPic, beforePic]);

    const closePanel = React.useMemo(() => {
        if (!TB.mongoIdValidator(selectedTicket?.data?.signedBy)) return <TicketSigner
            docList={docList}
            actions={regActions}
            setDocList={setDocList}
            onSubmit={updateTickets}
            tickets={[selectedTicket].filter(TB.isTicket)}
        />

        let equipData = [
            { label: TC.ER_CLOSED_BY, value: findUserName(selectedTicket?.data?.signedBy) },
            { label: TC.ER_CLOSED_THE, value: TB.formatDate(selectedTicket?.data?.dateSignature, DATE_FORMAT) },
            { label: TC.ER_SIGNATURE, src: selectedTicket?.data?.signature },
        ].filter(d => TB.validString(d.value) || TB.validString(d.src));

        return <>
            {equipData.map(({ label, value, src }) => <React.Fragment key={label}>
                <BS.Card.Subtitle>{getStaticElem(label)}</BS.Card.Subtitle>
                {TB.validString(value) && <BS.Card.Text>{value}</BS.Card.Text>}
                {TB.validString(src) && <BS.Figure.Image src={src} />}
            </React.Fragment>)}</>
    }, [selectedTicket, regActions, docList, updateTickets, getStaticElem, findUserName]);
    //#endregion

    //#region Selected Ticket Panel
    const tabsList = React.useMemo(() => {
        return [
            { title: TC.ER_EQUIP_FRAME, eventKey: "equip", content: equipmentPanel, footer: <></> },
            { title: TC.ER_NOTE_FRAME, eventKey: "note", content: notePanel, disabled: !TB.mongoIdValidator(idTicket) },
            { title: TC.ER_PICTURES, eventKey: "pic", content: picturePanel, disabled: !ticketHasPictures, },
            { title: TC.ER_CLOSE_TICKET_FRAME, eventKey: "close", content: activeKey === "close" ? closePanel : <></>, disabled: !canClose }
        ]
            .map(tab => ({ ...tab, key: tab.eventKey, title: getStaticText(tab.title) }))
    }, [getStaticText, canClose, equipmentPanel, idTicket, notePanel, picturePanel, closePanel, activeKey, ticketHasPictures]);

    const selectedTicketPanel = React.useMemo(() => <BS.Card className="h-100">
        <BS.Tabs fill activeKey={activeKey} onSelect={k => setActiveKey(k)}>
            {tabsList.map(({ content, footer, ...tab }) => <BS.Tab key={tab.key} {...tab}>
                <BS.Card.Body children={content} />
                {footer && <BS.Card.Footer>{footer}</BS.Card.Footer>}
            </BS.Tab>)}
        </BS.Tabs>
    </BS.Card>, [tabsList, activeKey]);
    //#endregion

    //#region Overflow
    const overflowColStyle = React.useMemo(() => ({ maxHeight: "90vh" }), []);
    //#endregion

    return <>
        {errorBanner}
        {status === "ready" && <>
            {showAllTicketModal.value && <TicketSigner
                popUp
                docList={docList}
                actions={regActions}
                setDocList={setDocList}
                onSubmit={signAllTicket}
                tickets={nonSignedTicket}
                onClose={showAllTicketModal.setFalse}
            />}
            <BS.Row className="w-100 g-2 mb-2">
                <BS.Col style={overflowColStyle} md={3}>{eventPanel}</BS.Col>
                <BS.Col style={overflowColStyle} md={3}>{ticketPanel}</BS.Col>
                <BS.Col style={overflowColStyle} md={6}>{selectedTicketPanel}</BS.Col>
            </BS.Row>
        </>}
    </>;
}

export default EventCloser;