import React from "react";
import { TB } from "../../../Constants";
import { useBoolean } from "../../../hooks";
import { ComponentWrapper, InputProps } from "../Input";
import { Button, Form, InputGroup } from "react-bootstrap";

export type PasswordProps = InputProps & {
    /** If true, the onChange callback will be call after each value update, not just after onBlur */
    uncontrolled?: boolean;
};

export type PasswordRef = {
    /** Get the controlled value of the input */
    get_controlled: () => string;
}

const PasswordField = React.forwardRef<PasswordRef, PasswordProps>((props, ref) => {
    const showClear = useBoolean(false);
    const [controlled, setControlled] = React.useState('');

    React.useEffect(() => {
        if (!TB.isBCryptHash(props.value) && typeof props.value === "string") setControlled(props.value);
    }, [props.value]);

    React.useImperativeHandle(ref, () => ({
        get_controlled: () => controlled,
    }), [controlled]);

    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    return <ComponentWrapper {...props} disabled={isDisabled}>
        <InputGroup>
            <Form.Control
                value={controlled}
                disabled={isDisabled}
                readOnly={props.readonly}
                type={showClear.value ? "textField" : "password"}
                onBlur={() => !props.uncontrolled && props.onChange?.(controlled)}
                onChange={e => props.uncontrolled ? props.onChange?.(e.target.value) : setControlled(e.target.value)}
            />
            <Button variant="secondary" size="sm" onClick={showClear.toggle}>
                <i className={`fa fa-${showClear.value ? "eye-slash" : "eye"}`}></i>
            </Button>
        </InputGroup>
    </ComponentWrapper>
});

PasswordField.displayName = "PasswordField";
export default PasswordField;