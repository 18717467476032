import React from "react";
import * as DnD from "react-dnd";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import { T, TB, TC } from "../../../Constants";

//#region Types
type Dataset = Element["datasets"][number];
type Category = T.Mission["structure"]["categories"][number];
type DatasetItem = Category["elements"][number]["datasets"][number];
type Indexed<A extends Record<string, any>> = A & { index: number };
type Element = ReturnType<T.API.Utils.Missions.GetMissionDatasets>[number];

type DatasetSelectProps = {
    /** The current categories layout */
    categories: Category[];
    /** Callback to handle categories change */
    onChange: (categories: DatasetSelectProps["categories"]) => void;
    /** The list of elements to offer as options */
    elements: Element[];
}
type CategoryProps = {
    /** The category item */
    category: Category;
    /** The current category index */
    index: number;
    /** Callback to handle the movement of a dataset */
    onMove_category: (params: Indexed<Category>, new_index: number) => void;
    /** Callback to handle the movement of an element, new means it's in a new category */
    onMove_element: (params: Indexed<Element> & Record<"category", string>, new_index: number, new_cat: string) => void;
    /** Callback to handle the movement of a dataset */
    onMove_dataset: (params: Indexed<Dataset> & Record<"element" | "category", string>, new_index: number) => void;
    /** The whole list of elements */
    elements: Element[];
    /** Shortcut to toggle a dataset's activation */
    toggle_dataset: (value: "none" | DatasetItem["value_type"], dataset: string, element: string, category: string) => void;
}
type ElementProps = {
    /** The element item */
    element: Element;
    /** The element's current category id */
    category_id: Category["value"];
    /** Index of the place in the current category */
    index: number;
    /** Callback to handle the movement of a dataset */
    onMove_dataset: CategoryProps["onMove_dataset"];
    /** Callback to handle the movement of an element */
    onMove_element: CategoryProps["onMove_element"];
    /** The active datasets for this element */
    activeDatasets: DatasetItem[];
    /** Shortcut to toggle a dataset's activation */
    toggle_dataset: CategoryProps["toggle_dataset"];
}
type DatasetProps = {
    /** The dataset item */
    dataset: Dataset;
    /** The element's current element id */
    element_id: Element["id"];
    /** Is the dataset active ? */
    value?: "none" | DatasetItem["value_type"];
    /** The index the dataset is in, at the element level */
    index: number;
    /** The id of the current item's category */
    category_id: Category["value"];
    /** Callback to handle the movement of a dataset */
    onMove_dataset: CategoryProps["onMove_dataset"];
    /** Shortcut to toggle a dataset's activation */
    toggle_dataset: CategoryProps["toggle_dataset"];
}

const Options = [
    { label: TC.CDM_DATA_DATASET_NOT_SELECTED, value: "none" },
    { label: TC.CDM_DATA_DATASET_INDEX, value: "index" },
    { label: TC.CDM_DATA_DATASET_DELTA, value: "delta" },
    { label: TC.CDM_DATA_DATASET_DELTA_INDEX, value: "index_delta" },
] as T.Option<object, DatasetProps["value"]>[];
//#endregion

const DatasetSelect: React.FC<DatasetSelectProps> = props => {
    const move = React.useMemo(() => ({
        category: ((params, new_index) => {
            let new_cat = props.categories || [];
            if (params.value !== "none" && new_cat?.[new_index]?.value !== "none") {
                let to_index = params.index < new_index ? new_index + 1 : new_index;
                new_cat = [...TB.moveElement([...new_cat], params.index, to_index)];
                props.onChange(new_cat);
            }
        }) as CategoryProps["onMove_category"],
        element: ((params, new_index, new_cat) => {
            let new_categories = props.categories || [];
            // Moved the element in a new category
            if (new_cat !== params.category) {
                // Find the element from the old category
                let old_cat = new_categories.filter(c => c.value === params.category)[0];
                let elem = old_cat?.elements?.filter(e => e.id === params.id)[0];

                // Remove the elem from the old category and add it to the new one
                if (elem) new_categories = new_categories.map(c => {
                    // Remove from the old category
                    if (c.value === params.category) return { ...c, elements: c.elements.filter(e => e.id !== params.id) };
                    // Add to the new category
                    if (c.value === new_cat) return { ...c, elements: c.elements.concat(elem) };
                    // None of the above
                    return c;
                });
            }
            // Element moved but still inside it's category
            else {
                // Don't know what's going on, I never want to touch this code again
                let from = params.index,
                    to = params.index < new_index ? new_index + 1 : new_index;

                new_categories = new_categories.map(cat => {
                    // Not the category searched for
                    if (cat.value !== params.category) return cat;
                    // The category searched for
                    else return { ...cat, elements: [...TB.moveElement([...cat.elements], from, to)] };
                });
            }
            props.onChange(new_categories);
        }) as CategoryProps["onMove_element"],
        dataset: ((params, new_index) => {
            let new_cat = props.categories || [];
            // Edit because when drop on an item, you want to take it's place more than it's index (not clear sorry)
            let to_index = params.index < new_index ? new_index + 1 : new_index;

            // Update category
            new_cat = new_cat.map(cat => {
                // Not the category searched for
                if (cat.value !== params.category) return cat;
                // The correct category
                else return {
                    ...cat,
                    elements: cat.elements.map(el => {
                        // Not the element searched for
                        if (el.id !== params.element) return el;
                        // The correct element
                        else return { ...el, datasets: TB.moveElement(el.datasets, params.index, to_index) };
                    })
                };
            })

            props.onChange(new_cat);
        }) as CategoryProps["onMove_dataset"],
    }), [props]);

    const toggle_dataset = React.useCallback<CategoryProps["toggle_dataset"]>((value, dataset, element, category) => {
        let new_cat = props.categories || [];

        // Unselect the dataset
        if (value === "none") new_cat = new_cat.map(cat => {
            // No the edited category
            if (cat.value !== category) return cat;
            // The edited category
            else return {
                ...cat,
                // Edit the elements
                elements: cat.elements.map(el => {
                    // Not the edited element
                    if (el.id !== element) return el;
                    // Remove the dataset from the selected list
                    else return { ...el, datasets: el.datasets.filter(d => d._id !== dataset) };
                })
                    // Remove the element if it contains no activated datasets
                    .filter(el => el.datasets.length > 0)
            }
        });
        // Select or update the dataset value
        else {
            // The category exists
            if (new_cat.some(c => c.value === category)) new_cat = new_cat.map(cat => {
                if (cat.value !== category) return cat;
                // The element exists
                if (cat.elements.some(e => e.id === element)) return {
                    ...cat,
                    elements: cat.elements.map(el => {
                        if (el.id !== element) return el;
                        // The dataset is already in the array
                        else if (el.datasets.some(d => d._id === dataset)) return {
                            ...el,
                            datasets: el.datasets.map(d => d._id === dataset ? { ...d, value_type: value } : d),
                        }
                        // Dataset is not already in the array, so add it
                        else return { ...el, datasets: el.datasets.concat({ _id: dataset, value_type: value }) };
                    })
                }
                // Add non-existent element
                else return { ...cat, elements: cat.elements.concat({ id: element, datasets: [{ _id: dataset, value_type: value }] }) };
            });
            // Add non-existent category
            else new_cat = new_cat.concat({ name: "N/A", value: category, elements: [{ id: element, datasets: [{ _id: dataset, value_type: value }] }] });
        }
        // Update the structure
        props.onChange(new_cat);
    }, [props]);

    const categories = React.useMemo(() => {
        let categories = (props.categories || [])//.filter(c => c.value !== "none");
        // Add the unassigned elements to the "none" default category
        let og_none = categories.filter(c => c.value === "none")[0];
        let none_category = og_none
            // Clone the og cat to avoid reference problems by the "push" below
            ? { ...og_none, elements: [...og_none.elements] }
            : { name: TC.CDM_DATA_DEFAULT_CAT, value: "none", elements: [] };
        // Remove temporarily the "none" category, to make sure it is at the bottom
        categories = categories.filter(c => c.value !== "none");

        for (let elem of props.elements) {
            let is_assigned = (props.categories || []).some(c => c.elements.some(e => e.id === elem.id));
            if (!is_assigned) none_category.elements.push({ id: elem.id, datasets: [] });
        }

        return categories.concat(none_category);
    }, [props.categories, props.elements]);

    return <div>
        {categories.map((cat, i) => <DragCategory
            index={i}
            category={cat}
            key={cat.value}
            elements={props.elements}
            onMove_dataset={move.dataset}
            onMove_element={move.element}
            onMove_category={move.category}
            toggle_dataset={toggle_dataset}
        />)}
    </div>;
}

const DragCategory: React.FC<CategoryProps> = props => {
    const lg = H.useLanguage();

    const [{ over }, drop] = DnD.useDrop({
        accept: ['element', "category"],
        canDrop: (item: any) => item?.value !== "none",
        collect: monitor => ({ over: monitor.isOver() }),
        drop: (element: any, monitor) => {
            if (monitor.getItemType() === "element") props.onMove_element(element, 0, props.category.value);
            else props.onMove_category(element, props.index);
        }
    });

    const [{ isDragging }, drag] = DnD.useDrag({
        type: "category",
        canDrag: props.category.value !== "none",
        item: { ...props.category, index: props.index },
        collect: monitor => ({ isDragging: monitor.isDragging() }),
    });

    const element_list = React.useMemo(() => {
        return props.category.elements
            .map(e => props.elements.filter(el => el.id === e.id)[0] || null)
            .filter(e => e !== null);
    }, [props.category.elements, props.elements]);

    const style = React.useMemo<React.CSSProperties>(() => {
        // This item is currently hovered
        if (over) {
            // The default category, is off limit
            if (props.category.value === "none") return { border: "1px red solid", background: "red", opacity: "0.25" };
            else return { border: "1px lightblue solid", background: "lightblue", opacity: "0.25" };
        }
    }, [over, props.category.value]);

    if (props.category.value === "none" && props.category.elements.length === 0) return null;
    return <div ref={drag as any}>
        <div ref={drop as any} style={style}>
            <div className="fs-150 fw-bold">{props.category.value === "none" ? lg.getStaticText(TC.CDM_DATA_DEFAULT_CAT) : props.category.name}</div>
            {element_list.length === 0 && <C.CenterMessage className="my-2" children={TC.WARNING_NO_SUB_ELEM} />}
        </div>
        <div className={isDragging ? "bg-success" : ""}>
            {element_list.map((element, i) => (
                <DragElement
                    index={i}
                    key={element.id}
                    element={element}
                    category_id={props.category.value}
                    onMove_element={props.onMove_element}
                    toggle_dataset={props.toggle_dataset}
                    onMove_dataset={props.onMove_dataset}
                    activeDatasets={props.category.elements.filter(e => e.id === element.id)[0]?.datasets || []}
                />
            ))}
        </div>
    </div>;
};

const DragElement: React.FC<ElementProps> = props => {
    const ref = React.useRef<HTMLDivElement>(null);
    const active = React.useMemo(() => Array.isArray(props.activeDatasets) && props.activeDatasets.length > 0, [props.activeDatasets]);

    const [{ over }, drop] = DnD.useDrop({
        accept: 'element',
        canDrop: () => active,
        collect: monitor => ({ over: monitor.isOver() }),
        drop: (element: Parameters<CategoryProps["onMove_element"]>[0]) => props.onMove_element(element, props.index, props.category_id),
    });

    const [{ dragging }, drag] = DnD.useDrag({
        type: "element",
        collect: monitor => ({ dragging: monitor.isDragging() }),
        canDrag: active,
        item: ({
            ...props.element,
            index: props.index,
            category: props.category_id,
        }) as Parameters<CategoryProps["onMove_element"]>[0],
    });

    const style = React.useMemo<React.CSSProperties>(() => {
        // An element is hovering over this element
        if (over) {
            if (!active) return {
                cursor: "not-allowed",
                border: "1px dotted red",
                opacity: over ? "0.25" : undefined,
                backgroundColor: over ? "red" : undefined,
            }
            else return {
                border: "1px solid lightblue",
                opacity: over ? "0.25" : undefined,
                backgroundColor: over ? "lightblue" : undefined,
            }
        }
        // The dataset is currently inactive
        if (!active) return { cursor: "not-allowed", border: "1px dotted red" };
        // Dataset is currently dragged
        if (dragging) return { border: "1px solid lightblue" };
        // Nothing
        return { cursor: "grab" };
    }, [active, dragging, over]);

    const ordered_datasets = React.useMemo(() => {
        let ordered = [] as typeof props.element.datasets;

        // Add the active dataset in the order given
        for (let dataset_item of props.activeDatasets) {
            let dataset = props.element.datasets.filter(d => d.id === dataset_item._id)[0];
            if (dataset) ordered.push(dataset);
        }
        // Add the missing dataset
        for (let dataset of props.element.datasets) {
            // Is not an active one, so add because not already in from previous loop
            if (!props.activeDatasets.some(d => d._id === dataset.id)) ordered.push(dataset);
        }
        return ordered;
    }, [props]);

    drag(drop(ref));

    return <div className="mb-2 p-1" ref={ref} style={style}>
        <span className="ms-2 fs-110">
            <i className="fa fa-cog me-2"></i>
            {props.element.name}
            {props.element.location && <span className="text-muted fs-85 ms-2">({props.element.location})</span>}
        </span>
        <div>
            {ordered_datasets.map((dataset, index) => <DragDataset
                index={index}
                key={dataset.id}
                dataset={dataset}
                element_id={props.element.id}
                category_id={props.category_id}
                toggle_dataset={props.toggle_dataset}
                onMove_dataset={props.onMove_dataset}
                value={props.activeDatasets.filter(d => d._id === dataset.id)[0]?.value_type || "none"}
            />)}
        </div>
    </div>;
};

const DragDataset: React.FC<DatasetProps> = props => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [{ over }, drop] = DnD.useDrop({
        accept: 'dataset_' + props.element_id,
        collect: monitor => ({ over: monitor.isOver() }),
        drop: (dataset: Parameters<CategoryProps["onMove_dataset"]>[0]) => props.onMove_dataset(dataset, props.index),
    });

    const [{ dragging }, drag] = DnD.useDrag({
        canDrag: props.value !== "none",
        type: 'dataset_' + props.element_id,
        collect: monitor => ({ dragging: monitor.isDragging() }),
        item: {
            ...props.dataset,
            index: props.index,
            element: props.element_id,
            category: props.category_id,
        } as Parameters<CategoryProps["onMove_dataset"]>[0],
    });

    const available_options = React.useMemo(() => {
        if (props.dataset.src === "calculated") return Options.filter(o => o.value !== "index" && o.value !== "index_delta");
        else if (!props.dataset.cumulated) return Options.filter(o => o.value !== "index" && o.value !== "index_delta");
        else return Options;
    }, [props.dataset.cumulated, props.dataset.src]);

    drag(drop(ref));

    const style = React.useMemo<React.CSSProperties>(() => {
        // An element is hovering over this element
        if (over) {
            if (props.value === "none") return {
                cursor: "not-allowed",
                border: "1px dotted red",
                opacity: over ? "0.25" : undefined,
                backgroundColor: over ? "red" : undefined,
            }
            else return {
                border: "1px solid lightblue",
                opacity: over ? "0.25" : undefined,
                backgroundColor: over ? "lightblue" : undefined,
            }
        }
        // The dataset is currently inactive
        if (props.value === "none") return { cursor: "not-allowed", border: "1px dotted red" };
        // Dataset is currently dragged
        if (dragging) return { border: "1px solid lightblue" };
        // Nothing
        return { cursor: "grab" };
    }, [props.value, dragging, over]);

    return <div className="ms-4 p-1 mb-1" style={style} ref={ref}>
        <C.Flex justifyContent="between">
            <span className="fs-85">
                <i className="fa fa-bar-chart me-2"></i>
                {props.dataset.name}
            </span>

            <C.Form.Select
                no_clear_btn
                noBottomMargin
                value={props.value}
                options={available_options}
                onChange={value => props.toggle_dataset(value, props.dataset.id, props.element_id, props.category_id)}
            />
        </C.Flex>
    </div>;
};

export default DatasetSelect;