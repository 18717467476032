import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { FP, T, TB, TC } from "../../../Constants";
import * as US from "../../../services/user.service";

export type ToolTipProps = {
    /** The state of the certification */
    last_certif?: T.FormConsumerData["last_certification"];
    /** Data about the last edit of the value */
    last_edit?: T.FormContext["previous_edits"][number];
    /** A string version of the current value */
    str_value?: string;
    /** The real value saved */
    value?: any;
    /** The _id of the item that will have a value certified */
    item_id: string;
    /** A context to search documents from */
    context: T.ContextParams;
    /** The prop of the item that will be certified */
    prop: string;
    /** The form id of the item */
    form: string;
    /** Callback after a certification was created */
    onCertification?: (certification: T.Certification) => void;
}

type DocumentData = { regDoc?: T.RegDoc, regAction?: T.Submission<T.RegAction> };

const ToolTip: React.FC<ToolTipProps> = ({ onCertification, ...props }) => {
    const lg = H.useLanguage();
    const [{ user }] = H.useAuth();
    const open_tip = H.useBoolean(false);
    const container_ref = React.useRef<HTMLDivElement>(null);
    const [document, set_document, doc_status] = H.useAsyncState<DocumentData>(null);

    H.useOnClickOutside(container_ref, open_tip.setFalse);

    const toggle_tip = React.useCallback(() => {
        if (open_tip.value) open_tip.setFalse();
        else {
            open_tip.setTrue();
            // Load the document if there is one
            if (document === null && props.last_certif?.document) {
                US.getFullResources(FP.ACTION_REG_FORM, { regFormId: props.last_certif.document, isRegForm: true })
                    .then(({ data }) => set_document(data, "done"))
                    .catch(() => set_document(null, "error"));
            }
        }
    }, [document, open_tip, props.last_certif, set_document]);

    const events = React.useMemo(() => ({
        render_history: () => M.renderCertHistory({ item: props.item_id, prop: props.prop }),
        open_document: () => M.renderRegDoc({ regFormId: props.last_certif.document, title: TC.CERT_PROOF_DOCUMENT }),
        render_certif_form: () => {
            let is_value_empty = props.value === null || props.value === undefined || props.value === "" || (Array.isArray(props.value) && props.value.length === 0);
            if (is_value_empty) M.renderAlert({ type: "warning", message: TC.NO_CERTIFY_EMPTY });
            else M.renderCertForm({ item_id: props.item_id, context: props.context, form: props.form, prop: props.prop, str_value: props.str_value, value: props.value })
                .then(certification => certification && onCertification?.(certification));
        },
        open_files: () => {
            let files = props.last_certif?.files || [];
            M.renderAlert({ type: "info", message: TC.ZIP_FILE_GENERATION, })
            S.createZip({ name: "test.zip", content: files.map(f => ({ type: "file", originalName: f.originalName, url: f.url })) })
                .catch(M.Alerts.file_generation_fail);
        },
    }), [onCertification, props.context, props.form, props.item_id, props.last_certif, props.prop, props.str_value, props.value]);

    const state = React.useMemo(() => {
        /** A forced string conversion of the data as it's stored in the database */
        let db_string_current_val = TB.valueToString(props.value);
        /** Was the value certified, either in the database, or waiting for saving */
        let is_certified = props.last_certif?.value === db_string_current_val;
        /** Was the value certified, and already in the database */
        let is_certification_saved = is_certified && TB.mongoIdValidator(props.last_certif?._id);
        /** Is the value considered 'missing' ? */
        let is_value_empty = props.value === null || props.value === undefined || props.value === "" || (Array.isArray(props.value) && props.value.length === 0);

        let tip_texts = "";
        if (is_certified) {
            tip_texts += lg.getStaticText(TC.CERT_CERTIFIED_THE, moment(props.last_certif.createdAt).format("DD/MM/YYYY HH:mm")) + " ";
            tip_texts += lg.getStaticText(TC.CERT_EDITED_BY, (props.last_certif as any).owner_name || user?.data?.name || "N/A");
            if (props.last_edit?.mission_name) tip_texts += " " + lg.getStaticText(TC.CERT_EDITED_FOR, props.last_edit.mission_name);
        }
        else if (props.last_edit) {
            tip_texts += lg.getStaticText(TC.CERT_EDITED_THE, props.last_edit.date_str) + " ";
            tip_texts += lg.getStaticText(TC.CERT_EDITED_BY, props.last_edit.user_name);
            if (props.last_edit.mission_name) tip_texts += " " + lg.getStaticText(TC.CERT_EDITED_FOR, props.last_edit.mission_name);
        }
        else tip_texts = lg.getStaticText(TC.NOT_CERTIFIED_YET);

        /** Wether or not the history button option should be visible */
        let show_history = !!props.last_edit;
        /** Whether or not the certify button should be visible */
        let show_certify_button = !is_value_empty;
        /** Wether or not to display a border under the text */
        let show_border = show_history || show_certify_button;

        let content = <div style={{ maxWidth: "20rem" }}>
            <div className={show_border ? "border-bottom border-white pb-2" : ""}>
                <div children={tip_texts} />
                {is_certification_saved && <div className="my-1">
                    <C.Flex justifyContent="between" alignItems="center">
                        <div className="me-2 text-start fw-bold" children={lg.getStaticText(TC.CERT_FORM_DATE) + " :"} />
                        <div className="text-end" children={moment(props.last_certif.data_date).format("DD/MM/YYYY HH:mm")} />
                    </C.Flex>
                    {props.last_certif.description && <C.Flex justifyContent="between" alignItems="center">
                        <div className="me-2 text-start fw-bold" children={lg.getStaticText(TC.CERT_FORM_DESCRIPTION) + " :"} />
                        <div className="text-end" children={props.last_certif.description} />
                    </C.Flex>}
                    {props.last_certif.document && <C.Spinner status={doc_status}>
                        <C.Flex justifyContent="between" alignItems="center">
                            <div className="me-2 text-start fw-bold" children={lg.getStaticText(TC.CERT_PROOF_DOCUMENT) + " :"} />
                            <div
                                onClick={events.open_document}
                                className="text-end btn-link pointer"
                                children={lg.getTextObj(document?.regAction?._id, "name", document?.regAction?.data?.name)}
                            />
                        </C.Flex>
                    </C.Spinner>}
                    {props.last_certif.files.length > 0 && <C.Flex justifyContent="between" alignItems="center">
                        <div className="me-2 text-start fw-bold" children={lg.getStaticText(TC.CERT_FORM_FILE) + " :"} />
                        <div
                            onClick={events.open_files}
                            className="text-end btn-link pointer"
                            children={lg.getStaticText(TC.CERT_NB_FILES, props.last_certif.files.length)}
                        />
                    </C.Flex>}
                </div>}
            </div>

            {show_history && <div className="text-start">
                <C.Button
                    size="sm"
                    icon="history"
                    variant="link"
                    className="color-white"
                    onClick={events.render_history}
                    text={TC.CERT_HISTORY_EDIT_HISTORY}
                />
            </div>}

            {show_certify_button && <div className="text-start">
                <C.Button
                    size="sm"
                    variant="link"
                    icon="check-circle"
                    className="color-white"
                    onClick={events.render_certif_form}
                    text={is_certified ? TC.CERT_CLICK_TO_RECERTIFY : TC.CERT_CLICK_TO_CERTIFY}
                />
            </div>}
        </div>;

        // Value is certified & already saved in database
        if (is_certification_saved) return { color: "green", variant: "success", content, icon: "check-circle" };
        // Value is certified but not saved in database yet
        else if (is_certified) return { color: "orange", variant: "warning", content, icon: "hourglass-half" };
        // Value is not certified
        else return { icon: "info-circle", content, variant: "secondary" };
    }, [lg, props.last_certif, props.value, props.last_edit, user?.data?.name, doc_status, document, events]);

    return <div className="d-inline-block" ref={container_ref}>
        <C.IconTip
            icon={state.icon}
            onClick={toggle_tip}
            show={open_tip.value}
            tipContent={state.content}
            className={`pointer text-${state.variant} me-2`}
            title={lg.getStaticText(TC.GLOBAL_CLICK_TO_SHOW_MORE)}
        />
    </div>;
}

export default ToolTip;