import { TB } from "../../../Constants";
import renderInContainer from "../getContainer";
import { Prompt, PromptProps } from "../Components";

export type AskPromptModalProps = Omit<PromptProps, "onClose" | "onConfirm">;

const askPrompt = (params?: AskPromptModalProps) => new Promise<Parameters<PromptProps["onConfirm"]>[0]>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};

    if (render && dismount) {
        let onClose = () => dismount?.(() => resolve(null));
        let onConfirm: PromptProps["onConfirm"] = text => dismount?.(() => resolve(text));
        let default_val = params.defaultVal;
        if (params.mail && typeof default_val === "string") default_val = default_val.toLowerCase();
        render(<Prompt {...params} onClose={onClose} onConfirm={onConfirm} />);
    }
    else resolve(null);
});

export default askPrompt;