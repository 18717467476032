import _ from "lodash";
import { T, TB } from "../Constants";

//#region Types
type ContextElem = T.TakeArrayElement<T.FormContextState>;
type PartialContextElem = { key: string, context: Partial<T.FormContext> };
type FormIdsRedux = T.ReduxFn<T.FormContextState, T.FormContextActions>;

type RemoveFormStore = T.ReduxDispatch<T.FormContextActions, string>;
type SetFormStore = T.ReduxDispatch<T.FormContextActions, ContextElem>;
type UpdateFormStore = T.ReduxDispatch<T.FormContextActions, PartialContextElem>;
//#endregion

const formContext: FormIdsRedux = (state = [], payload) => {
    const { type, action } = TB.getObject(payload);

    const addStore = (item: ContextElem) => state.concat(item);
    const removeStore = (key: string) => state.filter(s => s.key !== key);

    const updateStore = (item: PartialContextElem) => state.map(s => {
        if (s.key !== item.key) return s;

        const removeErrors = (prop: string) => {
            const toRemoveKeys = Object.keys(s.context.errors).filter(key => {
                if (key === prop) return true;
                const removeIndexFromKey = key.substring(0, key.lastIndexOf("."));
                return removeIndexFromKey === prop;
            });
            toRemoveKeys.forEach(key => s.context.errors = _.omit(s.context.errors, key));
        }

        if (item.context.updates) {
            for (const u of item.context.updates) {
                removeErrors(u.prop);
                if (s.context.updates.filter(su => su.prop === u.prop).length > 0) s.context.updates = s.context.updates.map(su => su.prop === u.prop ? u : su);
                else s.context.updates = s.context.updates.concat(u);
            }
        }
        if (item.context.options) {
            for (const o of item.context.options) {
                if (s.context.options.filter(so => so.prop === o.prop).length > 0) s.context.options = s.context.options.map(so => so.prop === o.prop ? o : so);
                else s.context.options = s.context.options.concat(o);
            }
        }
        if (item.context.hidden) {
            for (const h of item.context.hidden) {
                if (s.context.hidden.filter(sh => sh.prop === h.prop).length > 0) s.context.hidden = s.context.hidden.map(sh => sh.prop === h.prop ? h : sh);
                else s.context.hidden = s.context.hidden.concat(h);
            }
        }
        if (item.context.hideAddButton) {
            for (const h of item.context.hideAddButton) {
                if (s.context.hideAddButton.filter(sh => sh.prop === h.prop).length > 0) s.context.hideAddButton = s.context.hideAddButton.map(sh => sh.prop === h.prop ? h : sh);
                else s.context.hideAddButton = s.context.hideAddButton.concat(h);
            }
        }
        if (item.context.certif) {
            for (const c of item.context.certif) {
                if (s.context.certif.filter(sc => sc.prop === c.prop).length > 0) s.context.certif = s.context.certif.map(sc => sc.prop === c.prop ? c : sc);
                else s.context.certif = s.context.certif.concat(c);
            }
        }
        if (item.context.translations) {
            const keys = ["created", "edited", "loaded"] as (keyof T.FormContext["translations"])[];
            s.context.translations = { ...s.context.translations };
            for (const key of keys) {
                for (const t of item.context.translations[key]) {
                    if (s.context.translations[key].filter(sc => sc.prop === t.prop).length > 0) s.context.translations[key] = s.context.translations[key].map(sc => sc.prop === t.prop ? t : sc);
                    else s.context.translations[key] = s.context.translations[key].concat(t);
                }
            }
        }
        if (item.context.previous_edits) {
            for (const e of item.context.previous_edits) {
                if (s.context.previous_edits.filter(se => se.property === e.property).length > 0) s.context.previous_edits = s.context.previous_edits.map(se => se.property === e.property ? e : se);
                else s.context.previous_edits = s.context.previous_edits.concat(e);
            }
        }
        if (item.context.descriptions) {
            for (const e of item.context.descriptions) {
                if (s.context.descriptions.filter(se => se.prop === e.prop).length > 0) s.context.descriptions = s.context.descriptions.map(se => se.prop === e.prop ? e : se);
                else s.context.descriptions = s.context.descriptions.concat(e);
            }
        }


        if (typeof item.context.readOnly === "boolean") s.context.readOnly = item.context.readOnly;
        if (item.context.partial_certif) s.context.partial_certif = item.context.partial_certif;


        return {
            key: s.key,
            context: {
                ...s.context,
                data: item.context?.data || s.context.data,
                errors: item.context.errors || s.context.errors
            }
        };
    });

    switch (type) {
        case "SET_FORM_STORE": return addStore(action);
        case "REMOVE_FORM_STORE": return removeStore(action);
        case "UPDATE_FORM_STORE": return updateStore(action);
        default: return state;
    }
}

export default formContext;

export const setFormStore: SetFormStore = store => ({ type: "SET_FORM_STORE", action: store });
export const removeFormStore: RemoveFormStore = key => ({ type: "REMOVE_FORM_STORE", action: key });
export const updateFormStore: UpdateFormStore = store => ({ type: "UPDATE_FORM_STORE", action: store });