import * as T from "../Types";
import * as R from "./Rights";
import * as TC from "../textCode";
import * as FP from "./FormPaths";

export const RoleIntervenantsOptions = [
    { label: TC.MISSION_INTERV_CONSULTANT, value: "advisor" },
    { label: TC.MISSION_INTERV_FACILITY, value: "facility_mg" },
    { label: TC.MISSION_INTERV_EXPLOITER, value: "maintenance" },
    { label: TC.MISSION_INTERV_OTHER, value: "other" },
    { label: TC.MISSION_INTERV_MANAGER, value: "owner" },
    { label: TC.MISSION_INTERV_PROPERTY, value: "property_mg" },
] as T.Option<object, T.Mission_Intervenant["role"]>[];

export const EPRA_DATA = [
    { value: "elec_abs", label: "4.1 Elec-Abs, 4.2 Elec-LfL", data_type: ["ELEC", "ELEC_HEAD", "ELEC_PPV", "ELEC_PPV_HEAD"] },
    { value: "dhc_abs", label: "4.3 DH&C-Abs, 4.4 DH&C-LfL", data_type: ["THCH", "THCH_HEAD", "THFR", "THFR_HEAD"] },
    { value: "fuel_abs", label: "4.5 Fuel-Abs, 4.6 Fuel-LfL", data_type: ["FUEL", "FUEL_HEAD", "GAS", "GAS_HEAD"] },
    { value: "water_abs", label: "4.11. Water-Abs, 4.12. Water-LfL", data_type: ["WATER", "WATER_HEAD"] },
    { value: "waste_abs", label: "4.14. Waste-Abs, 4.15. Waste-LfL", data_type: "WASTE" },
] as T.Option<Record<"data_type", T.AllowArray<T.DataSet["type"]>>, keyof T.Mission["epra"]["categories"]>[];

export const EPRA_REPARTITION_DATA = [
    { value: "energy", label: "4.7 Energy-Int", src: ["elec_abs", "dhc_abs", "fuel_abs"] },
    { value: "ghg_dir_abs", label: "4.8 GHG-Dir-Abs", src: ["fuel_abs"] },
    { value: "ghg_indir_abs", label: "4.9 GHG-Indir-Abs", src: ["elec_abs", "dhc_abs", "fuel_abs"] },
    { value: "ghg_int", label: "4.10 GHG-Int", src: ["elec_abs", "dhc_abs", "fuel_abs"] },
    { value: "water_int", label: "4.13 Water-Int", src: ["water_abs"] },
] as T.Option<Record<"src", (keyof T.Mission["epra"]["categories"])[]>, keyof T.Mission["epra"]["repartition"]>[];

/** A dictionary to get the label of a mission type, for the mission report name */
export const MISSION_REPORT_LABEL: Record<T.Mission["type"], string> = {
    nrj: TC.MISSION_TYPES_NRJ,
    cdm: TC.MISSION_TYPES_CDM,
    epra: TC.MISSION_TYPES_EPRA,
    rsc01: TC.MISSION_TYPE_RSC01,
    edl_tech: TC.MISSION_TYPES_EDL,
    edl_reg: TC.MISSION_TYPE_EDL_REG,
    red_flag: TC.MISSION_TYPES_RED_FLAG,
    eu_taxonomy: TC.MISSION_TYPE_EU_TAXONOMY,
}

export const MissionTypes = [
    { label: TC.MISSION_TYPES_CDM, value: "cdm", allowed_asset: [FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_CDM_MISSIONS, report_right: R.MISSION.ALLOW_CDM_MISSIONS_REPORT },
    { label: TC.MISSION_TYPES_NRJ, value: "nrj", allowed_asset: [FP.BUILDING_FORM], right: R.MISSION.ALLOW_NRJ_MISSIONS, report_right: R.MISSION.ALLOW_NRJ_MISSIONS_REPORT },
    { label: TC.MISSION_TYPES_EDL, value: "edl_tech", allowed_asset: [FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_EDL_MISSIONS, report_right: R.MISSION.ALLOW_EDL_MISSIONS_REPORT },
    { label: TC.MISSION_TYPES_RED_FLAG, value: "red_flag", allowed_asset: [FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_RED_FLAG_MISSIONS, report_right: R.MISSION.ALLOW_RED_FLAG_MISSIONS_REPORT },
    { label: TC.MISSION_TYPES_REG, value: "edl_reg", allowed_asset: [FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_REG_MISSIONS, report_right: R.MISSION.ALLOW_REG_MISSIONS_REPORT },
    { label: TC.MISSION_TYPE_EU_TAXONOMY, value: "eu_taxonomy", allowed_asset: [FP.BUILDING_FORM], right: R.MISSION.ALLOW_EU_TAXONOMY_MISSIONS, report_right: R.MISSION.ALLOW_EU_TAXONOMY_MISSIONS_REPORT },
    { label: TC.MISSION_TYPE_RSC01, value: "rsc01", allowed_asset: [FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_RSC01_MISSIONS, report_right: R.MISSION.ALLOW_RSC01_MISSIONS_REPORT },
    { label: TC.MISSION_TYPES_EPRA, value: "epra", allowed_asset: [FP.CLIENT_FORM, FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_EPRA_MISSIONS, report_right: R.MISSION.ALLOW_EPRA_MISSIONS_REPORT },
] as T.Option<Record<"allowed_asset", string[]> & Record<"right" | "report_right", string>, T.Mission["type"]>[];