import React from "react";
import * as H from "../../hooks";
import * as BS from "react-bootstrap";
import { T, TB } from "../../Constants";
import IconTip, { IconTipProps } from "./IconTip";

export type ButtonProps = BS.ButtonProps & {
    /** Button's Icon props or just an icon class */
    icon?: string | IconTipProps;
    /** The content to display inside the button */
    children?: string | number | T.AllowArray<React.ReactElement>;
    /** Content of the tooltip */
    tip?: string | T.AllowArray<React.ReactElement>;
    /** Text content of the button */
    text?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ icon, children, tip, text, ...props }, ref) => {
    const lg = H.useLanguage();

    const iconTipProps = React.useMemo<IconTipProps>(() => typeof icon === "string" ? { icon } : icon, [icon]);

    const tipProps = React.useMemo(() => {
        if (!tip) return { active: false };
        return {
            active: true,
            render: (p => <BS.Tooltip {...p} className="z-index-higher-modal">
                {typeof tip === "string" ? lg.getStaticText(tip) : tip}
            </BS.Tooltip>) as BS.OverlayTriggerProps["overlay"],
        }
    }, [tip, lg]);

    const button = React.useMemo(() => <BS.Button ref={ref} {...props} className={(props.className || "") + (props.variant === "link" ? " false-link" : "")}>
        {iconTipProps && <IconTip {...iconTipProps} className={children || text ? "me-2" : ""} />}
        {TB.validString(text)
            ? lg.getStaticText(text)
            : typeof children === "string"
                ? lg.getStaticText(children)
                : children}
    </BS.Button>, [iconTipProps, lg, children, props, ref, text]);

    if (tipProps.active) return <BS.OverlayTrigger trigger={["hover", "focus"]} overlay={tipProps.render} children={button} />
    return button;
});

Button.displayName = "Button";
export default Button;