import React from "react";
import * as H from "../../../hooks";
import * as N from "../../Navigator";
import * as BS from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { TC, FP, T, TB } from "../../../Constants";

const CrumbsMenu: React.FC = () => {
    const lg = H.useLanguage([]);
    const { isMobile } = H.useWindowSize();
    const crumbs = useSelector((redux: T.ReduxSelector) => redux.crumbs);
    const validCrumbs = React.useMemo(() => TB.isValidCrumb(crumbs) ? crumbs : undefined, [crumbs]);

    const homeCrumbs = React.useMemo(() => <BS.Breadcrumb.Item as="div" children={<N.Navigator />} />, []);

    const crumbSort = React.useCallback((a: T.CrumbsRedux, b: T.CrumbsRedux) => {
        if (a.isContext && b.isContext) return 0;
        if (b.isContext) return 1;
        if (a.isContext) return -1;
        return 0;
    }, []);

    const crumbsArray = React.useMemo(() => {
        let array: T.CrumbsRedux[] = [];

        const recursive = (crumb?: T.CrumbsRedux) => {
            if (TB.isValidCrumb(crumb)) {
                array.push(crumb);
                recursive(crumb.child);
            }
        }
        recursive(validCrumbs);
        return array;
    }, [validCrumbs]);

    const textCodesArray = React.useMemo(() => Object.keys(TC).concat(FP.RESOURCE_FORMS), []);

    const restCrumbs = React.useMemo(() => crumbsArray
        .sort(crumbSort)
        .map(({ link, ...r }) => ({ ...r, link: TB.validString(link) ? link : undefined }))
        .map(({ label, link }, i) => {
            let okLabel = textCodesArray.includes(label) ? lg.getStaticText(label) : label;
            if (TB.validString(link)) return <BS.Breadcrumb.Item key={i} linkAs={Link} linkProps={{ to: link }} children={okLabel} />;
            return <BS.Breadcrumb.Item key={i} children={okLabel} />;
        }), [crumbsArray, textCodesArray, crumbSort, lg]);

    if (isMobile) return homeCrumbs;
    return <BS.Breadcrumb style={{ fontSize: "1.3rem" }} >
        {homeCrumbs}
        {restCrumbs}
    </BS.Breadcrumb>;
}

export default CrumbsMenu;