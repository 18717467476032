import React from "react";
import { useLanguage } from "../../hooks";
import { ReduxWrapper } from "../../Common";
import renderInContainer from "./getContainer";
import { TC, LANG, TB, EC } from "../../Constants";

//#region Types
type ErrorModalFnProps = {
    message?: string;
    errorCode?: number;
    language?: number;
}

interface ErrorModalProps extends ErrorModalFnProps {
    onQuit?: () => void;
}
//#endregion

//#region Constants
const TEXT_CODES = [TC.GLOBAL_FAILED_LOAD, TC.GLOBAL_ERROR_UPDATE, TC.GLOBAL_ERROR_DELETE, TC.GLOBAL_ERROR_OCCURRED];
//#endregion

const ErrorModal: React.FC<ErrorModalProps> = ({ errorCode = EC.CODE_LOAD_DATA_FAIL, message, language = LANG.FR, onQuit, ...props }) => {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null);
    const { getStaticText } = useLanguage(TEXT_CODES);

    //#region Quit
    React.useEffect(() => wrapperRef.current?.focus?.(), []);
    const quit = React.useCallback(() => typeof onQuit === "function" ? onQuit() : window.location.replace("/"), [onQuit]);
    const escListener = React.useCallback(({ code, keyCode }) => code === "Escape" || keyCode === 27 ? quit() : undefined, [quit]);
    //#endregion

    //#region Message
    const errorMessage = React.useMemo(() => {
        if (message) return getStaticText(message);
        switch (errorCode) {
            case EC.CODE_LOAD_DATA_FAIL: return getStaticText(TC.GLOBAL_FAILED_LOAD);
            case EC.CODE_DB_UPDATE_FAIL: return getStaticText(TC.GLOBAL_ERROR_UPDATE);
            case EC.CODE_DB_DELETE_FAIL: return getStaticText(TC.GLOBAL_ERROR_DELETE);
            default: return getStaticText(TC.GLOBAL_ERROR_OCCURRED);
        }
    }, [getStaticText, errorCode, message]);
    //#endregion

    return <div ref={wrapperRef} className="loader_bg_modal error_dark z-index-higher-loader" tabIndex={0} onKeyDown={escListener}>
        <div className="bg-white w-50 rounded">
            <div className="alert alert-danger m-0 d-flex justify-content-between align-items-center">
                <span className="flex-grow-1">{errorMessage}</span>
                <button onClick={quit} className="btn-none"><i className="fa fa-times p-1"></i></button>
            </div>
        </div>
    </div>
}

export default ErrorModal;

export const renderErrorModal = (params?: ErrorModalFnProps) => new Promise<null>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};
    if (render && dismount) {
        let onQuit = () => dismount(() => resolve(null));
        render(<ReduxWrapper children={<ErrorModal {...params} onQuit={onQuit} />} />);
    }
    else resolve(null);
});