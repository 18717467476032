import React from "react";
import * as M from "../BlankModal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { T, TB, TC } from "../../../Constants";

export type SankeyProps = {
    /** The current context */
    context: T.ContextParams;
    /** Callback to quit the modal */
    quit?: () => void;
    /** Extra params to style the modal */
    modal?: M.StyleModalProps;
}

const Sankey: React.FC<SankeyProps> = props => {
    const [error, set_error] = React.useState("");
    const [graph, set_graph, status] = H.useAsyncState("");
    const [time, set_time] = React.useState<T.NRJ.TimeSelection>({ interval: "7 DAY" });

    React.useEffect(() => {
        let isSubscribed = true;
        let period = TB.getFromTo(time);
        S.getSankey({ context: props.context, begin: period.from, end: period.to, is_origin: false })
            .then(({ data }) => {
                if (isSubscribed) {
                    set_error("");
                    set_graph(data, "done");
                }
            })
            .catch(error => {
                if (isSubscribed) {
                    set_graph("", "error");
                    set_error(error?.response?.data || error?.message);
                }
            });
        return () => {
            set_error("");
            isSubscribed = false;
            set_graph("", "load");
        }
    }, [time, set_graph, props.context]);

    const reset_legend = React.useCallback(() => {
        document.querySelector<HTMLButtonElement>("#reset_legend_sankey")?.click?.();
    }, []);

    return <M.default
        {...props.modal}
        onQuit={props.quit}
        size={props.modal?.size || "lg"}
        title={props.modal?.title || TC.DATA_ORGANIZER_SANKEY_TITLE}
        isFullScreen={typeof props.modal?.isFullScreen === "boolean" ? props.modal.isFullScreen : true}
    >
        <C.Flex>
            <C.TimeSelector
                to={time.to}
                from={time.from}
                interval={time.interval}
                onChangeDatePicker={time => set_time(time)}
                onChangeInterval={interval => set_time({ interval })}
            />

            <C.Button
                icon="history"
                className="ms-2"
                onClick={reset_legend}
                variant="outline-primary"
                text={TC.SANKEY_RESET_LEGEND}
            />
        </C.Flex>

        <C.Spinner min_load_size="700px" status={status} error={error}>
            <C.HtmlText html={graph} />
        </C.Spinner>
    </M.default>;
}

export default Sankey;