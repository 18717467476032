import { useMemo } from "react";
import { useSelector } from "react-redux";
import { T } from "../Constants";

const useFormIds = () => {
    const forms = useSelector((redux: T.ReduxSelector) => redux.formIds);

    const pathToId = useMemo<Record<string, string>>(() => {
        const entries = forms.map(f => [f.path, f._id]);
        return Object.fromEntries(entries);
    }, [forms]);

    const idToPath = useMemo<Record<string, string>>(() => {
        const entries = forms.map(f => [f._id, f.path]);
        return Object.fromEntries(entries);
    }, [forms]);

    return [
        /** An object { [path]: _id } */
        pathToId,
        /** An object { [_id]: path } */
        idToPath
    ];
}

export default useFormIds;