import _ from "lodash";
import React from "react";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import { TC } from "../../../Constants";
import { Dialog } from "@material-ui/core";

export type CarouselProps = {
    /** The list of images to display */
    images?: Record<"title" | "src", string>[];
    /** The default image's source */
    defaultSrc?: string;
    /** The default image's title */
    defaultTitle?: string;
    /** Callback to quit the modal */
    onQuit?: () => void;
}

const Carousel: React.FC<CarouselProps> = props => {
    const lg = H.useLanguage();
    const img_loaded = H.useBoolean(false);
    const failed_load = H.useBoolean(false);
    const [index, set_index] = React.useState(0);

    React.useEffect(() => {
        // Find the default image to show
        let wanted_image = -1;
        // Check if an image match the default title
        if (props.defaultTitle) wanted_image = _.findIndex(props.images, i => i?.title === props.defaultTitle);
        // Check if an image match the default source
        else if (props.defaultSrc) wanted_image = _.findIndex(props.images, i => i?.src === props.defaultSrc);
        // Found a default image to display
        if (wanted_image >= 0) set_index(wanted_image);
    }, [props.defaultSrc, props.defaultTitle, props.images]);

    const move_index = React.useMemo(() => ({
        last_index: Array.isArray(props.images) ? props.images.length - 1 : 0,
        left: (event?: React.MouseEvent) => {
            img_loaded.setFalse();
            failed_load.setFalse();
            event?.stopPropagation?.();
            set_index(previous => {
                // We currently are a the first picture, so move to the last
                if (previous === 0) return move_index.last_index;
                // Simply go back one index
                else return previous - 1;
            });
        },
        right: (event?: React.MouseEvent) => {
            img_loaded.setFalse();
            failed_load.setFalse();
            event?.stopPropagation?.();
            set_index(previous => {
                // We currently are a the last picture, so move to the first one
                if (previous === move_index.last_index) return 0;
                // Simply advance to the next one
                else return previous + 1;
            });
        },
    }), [props.images, failed_load, img_loaded]);

    const current_image = React.useMemo(() => props.images?.[index] || { src: "", title: "" }, [props.images, index]);

    const onKeyDown = React.useCallback<React.KeyboardEventHandler<HTMLDivElement>>(event => {
        if (event.key === "ArrowLeft" || event.keyCode === 37) move_index.left();
        else if (event.key === "ArrowRight" || event.keyCode === 39) move_index.right();
        else if (event.key === "Escape" || event.keyCode === 27) props.onQuit?.();
    }, [move_index, props]);

    const render_switcher = React.useCallback((move_to_next = false) => {
        // Only one image found, no need to show the switchers
        if (!Array.isArray(props.images) || props.images.length <= 1) return null;
        return <C.Flex alignItems="center" justifyContent="center" className="h-100 w-100 text-white">
            <C.IconButton
                onClick={move_to_next ? move_index.right : move_index.left}
                icon={`caret-${move_to_next ? "right" : "left"} fa-3x p-5`}
            />
        </C.Flex>
    }, [move_index, props.images]);

    return <Dialog
        open
        fullWidth
        maxWidth="xl"
        className="img_modal"
        onKeyDown={onKeyDown}
        onClose={props.onQuit}
        onClick={props.onQuit}
    >

        {/* Close button */}
        <button
            onClick={props.onQuit}
            className="btn-none close_button text-white"
            children={<i className="fa fa-times fa-2x p-2" />}
        />

        <BS.Row className="w-100 g-1">
            <BS.Col>
                <C.Flex alignItems="center" justifyContent="center">
                    {failed_load.value
                        ? <span children={lg.getStaticText(TC.IMG_FAIL_LOAD)} />
                        : <>
                            {!img_loaded.value && <C.Spinner min_load_size="200px" color="light" size="lg" loading />}
                            <img
                                key={current_image.src}
                                src={current_image.src}
                                alt={current_image.title}
                                hidden={!img_loaded.value}
                                onLoad={img_loaded.setTrue}
                                onError={failed_load.setTrue}
                                style={{ maxHeight: "75vh", maxWidth: "75vw" }}
                            />
                        </>}
                </C.Flex>

                <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}>{render_switcher()}</div>
                <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>{render_switcher(true)}</div>
            </BS.Col>
        </BS.Row>
        <BS.Row className="w-100 g-1 mt-2 text-white fs-130">
            <BS.Col>
                <C.Flex justifyContent="center">
                    {current_image?.title} ({index + 1}/{move_index.last_index + 1})
                </C.Flex>
            </BS.Col>
        </BS.Row>

    </Dialog>;
}

const WrappedCarousel: React.FC<CarouselProps> = props => <C.ReduxWrapper children={<Carousel {...props} />} />;
export default WrappedCarousel;