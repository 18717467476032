import axios from "axios";
import { getHeader } from "../Headers";
import { T, APIS } from "../../Constants";

const { EXCEL_API } = APIS;

//#region Types
type ImportTrad = (items: unknown[]) => T.AxiosPromise<unknown>;
type JSONTradImport = (formData: FormData) => T.AxiosPromise<unknown>;
type ExcelImport<A = undefined> = (values: T.AnyObject[], params?: A) => T.AxiosPromise<{ inserted: number, createdIds: string[] }>;
//#endregion

export const importTrad: ImportTrad = (items) => axios.post(EXCEL_API + "lang", { items }, getHeader("ADMIN"));
export const importJsonTrad: JSONTradImport = (formData) => axios.post(EXCEL_API + "json/lang", formData, getHeader("ADMIN"));
export const importRegActions: ExcelImport = (values) => axios.post(EXCEL_API + "regActions", { values }, getHeader("ADMIN"));