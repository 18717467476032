import axios from "axios";
import jwt from "jsonwebtoken";
import { LANG, T, TB } from "../Constants";

//#region Auto Set Headers
axios.interceptors.request.use(config => {
    if (config.url === "https://atmdata.be/auth") return config;

    let expired = false;
    const userStr = localStorage.getItem("formioUser");
    try {
        const user = JSON.parse(userStr);
        // Load the current mission_id
        const mission_id = sessionStorage.getItem("mission_id");
        // FR_FR because it is default options on login page when nothing selected
        const lang = localStorage.getItem("lang") || LANG.FR_PROP;
        const token = localStorage.getItem("formioToken");
        let decodedStrToken = jwt.decode(token);
        // If the token is not valid, set it to an empty object
        if (TB.validString(decodedStrToken)) decodedStrToken = {};
        const decodedToken = TB.getObject<T.API.Token>(decodedStrToken, { isAdmin: false });
        // Check if the token is expired, unless we are on the login page
        if (window.location.pathname !== "/login" && decodedToken.exp < Date.now() / 1000) {
            // Token is expired, set the expired flag to true
            expired = true;
            throw new Error("Token expired");
        }
        else {
            const headers = config.headers;

            if (headers) headers.lang = lang;
            if (user && headers) headers.user_id = user?._id;
            if (headers) headers.is_admin = decodedToken?.isAdmin;
            if (token && headers) headers["x-access-token"] = token;
            if (mission_id && headers) headers.mission_id = mission_id;
            if (!TB.validString(headers?.request_type)) headers.request_type = "READ";

            config.headers = headers;
            return config;
        }
    }
    catch (error) {
        // If the token is expired, redirect to the login page
        if (expired) {
            // Clean the localStorage
            localStorage.removeItem("formioUser");
            localStorage.removeItem("formioToken");
            // Clean the sessionStorage
            sessionStorage.removeItem("mission_id");
            // Redirect to the login page
            window.location.href = "/login";
        }
        return config;
    }
});

axios.interceptors.response.use(config => {
    const strToken = config.headers?.token_update;
    if (TB.validString(strToken)) localStorage.setItem("formioToken", strToken);
    return config;
});
//#endregion

export * from "./Utils";