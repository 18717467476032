import React from "react";
import * as G from "../Grid";
import * as F from "../../Form";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as R from "../../../reducers";
import * as S from "../../../services";
import { useDispatch } from "react-redux";
import { CellsTypes as CT } from "../AgGridDefs";
import { FP, T, TABS, TC } from "../../../Constants";

type Row = ReturnType<T.API.Utils.Missions.GetMissionLogs>[number];

export const Mission: React.FC = () => {
    const lg = H.useLanguage();
    const dispatch = useDispatch();
    const [, mission_id] = H.useRoots();
    const loading = H.useBoolean(false);
    const [rows, set_rows, status] = H.useAsyncState<Row[]>([]);
    const [{ userId, isAdmin }] = H.useAuth({ onlyAdmin: true });

    React.useEffect(() => {
        let isSubscribed = true;
        S.getMissionLogs(null)
            .then(({ data }) => isSubscribed && set_rows(data, "done"))
            .catch(() => isSubscribed && set_rows([], "error"));
        return () => {
            isSubscribed = false;
            set_rows([], "load");
        }
    }, [set_rows]);

    const tr_rows = React.useMemo(() => rows.map(r => ({
        ...r,
        tr_type: lg.getStaticText(F.Missions.MissionOptions.type.find(t => t.value === r.type)?.label),
    })), [rows, lg]);

    const on_inline_change = React.useCallback<G.TableProps<Row>["onValueChange"]>(params => {
        let row = params.data,
            old_value = params.oldValue,
            field = params.colDef.field as keyof typeof tr_rows[number];

        let prop: keyof T.Mission;
        if (field === "tags_names") {
            prop = "tags";
            old_value = row.tags;
        }
        else prop = field as typeof prop;

        const updatePromise = new Promise<Row | "canceled">((resolve, reject) => {
            let api_params = {
                field: prop,
                _id: row._id,
                old_value: old_value,
                new_value: params.newValue,
            } as Parameters<typeof S.edit_mission_field>[0];

            loading.setTrue();
            S.edit_mission_field(api_params).then(({ data }) => {
                if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                    if (confirmed) S.edit_mission_field({ ...api_params, force_update: true }).then(({ data }) => {
                        if (data === "changed") reject("Error");
                        else resolve(data);
                    }).catch(reject);
                    else resolve("canceled");
                });
                else resolve(data);
            }).catch(reject);
        });

        updatePromise.then(rows => {
            if (rows !== "canceled") set_rows(p => p.map(r => r._id === row._id ? rows : r));
        })
            .catch(M.Alerts.updateError)
            .finally(loading.setFalse);
    }, [loading, set_rows]);

    const get_options = React.useMemo(() => ({
        tags: (row: Row) => new Promise<T.Option[]>((resolve, reject) => {
            if (row) S.getNoteTags({ context: { roots: row._id }, type: "mission" })
                .then(r => resolve(r.data))
                .catch(reject);
            else resolve([]);
        }),
        create_tag: (text: string) => new Promise<T.Option>((resolve, reject) => {
            let submission = { name: text, users: [userId], sites: [], type: "mission" } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
    }), [userId]);

    const check_edit_possible = React.useCallback<G.TableProps<Row>["onCellEditingStarted"]>(event => {
        let field = event.colDef.field as keyof typeof tr_rows[number];
        // Trying to edit a name field
        if (field === "billed_date" && !isAdmin) event.api.stopEditing(true);
    }, [isAdmin]);

    const columns = React.useMemo<G.TableProps<Row>["columns"]>(() => [
        {
            field: "select_mission",
            headerName: " ",
            type: CT.TYPE_ACTION_BUTTON,
            params: {
                isHidden: row => !row || !!row.deleted,
                isDisabled: row => !row || row._id === mission_id,
                action: row => row && dispatch(R.selectContextMission({ asset: row.asset, mission: row._id, path: row.asset_path })),
                buttonProps: row => row && row._id === mission_id
                    ? { icon: "check", size: "sm", variant: "success" }
                    : { icon: "arrow-right", size: "sm", variant: "primary" },
            }
        },
        { field: "createdAt", headerName: TC.GLOBAL_CREATED_DATE, type: CT.TYPE_DATE },
        { field: "deleted", headerName: TC.DATASET_COL_DELETE_DATE, type: CT.TYPE_DATE },
        { field: "deleted_by_name", headerName: TC.DATASET_COL_DELETE_USER },
        { field: "tr_type", headerName: TC.MISSION_TYPE },
        { field: "owner_name", headerName: TC.CAPEX_TABLE_OWNER },
        { field: "final", headerName: TC.REPORT_FINAL_VERSION, type: CT.TYPE_CHECKBOX },
        { field: "billed_date", headerName: TC.MISSION_BILLED_DATE, type: CT.TYPE_DATE, editable: true },
        {
            editable: true,
            field: "tags_names",
            type: CT.TYPE_SELECT,
            headerName: TC.GLOBAL_TAGS,
            valueParser: (value) => value.oldValue,
            params: {
                multiple: true,
                field_value: "tags",
                getValues: get_options.tags,
                typeahead: { allowNewOption: true, onAddOption: get_options.create_tag },
            }
        },
        { field: "client", headerName: FP.CLIENT_FORM },
        { field: "site", headerName: FP.SITE_FORM },
        { field: "building", headerName: FP.BUILDING_FORM },
        { field: "ref_provider", headerName: TC.MISSION_PROV_REF },
        { field: "ref_client", headerName: TC.MISSION_CLIENT_REF },
    ], [get_options, mission_id, dispatch]);

    return <div className="w-100">
        <C.Spinner error={status === "error"}>
            <G.Table<Row>
                sideBar
                autoFit
                rows={tr_rows}
                status={status}
                columns={columns}
                loading={loading.value}
                getRowId={r => r.data._id}
                columns_base="all_but_edit"
                onValueChange={on_inline_change}
                adaptableId={TABS.MISSION_LOG_ADMIN}
                onCellEditingStarted={check_edit_possible}
            />
        </C.Spinner>
    </div>;
}