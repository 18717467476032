import React from "react";
import { T, TB } from "../../../Constants";
import { InputProps, ComponentWrapper, Input } from "../Input";

export type WellProps = InputProps & {
    components?: T.FormComponentsMain[],
};

const Well: React.FC<WellProps> = ({ components, onChange, ...props }) => {

    const vComponents = React.useMemo(() => TB.getArray(components), [components]);

    return <ComponentWrapper {...props}>
        <div className="border rounded p-3 bg-white">
            {vComponents.map((c, i, a) => <Input
                {...c}
                key={c.key}
                prop={c.key}
                formId={props.formId}
                lock_form={props.lock_form}
                contextKey={props.contextKey}
                unlock_form={props.unlock_form}
                submissionId={props.submissionId}
                noBottomMargin={i === a.length - 1}
                set_translation={props.set_translation}
                onAddCertification={props.onAddCertification}
                onChange={(v, p) => onChange?.(v, p || c.key)}
            />)}
        </div>
    </ComponentWrapper>
}

Well.displayName = "Well";
export default Well;