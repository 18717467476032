import { TB, T } from "../../Constants";

export const getHeader = (requestType: T.RequestType = "READ") => ({ headers: authHeader(requestType) });
export const authHeader = (requestType: T.RequestType = "READ") => ({ request_type: TB.getString(requestType, "READ") });

export const get_user_id = (): string => {
    try {
        const userStr = localStorage.getItem("formioUser");
        const user = JSON.parse(userStr);
        if (user) return user?._id || "";
        else return "";
    }
    catch (error) {
        return "";
    }
};

export const get_mission_id = (): string => sessionStorage.getItem("mission_id") || "";