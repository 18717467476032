import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import { useNavigate } from "react-router-dom";

const ReglementationDashboard: React.FC<{ building: string }> = props => {
    const lg = H.useLanguage();
    const navigate = useNavigate();
    const [html, set_html, status] = H.useAsyncState("");

    React.useEffect(() => {
        let isSubscribed = true;
        S.html_report(({ template: "Reg_Doc_Presence", params: { building: props.building, lang: lg.prop } }))
            .then(({ data }) => isSubscribed && set_html(data, "done"))
            .catch(() => isSubscribed && set_html("", "error"));
        return () => {
            isSubscribed = false;
            set_html("", "load");
        }
    }, [set_html, props.building, lg.prop]);

    React.useEffect(() => {
        let cells = document.querySelectorAll<HTMLTableCellElement>("td.cell-link");
        cells.forEach(cell => {
            let url_params = cell.dataset?.url;
            cell.onclick = () => navigate(`/table/reg?${url_params}`);
        });
    });

    return <C.Spinner status={status} min_load_size="20vh">
        <div className="bg-white text-dark p-2 mb-3 rounded">
            <C.HtmlText html={html} />
        </div>
    </C.Spinner>;
};

export default ReglementationDashboard;